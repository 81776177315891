import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Form, Switch, Select } from 'antd';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';

import api from '../../../../api';
import {
  checkEmailPattern,
  checkPhonePattern,
  onDisabledSaveButton,
} from '../../../../utils';
import { getStates } from '../../actions';

import {
  selectCountries,
  selectStates,
  selectOrganizationSizes,
  selectCompanyIndustries,
} from '../../selectors';
import { selectUser } from '../../../pages/Login/selectors';

import { FormInput } from '../../../../components';
import { AreYouWantToCancel, CustomModal } from '../../components';

import styles from './AddCompany.module.scss';

let formInput_1 = React.createRef();
let formInput_2 = React.createRef();
let formInput_3 = React.createRef();
let formInput_4 = React.createRef();
let formInput_5 = React.createRef();
let formInput_6 = React.createRef();
let formInput_7 = React.createRef();
let formInput_8 = React.createRef();
let formInput_9 = React.createRef();
let formInput_10 = React.createRef();
let formInput_11 = React.createRef();
let formInput_12 = React.createRef();
let formInput_13 = React.createRef();
let formInput_14 = React.createRef();

const validatePhone = (rule, value, callback) => {
  if (value && !checkPhonePattern(value)) {
    callback('Invalid phone number');
  }
  callback();
};

const validateEmail = (rule, value, callback) => {
  if (value && !checkEmailPattern(value)) {
    callback('Invalid email');
  }
  callback();
};

const companyInformationTemplate = [
  {
    fieldName: 'companyName',
    ref: formInput_1,
    label: 'Company Name*',
    rules: [
      {
        message: ' ',
        type: 'string',
        required: true,
      },
      {
        max: 250,
        message: ' ',
      },
    ],
  },
  {
    fieldName: 'email',
    ref: formInput_2,
    label: 'Email',
    rules: [
      {
        message: ' ',
        type: 'email',
      },
      {
        validator: validateEmail,
      },
    ],
  },
  {
    fieldName: 'phone',
    ref: formInput_3,
    label: 'Phone Number',
    validateTrigger: ['onSubmit'],
    mask: '(999) 999-9999',
    rules: [
      {
        message: ' ',
      },
      {
        validator: validatePhone,
      },
    ],
  },
  {
    fieldName: 'address_1',
    ref: formInput_4,
    label: 'Address 1',
    rules: [
      {
        message: ' ',
      },
      {
        max: 250,
        message: ' ',
      },
    ],
  },
  {
    fieldName: 'address_2',
    ref: formInput_5,
    label: 'Address 2',
    rules: [
      {
        message: ' ',
        required: false,
      },
      {
        max: 250,
        message: ' ',
      },
    ],
  },
  {
    fieldName: 'zip',
    ref: formInput_6,
    label: 'Zip',
    rules: [
      {},
      {
        max: 250,
        message: ' ',
      },
    ],
  },
  {
    fieldName: 'country',
    type: 'select',
    forRole: 'ADMIN',
    ref: formInput_7,
    label: 'Country*',
    rules: [
      {
        message: ' ',
        required: true,
      },
      {},
    ],
  },
  {
    fieldName: 'state',
    type: 'select',
    forRole: 'ADMIN',
    ref: formInput_8,
    label: 'State*',
    rules: [
      {
        message: ' ',
        required: true,
      },
      {},
    ],
  },
  {
    fieldName: 'size',
    type: 'select',
    forRole: 'ADMIN',
    ref: formInput_9,
    label: 'Organization size*',
    rules: [
      {
        message: ' ',
        required: true,
      },
      {},
    ],
  },
  {
    fieldName: 'industry',
    type: 'select',
    forRole: 'ADMIN',
    ref: formInput_10,
    label: 'Company industry*',
    rules: [
      {
        message: ' ',
        required: true,
      },
      {},
    ],
  },
];

const contactDataTemplate = [
  {
    fieldName: 'firstName',
    ref: formInput_11,
    label: 'First Name*',
    rules: [
      {
        message: ' ',
        type: 'string',
        required: true,
      },
      {
        max: 250,
        message: ' ',
      },
    ],
  },
  {
    fieldName: 'lastName',
    ref: formInput_12,
    label: 'Last Name*',
    rules: [
      {
        message: ' ',
        type: 'string',
        required: true,
      },
      {
        max: 250,
        message: ' ',
      },
    ],
  },
  {
    fieldName: 'contactEmail',
    ref: formInput_13,
    label: 'Email*',
    rules: [
      {
        message: ' ',
        required: true,
        type: 'email',
      },
      {
        validator: validateEmail,
      },
    ],
  },
  {
    fieldName: 'contactPhone',
    ref: formInput_14,
    label: 'Phone Number',
    mask: '(999) 999-9999',
    rules: [
      {
        message: ' ',
        required: false,
      },
      {
        validator: validatePhone,
      },
    ],
  },
];

const sections = [
  {
    sectionId: 'companyInformation',
    sectionTitle: 'Company Information',
    sectionTemplate: companyInformationTemplate,
  },
  {
    sectionId: 'accountOwnerData',
    sectionTitle: 'Account Owner',
    sectionTemplate: contactDataTemplate,
  },
];

class AddCompany extends PureComponent {
  state = {
    cancelModal: false,
    firstAttempt: true,
    focusedInputName: '',
    initialPopUpData: null,
    isSaveButtonDisabled: true,
    showValidation: true,
    shareAccess: false,
    disabledSaveButtonFields: [
      'firstName',
      'lastName',
      'contactEmail',
      'companyName',
      'country',
      'size',
      'industry',
    ],
  };

  componentDidMount() {
    const {
      currentCompany,
      form,
      isDetails,
      noRefPopUp,
      isMainAdmin,
      onGetStates,
      states,
      isNotModal,
    } = this.props;

    const {
      name,
      email,
      phone,
      address1,
      address2,
      zipCode,
      countryId,
      stateId,
      companySizeTierId,
      companyIndustryId,
      shareAccess,
    } = currentCompany;

    if (isMainAdmin && isDetails && countryId) {
      this.getStates(countryId);
    } else if (!countryId && states.length) {
      onGetStates([]);
    }

    if (!noRefPopUp) {
      this.props.onRef(this);
    }

    const fieldsValue = {
      companyName: isDetails ? name : '',
      email: isDetails ? email : '',
      phone: isDetails ? phone : '',
      address_1: isDetails ? address1 : '',
      address_2: isDetails ? address2 : '',
      zip: isDetails ? zipCode : '',
    };

    if (!isNotModal) {
      fieldsValue.country =
        isDetails && countryId ? countryId.toString() : undefined;
      fieldsValue.state = isDetails && stateId ? stateId.toString() : undefined;
      fieldsValue.size =
        isDetails && companySizeTierId
          ? companySizeTierId.toString()
          : undefined;
      fieldsValue.industry =
        isDetails && companyIndustryId
          ? companyIndustryId.toString()
          : undefined;
    }

    form.setFieldsValue(fieldsValue);

    const initialPopUpData = {
      ...fieldsValue,
      shareAccess,
    };
    this.setState({ initialPopUpData, shareAccess });
  }

  componentWillUnmount() {
    this.props.onRef(undefined);
  }

  componentDidUpdate() {
    const { initialPopUpData, disabledSaveButtonFields } = this.state;
    const { states } = this.props;

    const newPopUpData = this.getCurrentData();

    const isInputsChanged =
      JSON.stringify(initialPopUpData) !== JSON.stringify(newPopUpData);
      
    if (isInputsChanged) {
      this.setState({ isSaveButtonDisabled: false });
    } else {
      this.setState({ isSaveButtonDisabled: true });
    }

    if (states.length && !disabledSaveButtonFields.includes('state')) {
      this.setState({
        disabledSaveButtonFields: [...disabledSaveButtonFields, 'state'],
      });
    }
  }

  getCurrentData = () => {
    const { form, isDetails, isNotModal } = this.props;
    const { shareAccess } = this.state;

    const {
      companyName,
      email,
      phone,
      address_1,
      address_2,
      zip,
      firstName,
      lastName,
      contactEmail,
      contactPhone,
      country,
      state,
      size,
      industry,
    } = form.getFieldsValue();

    const data = {
      companyName,
      email,
      phone,
      address_1,
      address_2,
      zip,
    };

    if (!isNotModal) {
      data.country = country;
      data.state = state;
      data.size = size;
      data.industry = industry;
    }

    if (!isDetails) {
      data.firstName = firstName;
      data.lastName = lastName;
      data.contactEmail = contactEmail;
      data.contactPhone = contactPhone;
    }

    return {
      ...data,
      shareAccess,
    };
  };

  onEditClick = (ref, fieldName) => {
    if (fieldName !== this.state.focusedInputName) {
      this.setState({ focusedInputName: fieldName });
      ref.current.disabled = false;
    }
    return ref.current.focus();
  };

  onSubmit = (e) => {
    const {
      currentCompany,
      form,
      isDetails,
      modalClose,
      sendNotification,
      isNotModal,
    } = this.props;
    const { shareAccess } = this.state;
    const {
      companyName,
      email,
      phone,
      address_1,
      address_2,
      zip,
      firstName,
      lastName,
      contactEmail,
      contactPhone,
      country,
      state,
      size,
      industry,
    } = form.getFieldsValue();
    e.preventDefault();
    this.setState({ firstAttempt: false });
    form.validateFields((err) => {
      if (!err) {
        const successfulText = isDetails
          ? 'Company was successfully changed'
          : 'Company was successfully added';

        const currentApi = (data) =>
          isDetails
            ? api.companies.updateCompany(data)
            : api.companies.addCompany(data);

        const data = {
          active: true,
          address1: address_1 || '',
          address2: address_2 || '',
          email: email || '',
          id: isDetails ? currentCompany.id : null,
          name: companyName || '',
          phone: phone || '',
          zipCode: zip || '',
          shareAccess: shareAccess || false,
        };

        if (!isNotModal) {
          data.countryId = +country;
          data.stateId = +state;
          data.companySizeTierId = +size;
          data.companyIndustryId = +industry;
        }

        if (!isNotModal && !isDetails) {
          data.accountOwnerData = {
            firstName,
            lastName,
            email: contactEmail,
            phoneNumber: contactPhone || '',
          };
        }

        currentApi(data)
          .then(() => {
            sendNotification(successfulText);
            if (!isNotModal) {
              modalClose(true);
            } else {
              this.setState({
                isSaveButtonDisabled: true,
                initialPopUpData: this.getCurrentData(),
              });
            }
          })
          .catch((res) => {
            const eroorMessage = res.response.data
              ? Object.values(res.response.data).join(', ')
              : res.message;
            sendNotification(eroorMessage.includes('on unique user name')?'Non unique user name':eroorMessage, false);
          });
      } else {
        this.setState({ showValidation: true });
      }
    });
  };

  onToggleShareAccess = () =>
    this.setState((prev) => ({ shareAccess: !prev.shareAccess }));

  onCancelHandle = () => {
    const { initialPopUpData, shareAccess } = this.state;
    const { form, modalClose } = this.props;
    const {
      companyName,
      email,
      phone,
      address_1,
      address_2,
      zip,
      contactName,
      contactEmail,
      contactPhone,
      country,
      state,
      size,
      industry,
    } = form.getFieldsValue();

    const newPopUpData = {
      companyName,
      email,
      phone,
      address_1,
      address_2,
      zip,
      country,
      state,
      size,
      industry,
      contactName,
      contactEmail,
      contactPhone,
      shareAccess,
    };

    const isPopUpDataEqual =
      JSON.stringify(initialPopUpData) === JSON.stringify(newPopUpData);

    if (!isPopUpDataEqual) {
      this.setState({ cancelModal: true });
    } else {
      modalClose();
    }
  };

  onChangeSelect = (fieldName, val) => {
    if (fieldName === 'country') {
      this.getStates(+val);
    }
  };

  renderSelectOption = (id, value) => (
    <Select.Option value={`${id}`} key={id}>
      {value}
    </Select.Option>
  );

  getSelectOptions = (fieldName, selectsData) => {
    switch (fieldName) {
      case 'country':
        return selectsData.map(({ countryId, countryName }) =>
          this.renderSelectOption(countryId, countryName)
        );
      case 'state':
        return selectsData.map(({ stateId, stateName }) =>
          this.renderSelectOption(stateId, stateName)
        );
      case 'size':
        return selectsData.map(({ id, companySizeTier }) =>
          this.renderSelectOption(id, companySizeTier)
        );
      case 'industry':
        return selectsData.map(({ id, industryName }) =>
          this.renderSelectOption(id, industryName)
        );
      default:
        return null;
    }
  };

  getStates = (country) => {
    this.setState({ isLoading: true });

    const { countries, onGetStates } = this.props;

    const response = countries.find(({ countryId }) => countryId === country);

    onGetStates(response ? response.stateDtoList : []);

    this.setState({ isLoading: false });
  };

  render() {
    const {
      cancelModal,
      firstAttempt,
      focusedInputName,
      isSaveButtonDisabled,
      showValidation,
      shareAccess,
      disabledSaveButtonFields,
    } = this.state;
    const {
      form,
      isDetails,
      modalClose,
      isNotModal,
      shared,
      isNotAccountOwner,
      countries,
      states,
      organizationSizes,
      companyIndustries,
      isMainAdmin,
      currentCompany: { stateId },
      user: { roles = [] },
    } = this.props;

    const { getFieldDecorator, getFieldError, getFieldValue } = form;

    const isAccountManager =
      roles.includes('ACCOUNT MANAGER') ||
      roles.includes('ACCOUNT OWNER/MANAGER');

    const selectsData = {
      country: countries,
      state: states,
      size: organizationSizes,
      industry: companyIndustries,
    };

    const isSubmitDisable =
      onDisabledSaveButton(
        form,
        disabledSaveButtonFields,
        firstAttempt ? true : false
      ) || isSaveButtonDisabled;
      
    return (
      <section className={styles.wrapper}>
        <h2 className={styles.title}>
          {isDetails ? 'Company Details' : 'Add Company'}
        </h2>
        <form id='company-data' onSubmit={this.onSubmit}>
          <div className={isNotModal ? styles.notModal : ''}>
            {sections.map(
              (section) =>
                !(isDetails && section.sectionId === 'accountOwnerData') && (
                  <div
                    className={`${styles.sectionWrap} ${
                      isNotModal ? styles.notModalBlock : ''
                    }`}
                    key={`section-${section.sectionTitle}`}>
                    <h3 className={styles.titleSection}>
                      {section.sectionTitle}
                    </h3>
                    {section.sectionTemplate.map((item) =>
                      !(!isMainAdmin && item.forRole === 'ADMIN') &&
                      !(
                        item.fieldName === 'state' &&
                        !selectsData[item.fieldName].length &&
                        !stateId
                      ) ? (
                        <div
                          className={classnames(
                            styles.inputSection,
                            getFieldValue(item.fieldName)
                              ? styles.usersSelectHasValue
                              : ''
                          )}
                          key={`company-information-${item.fieldName}`}>
                          {getFieldDecorator(item.fieldName, {
                            rules: item.rules,
                          })(
                            item.type === 'select' ? (
                              <Select
                                disabled={isAccountManager}
                                placeholder={item.label}
                                showArrow
                                dropdownClassName={styles.selectDropdown}
                                onChange={(val) =>
                                  this.onChangeSelect(item.fieldName, val)
                                }
                                suffixIcon={
                                  <span className={styles.selectIcon} />
                                }>
                                {this.getSelectOptions(
                                  item.fieldName,
                                  selectsData[item.fieldName]
                                )}
                              </Select>
                            ) : (
                              <FormInput
                                className={`${styles.formInput}`}
                                disabled={
                                  isDetails &&
                                  item.fieldName !== focusedInputName
                                }
                                error={
                                  showValidation &&
                                  getFieldError(item.fieldName)
                                }
                                inputRef={item.ref}
                                mask={item.mask}
                                onFocus={() =>
                                  this.onEditClick(item.ref, item.fieldName)
                                }
                                onBlur={() =>
                                  this.setState({ focusedInputName: '' })
                                }
                                label={item.label}
                                onChange={() =>
                                  this.setState({ showValidation: false })
                                }
                                isFocusedInput={
                                  focusedInputName === item.fieldName
                                }
                              />
                            )
                          )}
                          {(isNotModal && item.fieldName === 'companyName') ||
                          isAccountManager
                            ? null
                            : isDetails &&
                              item.type !== 'select' && (
                                <button
                                  type='button'
                                  className={styles.editBtn}
                                  onClick={() =>
                                    this.onEditClick(item.ref, item.fieldName)
                                  }>
                                  <FontAwesomeIcon icon={faPen} />
                                </button>
                              )}
                        </div>
                      ) : null
                    )}
                  </div>
                )
            )}
          </div>
          {shared || isNotAccountOwner ? null : (
            <div className={styles.shareAccessBlock}>
              <h3>Share Access</h3>
              <div className={styles.shareAccessContainer}>
                <div className={styles.shareAccessTextContainer}>
                  <span className={styles.shareAccessDefText}>
                    Use this toggle to share access to your data with ALL
                    MindWire Administrators. Enabling this feature will allow us
                    to provide your company with the help and support needed
                    when using the dashboards platform
                  </span>
                </div>
                <span className={styles.shareAccessText}>Share Access</span>
                <Switch
                  checked={shareAccess}
                  onChange={this.onToggleShareAccess}
                />
              </div>
            </div>
          )}
          <button className={styles.visuallyHidden} />
        </form>
        <div className={isNotModal ? styles.btnWrapSingle : styles.btnWrap}>
          <button
            className={styles.btnSave}
            onClick={this.onSubmit}
            disabled={isSubmitDisable || isAccountManager}>
            {isDetails ? 'Save' : 'Add'}
          </button>
          {isNotModal ? null : (
            <button className={styles.btnNo} onClick={this.onCancelHandle}>
              Cancel
            </button>
          )}
        </div>
        {isNotModal ? null : (
          <CustomModal visible={cancelModal} className={styles.modalWrap}>
            <AreYouWantToCancel
              onNo={() => this.setState({ cancelModal: false })}
              onYes={() => modalClose()}
            />
            ;
          </CustomModal>
        )}
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  countries: selectCountries(state),
  states: selectStates(state),
  organizationSizes: selectOrganizationSizes(state),
  companyIndustries: selectCompanyIndustries(state),
  user: selectUser(state),
});

const mapDispatchToProps = (dispatch) => ({
  onGetStates: (data) => dispatch(getStates(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(AddCompany));
