import React, { useState, useEffect } from 'react';
import styles from './DatePickerCell.module.scss';
import { DatePicker } from 'antd';
import moment from 'moment';
import { DatePickerActions } from './DatePickerActions';
import api from '../../../api';
import { useDispatch, useSelector } from 'react-redux';
import {selectDatePickerOpened} from '../../../redux/pages/Perfomance/selectors'
import {toggleDatePicker} from '../../../redux/pages/Perfomance/actions'

import {faPencilAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const DatePickerCell = ({  value,
  name,
  record,
  onlyView,
  type,
  sendNotification,
  minDate,
  maxDate,
  updateUser, dashboardId,isSuccession }) => {
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState('');
  const isOpened = useSelector(selectDatePickerOpened); 
  const dispatch = useDispatch();

  const isHireDate = type === 'hireDate';
  const dateFormat = 'MM-DD-YYYY';

  const {
    subHeaderRow,
    id,
    employeeStatus,
    currentJob,
    division
  } = record;
 
  const handleTogglePicker = () => {
    dispatch(toggleDatePicker(!open))
    setOpen(prev => !prev);
  }

  useEffect(() => {
    if(!isOpened) setOpen(isOpened);
      }, [isOpened])

  const handleSetDate = (isClear = false) => {
    const secondType = isHireDate ? 'terminationDate' : 'hireDate';

    api.employees
      .updateDates([{
        id,
        [type]: isClear ? null : date || moment(new Date(), dateFormat),
        [secondType]: isHireDate ? maxDate || null : minDate || null
      }], dashboardId)
      .then(() => api.employees.getEmployee(id))
      .then((user) => {
        updateUser({ user, currentJob: currentJob.title, division: isSuccession ? 'No division' : division, userId: id })
      })
      .then(() => {
        sendNotification(
          `${name} date was successfully ${value ? 'updated' : 'added'}`,
          true
        );
        setOpen(false);
      })
      .catch((e) => {
        console.log(e)
        const message =
          employeeStatus === 'AP'
            ? 'Impossible to set a termination date for the applicant'
            : "The date isn't updating";

        sendNotification(message, false);
      });
  };

  const handleChangeDate = (e) => setDate(e.toISOString(true))

  const handleOpenChange = (value) => {
    if (!value) return handleTogglePicker();
    return;
  }

  const disabledDate = (current) => {
    if (maxDate && minDate) return current < moment(minDate) && current > moment(maxDate);
    if (maxDate) return current > moment(maxDate);
    if (minDate) return current < moment(minDate);
    return false;
  };

  return(
    <div className={styles.wrapper}>
      {subHeaderRow
        ? null
        : onlyView ? (
          <div className={styles.pointer} style={{cursor: onlyView ? 'default' : 'pointer'}}>
            <span className={styles.noWrap}>{value ? moment(value).format(dateFormat) : '-'}</span>
          </div>
        ) : (
          <div className={styles.pointer}>
            {open ? (
              <div>
                <DatePicker
                  allowClear
                  showToday={false}
                  disabledDate={disabledDate}
                  autoFocus
                  showTime
                  open={open}
                  defaultValue={
                    value
                      ? moment(moment(value).format(dateFormat), dateFormat)
                      : moment(new Date(), dateFormat)
                  }
                  format={dateFormat}
                  renderExtraFooter={() =>
                    <DatePickerActions
                      className={styles.pickerActions}
                      handleClear={() => handleSetDate(true)}
                      handleSelect={() => handleSetDate(false)}
                    />}
                  onChange={handleChangeDate}
                  onOpenChange={handleOpenChange}
                />
              </div>
            )
              : (
                <>
                  <span className={styles.noWrap}>{value ? moment(value).format(dateFormat) : '-'}</span>
                  <div className={styles.iconPencil} onClick={handleTogglePicker}>
                    <FontAwesomeIcon icon={faPencilAlt}/>
                  </div>
                </>
              )}
          </div>
        )}
    </div>
  )
};
