import React from 'react';
import InputMask from 'react-input-mask';

import { IconButton } from '../../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEye,
  faEyeSlash,
} from '@fortawesome/free-solid-svg-icons';
import styles from './FormInput.module.scss';

class FormInput extends React.PureComponent {
  id = Date.now() - Math.floor(Math.random() * 1000);
  state = {
    showPassword: false,
  };

  getFieldType = () => {
    const { showPassword } = this.state;
    const { type } = this.props;
    if (!showPassword) {
      return type || 'text';
    }

    return 'text';
  };

  handleOnBlur = e => {
    const { onBlur } = this.props;
    if (onBlur) {
      onBlur(e);
    }
  };

  onEyeClick = () =>
    this.setState(prevState => ({ showPassword: !prevState.showPassword }));

  render() {
    const {
      autocomplete,
      className,
      disabled,
      error,
      inputRef,
      label,
      onChange,
      onFocus,
      onKeyPress,
      mask,
      maskChar = "_",
      name,
      readOnly,
      type = 'text',
      value,
      isFocusedInput,
      defaultValue,
      passwordFieldStyle,
    } = this.props;
    const { showPassword } = this.state;

    return (
      <div
        className={`
        ${styles.labelWrapper}
        ${className ? className : ''}
      `}>
        <div
          className={`
          ${styles.inputWrapper}
          ${type === "password" ? styles.passwordInputWrapper : ''}
        `}>
          <label
            htmlFor={this.id}
            className={`
            ${styles.inputLabeled} ${ (value || defaultValue ) || isFocusedInput ? styles.fieldActive : ''}`}
          >
            {label}
          </label>
          {mask 
            ? <InputMask
              autoComplete={autocomplete || 'new-password'}
              className={`${styles.inputTxt} ${passwordFieldStyle && !showPassword ? styles.passwordStyle : ''}`}
              id={this.id}
              type={this.getFieldType()}
              value={value}
              onChange={onChange}
              onBlur={this.handleOnBlur}
              onFocus={onFocus}
              readOnly={readOnly}
              onKeyPress={onKeyPress}
              mask={mask}
              maskChar={maskChar}
              defaultValue={defaultValue}
              name={name}
              disabled={disabled}
            >{inputProps => <input disabled={disabled} ref={inputRef} {...inputProps} />}</InputMask>  
            : <input
              autoComplete={autocomplete || 'new-password'}
              className={`${styles.inputTxt} ${passwordFieldStyle && !showPassword ? styles.passwordStyle : ''}`}
              id={this.id}
              type={this.getFieldType()}
              value={value}
              onChange={onChange}
              onBlur={this.handleOnBlur}
              onFocus={onFocus}
              readOnly={readOnly}
              onKeyPress={onKeyPress}
              disabled={disabled}
              ref={inputRef}
              name={name}
              defaultValue={defaultValue}
            />
          }        
          {(type === 'password' || passwordFieldStyle) && (
            <IconButton
              tabIndex="-1"
              icon={
                <FontAwesomeIcon
                  className={styles.viewPasswordIcon}
                  icon={showPassword ? faEye : faEyeSlash}
                />
              }
              onClick={this.onEyeClick}
              className={styles.viewIconBtn}
            />
          )}
        </div>
        {error && (
          <div className={styles.messageError}>
            {error.map((errorItem, id) => (
              <span key={id}>{errorItem}</span>
            ))}
          </div>
        )}
      </div>
    );
  }
}

export default FormInput;
