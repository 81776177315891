export const parseString = (string) =>
  string
    .toLowerCase()
    .replace(
      /([a-z])(\w+)/g,
      (m, match1, match2) => `${match1.toUpperCase()}${match2}`
    );

export const capitalize = (string) => {
  if (!string) return string;

  const lowerCaseString = string.toLowerCase().replace(/\s\s+/g, ' ');

  const words = lowerCaseString.split(' ');

  return words
    .map((word) => {
      const first = word.charAt(0);

      return first.toUpperCase() + word.slice(1, word.length);
    })
    .join(' ');
};

export const compareStrings = (str1 = '', str2 = '') =>
  str1.toLowerCase() === str2.toLowerCase();
