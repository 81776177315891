import React from 'react';
import styles from './DashboardActions.module.scss';
import {faDownload, faPen, faLink} from '@fortawesome/free-solid-svg-icons';
import {faCopy, faTrashCan} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'antd';

const getActions = (employeeSetId, roles, isOwned) => {
  return [
    {
      icon: faDownload,
      title: '.xlsx',
      text: '.XLSX',
      tooltip: false,
      tooltipText: '',
      disabled: !employeeSetId
    },
    {
      icon: faLink,
      title: 'link',
      text: 'LINK',
      tooltip: true,
      tooltipText: 'click to generate a link to this dashboard and copy it to the clipboard',
      disabled: false
    },
    {
      icon: faCopy,
      title: 'copy',
      text: '',
      tooltip: false,
      tooltipText: '',
      disabled: false
    },
    {
      icon: faTrashCan,
      title: 'remove',
      text: '',
      tooltip: false,
      tooltipText: '',
      disabled: !isOwned && roles.includes('ACCOUNT OWNER')
    }
  ]
};

export const DashboardActions = ({
  toggleModal,
  downloadFileInFormat,
  selectedDashboard,
  toggleCreateLinkModal,
  toggleEditNameModal,
  toggleDeleteModal,
  isSuccession,
  roles
}) => {
  const handleAction = (value) => {
    const actionHandlers = {
      '.xlsx': () => downloadFileInFormat('XLSX'),
      link: () => toggleCreateLinkModal(),
      copy: () => toggleModal(false),
      edit: () => toggleEditNameModal(),
      remove: () => toggleDeleteModal(),
    }

    return actionHandlers[value]();
  }

  const handleActionClick = (e) => {
    e.stopPropagation();
    const { value } = e.currentTarget;

    return handleAction(value);
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.titleWrapper}>
        <span>{selectedDashboard.name || ''}</span>
        <button disabled={!selectedDashboard.isOwned && roles.includes('ACCOUNT OWNER')} value="edit" className={styles.editBtn} onClick={handleActionClick}>
          <FontAwesomeIcon icon={faPen}/>
        </button>
      </div>
      <div className={styles.actionsWrapper}>
        <div>
          {getActions(selectedDashboard.employeeSetId, roles, selectedDashboard.isOwned).map(({ icon, title, text, tooltip, tooltipText, disabled }) =>
            tooltip ? (
              <Tooltip
                key={title}
                title={
                  <span className={styles.tooltipOnButtonLink}>
                    {tooltipText}
                  </span>
                }
              >
                <div style={{display:'inline', cursor: disabled ? 'no-drop' : 'pointer' }}>
                <button
                  disabled={disabled}
                  value={title}
                  className={styles.actionsBtn}
                  onClick={handleActionClick}
                  style={{width: 'unset'}}
                >
                  <FontAwesomeIcon
                    className={styles.actionsImg}
                    icon={icon}
                    alt={title}
                  />
                <span style={{paddingBottom: text === 'LINK' ? '3px' : '0px' }}>{text}</span> 
                </button>
                </div>
              </Tooltip>
            ) : (
              <div style={{display:'inline', cursor: disabled ? 'no-drop' : 'pointer' }}>
              <button
                value={title}
                style={{cursor: disabled ? 'no-drop' : 'pointer', pointerEvents: disabled ? 'none' : 'auto', width: 'unset' }}
                //disabled={disabled}
                className={styles.actionsBtn}
                onClick={handleActionClick}
                key={title}
              >
                <FontAwesomeIcon
                    style={{filter: disabled ? 'grayscale(100%)' : 'grayscale(0%)', opacity: disabled ? '35%' : '100%' }}
                    className={styles.actionsImg}
                    icon={icon}
                    alt={title}
                />
                {text}
              </button>
              </div>
            )
          )}
        </div>
        <span className={styles.dashboardType}>Type: {isSuccession ? 'Succession (group by jobs)' : 'Performance (group by division)'}</span>
      </div>
    </div>
  )
}
