import React, { PureComponent } from "react";

import { FormInput } from "../../../../components";
import {
  AreYouWantToCancel,
  CustomModal,
} from "../../../admin-pages/components";

import styles from "./CreateDataSetModal.module.scss";

class CreateDataSetModal extends PureComponent {
  state = {
    isOpenModal: this.props.isOpen,
    dataSetName: "",
    inputFocused: false,
    cancelModal: false,
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.props.onCreate(this.state.dataSetName);
  };

  render() {
    const { dataSetName, inputFocused, cancelModal } = this.state;
    const { isOpen, onClose } = this.props;

    return (
      <CustomModal
        visible={isOpen}
        onCancel={() => {
          dataSetName ? this.setState({ cancelModal: true }) : onClose();
        }}
        className={styles.modalWrap}
      >
        <div className={styles.innerWrap}>
          <h2 className={styles.title}>Create Dataset</h2>
          <form onSubmit={(e) => e.preventDefault()} id="user-data">
            <FormInput
              className={styles.formInput}
              label={"Dataset name*"}
              onChange={(e) => this.setState({ dataSetName: e.target.value })}
              onFocus={() => this.setState({ inputFocused: true })}
              onBlur={() => this.setState({ inputFocused: false })}
              isFocusedInput={inputFocused || dataSetName}
            />
            <div className={styles.btnWrap}>
              <button
                className={styles.btnYes}
                disabled={!dataSetName}
                onClick={this.onSubmit}
              >
                Create
              </button>
              <button
                className={styles.btnNo}
                onClick={() => {
                  dataSetName
                    ? this.setState({ cancelModal: true })
                    : onClose();
                }}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
        {dataSetName && (
          <CustomModal className={styles.modalConfirm} visible={cancelModal}>
            <AreYouWantToCancel
              onNo={() => this.setState({ cancelModal: false })}
              onYes={() => {
                this.setState({ dataSetName: "", cancelModal: false });
                onClose();
              }}
            />
          </CustomModal>
        )}
      </CustomModal>
    );
  }
}

export default CreateDataSetModal;
