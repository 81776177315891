import React, { PureComponent } from 'react';
import { Radio, Select } from 'antd';

import { FormInput } from '../../../../components';
import {
  AreYouWantToCancel,
  CustomModal,
} from '../../../admin-pages/components';

import styles from './CreateDashBoardModal.module.scss';
import cn from 'classnames';

class CreateDashBoardModal extends PureComponent {
  state = {
    isOpenModal: this.props.isOpen,
    dashboardName: '',
    inputFocused: false,
    cancelModal: false,
    dashboardType: null,
    employeeSetId: null,
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.isOpen !== this.props.isOpen) {
      this.setState({isOpenModal: this.props.isOpen})
    }
  }

  render() {
    const {
      dashboardName,
      inputFocused,
      cancelModal,
      dashboardType,
      employeeSetId,
    } = this.state;
    const { isOpen, onClose, onCreate, dataSets, isCopy, selectedDashboard } = this.props;

    const isCreateDisabled = isCopy
      ? !dashboardName
      : !dashboardName || !employeeSetId || !dashboardType

    const onSubmit = (e) => {
      e.preventDefault();
      if (isCopy) {
        const { id, createdAt, updatedAt, isOwned, ...otherParams } = selectedDashboard
        onCreate({ ...otherParams, name: dashboardName })
      } else {
        const isSuccession = dashboardType !== 'performance';
        onCreate({
          employeeSetId,
          succession: isSuccession,
          name: dashboardName,
          filterDivisions: [],
          filterEmploymentsStatus: [],
          filterFactorComboMatch: [],
          filterFirstScore: [],
          filterJobs: [],
          filterOverallScore: [],
          filterCognitive: [],
          filterDivisionSupervisor: [],
          filterLocation: [],
          filterPerformance: [],
          filterPotential: [],
          filterRating: [],
          filterReadiness: [],
          filterTenure: [],
          displayBlocks: {
            behavioralPerformance: !isSuccession,
            behavioralSuccession: isSuccession,
            cognitive: true,
            competencies: isSuccession,
            currentSkills: !isSuccession,
            customSkills: false,
            employmentStatus: true,
            nineBox: isSuccession,
            organizationalData: true,
            performance: !isSuccession,
          },
        })
      }
      /*this.setState({ isOpenModal: false });*/
      /*onClose();*/
    };

    const closeModal = () => {
      this.setState({ employeeSetId: null, dashboardName: '', dashboardType: null })
      onClose()
    }

    return (
      <CustomModal
        visible={isOpen}
        onCancel={() => {
          (dashboardName || employeeSetId) ? this.setState({ cancelModal: true }) : closeModal();
        }}
        className={styles.modalWrap}
      >
        <div className={styles.innerWrap}>
          <h2 className={styles.title}>{isCopy ? 'Copy Dashboard' : 'Create Dashboard'}</h2>
          <form
            onSubmit={onSubmit}
            id="dashboard-data"
            className={styles.inputSection}
          >
            <FormInput
              className={styles.formInput}
              label={'Dashboard name*'}
              onChange={(e) => this.setState({ dashboardName: e.target.value })}
              onFocus={() => this.setState({ inputFocused: true })}
              onBlur={() => this.setState({ inputFocused: false })}
              isFocusedInput={inputFocused || dashboardName}
            />
            {!isCopy && (
              <>
                <div className={cn(!!employeeSetId && styles.datasetHasValues, styles.formSelect)}>
                  <Select
                    placeholder="Select Dataset*"
                    className={styles.formSelect111}
                    onChange={(val) => this.setState({ employeeSetId: val })}
                    suffixIcon={<span className={styles.selectIcon}/>}
                    disabled={!dataSets.length}
                    showArrow
                  >
                    {dataSets.map((dataset) => (
                      <Select.Option value={dataset.id} key={dataset.id}>
                        {dataset.name}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
                <Radio.Group
                  className={styles.formRadio}
                  onChange={(e) => this.setState({ dashboardType: e.target.value })}
                  value={dashboardType}
                >
                  <label className={styles.formRadioLabel}>
                    Set dashboard type
                  </label>
                  <div className={styles.formRadioItem}>
                    <Radio className={styles.radioBtn} value="performance">
                      Performance (group by divisions)
                    </Radio>
                  </div>
                  <div className={styles.formRadioItem}>
                    <Radio className={styles.radioBtn} value="succession">
                      Succession (group by jobs)
                    </Radio>
                  </div>
                </Radio.Group>
              </>
            )}
            <div className={styles.btnWrap}>
              <button
                type="submit"
                className={styles.btnYes}
                disabled={isCreateDisabled}
              >
                Create
              </button>
              <button
                type="button"
                className={styles.btnNo}
                onClick={() => {
                  dashboardName
                    ? this.setState({ cancelModal: true })
                    : closeModal();
                }}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
        {dashboardName && (
          <CustomModal className={styles.modalConfirm} visible={cancelModal}>
            <AreYouWantToCancel
              onNo={() => this.setState({ cancelModal: false })}
              onYes={() => {
                this.setState({ dashboardName: '', cancelModal: false });
                closeModal();
              }}
            />
          </CustomModal>
        )}
      </CustomModal>
    );
  }
}

export default CreateDashBoardModal;
