import React, { PureComponent } from "react";
import classnames from "classnames";
import { Tooltip } from "antd";

import { openNotificationWithIcon } from "../../../../utils";
import api from "../../../../api";

import styles from "./DatasetBadges.module.scss";

class DataSetBadges extends PureComponent {
  /* TODO: need to move datasets in props */
  state = {
    isLoading: false,
    dataSets: [],
    showMore: false,
    badgesInRow: 0,
  };
  badgesWrapperRef = React.createRef();
  firstBadgeRef = React.createRef();



  render() {
    const { datasets, selected, onChange, values } = this.props;

    return (
      <div className={styles.badgesWrap}>
        <div className={styles.badgesTitle}>Dataset</div>
        <div className={styles.badges} ref={this.badgesWrapperRef}>
          {datasets.map((dataset, index) => {
            return (
              <Tooltip key={dataset.id} title={dataset.name}>
                <button
                  className={classnames(
                    styles.badgesItem,
                    dataset.id === selected && styles.selected
                  )}
                  onClick={() => onChange(dataset.id)}
                  ref={index === 0 && this.firstBadgeRef}
                >
                  {dataset.name}
                </button>
              </Tooltip>
            );
          })}
          {!datasets.length && <div>No datasets available</div>}
        </div>
      </div>
    );
  }
}

export default DataSetBadges;
