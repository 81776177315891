import React from 'react';

import styles from './AreYouWantToCancel.module.scss';

const AreYouWantToCancel = ({ onYes, onNo, title, yesText, noText }) => (
  <section className={styles.wrapper}>
    <h3 className={styles.popUpTitle}>{title || 'Are you sure you want to cancel?'}</h3>
    <div className={styles.btnWrap}>
      <button className={styles.btnSaveConfirmModal} onClick={onYes}>{yesText || 'Yes'}</button>
      <button className={styles.btnNoConfirmModal} onClick={onNo}>{noText || 'No'}</button>
    </div>
  </section>
);

export default AreYouWantToCancel;
