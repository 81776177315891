import {
  CLEAR_EMPLOYEES_SUCCESS_VIEW,
  GET_EMPLOYEES_FORMAT_VIEW,
  GET_EMPLOYEES_SUCCESS_VIEW,
  LAST_UPDATE_CLEAR_VIEW,
  LAST_UPDATE_SUCCESS_VIEW,
} from '../../../constants/constants';

export const initialState = {
  divisions: [],
  employees: [],
  employeesFormat: [],
  employeeJobs: [],
  employeePerformance: [],
  employeePotential: [],
  employeeRatings: [],
  employeeReadiness: [],
  employeeStatuses: [],
  lastUpdate: [],
  piApiKey: '',
  activeFilters: null,
};

export const dashboardReducerView = (state = initialState, { data, type }) => {
  switch (type) {
    case CLEAR_EMPLOYEES_SUCCESS_VIEW:
      return {
        ...state,
        divisions: [],
        employees: {},
        employeesFormat: [],
        employeeJobs: [],
        employeeRatings: [],
        employeeStatuses: [],
        piApiKey: '',
        employeeLines9BoxData: null,
        activeFilterPerfomance: null,
        activeFilters: null,
      };
    case GET_EMPLOYEES_FORMAT_VIEW:
      return {
        ...state,
        employeesFormat: data,
      };
    case GET_EMPLOYEES_SUCCESS_VIEW:
      return {
        ...state,
        divisions: data.divisions,
        employees: data.employeeMap,
        employeeJobs: data.employeeJobs,
        employeePerformance: data.employeePerfomance,
        employeePotential: data.employeePotentials,
        employeeRatings: data.employeeRatings,
        employeeReadiness: data.employeeReadiness,
        employeeStatuses: data.employeeStatuses,
        piApiKey: data.piApiKey,
        employeeLines: data.employeeLines9BoxData,
        activeFilters: data.currentFilterLinkData
      };
    case LAST_UPDATE_SUCCESS_VIEW:
      return {
        ...state,
        lastUpdate: data,
      };
    case LAST_UPDATE_CLEAR_VIEW:
      return {
        ...state,
        lastUpdate: [],
      };
    default:
      return state;
  }
};
