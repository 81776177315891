import axios from 'axios';
import {openNotificationWithIcon, TOKEN_KEY} from '../utils';
import store from '../store';
import { logoutUser } from '../redux/pages/Login/actions';

const API_BASE_ADDRESS = process.env.REACT_APP_API_URL;

/*console.log("Domain:", API_BASE_ADDRESS)*/

const axiosInstance = axios.create({
  baseURL: API_BASE_ADDRESS,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use(
  config => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
      config.headers.authorization = `Bearer ${token}`;
    }

    return config;
  },
  error => Promise.reject(error),
);

axiosInstance.interceptors.response.use(
  response => response,
  error => {
    if (!error.response) {
      return Promise.reject(error);
    }
    if (error.response.status === 401) {
      store.dispatch(logoutUser());
    }
    if (error.response.status === 403) {
      if(localStorage.getItem(TOKEN_KEY)){
        openNotificationWithIcon({type: 'error', message:" error.response.data.message"})
      }
      store.dispatch(logoutUser());
    }

    return Promise.reject(error);
  },
);

export default {
  get: (url, config) => axiosInstance.get(url, config),
  post: (url, data, config) => axiosInstance.post(url, data, config),
  put: (url, data, config) => axiosInstance.put(url, data, config),
  delete: (url, data, config) => axiosInstance.delete(url, {data: {...data}, ...config}),
  patch: (url, data, config) => axiosInstance.patch(url, data, config),
};
