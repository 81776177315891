import React from 'react';
import { Route, Router, Redirect, HashRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';
import { Switch } from 'react-router';
import { browserHistory } from './browserHistory';
import 'antd/dist/antd.css';

import {
  Home,
  Login,
  PrivateRoute,
  DashboardView,
  Confirmation,
  ForgotPassword,
} from './redux/pages';
import { Layout } from './components';
import {
  CompanyManagement,
  UserManagement,
  CompanyDetails,
} from './redux/admin-pages';
import { selectUserRole } from './redux/pages/Login/selectors';
import { initializeApp } from './redux/pages/Login/actions';
import './App.css';
import Dashboards from "./redux/pages/Dashboards/Dashboards";

const App = ({ roles }) => {
  const usersRoutes = [
    <PrivateRoute key={'home-user'} exact path={['/']} component={Home} />,
    <PrivateRoute
      key={'dashboardView-performance-user'}
      path={'/dashboardView/'}
      component={DashboardView}
    />,
    <Route key={'login-user'} exact path={'/login'} component={Login} />,
    <Route
      key={'confirmation-user'}
      exact
      path={'/confirmation'}
      component={Confirmation}
    />,
    <Route
      key={'forgot-password-user'}
      exact
      path={'/forgotPassword'}
      component={ForgotPassword}
    />,
    <PrivateRoute
        key={'dashboards-user'}
        exact
        path={'/dashboards'}
        render={() => <Dashboards roles={roles}/>}
    />
  ];

  return (
    <>
      <HashRouter history={browserHistory}>
        <Layout>
          {roles.includes('ADMIN') || roles.includes('ACCOUNT MANAGER') ? (
            <Switch>
              <PrivateRoute
                exact
                path={'/companyManagement'}
                component={CompanyManagement}
              />
              <PrivateRoute
                exact
                path={'/userManagement'}
                render={() => <UserManagement />}
              />
              <Route
                path={'/dashboardView/'}
                component={DashboardView}
              />
              <Redirect to='/companyManagement' />
            </Switch>
          ) : (roles.includes('ACCOUNT OWNER SHARED') || roles.includes('ACCOUNT OWNER/MANAGER')) ? (
            <Switch>
              {usersRoutes}
              <PrivateRoute
                exact
                path={'/companyDetails'}
                render={() => <CompanyDetails isNotMainAdmin shared />}
              />
              <PrivateRoute
                exact
                path={'/userManagement'}
                render={() => <UserManagement isNotMainAdmin />}
              />
              <Redirect key={'main-user'} to='/' />
            </Switch>
          ) : roles.includes('ACCOUNT OWNER') ? (
            <Switch>
              {usersRoutes}
              <PrivateRoute
                exact
                path={'/companyDetails'}
                render={() => <CompanyDetails isNotMainAdmin />}
              />
              <PrivateRoute
                exact
                path={'/userManagement'}
                render={() => <UserManagement isNotMainAdmin />}
              />
              <Redirect key={'main-user'} to='/' />
            </Switch>
          ) : (
            <Switch>
              {usersRoutes}
              <Redirect key={'main-user'} to='/' />
            </Switch>
          )}
        </Layout>
      </HashRouter>
    </>
  );
};

const mapStateToProps = (state) => ({
  roles: selectUserRole(state),
});

export default compose(
  connect(mapStateToProps),
  lifecycle({
    componentDidMount() {
      !/dashboardView/.test(window.location.hash) &&
        !/confirmation/.test(window.location.hash) &&
        !/forgotPassword/.test(window.location.hash) &&
        this.props.dispatch(initializeApp());
    },
  })
)(App);
