import React from 'react';
import { Select } from 'antd';
import styles from './Filters.module.scss';
import { FilterActions } from '../FilterAtions/FilterActions';
import { getFiltersConfig } from '../../../utils/getFiltersConfig';

export const Filters = ({
  currentValues,
  initialValues,
  roles,
  divisions,
  handleChange,
  onRebuildDashboard,
  jobs,
  locations,
  employeeReadiness,
  employeeRatings,
  employeeLines9BoxData,
  hideCognitive,
  isActiveMiniLoading,
  submitCount
}) => {
  const filterConfig = getFiltersConfig({
    currentValues,
    employeeLines9BoxData,
    employeeReadiness,
    employeeRatings,
    hideCognitive,
    jobsOptions: jobs,
    divisionsOptions: divisions,
    locations,
  });

  return (
    <div className={styles.wrapper}>
      <h3 className={styles.itemTitle}>Filters</h3>
      <div className={styles.filterWrapper}>
        <div className={styles.filterSets}>
          {filterConfig.map(({ name, value, mode, placeholder, options, optionValues, disabled }) => (
            <div
              key={name}
              className={`${styles.filterOption} ${
                value.length &&
                styles.teamFilterHasValue
              }`}
            >
              <Select
                value={value}
                mode={mode}
                style={{ width: '221px' }}
                showArrow
                suffixIcon={
                  <span className={styles.selectFilterIcon}/>
                }
                dropdownClassName={styles.teamFilterDropdown}
                placeholder={placeholder}
                onChange={(value) => handleChange(name, value, disabled)}
                disabled={disabled}
              >
                {options.map((item, index) => (
                  <Select.Option value={item} key={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </div>
          ))}
        </div>
        <FilterActions
          currentValues={currentValues}
          initialValues={initialValues}
          roles={roles}
          onRebuildDashboard={onRebuildDashboard}
          handleChange={handleChange}
          isActiveMiniLoading={isActiveMiniLoading}
          submitCount={submitCount}
        />
      </div>
    </div>

  )
}
