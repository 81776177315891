import api from "../api";

export const getJobSuccession = (id) =>
  api.get(`/employees/jobSuccession/${id}`).then((res) => res.data);

export const getEmployees = (params) =>
  api.post("/employees", params).then((res) => res.data);

export const getPartEmployees = (queryParams = {}, params = {}) =>
  api
    .post(
      `/employees?${Object.keys(queryParams)
        .map((key) => `${key}=${queryParams[key]}`)
        .join("&")}`,
      params
    )
    .then((res) => res.data);

export const getEmployeesDivisions = (params) =>
  api.get("/employees/divisions", params).then((res) => res.data);

export const getEmployeesJobs = (params) =>
  api.get("/employees/jobs", params).then((res) => res.data);

export const getEmployeesSuccession = (params) =>
  api.post("/employees/succession", params).then((res) => res.data);

export const getPartEmployeesSuccession = (page, size, order, params) =>
  api
    .post(`/employees/succession?page=${page}&size=${size}${
        order ? `&order=${order}` : ""
      }`,
      params
    )
    .then((res) => res.data);

export const getEmployeesView = (id, token, page, size, order, isSuccession, sort) =>
    api
        .post(
            `employees/data/${id}?${isSuccession ? 'isSuccession=true&' : ''}${token}&page=${page}&size=${size}${
        order ? `&order=${order}` : ""
      }`,
      {sort}
    )
    .then((res) => res.data);

export const getEmployeesViewSuccession = (id, token, page, size) =>
  api
    .get(
      `employees/data/${id}?isSuccession=true&${token}&page=${page}&size=${size}`
    )
    .then((res) => res.data);

export const getLink = (params) =>
  api.post(`employees/link-data`, params).then((res) => res.data);

export const updateEmployee = (id, params, dashboardId) =>
  api.post(`/employees/updateEmployee/${dashboardId}/${id}`, params).then((res) => res.data);

export const setTerminationDate = (params) =>
  api.post(`/employees/terminationDate`, params).then((res) => res.data);

export const updateDates = (params, dashboardId) =>
  api.put(`/employees/updateDates/${dashboardId}`, params).then((res) => res.data);

export const getGraphImage = (params, config) =>
  api
    .post(`/employees/patternImageURL`, params, config)
    .then((res) => res.data);

export const getGraphJobImage = (params, config) =>
  api
    .post(`/employees/jobPatternImageURL`, params, config)
    .then((res) => res.data);

export const updateAssesmentData = () =>
  api.post("/updateAssesmentData").then((res) => res.data);

export const getLastUpdate = () => api.get("/audit").then((res) => res.data);

export const getEmployee = (id) =>
  api.get(`employees/${id}`).then((res) => res.data);

export const addJobBehavioralResults = (jobNumber, employeeId, params, dashboardId) =>
  api
    .post(
      `/employees/${employeeId}/job-behavioral-results/${dashboardId}/${jobNumber}`,
      params
    )
    .then((res) => res.data);

export const deleteJobBehavioralResults = (jobNumber, employeeId) =>
  api
    .delete(`/employees/${employeeId}/job-behavioral-results/${jobNumber}`)
    .then((res) => res.data);

export const saveFilterP = (data) =>
  api.post(`/employees/filters`, data).then((res) => res.data);

export const saveFilterS = (data) =>
  api.post(`/employees/filtersSuccession`, data).then((res) => res.data);

export const deleteFilterP = (data) =>
  api.post(`/employees/filters-delete`, data).then((res) => res.data);

export const deleteFilterS = (data) =>
  api.post(`/employees/filtersSuccession-delete`, data).then((res) => res.data);

export const deleteEmployee = (data, dashboardId) =>
  api.post(`/employees/delete/${dashboardId}`, data).then((res) => res.data);

export const getDataSets = (params) =>
  api.get("/employees/sets", params).then((res) => res.data);

export const createDataSet = (params) =>
  api.post("/employees/sets", params).then((res) => res.data);

export const updateDataSet = (params) =>
  api.put("/employees/sets", params).then((res) => res.data);

export const deleteDataSet = (params) =>
  api
    .delete(`/employees/sets?employeeSetId=${params}`, {})
    .then((res) => res.data);
