import { AddUser, BlockDeleteMethods, CustomModal } from '../components';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Table, Tooltip } from 'antd';

import {
  getSortedData,
  openNotificationWithIcon,
  decorateByFilter,
  capitalize,
} from '../../../utils';
import { selectCompanies, selectRoles, selectUsers } from '../selectors';
import { selectUser } from '../../pages/Login/selectors';
import api from '../../../api';
import { getUsersManagment } from '../actions';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPenToSquare, faTrashCan} from "@fortawesome/free-regular-svg-icons";
import {faPlusCircle, faLock, faLockOpen} from "@fortawesome/free-solid-svg-icons";

import { Loader } from '../../../components';
import { SortIcon } from '../../pages/components';

import styles from './UserManagement.module.scss';

let popUp_1 = React.createRef();

const actions = [
  {
    img: <FontAwesomeIcon alt='action unblock' icon={faLockOpen} />,
    title: 'action_unblock',
  },
  {
    img: <FontAwesomeIcon alt='action block' icon={faLock}/>,
    title: 'action_block',
  },
  {
    img: <FontAwesomeIcon alt='action delete' icon={faTrashCan}/>,
    title: 'action_delete',
  },
  {
    img: <FontAwesomeIcon alt='action add' icon={faPlusCircle}/>,
    title: 'action_add',
  },
];

class UserManagement extends PureComponent {
  state = {
    currentPeople: {},
    filterName: '',
    isLoading: false,
    modalType: 'none',
    selectedRowKeys: [],
    itemsPerPage: 10,
    currentPage: 1,
    sortType: null,
    sortDirection: null,
    sortColumn: null,
    isRoleAccountManager: false,
    mainUser: null,
  };

  columns = [
    {
      title: '#',
      dataIndex: 'count',
      align: 'center',
      width: 55,
    },
    {
      title: () => (
        <span className={styles.sortImg}>
          <SortIcon
            text={'Last name'}
            onClick={() => this.sortBy('Alphabetical', 'lastName')}
            type={
              this.state.sortColumn === 'lastName'
                ? this.state.sortDirection
                : null
            }
          />
        </span>
      ),
      render: (text, { firstName, lastName }) => (
        <span>
          {lastName ? this.decorateNameByFilter(lastName, firstName) : lastName}
        </span>
      ),
      dataIndex: 'lastName',
      width: 200,
    },
    {
      title: () => (
        <span className={styles.sortImg}>
          <SortIcon
            text={'First name'}
            onClick={() => this.sortBy('Alphabetical', 'firstName')}
            type={
              this.state.sortColumn === 'firstName'
                ? this.state.sortDirection
                : null
            }
          />
        </span>
      ),
      render: (text, { firstName, lastName }) => (
        <span>
          {firstName
            ? this.decorateNameByFilter(firstName, lastName)
            : firstName}
        </span>
      ),
      dataIndex: 'firstName',
      width: 200,
    },
    {
      title: () => (
        <span className={styles.sortImg}>
          <SortIcon
            text={'Company name'}
            onClick={() => this.sortBy('Alphabetical', 'companyName')}
            type={
              this.state.sortColumn === 'companyName'
                ? this.state.sortDirection
                : null
            }
          />
        </span>
      ),
      dataIndex: 'companyName',
      render: (text, { companyName }) => (
        <span>
          {companyName
            ? decorateByFilter(
                this.state.filterName,
                companyName,
                styles.searchedWord
              )
            : companyName}
        </span>
      ),
    },
    {
      title: () => (
        <span className={styles.sortImg}>
          <SortIcon
            text={'Roles'}
            onClick={() => this.sortBy('Alphabetical', 'roles.0')}
            type={
              this.state.sortColumn === 'roles.0'
                ? this.state.sortDirection
                : null
            }
          />
        </span>
      ),
      dataIndex: 'roles',
      render: (roles) => {
        const role = capitalize(roles[0] || '');

        return decorateByFilter(
          this.state.filterName,
          role,
          styles.searchedWord
        );
      },
      className: `${styles.listOfRoles}`,
    },
    {
      title: 'Actions',
      key: 'action',
      width: 120,
      render: (text, record) => (
        <div className={styles.actionsTableCol}>
          <Tooltip title={'Edit User Details'}>
            <span
              className={styles.details}
              onClick={() => this.displayModal('details', record)}>
              <FontAwesomeIcon alt='action details' icon={faPenToSquare} />
            </span>
          </Tooltip>
        </div>
      ),
    },
  ];

  componentDidMount() {
    const {
      user: { id, roles },
    } = this.props;

    if (roles.includes('ACCOUNT MANAGER')) {
      api.users
        .getUser(id)
        .then((res) => this.setState({ mainUser: res }))
        .catch((res) => this.requestNotification(res.message, false));
    }

    this.getUsers();
  }

  getUsers = () => {
    const { onGetUsersManagment } = this.props;
    this.setState({ isLoading: true });

    api.users.getUsers().then(
      (res) => {
        const { mainUser } = this.state;
        let resFormatter = [];
        let count = 1;

        if (mainUser) {
          const companiesId = Object.keys(res.companies);

          resFormatter.push(
            { ...mainUser },
            ...res.userDataList.filter(
              ({ companyId }) =>
                companyId && companiesId.includes(`${companyId}`)
            )
          );
        } else {
          resFormatter.push(...res.userDataList);
        }

        const users = resFormatter.map((item) => {
          item.count = count;
          count++;

          return item;
        });

        const data = {
          users,
          companies: res.companies,
          roles: res.rolesForNewUser,
        };

        onGetUsersManagment(data);
        this.setState({ isLoading: false });
      },
      () => this.setState({ isLoading: false })
    );
  };

  handleMethod = (type) => {
    const { selectedRowKeys } = this.state;
    const users = selectedRowKeys.length > 1 ? 'Users' : 'User';
    this.setState({ isLoading: true });

    const getSuccessTitle = (type) => {
      switch (type) {
        case 'block':
          return `${users} was successfully blocked`;
        case 'unblock':
          return `${users} was successfully unblocked`;
        case 'delete':
          return `${users} was successfully deleted`;
        default:
          return;
      }
    };

    const getCurrentMethod = (type, data) => {
      switch (type) {
        case 'block':
          return api.users.blockUsers(data);
        case 'unblock':
          return api.users.unblockUsers(data);
        case 'delete':
          return api.users.deleteUsers(data);
        default:
          return;
      }
    };

    getCurrentMethod(type, selectedRowKeys)
      .then(() => {
        this.setState({ selectedRowKeys: [] });
        this.closeModal(true);
        this.requestNotification(getSuccessTitle(type));
      })
      .catch((err) => {
        const errorMessage = err.response.data.message
          ? err.response.data.message
          : err.response.data;
        
        this.requestNotification(errorMessage, false);
      });
  };

  requestNotification = (message, success = true) => {
    const notificationSettings = {
      type: success ? 'success' : 'error',
      message: message,
    };
    openNotificationWithIcon(notificationSettings);
    this.setState({ isLoading: false });
  };

  onSelectChange = (selectedRowKeys) => this.setState({ selectedRowKeys });

  displayModal = (type, record = {}) =>
    this.setState({ modalType: type, currentPeople: record });

  getModalBlock = () => {
    const { currentPeople, modalType, selectedRowKeys } = this.state;
    const { isNotMainAdmin, roles, isNotAccOwner } = this.props;
    const usersText = selectedRowKeys.length > 1 ? 'users' : 'user';

    switch (modalType) {
      case 'add':
        return (
          <AddUser
            getUsers={this.getUsers}
            currentPeople={currentPeople}
            modalClose={this.closeModal}
            onRef={(ref) => (popUp_1 = ref)}
            isNotMainAdmin={isNotMainAdmin}
            isNotAccOwner={isNotAccOwner}
            roles={roles}
            handleAccountManagerRole={(isRoleAccountManager) =>
              this.setState({ isRoleAccountManager })
            }
          />
        );
      case 'block':
        return (
          <BlockDeleteMethods
            modalClose={this.closeModal}
            onYes={() => this.handleMethod('block')}
            title={`Are you sure you want to block ${usersText}?`}
          />
        );
      case 'delete':
        return (
          <BlockDeleteMethods
            modalClose={this.closeModal}
            onYes={() => this.handleMethod('delete')}
            title={`Are you sure you want to delete ${usersText}?`}
          />
        );
      case 'unblock':
        return (
          <BlockDeleteMethods
            modalClose={this.closeModal}
            onYes={() => this.handleMethod('unblock')}
            title={`Are you sure you want to unblock ${usersText}?`}
          />
        );
      case 'details':
        if (currentPeople.roles.includes('ACCOUNT MANAGER'))
          this.setState({ isRoleAccountManager: true });

        return (
          <AddUser
            getUsers={this.getUsers}
            modalClose={this.closeModal}
            currentPeople={currentPeople}
            onRef={(ref) => (popUp_1 = ref)}
            isDetails
            isNotMainAdmin={isNotMainAdmin}
            isNotAccOwner={isNotAccOwner}
            roles={roles}
            handleAccountManagerRole={(isRoleAccountManager) =>
              this.setState({ isRoleAccountManager })
            }
          />
        );
      default:
        return null;
    }
  };

  closeModal = (isShouldUpdate = false) => {
    this.setState({ modalType: 'none', isRoleAccountManager: false });

    if (isShouldUpdate) {
      this.getUsers();
    }
  };

  actionsClick = (type) => {
    switch (type) {
      case 'action_block':
        return this.displayModal('block');
      case 'action_unblock':
        return this.displayModal('unblock');
      case 'action_delete':
        return this.displayModal('delete');
      case 'action_add':
        return this.displayModal('add');
      default:
        return;
    }
  };

  actionsTooltipText = (type) => {
    switch (type) {
      case 'action_block':
        return 'Lock Client User';
      case 'action_unblock':
        return 'Unlock Client User';
      case 'action_delete':
        return 'Delete User';
      case 'action_add':
        return 'Create New User';
      default:
        return '';
    }
  };

  closePopUp = () => {
    const { modalType } = this.state;
    const popUpsTypesWithoutRef = ['block', 'delete', 'unblock'];
    const isFindPopUpWithoutRed = popUpsTypesWithoutRef.find(
      (item) => item === modalType
    );
    if (isFindPopUpWithoutRed) {
      this.setState({ modalType: 'none' });
    } else {
      popUp_1.onCancelHandle();
    }
  };

  sortBy = (type, sortColumn) => {
    const { sortType, sortDirection } = this.state;
    this.setState({ sortColumn });
    if (type === sortType) {
      if (sortDirection === 'topSort') {
        return this.setState({ sortDirection: 'downSort' });
      } else if (sortDirection === 'downSort') {
        return this.setState({ sortDirection: null });
      }
      return this.setState({ sortDirection: 'topSort' });
    } else {
      return this.setState({ sortDirection: 'topSort', sortType: type });
    }
  };

  sortTable = (data) => {
    const { sortType, sortDirection, sortColumn } = this.state;
    return getSortedData({ data, sortType, sortDirection, sortColumn });
  };

  onSearchName = (e) => {
    if (e.target.value.length > 0) {
      this.setState({ filterName: e.target.value.toLowerCase() });
    } else {
      this.setState({ filterName: '' });
    }
  };

  getFilteredUsers = (users) => {
    const { filterName } = this.state;

    return users.filter(({ firstName, lastName, companyName, roles }) => {
      const name =
        firstName && lastName ? (firstName + ' ' + lastName).toLowerCase() : '';
      const nameRevert =
        firstName && lastName ? (lastName + ' ' + firstName).toLowerCase() : '';
      const company = companyName?.toLowerCase() || '';
      const role = roles[0]?.toLowerCase() || '';

      return (
        name.includes(filterName) ||
        nameRevert.includes(filterName) ||
        company.includes(filterName) ||
        role.includes(filterName)
      );
    });
  };

  decorateNameByFilter = (mainWord, secondWord) => {
    const { filterName } = this.state;

    if (!filterName) return mainWord;

    if (mainWord.toLowerCase().includes(filterName))
      return decorateByFilter(filterName, mainWord, styles.searchedWord);

    const firstVariation = (mainWord + ' ' + secondWord).toLowerCase();
    const secondVariation = (secondWord + ' ' + mainWord).toLowerCase();

    if (firstVariation.includes(filterName)) {
      const startIndex = firstVariation.indexOf(filterName);

      const firstPart = mainWord.slice(0, startIndex);
      const secondPart = mainWord.slice(startIndex, mainWord.length);

      return (
        <>
          {firstPart}
          {secondPart && (
            <span className={styles.searchedWord}>{secondPart}</span>
          )}
        </>
      );
    }

    if (secondVariation.includes(filterName)) {
      const endIndex = secondVariation.indexOf(filterName) + filterName.length;

      const firstPart = mainWord.slice(0, endIndex - secondWord.length - 1);
      const secondPart = mainWord.slice(firstPart.length, mainWord.length);

      return (
        <>
          {firstPart && (
            <span className={styles.searchedWord}>{firstPart}</span>
          )}
          {secondPart}
        </>
      );
    }

    return mainWord;
  };

  render() {
    const {
      isLoading,
      selectedRowKeys,
      modalType,
      itemsPerPage,
      currentPage,
      isRoleAccountManager,
      filterName,
    } = this.state;
    const {
      users,
      isNotMainAdmin,
      user: { roles },
    } = this.props;

    let sortedUsers = this.sortTable(users);

    const isAccountManager =
      roles.includes('ACCOUNT MANAGER') ||
      roles.includes('ACCOUNT OWNER/MANAGER');

    const rowSelection = {
      selectedRowKeys,
      hideDefaultSelections: true,
      onChange: this.onSelectChange,
      getCheckboxProps: () => ({
        disabled: isAccountManager,
      }),
    };

    const columns = isNotMainAdmin
      ? this.columns.filter((el) => el.title !== 'Company name')
      : this.columns;

    if (filterName) {
      sortedUsers = this.getFilteredUsers(sortedUsers);
    }

    return (
      <>
        <section className={styles.wrapper}>
          <h2 className={styles.title}>User management</h2>
          <div className={styles.optionsWrap}>
            <div className={styles.searchWrap}>
              <input placeholder={'Search'} onChange={this.onSearchName} />
            </div>
            {!isAccountManager && (
              <div className={styles.actionsWrap}>
                {actions.map(({ img, title }, i) => {
                  const disabledBtns =
                    title === 'action_block' ||
                    title === 'action_unblock' ||
                    title === 'action_delete';
                  const disabled = disabledBtns && selectedRowKeys.length === 0;
                  return (
                    <Tooltip key={i} title={this.actionsTooltipText(title)}>
                      <div  className={` ${
                          disabled ? styles.actionsBtnWrapDisabled : styles.actionsBtnWrap
                        }`}>
                        <button
                        className={`${styles.actionsBtn} ${
                          disabled ? styles.actionsBtnDisabled : ''
                        }`}
                        onClick={() => this.actionsClick(title)}
                        key={i}>
                        {img}
                        </button>
                      </div>
                      
                    </Tooltip>
                  );
                })}
              </div>
            )}
          </div>
          <div className={styles.tableWrap}>
            <Table
              rowSelection={rowSelection}
              columns={columns}
              dataSource={sortedUsers}
              bordered
              rowKey='id'
              rowClassName={(record) => {
                if (record.active === false) {
                  return `${styles.blocked}`;
                }
                if (record.roles[0] === 'ADMIN') {
                  return `${styles.isAdmin}`;
                }
                if (
                  roles.includes('ACCOUNT MANAGER') &&
                  record.roles.includes('ACCOUNT MANAGER')
                ) {
                  return `${styles.isAccountManager}`;
                }
              }}
              pagination={{
                pageSizeOptions: ['5', '10', '20', '30'],
                onChange: (current) => this.setState({ currentPage: current }),
                onShowSizeChange: (current, size) =>
                  this.setState({ itemsPerPage: size, currentPage: current }),
                showTotal: (total) =>
                  `${
                    Math.ceil(total / itemsPerPage) < currentPage
                      ? Math.ceil(total / itemsPerPage)
                      : currentPage
                  } of ${Math.ceil(total / itemsPerPage)}`,
                defaultCurrent: 1,
                showSizeChanger: true,
                showTitle: false,
                locale: { items_per_page: '' },
              }}
            />
          </div>
          <CustomModal
            visible={modalType !== 'none'}
            onCancel={this.closePopUp}
            className={styles.modalWrap}
            width={isRoleAccountManager ? 800 : null}>
            {this.getModalBlock()}
          </CustomModal>
        </section>
        {isLoading && <Loader />}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  companies: selectCompanies(state),
  users: selectUsers(state),
  roles: selectRoles(state),
  user: selectUser(state),
});

const mapDispatchToProps = (dispatch) => ({
  onGetUsersManagment: (data) => dispatch(getUsersManagment(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserManagement);
