import * as auth from './auth';
import * as companies from './companies';
import * as employees from './employees';
import * as files from './files';
import * as users from './users';
import * as dashboards from './dashboards';

export default {
  auth,
  companies,
  employees,
  files,
  users,
  dashboards
};
