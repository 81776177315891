import React, { PureComponent } from 'react';
import { createForm } from 'rc-form';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import queryString from 'query-string';
import { Link } from 'react-router-dom';

import Logo from '../../../images/logo.png';

import { openNotificationWithIcon } from '../../../utils';
import {clearAuthFail, loginUser} from './actions';

import {selectAuthError} from "./selectors";

import LoginFormInner from './LoginFormInner';

import styles from './Login.module.scss';

class Login extends PureComponent {
  state = {
    showValidation: false,
    focusedInputName: '',
    params: {},
  }

  UNSAFE_componentWillMount() {
    let url = this.props.location.search;
    this.setState({params: queryString.parse(url)})
    queryString.parse(url)?.notification === 'confirmation' && openNotificationWithIcon({ 
      type: 'success', 
      message: 'Password was successfully set',
    });
  }

  login = e => {
    const { form, login } = this.props;
    e.preventDefault();
    this.setState({showValidation: true});
    form.validateFields(err => {
      if (!err) {
        login(form.getFieldsValue());
      }
    });
  };

  onInputChange = () => {
    const { errorData, onClearAuthFail  } = this.props;
    this.setState({showValidation: false});
    if (errorData !== null ) {
      onClearAuthFail();
    }
  }

  setFocusedInputName = (inputName) => {
    this.setState({focusedInputName: inputName})
  }

  render() {
    const { form, errorData } = this.props;
    const { showValidation, focusedInputName, params } = this.state;

    return (
      <section className={styles.wrapper}>
        <div className={styles.inner}>
          <img src={Logo} className={styles.logoImg} alt="MindWire"/>
          <h2 className={styles.title}>Sign In</h2>
          <form onSubmit={this.login}>
            <LoginFormInner
              form={form}
              showValidation={showValidation}
              focusedInputName={focusedInputName}
              onInputChange={this.onInputChange}
              onFocus={(inputName) => this.setFocusedInputName(inputName)}
              onBlur={() => this.setFocusedInputName("")}
              userNameInitialValue = {params?.email || ""}
            />
            {errorData && errorData !== null && <p className={styles.errorText}>{errorData.data}</p>}
            <button className={styles.loginBtn}>
              <span><FontAwesomeIcon className={styles.errorIcon} icon={faUser} /></span>
              Login
            </button>
            <div className={styles.forgotPassword}>
              <Link to={'/forgotPassword'} className={styles.stepsBtn}>Forgot password?</Link>
            </div>
          </form>
        </div>
      </section>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  login: credentials => dispatch(loginUser(credentials)),
  onClearAuthFail: () => dispatch(clearAuthFail()),
});

const mapStateToProps = state => ({
  errorData: selectAuthError(state),
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  createForm(),
)(Login);
