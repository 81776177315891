import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Tooltip } from 'antd';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircle, faKey} from "@fortawesome/free-solid-svg-icons";
import {faTrashCan} from "@fortawesome/free-regular-svg-icons";

import api from '../../../api';
import { getCompaniesManagment, isUpdateCompanies } from '../actions';
import { openNotificationWithIcon } from '../../../utils';
import { getUser } from '../../pages/Login/actions';

import { selectCompaniesData, selectIsUpdateCompanies } from '../selectors';
import { selectUser } from '../../pages/Login/selectors';

import {
  CustomModal,
  BlockDeleteMethods,
  AddCompany,
  Edit9Box,
} from '../components';
import { Loader } from '../../../components';
import EditCompanySurvey from '../components/EditCompanySurvey/EditCompanySurvey';

import styles from './CompanyDetails.module.scss';

let popUp_1 = React.createRef();

const actions = [
  {
    icon: faKey,
    title: 'action_editSurvey',
    label: 'API Key Entry',
  },
  {
    icon: faTrashCan,
    title: 'action_delete',
    label: 'Delete all company data',
  },
];

class CompanyDetails extends PureComponent {
  state = {
    isLoading: false,
    modalType: 'none',
  };

  componentDidMount() {
    const { onUpdateCompanies } = this.props;
    this.getCompanies();
    onUpdateCompanies(false);
  }

  getCompanies = () => {
    const { onGetUsersManagment } = this.props;
    this.setState({ isLoading: true });
    api.companies.getCompanies().then(
      (res) => {
        let count = 1;
        const resFormatter = res.map((item) => {
          item.count = count;
          count++;
          return item;
        });
        onGetUsersManagment(resFormatter);
        this.setState({ isLoading: false });
      },
      () => this.setState({ isLoading: false })
    );
  };

  updateData = () => {
    const {
      onGetUser,
      user: { id },
    } = this.props;

    api.users
      .getUser(id)
      .then((res) => {
        onGetUser(res);
      })
      .catch((res) => this.requestNotification(res.message, false));

    this.getCompanies();
  };

  handleMethod = (type) => {
    const { companies } = this.props;
    this.setState({ isLoading: true });

    const getSuccessTitle = (type) => {
      switch (type) {
        case 'delete':
          return `Your company was marked to the deletion successfully`;
        case 'editSurvey':
          return `Company was successfully deleted`;
        default:
          return;
      }
    };

    const getCurrentMethod = (type, id) => {
      switch (type) {
        case 'delete':
          return api.companies.selfDelete(id);
        default:
          return;
      }
    };

    getCurrentMethod(type, companies[0].id)
      .then(() => {
        this.setState({ selectedRowKeys: [] });
        this.closeModal(true);
        this.requestNotification(getSuccessTitle(type));

        if (type === 'delete') {
          this.updateData();
        }
      })
      .catch((res) => this.requestNotification(res.message, false));
  };

  requestNotification = (message, success = true) => {
    const notificationSettings = {
      type: success ? 'success' : 'error',
      message: message,
    };
    openNotificationWithIcon(notificationSettings);
    this.setState({ isLoading: false });
  };

  displayModal = (type) => this.setState({ modalType: type });

  getModalBlock = () => {
    const { modalType } = this.state;
    const { companies } = this.props;
    switch (modalType) {
      case 'editSurvey':
        return (
          <EditCompanySurvey
            modalClose={this.closeModal}
            currentCompany={companies[0]}
            sendNotification={this.requestNotification}
            onRef={(ref) => (popUp_1 = ref)}
            notMainAdmin
          />
        );
      case 'edit9Box':
        return (
          <Edit9Box
            modalClose={this.closeModal}
            currentCompany={companies[0]}
            sendNotification={this.requestNotification}
            onRef={(ref) => (popUp_1 = ref)}
          />
        );
      case 'delete':
        return (
          <BlockDeleteMethods
            modalClose={this.closeModal}
            onYes={() => this.handleMethod('delete')}
            title={`Delete all company Data`}
            text='Your data will be deleted within 48 hours and we advise that you export your data if you’d like to have a backup/copy of the dashboards in *.xlsx format'
            yesBtnText='Delete'
            noBtnText='Cancel'
          />
        );
      default:
        return null;
    }
  };

  closeModal = (isShouldUpdate = false) => {
    this.setState({ modalType: 'none' });
    if (isShouldUpdate) {
      this.getCompanies();
    }
  };

  actionsClick = (type) => {
    switch (type) {
      case 'action_delete':
        return this.displayModal('delete');
      case 'action_editSurvey':
        return this.displayModal('editSurvey');
      case 'action_edit9Box':
        return this.displayModal('edit9Box');
      default:
        return;
    }
  };

  closePopUp = () => {
    const { modalType } = this.state;
    const popUpsTypesWithoutRef = 'delete';
    const isFindPopUpWithoutRef = popUpsTypesWithoutRef === modalType;
    if (isFindPopUpWithoutRef) {
      this.setState({ modalType: 'none' });
    } else {
      popUp_1.onCancelHandle();
    }
  };

  render() {
    const { isLoading, modalType } = this.state;
    const {
      companies,
      isNotAccountOwner,
      shared,
      isNotMainAdmin,
      user: { roles },
    } = this.props;

    const isAccountOwnerManager = roles.includes('ACCOUNT OWNER/MANAGER');

    return (
      <>
        <section className={styles.wrapper}>
          <div className={styles.optionsWrap}>
            <div className={styles.actionsWrap}>
              {isNotAccountOwner || isAccountOwnerManager ? null : (
                <Tooltip title={<span>9-BOX Settings</span>}>
                  <button
                    className={`${styles.actionsBtnImage} ${
                      styles.wrapper9Box
                    } ${isNotAccountOwner ? styles.actionsBtnDisabled : ''}`}
                    onClick={() => this.actionsClick('action_edit9Box')}
                    disabled={isNotAccountOwner}>
                    {new Array(9).fill(null).map((el, i) => (
                      <div key={i} className={styles.round}>
                        <FontAwesomeIcon icon={faCircle} />
                      </div>
                    ))}
                  </button>
                </Tooltip>
              )}
              {!isAccountOwnerManager && actions.map(({ icon, title, label }, i) => {
                let disabled = !companies[0];

                if (!disabled && title === 'action_delete')
                  disabled =
                    (isNotMainAdmin && !!shared) ||
                    !!isNotAccountOwner ||
                    !!companies[0].deletionTime;

                return disabled ? null : (
                  <Tooltip key={title} title={<span>{label}</span>}>
                    <button
                      className={`${styles.actionsBtnImage} ${
                        disabled ? styles.actionsBtnDisabled : ''
                      }`}
                      onClick={() => this.actionsClick(title)}
                      disabled={disabled}
                      key={i}>
                      <FontAwesomeIcon
                        className={styles.actionsImg}
                        icon={icon}
                        alt={title}
                      />
                    </button>
                  </Tooltip>
                );
              })}
            </div>
          </div>
          <div className={styles.tableWrap}>
            {companies.length && (
              <AddCompany
                currentCompany={companies[0]}
                key={JSON.stringify(companies[0])}
                sendNotification={this.requestNotification}
                onRef={(ref) => (popUp_1 = ref)}
                isDetails
                isNotModal
                shared={shared}
                isNotAccountOwner={isNotAccountOwner}
              />
            )}
          </div>
          <CustomModal
            visible={modalType !== 'none'}
            onCancel={this.closePopUp}
            className={styles.modalWrap}>
            {this.getModalBlock()}
          </CustomModal>
        </section>
        {isLoading && <Loader />}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  companies: selectCompaniesData(state),
  isNeedUpdateCompanies: selectIsUpdateCompanies(state),
  user: selectUser(state),
});

const mapDispatchToProps = (dispatch) => ({
  onGetUsersManagment: (data) => dispatch(getCompaniesManagment(data)),
  onUpdateCompanies: (data) => dispatch(isUpdateCompanies(data)),
  onGetUser: (data) => dispatch(getUser(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDetails);
