import React, { PureComponent } from 'react';

import Logo from '../../../../images/logo.png';

import styles from './HelpPopUp.module.scss';

const supportMail = 'dashboards@mindwiregroup.com';

class HelpPopUp extends PureComponent {

  openMessanger = () => {
    window.location.href = `mailto:${supportMail}`;
  }

  render() {
    const { closePopUp } = this.props;
    return (
      <section className={styles.wrapper}>
        <h2 className={styles.title}>Help</h2>
        <div className={styles.sectionWrap}>
          <img className={styles.logo} src={Logo} alt="MindWire"/>
          <p>MindWire Customer Service is ready to assist you. Please choose from the following options for information.</p>
          <div className={styles.btnEmailWrap}>
            <button className={styles.emailUs} onClick={this.openMessanger}>EMAIL US</button>
          </div>
          <p><span>Phone Number: </span>1-888-234-8175</p>
          <p><span>Support Hours: </span>8 AM to 4 PM AZ Time</p>
        </div>
        <div className={styles.btnWrap}>
          <button className={styles.btnNo} onClick={closePopUp}>Close</button>
        </div>
      </section>
    );
  }
}

export default HelpPopUp;
