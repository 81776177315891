import React, { PureComponent } from 'react';

import { FormInput } from '../../../components';
import styles from './Login.module.scss';

class LoginFormInner extends PureComponent {
  render() {
    const { form, showValidation, focusedInputName, onFocus, onBlur, onInputChange, userNameInitialValue = '' } = this.props;
    return (
      <>
        {form.getFieldDecorator('username', {
          initialValue: `${userNameInitialValue}`,
          validateTrigger: ['onSubmit'],
          rules: [
            {
              required: true,
              message: <span key="usernameError">Please enter your email address</span>,
            },
            {
              type: 'email',
              message: <span key="usernameFormatError">Email address format is incorrect</span>,
            },
          ],
        })(
          <FormInput
            label={'Login'}
            error={showValidation && form.getFieldError('username')}
            autocomplete="username"
            onFocus={() => onFocus('username')}
            onBlur={onBlur}
            onChange={onInputChange}
            isFocusedInput={focusedInputName === 'username'}
          />,
        )}
        {form.getFieldDecorator('password', {
          initialValue: '',
          validateTrigger: ['onSubmit'],
          rules: [
            {
              required: true,
              message: <span key="password">Please enter password</span>,
            }],
        })(
          <FormInput
            className={`${styles.passwordInput}`}
            type="password"
            label={'Password'}
            onChange={onInputChange}
            onFocus={() => onFocus('password')}
            onBlur={onBlur}
            isFocusedInput={focusedInputName === 'password'}
            error={showValidation && form.getFieldError('password')}
            autocomplete="current-password"
          />,
        )}
      </>
    );
  }
}

export default LoginFormInner;
