import { merge } from 'lodash';

import {
  PERFOMANCE_CLEAR_EMPLOYEES_SUCCESS,
  PERFOMANCE_GET_EMPLOYEES_FORMAT,
  PERFOMANCE_UPDATE_EMPLOYEE_MAP,
  PERFOMANCE_GET_EMPLOYEES_SUCCESS,
  PERFOMANCE_LAST_UPDATE_CLEAR,
  PERFOMANCE_LAST_UPDATE_SUCCESS,
  PERFOMANCE_UPDATE_FILTERS_SUCCESS,
  PERFOMANCE_UPDATE_EMPLOYEE,
  PERFOMANCE_REMOVE_EMPLOYEE,
  PERFOMANCE_UPDATE_EMPLOYEES,
  TOGGLE_DATE_PICKER,
} from '../../../constants/constants';

export const initialState = {
  divisions: [],
  employees: [],
  employeesFormat: [],
  employeeJobs: [],
  employeePerformance: [],
  employeePotential: [],
  employeeRatings: [],
  employeeReadiness: [],
  employeeStatuses: [],
  lastUpdate: [],
  filtersPresets: [],
  piApiKey: '',
  isNeedToUpdateEmployees: false,
  isOpenedDatePicker: false,
};

export const perfomanceReducer = (
  state = initialState,
  { data, type, isNeedToUpdateEmployees }
) => {
  switch (type) {
    case PERFOMANCE_CLEAR_EMPLOYEES_SUCCESS:
      return {
        ...state,
        divisions: [],
        employees: {},
        employeesFormat: [],
        employeeJobs: [],
        employeeRatings: [],
        employeeStatuses: [],
        filtersPresets: [],
        piApiKey: '',
      };
    case PERFOMANCE_GET_EMPLOYEES_FORMAT:
      const { employeesFormatted, employees } = data;

      return {
        ...state,
        employeesFormat: [...employeesFormatted],
        employees: merge(state.employees, employees),
      };
    case PERFOMANCE_UPDATE_EMPLOYEE_MAP:
      return {
        ...state,
        employees: merge(state.employees, data),
      };
    case PERFOMANCE_UPDATE_EMPLOYEE: {
      const {
        user: newUser,
        currentJob,
        userId,
        division: userDivision,
      } = data;

      let division = userDivision;
      let currJob = state.employees[division] || state.employees['No division']
      if (currentJob.includes('_supervisor')) {
        division = Object.keys(state.employees).find(
          (div) => !!state.employees[div][currentJob]
        );
      }

      return {
        ...state,
        employeesFormat: state.employeesFormat.map((el) => {
          if (el.id === userId) {
            return {
              ...el,
              ...newUser,
              division: el.division,
            };
          }
          return el;
        }),
        employees: {
          ...state.employees,
          [division]: {
            ...state.employees[division],
            [currentJob]: currJob[currentJob].map(
              (oldUser) => {
                if (oldUser.id === userId) {
                  return {
                    ...newUser,
                    currentJob: oldUser.currentJob,
                    division: oldUser.division,
                  };
                }
                return oldUser;
              }
            ),
          },
        },
      };
    }
    case PERFOMANCE_REMOVE_EMPLOYEE: {
      const { userId, currentJob, division: userDivision } = data;

      let division = userDivision;

      if (currentJob.includes('_supervisor')) {
        division = Object.keys(state.employees).find(
          (div) => !!state.employees[div][currentJob]
        );
      }

      return {
        ...state,
        employeesFormat: [
          ...state.employeesFormat.filter((employee) => employee.id !== userId),
        ],
        employees: {
          ...state.employees,
          [division]: {
            ...state.employees[division],
            [currentJob]: state.employees[division][currentJob].filter(
              (employee) => employee.id !== userId
            ),
          },
        },
      };
    }
    case PERFOMANCE_GET_EMPLOYEES_SUCCESS:
      return {
        ...state,
        divisions: data.divisions,
        employees: data.employeeMap,
        employeeJobs: data.employeeJobs,
        employeePerformance: data.employeePerfomance,
        employeePotential: data.employeePotentials,
        employeeRatings: data.employeeRatings,
        employeeReadiness: data.employeeReadiness,
        employeeStatuses: data.employeeStatuses,
        filtersPresets: data.filtersPresets,
        piApiKey: data.piApiKey,
      };
    case PERFOMANCE_LAST_UPDATE_SUCCESS:
      return {
        ...state,
        lastUpdate: data,
      };
    case PERFOMANCE_LAST_UPDATE_CLEAR:
      return {
        ...state,
        lastUpdate: [],
      };
    case PERFOMANCE_UPDATE_FILTERS_SUCCESS:
      return {
        ...state,
        filtersPresets: data,
      };
    case PERFOMANCE_UPDATE_EMPLOYEES:
      return {
        ...state,
        isNeedToUpdateEmployees,
      };
    case TOGGLE_DATE_PICKER:
       return {
        ...state,
        isOpenedDatePicker: data,
      };
    default:
      return state;
  }
};
