import React from "react";
import { withRouter } from "react-router-dom";

import HelpPopUp from "../../redux/pages/components/HelpPopUp/HelpPopUp";
import { CustomModal } from "../../redux/admin-pages/components";
import RefreshAssessments from "../../redux/pages/components/RefreshAssessments/RefreshAssessments";
import { Header, Footer, WarningBanner } from "../index";

import styles from "./Layout.module.scss";

const Layout = ({ children, location }) => {
  const shwow =
    location.pathname !== "/login" &&
    location.pathname !== "/confirmation" &&
    location.pathname !== "/forgotPassword";
  const onlyShow = /dashboardView/.test(location.pathname);
  let type;
  if (onlyShow) {
    type = /performance/.test(location.pathname)
      ? "/performance"
      : "/succession";
  }

  const showRefresh = location.pathname === "/";

  const [isPopUpOpen, setIsPopUpOpen] = React.useState(false);
  const onHelpPopUpOpen = () => {
    setIsPopUpOpen(true);
  };

  return (
    <div className={styles.wrapper}>
      <WarningBanner />
      {shwow && (
        <Header
          onHelpPopUpOpen={onHelpPopUpOpen}
          onlyShow={onlyShow}
          type={type}
        />
      )}
      <div className={styles.inner}>
        <div className={styles.container}>{children}</div>
        {shwow && !onlyShow && (
          <CustomModal
            visible={isPopUpOpen}
            onCancel={() => setIsPopUpOpen(false)}
          >
            <HelpPopUp closePopUp={() => setIsPopUpOpen(false)} />
          </CustomModal>
        )}
      </div>
      {showRefresh && <RefreshAssessments />}
      <Footer />
    </div>
  );
};

export default withRouter(Layout);
