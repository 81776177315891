import React from 'react';

import styles from './BlockDeleteMethods.module.scss';

const BlockDeleteMethods = ({
  modalClose,
  onYes,
  title,
  text,
  yesBtnText,
  noBtnText,
}) => (
  <section className={styles.wrapper}>
    <h3 className={styles.popUpTitle}>{title}</h3>
    {text && (
      <div className={styles.popUpWrap}>
        <p className={styles.popUpText}>{text}</p>
      </div>
    )}
    <div className={styles.btnWrap}>
      <button className={styles.btnNo} onClick={onYes}>
        {yesBtnText || 'Yes'}
      </button>
      <button className={styles.btnYes} onClick={() => modalClose()}>
        {noBtnText || 'No'}
      </button>
    </div>
  </section>
);

export default BlockDeleteMethods;
