import React from 'react';
import {
  getEmployees,
  getEmployeesFormat,
  removeEmployee,
  updateEmployees,
  updateFilters,
  updateUser,
  toggleDatePicker
} from '../../redux/pages/Perfomance/actions';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import {
  selectDivisions,
  selectEmployeePerformance,
  selectEmployeePotential,
  selectEmployeeRatings,
  selectEmployeeReadiness,
  selectEmployees,
  selectEmployeesFormat,
  selectEmployeesJobs,
  selectEmployeeStatuses,
  selectFiltersPresets,
  selectIsNeedToUpdateEmployees,
  selectLastUpdate,
  selectDatePickerOpened
} from '../../redux/pages/Perfomance/selectors';
import { selectUser,selectUserRole } from '../../redux/pages/Login/selectors';
import DashboardContent from './DashboardContent';
import api from '../../api';
import { copyTextInBuffer, downloadFile, getFormatData, openNotificationWithIcon } from '../../utils';
import { getFiltersParams } from '../../utils/getFiltersParams';
import {clearEmployees} from "../../redux/pages/Perfomance/actions";
import { getDashboardColumns } from "../DashboardCells/getDashboardColumns";

class ContentContainer extends React.Component {
  state = {
    currentFilterSetName: 'Unset',
    hideCognitive: false,
    actualPerformance: true,
    behavioral: true,
    cognitive: true,
    columnsData: this.columns,
    consultativeSkill: true,
    empStatus: true,
    filterDivisions: [],
    filterEmploymentsStatus: [],
    filterFactorComboMatch: [],
    filterFirstScore: [],
    filterOverallScore: [],
    currentFilter: null,
    filtersApply: false,
    pageNumber: 0,
    perfomanceList: [],
    divisions: [],
    isCreateLinkModalOpen: false,
    employeeLines9BoxData: {
      employeeLineFirst: {
        title: 'Performance',
        values: [],
        default: ["LOW", "EFFECTIVE", "HIGH"]
      },
      employeeLineSecond: {
        title: 'Potential',
        values: [],
        default: ["LOW", "MODERATE", "HIGH"]
      }
    },
    defaultSetFilter: {
      filterDivisions: [],
      filterEmploymentsStatus: [],
      filterFactorComboMatch: [],
      filterFirstScore: [],
      filterOverallScore: [],
      filterJobs: [],
      title: 'Unset',
      displayBlocks: {
        behavioralPerformance: true,
        behavioralSuccession: true,
        cognitive: true,
        competencies: true,
        currentSkills: true,
        customSkills: true,
        employmentStatus: true,
        nineBox: true,
        organizationalData: true,
        performance: true,
      },
    },
    employeeSetId: this.props.datasets[0]?.id,
    filters: {
      displayBlocks: {
        behavioralPerformance: true,
        behavioralSuccession: true,
        cognitive: true,
        competencies: true,
        currentSkills: true,
        customSkills: true,
        employmentStatus: true,
        nineBox: true,
        organizationalData: true,
        performance: true,
      },
    },
    generalData: true,
    isLoading: false,
    isEmployeesLoading: false,
    maxWidthTable: 1000,
    linkToShare: null,
    sortType: null,
    sortDirection: null,
    windowStartInnerHeight: window.innerHeight,
    marginBottomStart: 0,
    node: null,
    isActiveMiniLoading: false,
    rowCount: 1,
    order: {
      type: '',
      direction: '',
    },
    isOpenModal: false,
  };

  onScrollTable = () => {
    this.props.onToggleDatePicker(false)
  }

  sortBy = (event, name) => {
    const { sortType, sortDirection } = this.state;
    let newSortDirection = sortDirection;
    let newSortType = sortType;

    if (name === sortType) {
      if (sortDirection === 'downSort') {
        newSortDirection = 'topSort';
      } else if (sortDirection === 'topSort') {
        newSortDirection = null;
      } else {
        newSortDirection = 'downSort';
      }
    } else {
      newSortDirection = 'downSort';
      newSortType = name;
    }

    if (newSortDirection !== sortDirection)
      this.setState({ sortDirection: newSortDirection });
    if (newSortType !== sortType) this.setState({ sortType: newSortType });

    this.addSort(newSortType, newSortDirection);
  }

  requestNotification = (message, success = true) => {
    const notificationSettings = {
      type: success ? 'success' : 'error',
      message: message,
    };
    openNotificationWithIcon(notificationSettings);
    this.setState({ isLoading: false });
  };

  getDivisions() {
    !this.props.onlyView &&
    api.employees.getEmployeesDivisions().then((res) => {
      this.setState({ divisions: res });
    });
  }

  updateEmployee = (value, record, column, arr, arrDefault) => {
    const {selectedDashboard} = this.props;
    if(column==="potential" || column==="perfomance"){
      const letterIndices = arr.reduce((acc, letter, index) => 
      Object.assign(acc, {[letter]: index}), {})
    
      value = arrDefault[letterIndices[value]]
    }
    this.setState({ isLoading: true });
    const params = [
      {
        name: column,
        value: value,
      },
    ];

    return api.employees
      .updateEmployee(record.id, params, this.props.selectedDashboard.id)
      .then(() => api.employees.getEmployee(record.id))
      .then((user) => {
        this.props.updateUser({
          user,
          currentJob: record.currentJob.title,
          userId: record.id,
          division: selectedDashboard.succession ? 'No division' : record.division
        });
        openNotificationWithIcon({type: 'success', message: 'Update was successful'});
        this.setState({ isLoading: false });
        /*this.getEmployees();*/
      })
      .catch((err) => {
        console.log(err);
        const errorMessage = err.response.data.message
          ? err.response.data.message
          : err.response.data;
        console.warn(errorMessage);
      });
  };

  columns = getDashboardColumns(this.state, this.props, this.sortBy, this.requestNotification, this.updateEmployee);

  downloadFileInFormat = (type) => {
    const { selectedDashboard, datasets } = this.props;
    const { employeeSetId, currentFilter } = this.state;

    this.setState({ isActiveMiniLoading: true });

    const queryParams = {
      contentType: type,
      dashboardId: selectedDashboard.id,
      employeeSetId: selectedDashboard.employeeSetId,
      isSuccession: selectedDashboard.succession,
    };

    const filter = getFiltersParams(currentFilter, true);

    api.files
      .generateEmployeesFile(queryParams, filter)
      .then((res) => {
        const { succession, name: dashboardName } = selectedDashboard;
        const dashboardType = succession ? 'succession' : 'perfomance';

        const { name: datasetName } = datasets.find(
          ({ id }) => id === selectedDashboard.employeeSetId
        );

        const name = `${dashboardType}_${dashboardName}_${datasetName}.xlsx`;
        downloadFile(res, name);
        this.setState({ isActiveMiniLoading: false, isLoading: false });
        openNotificationWithIcon({type: 'success', message: 'Downloading was successful'});
      })
      .catch((res) => openNotificationWithIcon({type: 'error', message: res.message}));
  };

  getLinkToDashboard = (liveness) => {
    const {
      /*filterDivisions,
      filterEmploymentsStatus,
      filterFactorComboMatch,
      filterFirstScore,
      filterOverallScore,*/
      currentFilter,
      employeeSetId
    } = this.state;

    const { selectedDashboard } = this.props;
    const setId = currentFilter?.employeeSetId
      ? currentFilter?.employeeSetId
      : selectedDashboard.employeeSetId || employeeSetId;

    const filter = getFiltersParams(currentFilter, true);

    const params = {
      ...filter,
      liveness,
    };


    this.setState(
      () => ({ linkToShare: 'inProgress' }),
      () => {
        const notificationSettings = {
          type: 'error',
          message: `Link wasn't copied to a clipboard`,
        };

        api.employees
          .getLink(params)
          .then((res) => {
            const path = window.location.origin;
            const [id, { token }] = [res.url.match(/\d+$/)[0], res];
            const linkToShare = `${path}/#/dashboardView/${id}?token=${token}${
              selectedDashboard ? `&employeeSetId=${setId}&dashboardId=${selectedDashboard.id}` : ''
            }`;

            this.setState({
              linkToShare,
            });

            try {
              copyTextInBuffer(linkToShare);

              notificationSettings.type = 'success';
              notificationSettings.message = 'Link was copied to a clipboard';

              openNotificationWithIcon(notificationSettings);
            } catch (e) {
              openNotificationWithIcon(notificationSettings);
            }
          })
          .catch(() => {
            this.setState({ linkToShare: null });
            openNotificationWithIcon(notificationSettings);
          })
          .finally(() => {
            this.setState({ isCreateLinkModalOpen: false });
          });
      }
    );
  };

  onGetCurrentFilter = () => {
    const {
      filterDivisions,
      filterEmploymentsStatus,
      filterFactorComboMatch,
      filterFirstScore,
      filterOverallScore,
      actualPerformance,
      behavioral,
      cognitive,
      empStatus,
      consultativeSkill,
      generalData,
    } = this.state;
    return {
      divisionsFilter: {
        divisions: filterDivisions,
      },
      jobsFilter: {
        jobs: [],
      },
      currentFilterLinkData: {
        performanceFilters: {
          title: null,
          filterDivisions,
          filterEmploymentsStatus,
          filterFactorComboMatch,
          filterFirstScore,
          filterOverallScore,

          displayBlocks: {
            actualPerformance,
            behavioral,
            cognitive,
            empStatus,
            consultativeSkill,
            generalData,
          },
        },
        successionFilters: null,
      },
    };
  };

  refreshShowingColumns = () => {
    const columns = getDashboardColumns(this.state, this.props, this.sortBy, this.requestNotification, this.updateEmployee);
    const {
      hideCognitive,
      currentFilter,
    } = this.state;
    const behavioralSuccession = !!currentFilter?.currentFilterLinkData?.performanceFilters?.displayBlocks?.behavioralSuccession
    const behavioralPerformance = !!currentFilter?.currentFilterLinkData?.performanceFilters?.displayBlocks?.behavioralPerformance
    const cognitive = !!currentFilter?.currentFilterLinkData?.performanceFilters?.displayBlocks?.cognitive
    const competencies = !!currentFilter?.currentFilterLinkData?.performanceFilters?.displayBlocks?.competencies
    const currentSkills = !!currentFilter?.currentFilterLinkData?.performanceFilters?.displayBlocks?.currentSkills
    const customSkills = !!currentFilter?.currentFilterLinkData?.performanceFilters?.displayBlocks?.customSkills
    const employmentStatus = !!currentFilter?.currentFilterLinkData?.performanceFilters?.displayBlocks?.employmentStatus
    const nineBox = !!currentFilter?.currentFilterLinkData?.performanceFilters?.displayBlocks?.nineBox
    const organizationalData = !!currentFilter?.currentFilterLinkData?.performanceFilters?.displayBlocks?.organizationalData
    const performance = !!currentFilter?.currentFilterLinkData?.performanceFilters?.displayBlocks?.performance

    const generalDataWidth = 404;
    const cognitiveWidth = 80;
    const consultativeSkillWidth = 382;
    const actualPerformanceWidth = 438;
    const actualCompetenciesWidth = 350;
    const behavioralPerformanceWidth = 475;
    const behavioralSuccessionWidth = 622;
    const empStatusWidth = 65;
    const nineBoxWidth = 375;

    const width =
      275 +
      (cognitive && !hideCognitive ? cognitiveWidth : 0) +
      (currentSkills ? consultativeSkillWidth : 0) +
      (behavioralPerformance ? behavioralPerformanceWidth : 0) +
      (behavioralSuccession ? behavioralSuccessionWidth : 0) +
      (organizationalData ? generalDataWidth : 0) +
      (employmentStatus ? empStatusWidth : 0) +
      (performance ? actualPerformanceWidth : 0) +
      (competencies ? actualCompetenciesWidth : 0) +
      (nineBox ? nineBoxWidth : 0);

    if(nineBox) {
      columns.find(({ title }) => title === '9-box & readiness calculator').children.find(({ dataIndex }) => dataIndex === 'successionData.perfomance').title = this.state.employeeLines9BoxData?.employeeLineFirst?.title || 'Performance';
      columns.find(({ title }) => title === '9-box & readiness calculator').children.find(({ dataIndex }) => dataIndex === 'successionData.potential').title = this.state.employeeLines9BoxData?.employeeLineSecond?.title || 'Potential';
    }

    const result = [
      columns.find(({ title }) => title === 'Employee Information'),
      cognitive &&
      !hideCognitive &&
      columns.find(({ dataIndex }) => dataIndex === 'Cognitive (CA)'),
      behavioralPerformance &&
      columns.find(({ title }) => title === 'BA Performance'),
      behavioralSuccession &&
      columns.find(({ title }) => title === 'BA Succession'),
      currentSkills &&
      columns.find(({ dataIndex }) => dataIndex === 'Current Skill'),
      performance &&
      columns.find(({ dataIndex }) => dataIndex === 'Performance'),
      competencies &&
      columns.find(({ dataIndex }) => dataIndex === 'Competencies'),
      nineBox &&
      columns.find(({ title }) => title === '9-box & readiness calculator'),
      employmentStatus &&
      columns.find(({ dataIndex }) => dataIndex === 'Emp Status'),
      organizationalData &&
      columns.find(({ title }) => title === 'Organizational Data'),
      columns.find(({ title }) => title === 'Action')
    ].filter(Boolean);

    this.setState({
      columnsData: result,
      maxWidthTable: width,
    });
  };

  checkDublicate = (data) => {
    let checkingJob = '';

    const arr = data.filter((item) => {
      if (item.divisionRow) {
        checkingJob = '';
      }

      if (item.jobTitle) {
        if (checkingJob === item?.jobTitle) {
          return false;
        }

        checkingJob = item.jobTitle;
      }

      return item;
    });

    return arr;
  };

  groupingEmployees = (data) => {
    const { divisions, employeeJobs, employeeMap } = data;
    const { rowCount, perfomanceList } = this.state;
    let count = rowCount;
    const newEmployees = [];

    const addHeaderLine = (allData, jobName, divisionName = '', divisonOnly = false) => {
      const divisionData = divisions?.find(({ name }) => name === divisionName);
      const jobTitle = jobName.includes('_supervisor')
        ? jobName.replace(/_supervisor/, ' ')
        : jobName;
      /*console.log(jobName)*/
      const job = employeeJobs.find(({ name }) => name === jobTitle);
      divisonOnly &&
      newEmployees.push({
        id: `division-${count}`,
        divisionRow: divisionName || null,
        divisionId: divisionData ? divisionData.id : 0,
        supervisorJob: allData[jobName][0]?.supervisor ? jobName : null,
        subHeaderRow: true,
        patternImageURL: divisionData?.supervisorJob ? (job ? job.patternImageURL : null) : 'hidden',
        nineBox: !!divisionData?.supervisorJob,
        perfomanceData: {
          firstPerfColumn: divisionData?.supervisorJob ? (job ? job.firstPerformanceColumn : '') : 'hidden',
          secondPerfColumn: divisionData?.supervisorJob ? (job ? job.secondPerformanceColumn : '') : 'hidden',
          thirdPerfColumn: divisionData?.supervisorJob ? (job ? job.thirdPerformanceColumn : '') : 'hidden',
          fourthPerfColumn: divisionData?.supervisorJob ? (job ? job.fourthPerformanceColumn : '') : 'hidden',
          fifthPerfColumn: divisionData?.supervisorJob ? (job ? job.fifthPerformanceColumn : '') : 'hidden',
        },
        behavioralResults: divisionData?.supervisorJob && [
          {
            title: job && job.behavioralHeaders ? job.behavioralHeaders[0] : '',
          },
          {
            title: job && job.behavioralHeaders ? job.behavioralHeaders[1] : '',
          },
          {
            title: job && job.behavioralHeaders ? job.behavioralHeaders[2] : '',
          },
          {
            title: job && job.behavioralHeaders ? job.behavioralHeaders[3] : '',
          },
          {
            title: job && job.behavioralHeaders ? job.behavioralHeaders[4] : '',
          },
          {
            title: job && job.behavioralHeaders ? job.behavioralHeaders[5] : '',
          },
        ],
        competenciesData: divisionData?.supervisorJob && {
          firstCompetenciesColumn:
              job && job.competenciesData
                  ? job.competenciesData.firstCompetenciesColumn
                  : '',
          secondCompetenciesColumn:
              job && job.competenciesData
                  ? job.competenciesData.secondCompetenciesColumn
                  : '',
          thirdCompetenciesColumn:
              job && job.competenciesData
                  ? job.competenciesData.thirdCompetenciesColumn
                  : '',
          fourthCompetenciesColumn:
              job && job.competenciesData
                  ? job.competenciesData.fourthCompetenciesColumn
                  : '',
          fifthCompetenciesColumn:
              job && job.competenciesData
                  ? job.competenciesData.fifthCompetenciesColumn
                  : '',
        },
        cognitiveResult: divisionData?.supervisorJob ? { actual: job ? job.cognitiveTarget : '' } : 'hidden',
      });
      const jobRow = {
        id: `job-${count}`,
        jobTitle: jobTitle,
        subHeaderRow: true,
        patternImageURL: job ? job.patternImageURL : null,
        competenciesData: {
          firstCompetenciesColumn:
              job && job.competenciesData
                  ? job.competenciesData.firstCompetenciesColumn
                  : '',
          secondCompetenciesColumn:
              job && job.competenciesData
                  ? job.competenciesData.secondCompetenciesColumn
                  : '',
          thirdCompetenciesColumn:
              job && job.competenciesData
                  ? job.competenciesData.thirdCompetenciesColumn
                  : '',
          fourthCompetenciesColumn:
              job && job.competenciesData
                  ? job.competenciesData.fourthCompetenciesColumn
                  : '',
          fifthCompetenciesColumn:
              job && job.competenciesData
                  ? job.competenciesData.fifthCompetenciesColumn
                  : '',
        },
        perfomanceData: {
          firstPerfColumn: job ? job.firstPerformanceColumn : '',
          secondPerfColumn: job ? job.secondPerformanceColumn : '',
          thirdPerfColumn: job ? job.thirdPerformanceColumn : '',
          fourthPerfColumn: job ? job.fourthPerformanceColumn : '',
          fifthPerfColumn: job ? job.fifthPerformanceColumn : '',
        },
        behavioralResults: [
          {
            title: job && job.behavioralHeaders ? job.behavioralHeaders[0] : '',
          },
          {
            title: job && job.behavioralHeaders ? job.behavioralHeaders[1] : '',
          },
          {
            title: job && job.behavioralHeaders ? job.behavioralHeaders[2] : '',
          },
          {
            title: job && job.behavioralHeaders ? job.behavioralHeaders[3] : '',
          },
          {
            title: job && job.behavioralHeaders ? job.behavioralHeaders[4] : '',
          },
          {
            title: job && job.behavioralHeaders ? job.behavioralHeaders[5] : '',
          },
        ],
        cognitiveResult: { actual: job ? job.cognitiveTarget : '' },
      };

      if (!divisonOnly && divisionName.length) {
        jobRow.id = `job-division-${count}`;
        jobRow.divisionRow = divisionName;
        jobRow.divisionId = divisionData ? divisionData.id : 0;

        if (divisionData?.supervisorJob) {
          const supervisorJob = employeeJobs.find(
            ({ name }) => name === divisionData.supervisorJob
          );

          if (supervisorJob) {
            const { cognitiveTarget, patternImageURL, behavioralHeaders } =
              supervisorJob;

            jobRow.cognitiveResult = {
              actual: cognitiveTarget,
            };
            jobRow.patternImageURL = patternImageURL || null;
            jobRow.behavioralResults = [
              {
                title: behavioralHeaders ? behavioralHeaders[0] : '',
              },
              {
                title: behavioralHeaders ? behavioralHeaders[1] : '',
              },
              {
                title: behavioralHeaders ? behavioralHeaders[2] : '',
              },
              {
                title: behavioralHeaders ? behavioralHeaders[3] : '',
              },
              {
                title: behavioralHeaders ? behavioralHeaders[4] : '',
              },
              {
                title: behavioralHeaders ? behavioralHeaders[5] : '',
              },
            ];
          }
        }
      }
      newEmployees.push(jobRow);
    };

    for (let name in employeeMap) {
      let jobNumber = 1;
      for (let job in employeeMap[name]) {
        if (jobNumber === 1) {
          job.includes('_supervisor')
            ? addHeaderLine(employeeMap[name],job, name)
            : addHeaderLine(employeeMap[name], job, name, true);
        } else {
          addHeaderLine(employeeMap[name], job);
        }
        // eslint-disable-next-line
        employeeMap[name][job].forEach((item) => {
          item.currentJob = {
            title: job,
          };

          item.canDelete = true;

          item.count = count;
          count++;
          return newEmployees.push(item);
        });
        jobNumber++;
      }
    }

    this.setState({ rowCount: count });

    if (
      perfomanceList.find(
        (item) => item.divisionRow === newEmployees[0]?.divisionRow
      )
    ) {
      newEmployees.splice(0, 1);
    }
    let sorted = newEmployees
    .map((val, index) => ({ val, index }))
    .filter(({val }) => val.supervisor)
    .map(({index}) => index)
    sorted.map((index) => {
      let el = newEmployees[index];
      newEmployees[index] = newEmployees[index - 1];
      newEmployees[index - 1] = el;
    })
    newEmployees.forEach(function (item, index) {
      if (!newEmployees[index + 1]) return;
         if((typeof(item?.id) === 'string' && typeof(newEmployees[index+1]?.id) === 'string' && item?.id.includes('job'))){
             newEmployees.splice(index, 1);
           }
      });
    newEmployees.forEach(function (item, index) {
        if (!newEmployees[index + 1]) return;
           if((typeof(item?.id) === 'string' && typeof(newEmployees[index+1]?.id) === 'string' && item?.id.includes('job'))){
               newEmployees.splice(index, 1);
             }
        });
    if(typeof(newEmployees[newEmployees.length - 1]?.id) === 'string' && newEmployees[newEmployees.length - 1]?.id.includes('job')){
         newEmployees.splice(newEmployees.length - 1, 1);
    }
    if(newEmployees[0]?.supervisor) {
      newEmployees.splice(0, 1);
    }
    return newEmployees;
  };

  groupingEmployeesByJob = (data) => {
    const { employeeJobs, employeeJobMap: employeeMap } = data;
    const { rowCount } = this.state;
    let count = rowCount;
    const newEmployees = [];

    const addHeaderLine = (jobName) => {
      const jobTitle = jobName;
      const job = employeeJobs.find(({ name }) => name === jobTitle);
      const jobRow = {
        id: `job-${count}`,
        jobTitle: jobTitle,
        subHeaderRow: true,
        patternImageURL: job ? job.patternImageURL : null,
        competenciesData: {
          firstCompetenciesColumn:
            job && job.competenciesData
              ? job.competenciesData.firstCompetenciesColumn
              : '',
          secondCompetenciesColumn:
            job && job.competenciesData
              ? job.competenciesData.secondCompetenciesColumn
              : '',
          thirdCompetenciesColumn:
            job && job.competenciesData
              ? job.competenciesData.thirdCompetenciesColumn
              : '',
          fourthCompetenciesColumn:
            job && job.competenciesData
              ? job.competenciesData.fourthCompetenciesColumn
              : '',
          fifthCompetenciesColumn:
            job && job.competenciesData
              ? job.competenciesData.fifthCompetenciesColumn
              : '',
        },
        perfomanceData: {
          firstPerfColumn:
            job && job.firstPerformanceColumn
              ? job.firstPerformanceColumn
              : '',
              secondPerfColumn:
            job && job.secondPerformanceColumn
              ? job.secondPerformanceColumn
              : '',
              thirdPerfColumn:
            job && job.thirdPerformanceColumn
              ? job.thirdPerformanceColumn
              : '',
              fourthPerfColumn:
            job && job.fourthPerformanceColumn
              ? job.fourthPerformanceColumn
              : '',
              fifthPerfColumn:
            job && job.fifthPerformanceColumn
              ? job.fifthPerformanceColumn
              : '',
        },
        behavioralResults: [
          {
            title: job && job.behavioralHeaders ? job.behavioralHeaders[0] : '',
          },
          {
            title: job && job.behavioralHeaders ? job.behavioralHeaders[1] : '',
          },
          {
            title: job && job.behavioralHeaders ? job.behavioralHeaders[2] : '',
          },
          {
            title: job && job.behavioralHeaders ? job.behavioralHeaders[3] : '',
          },
          {
            title: job && job.behavioralHeaders ? job.behavioralHeaders[4] : '',
          },
          {
            title: job && job.behavioralHeaders ? job.behavioralHeaders[5] : '',
          },
        ],
        cognitiveResult: { actual: job ? job.cognitiveTarget : '' },
      };
      newEmployees.push(jobRow);
    };

    for (let job in employeeMap) {
      addHeaderLine(job);
      // eslint-disable-next-line
      employeeMap[job].forEach((item) => {
        item.currentJob = {
          title: job,
        };

        item.canDelete = true;

        item.count = count;
        count++;
        return newEmployees.push(item);
      });
    }

    this.setState({ rowCount: count });
    return newEmployees;
  };

  setEmployees = (table, response) => {
    const { pageNumber, perfomanceList } = this.state;
    const { onGetEmployees, onGetEmployeesFormat, selectedDashboard } = this.props;
    const filteredFormatData =  selectedDashboard.succession
      ? this.groupingEmployeesByJob(response)
      : this.groupingEmployees(response);

    if (pageNumber === 0) {
      this.setState({ isEmployeesLoading: false });
      onGetEmployees(response);
    }
/* 
    if (
      (pageNumber + 1 === response.paginationData.totalPages ||
        pageNumber + 1 > response.paginationData.totalPages) &&
      table
    ) {
      table.addEventListener('scroll', this.onScrollTable);
    } else if (table) {
      table.addEventListener('scroll', this.onScrollTable);
    } */

    if (table) {
      table.addEventListener('scroll', this.onScrollTable);
    }

    const groupedData = [...perfomanceList, ...filteredFormatData];
    const finishedData = {
      employeesFormatted: [...this.checkDublicate(groupedData)],
    };

    if (pageNumber > 0) finishedData.employees = response.employeeMap;
    const finishedDataString = JSON.stringify(finishedData);
    onGetEmployeesFormat(JSON.parse(finishedDataString));

    this.setState({
      /*pageNumber: pageNumber + 1,*/
      perfomanceList: [...JSON.parse(finishedDataString).employeesFormatted],
    }, () => {
      if (pageNumber !== 0) {
        this.setState({ isActiveMiniLoading: false });
      }
    });
  };

  getEmployeesUnAuth = () => {
    const {
      location: { pathname, search },
    } = this.props;
    const { pageNumber, order } = this.state;

    const id = pathname.match(/\d+$/)[0];
    const token = (search && search.match(/token=.+$/)[0]) || '';

    const table = document.querySelector('.ant-table-body');

    if (table) {
      table.removeEventListener('scroll', this.onScrollTable);
    }

    if (pageNumber === 0) {
      this.setState({ isEmployeesLoading: true });
    } else {
      this.setState({ isActiveMiniLoading: true });
    }

    api.employees
      .getEmployeesView(id, token, pageNumber, 40, order.direction, this.props.selectedDashboard.succession, order)
      .then((res) => {
        this.setEmployees(table, res);
        this.setState({ isEmployeesLoading: false, paginationData: res.paginationData, employeeLines9BoxData: res.employeeLines9BoxData, pageNumber: pageNumber + 1 });
        this.refreshShowingColumns();
      })
      .catch((res) => {
        const { data, status } = res['response'];

        switch (status) {
          case 500: {
            return this.setState({
              isEmployeesLoading: false,
              error: 'Bad token, access denied.',
            });
          }
          case 400: {
            !!data &&
            openNotificationWithIcon({
              type: 'error',
              message: 'Bad request',
              description: data,
            });

            return this.setState({
              isEmployeesLoading: false,
              error: 'Token not found, access denied.',
            });
          }
          case 403: {
            return this.setState({
              isEmployeesLoading: false,
              error: 'Token was expired, access denied.',
            });
          }
          default:
            return this.setState({
              isEmployeesLoading: false,
              error: 'Something goes wrong, access denied.',
            });
        }
      });
  };

  getEmployees = (isApplyFilter = false) => {
    const { datasets, selectedDashboard } = this.props;
    const {
      pageNumber,
      order,
      currentFilter
    } = this.state;

    const {
      id,
      isOwned,
      name,
      performanceName,
      succession,
      updatedAt,
      competenciesName,
      createdAt,
      employeeSetId,
      ...otherFilterParams
    } = selectedDashboard;

    if (datasets?.length) {
      const table = document.querySelector('.ant-table-body');

      if (pageNumber === 0) {
        this.setState({
          isEmployeesLoading: true,
          isActiveMiniLoading: false
        });

        table.scrollTop = 0;
      } else {
        this.setState({ isActiveMiniLoading: true });
      }

      let filtersParams = getFiltersParams(currentFilter, true);
      if(filtersParams?.readinessPeriods.length>0){
        filtersParams.readinessPeriods = filtersParams.readinessPeriods.map(u => u !== "Now" ? u : "NOW");
      }
      const params = order.type ? { ...filtersParams, sort: order } : filtersParams;

      if (table) {
        table.removeEventListener('scroll', this.onScrollTable);
      }
      
      const queryParams = {
        page: pageNumber,
        size: 40,
        order: order.direction,
        isSuccessionDashboard: selectedDashboard.succession,
        employeeSetId:
          currentFilter?.employeeSetId ? currentFilter?.employeeSetId  : selectedDashboard.employeeSetId/* || employeeSetId*/,
      };

      api.employees.getPartEmployees(queryParams, params)
        .then((res) => {
          res.employeeLines9BoxData.employeeLineFirst.default =  ["LOW", "EFFECTIVE", "HIGH"]
          res.employeeLines9BoxData.employeeLineSecond.default =  ["LOW", "MODERATE", "HIGH"]
          this.setEmployees(table, res);
          this.setState(
            (prev) => ({
              pageNumber: pageNumber + 1,
              hideCognitive: !res.cognitiveView,
              paginationData: res.paginationData,
              employeeLines9BoxData: !!res.employeeLines9BoxData ? res.employeeLines9BoxData : prev.employeeLines9BoxData,
              divisions: res.divisions?.length ? res.divisions.map(({ name }) => name) : prev.divisions,
              jobs: res.jobNames || [],
              locationNames: res.locationNames || {}
            }),
            this.refreshShowingColumns
          );
        })
        .catch((e) => {
          this.props.onClearEmployees();
          this.setState({employeeLines9BoxData:{
              employeeLineFirst: {
                title: 'Performance',
                values: []
              },
              employeeLineSecond: {
                title: 'Potential',
                values: []
              }
            } });
          this.refreshShowingColumns()
        })
        .finally(() => {
          this.setState({ isActiveMiniLoading: false, isEmployeesLoading: false })
        });
    }
  };

  onChangeCurrentFilter = (currentFilter) => this.setState({ currentFilter })

  onRebuildDashboard = (currentFilter) => {
    this.setState(
      {
        currentFilter,
        perfomanceList: [],
        pageNumber: 0,
        rowCount: 1
      },
      () => {
        this.refreshShowingColumns();
        this.getEmployees();
        currentFilter.isAppliedFilters && this.props.handleApplyNewFilters()
      }
    );
  };

  sortTable = (param) => {
    const { sortType, sortDirection } = this.state;

    const data = param.employeesFormatted || param;
    let sortedData;

    if (sortType === 'FactorComboMatch') {
      sortedData = data;
    } else {
      const formatData = getFormatData({ data, sortType, sortDirection });

      sortedData = Object.keys(formatData).includes('employeesFormatted')
        ? formatData.employeesFormatted
        : formatData;
    }

    let counter = 1;

    return sortedData.map((item) => {
      if (item.count) {
        item.count = counter;
        counter++;
      }

      return item;
    });
  };

  addSort = (sortType, sortDirection) => {
      return this.setState(
        {
          pageNumber: 0,
          perfomanceList: [],
          order: sortDirection
            ? {
              field: sortType,
              type: sortDirection === 'topSort' ? 'ASC' : 'DESC',
            }
            : { field: '', type: '' },
        },
        () => {
          this.props.onlyView ? this.getEmployeesUnAuth() : this.getEmployees();
        }
      );
  };

  handlePresetsFilter = (value) => {
    const { defaultSetFilter } = this.state;
    const { filtersPresets } = this.props;
    const currentFilterSet = value
      ? [...filtersPresets, defaultSetFilter].find((set) => set.title === value)
      : defaultSetFilter;
    const {
      title,
      filterDivisions,
      filterEmploymentsStatus,
      filterFactorComboMatch,
      filterFirstScore,
      filterOverallScore,
      displayBlocks: {
        actualPerformance,
        behavioral,
        cognitive,
        empStatus,
        consultativeSkill,
        generalData,
      },
    } = currentFilterSet;

    this.setState({
      currentFilterSetName: title,
      filterDivisions,
      filterEmploymentsStatus,
      filterFactorComboMatch,
      filterFirstScore,
      filterOverallScore,
      actualPerformance,
      behavioral,
      cognitive,
      empStatus,
      consultativeSkill,
      generalData,
    });
  };

  componentDidMount() {
    const { isNeedToUpdateEmployees, onUpdateEmployees, onlyView, selectedDashboard } = this.props;

    if (isNeedToUpdateEmployees) {
      onUpdateEmployees(false);
    }

    onlyView && this.getEmployeesUnAuth();

    const node = document.querySelector(
      '.ant-table-header.ant-table-hide-scrollbar'
    );
    const table = document.querySelector('.ant-table-body');
    if (table) {
      table.addEventListener('scroll', this.onScrollTable);
    }
    this.getDivisions();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { node: startNode } = this.state;
    if (!startNode) {
      const node = document.querySelector(
        '.ant-table-header.ant-table-hide-scrollbar'
      );
      if (node) {
        const {
          style: { marginBottom },
        } = node;
        const baseMarginBottom = Number.parseInt(marginBottom);
        this.setState({
          windowStartInnerHeight: window.innerHeight,
          marginBottomStart: baseMarginBottom,
          node,
        });
        window.addEventListener('resize', this.onResize);
      }
    }
    const {
      activeFilters,
      employeesFormat,
      isNeedToUpdateEmployees,
      onUpdateEmployees,
      datasets,
      selectedDashboard,
    } = this.props;
    const {
      filtersApply,
      filterDivisions,
      filterEmploymentsStatus,
      filterFactorComboMatch,
      filterFirstScore,
      filterOverallScore,
    } = this.state;

    if (prevProps.datasets.length === 0 && datasets.length !== 0) {

      this.setState({ employeeSetId: datasets[0].id });
      this.getEmployees();
    }

    if (isNeedToUpdateEmployees !== prevProps.isNeedToUpdateEmployees) {
      this.onRebuildDashboard();

      isNeedToUpdateEmployees && onUpdateEmployees(false);
    }

    if (selectedDashboard?.id !== prevProps.selectedDashboard?.id) {
      this.setState({ sortType: null, sortDirection: null, order: {type: '', direction: ''} });
    }

    if (!filtersApply && activeFilters && employeesFormat.length) {
      this.setState(
        {
          filterDivisions,
          filterEmploymentsStatus,
          filterFactorComboMatch,
          filterFirstScore,
          filterOverallScore,
          filtersApply: true,
        },
        () => {
          this.refreshShowingColumns();
          this.props.onGetEmployeesFormat({
            employeesFormatted: employeesFormat,
          });
        }
      );
    }
  }

  componentWillUnmount() {
    const table = document.querySelector('.ant-table-body');
    table.removeEventListener('scroll', this.onScrollTable);
    window.removeEventListener('resize', this.onResize);
  }

  render() {
    const sortedEmployeesFormat = this.sortTable(this.props.employeesFormat);

    const propsObject = {
      ...this.props,
      sortedEmployeesFormat,
      columnsData: this.state.columnsData || this.columns,
      getLinkToDashboard: this.getLinkToDashboard,
      downloadFileInFormat: this.downloadFileInFormat,
      onRebuildDashboard: this.onRebuildDashboard,
      requestNotification: this.requestNotification,
      handlePresetsFilter: this.handlePresetsFilter,
      isFiltersChanged: this.props.isFiltersChanged,
      handleApplyNewFilters: this.props.handleApplyNewFilters,
      onChangeCurrentFilter: this.onChangeCurrentFilter,
      getEmployees: this.props.onlyView ? this.getEmployeesUnAuth : this.getEmployees,
    }

    return (
      <DashboardContent {...propsObject} state={this.state}/>
    )
  }
}

const mapStateToProps = (state) => ({
  divisions: selectDivisions(state),
  employees: selectEmployees(state),
  employeesFormat: selectEmployeesFormat(state),
  employeeJobs: selectEmployeesJobs(state),
  employeePerformance: selectEmployeePerformance(state),
  employeePotential: selectEmployeePotential(state),
  employeeRatings: selectEmployeeRatings(state),
  employeeReadiness: selectEmployeeReadiness(state),
  employeeStatuses: selectEmployeeStatuses(state),
  lastUpdate: selectLastUpdate(state),
  filtersPresets: selectFiltersPresets(state),
  isNeedToUpdateEmployees: selectIsNeedToUpdateEmployees(state),
  user: selectUser(state),
  roles: selectUserRole(state),
  isOpenedDatePicker: selectDatePickerOpened(state),
});

const mapDispatchToProps = (dispatch) => ({
  onGetUpdatedFilters: (data) => dispatch(updateFilters(data)),
  onGetEmployees: (data) => dispatch(getEmployees(data)),
  onGetEmployeesFormat: (data) => dispatch(getEmployeesFormat(data)),
  updateUser: ({ user, currentJob, userId, division }) =>
    dispatch(updateUser({ user, currentJob, userId, division })),
  removeEmployee: ({ currentJob, userId, division }) =>
    dispatch(removeEmployee({ currentJob, userId, division })),
  onUpdateEmployees: (data) => dispatch(updateEmployees(data)),
  onClearEmployees: data => dispatch(clearEmployees(data)),
  onToggleDatePicker: data => dispatch(toggleDatePicker(data))
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ContentContainer,
);
