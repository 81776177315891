import api from '../api';

export const getDashboards = ( ) =>
    api.get(`/dashboards`).then((res) => res.data);

export const addDashboard = (params) =>
    api.post('/dashboards', params).then((res) => res.data);

export const updateDashboard = (params) =>
    api.put('/dashboards', params).then((res) => res.data);

export const deleteDashboard = (id) =>
    api.delete(`/dashboards?dashboardId=${id}`).then((res) => res.data);
