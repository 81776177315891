import React from 'react';

import {faSort, faSortDown, faSortUp} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import cn from 'classnames'

import styles from './SortIcon.module.scss';

const SortIcon = ({ type, name, text, onClick, Component, onlyView }) => {
  const getIcon = (type) => {
    switch(type) {
      case 'downSort':
        return faSortDown
      case 'topSort':
        return faSortUp
      default:
        return faSort
    }
  }
      return (
        <>
          {Component  || <span>{text}</span>}
          <div onClick={(e) => onClick(e, name)} style={{marginLeft: 5, cursor: 'pointer'}}>
            <FontAwesomeIcon icon={getIcon(type)} className={cn(type && styles.red)}/>
          </div>
        </>
      );
};

export default SortIcon;
