import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './LoaderMini.module.scss';

const LoaderMini = ({
  className,
  visible,
}) => (
  <div  className={visible?`${styles.wrapper}`: ''}>
    <div
      className={classnames(styles.loader, className, {
        [styles.visible]: visible,
      })}
    />
  </div>
);

LoaderMini.propTypes = {
  className: PropTypes.string,
  visible: PropTypes.bool.isRequired,
};

LoaderMini.defaultProps = {
  className: '',
};

export default LoaderMini;
