export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const CLEAR_AUTH_FAIL = 'CLEAR_AUTH_FAIL';

export const PERFOMANCE_CLEAR_EMPLOYEES_SUCCESS =
  'PERFOMANCE_CLEAR_EMPLOYEES_SUCCESS';
export const PERFOMANCE_GET_EMPLOYEES_FORMAT =
  'PERFOMANCE_GET_EMPLOYEES_FORMAT';
export const PERFOMANCE_UPDATE_EMPLOYEE_MAP = 'PERFOMANCE_UPDATE_EMPLOYEE_MAP';
export const PERFOMANCE_GET_EMPLOYEES_SUCCESS =
  'PERFOMANCE_GET_EMPLOYEES_SUCCESS';
export const PERFOMANCE_UPDATE_EMPLOYEE = 'PERFOMANCE_UPDATE_EMPLOYEE';
export const PERFOMANCE_REMOVE_EMPLOYEE = 'PERFOMANCE_REMOVE_EMPLOYEE';
export const PERFOMANCE_UPDATE_EMPLOYEES = 'PERFOMANCE_UPDATE_EMPLOYEES';

export const SUCCESSION_CLEAR_EMPLOYEES_SUCCESS =
  'SUCCESSION_CLEAR_EMPLOYEES_SUCCESS';
export const SUCCESSION_GET_EMPLOYEES_FORMAT =
  'SUCCESSION_GET_EMPLOYEES_FORMAT';
export const SUCCESSION_GET_EMPLOYEES_SUCCESS =
  'SUCCESSION_GET_EMPLOYEES_SUCCESS';
export const SUCCESSION_UPDATE_EMPLOYEE = 'SUCCESSION_UPDATE_EMPLOYEE';
export const SUCCESSION_REMOVE_EMPLOYEE = 'SUCCESSION_REMOVE_EMPLOYEE';
export const SUCCESSION_UPDATE_EMPLOYEES = 'SUCCESSION_UPDATE_EMPLOYEES';

export const CLEAR_EMPLOYEES_SUCCESS_VIEW = 'CLEAR_EMPLOYEES_SUCCESS_VIEW';
export const GET_EMPLOYEES_FORMAT_VIEW = 'GET_EMPLOYEES_FORMAT_VIEW';
export const GET_EMPLOYEES_SUCCESS_VIEW = 'GET_EMPLOYEES_SUCCESS_VIEW';

export const GET_COMPANIES_MANAGMENT_SUCCESS =
  'GET_COMPANIES_MANAGMENT_SUCCESS';
export const GET_COUNTRIES_SUCCESS = 'GET_COUNTRIES_SUCCESS';
export const GET_STATES_SUCCESS = 'GET_STATES_SUCCESS';
export const GET_ORGANIZATION_SIZES_SUCCESS = 'GET_ORGANIZATION_SIZES_SUCCESS';
export const GET_COMPANY_INDUSTRIES_SUCCESS = 'GET_COMPANY_INDUSTRIES_SUCCESS';
export const GET_USERS_MANAGMENT_SUCCESS = 'GET_USERS_MANAGMENT_SUCCESS';
export const IS_NEED_UPDATE_COMPANIES = 'IS_NEED_UPDATE_COMPANIES';

export const PERFOMANCE_LAST_UPDATE_CLEAR = 'PERFOMANCE_LAST_UPDATE_CLEAR';
export const PERFOMANCE_LAST_UPDATE_SUCCESS = 'PERFOMANCE_LAST_UPDATE_SUCCESS';

export const PERFOMANCE_UPDATE_FILTERS_SUCCESS =
  'PERFOMANCE_UPDATE_FILTERS_SUCCESS';

export const SUCCESSION_UPDATE_FILTERS_SUCCESS =
  'SUCCESSION_UPDATE_FILTERS_SUCCESS';

export const SUCCESSION_LAST_UPDATE_CLEAR = 'SUCCESSION_LAST_UPDATE_CLEAR';
export const SUCCESSION_LAST_UPDATE_SUCCESS = 'SUCCESSION_LAST_UPDATE_SUCCESS';

export const LAST_UPDATE_CLEAR_VIEW = 'LAST_UPDATE_CLEAR_VIEW';
export const LAST_UPDATE_SUCCESS_VIEW = 'LAST_UPDATE_SUCCESS_VIEW';

export const LOGOUT = 'LOGOUT';

export const USER_DATA = 'USER_DATA';

export const USER_CHANGE_ROLE = 'USER_CHANGE_ROLE';
export const USER_ROLE_GETBACK = 'USER_ROLE_GETBACK';
export const GET_USER = 'GET_USER';

export const TOGGLE_DATE_PICKER = 'TOGGLE_DATE_PICKER';
