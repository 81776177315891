import {
  PERFOMANCE_CLEAR_EMPLOYEES_SUCCESS,
  PERFOMANCE_GET_EMPLOYEES_FORMAT,
  PERFOMANCE_UPDATE_EMPLOYEE_MAP,
  PERFOMANCE_GET_EMPLOYEES_SUCCESS,
  PERFOMANCE_LAST_UPDATE_CLEAR,
  PERFOMANCE_LAST_UPDATE_SUCCESS,
  PERFOMANCE_UPDATE_FILTERS_SUCCESS,
  PERFOMANCE_UPDATE_EMPLOYEE,
  PERFOMANCE_REMOVE_EMPLOYEE,
  PERFOMANCE_UPDATE_EMPLOYEES,
  TOGGLE_DATE_PICKER,
} from '../../../constants/constants';

export const getEmployees = (data) => ({
  type: PERFOMANCE_GET_EMPLOYEES_SUCCESS,
  data,
});

export const getEmployeesFormat = (data) => ({
  type: PERFOMANCE_GET_EMPLOYEES_FORMAT,
  data,
});

export const updateEmployeeMap = (data) => ({
  type: PERFOMANCE_UPDATE_EMPLOYEE_MAP,
  data,
});

export const clearEmployees = (data) => ({
  type: PERFOMANCE_CLEAR_EMPLOYEES_SUCCESS,
  data,
});

export const lastUpdateData = (data) => ({
  type: PERFOMANCE_LAST_UPDATE_SUCCESS,
  data,
});

export const lastUpdateDataClear = () => ({
  type: PERFOMANCE_LAST_UPDATE_CLEAR,
});

export const updateFilters = (data) => ({
  type: PERFOMANCE_UPDATE_FILTERS_SUCCESS,
  data,
});

export const updateUser = (data) => ({
  type: PERFOMANCE_UPDATE_EMPLOYEE,
  data,
});

export const removeEmployee = (data) => ({
  type: PERFOMANCE_REMOVE_EMPLOYEE,
  data,
});

export const updateEmployees = (isNeedToUpdateEmployees) => ({
  type: PERFOMANCE_UPDATE_EMPLOYEES,
  isNeedToUpdateEmployees,
});

export const toggleDatePicker = (data) => ({
  type: TOGGLE_DATE_PICKER,
  data,
});