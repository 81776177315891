import React, { PureComponent } from 'react';
import { Select } from 'antd';

import api from '../../../../api';
import { CustomModal } from '../../../admin-pages/components';

import {faPencilAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import styles from './JobSetTable.module.scss';

const { Option } = Select;

class JobSetTable extends PureComponent {
  state = {
    isOpenModal: false,
    selectedJobId: null,
    requestInProcess: false,
    failToSet: false,
  };

  ref = React.createRef();

  showTable = () => this.setState({ isOpenModal: true });

  onChange = (val) => {
    this.setState({ selectedJobId: val });
  };

  handleSetJob = () => {
    const { selectedJobId } = this.state;
    const { jobNumber, userId, currentJob, division, dashboardId, isSuccession } = this.props;


    const request = selectedJobId
      ? api.employees.addJobBehavioralResults(jobNumber, userId, {
        jobId: selectedJobId,
      },dashboardId)
      : api.employees.deleteJobBehavioralResults(jobNumber, userId);

    this.setState({ requestInProcess: true, failToSet: false });

    request
      .then(() => api.employees.getEmployee(userId))
      .then((user) => {
        this.props.updateUser({ user, currentJob, division: isSuccession ? 'No division' : division, userId })
      })
      .then(() =>
      this.setState({ requestInProcess: false, isOpenModal: false })
      )
      .catch((e) => {
        this.setState({ requestInProcess: false, failToSet: true })
      });
  };

  onCancelModal = () => {
    this.setState({
      isOpenModal: false,
      selectedJobId: null,
      requestInProcess: false,
      failToSet: false,
    });
  };

  render() {
    const {
      isOpenModal,
      requestInProcess,
      failToSet,
      selectedJobId,
    } = this.state;
    const { jobId, employeeJobs, onlyView } = this.props;

    const jobs = employeeJobs.map((el) => (
      <Option key={el.id} value={el.id}>
        {el.name}
      </Option>
    ));
    const defaultValue = jobId || undefined;

    return (
      <section className={styles.wrapper}>
        {defaultValue ? (
          <div
            className={styles.pointer}
            style={{cursor: onlyView ? 'default' : 'pointer'}}
            onClick={onlyView ? () => null : this.showTable}>
            <span className={styles.noWrap}>
              {employeeJobs.find((el) => el.id === defaultValue)?.name}
            </span>
            {!onlyView && (
              <div className={styles.iconPencil}>
                <FontAwesomeIcon icon={faPencilAlt}/>
              </div>
            )}
          </div>
        ) : (
          <div
            className={styles.pointer}
            style={{cursor: onlyView ? 'default' : 'pointer'}}
            onClick={onlyView ? () => null : this.showTable}>
            {onlyView ? (
              <span className={styles.link}>Not selected</span>
            ) : (
              <span className={styles.link}>Select job</span>
            )}
          </div>
        )}
        <CustomModal
          visible={isOpenModal}
          onCancel={this.onCancelModal}
          className={styles.modalWrap}>
          <div className={styles.innerWrap}>
            <div className={styles.content}>
              <div className={styles.select}>
                <Select
                  ref={this.ref}
                  showSearch
                  disabled={requestInProcess}
                  style={{ width: 400 }}
                  defaultValue={defaultValue}
                  placeholder='click to select'
                  optionFilterProp='children'
                  onChange={this.onChange}
                  allowClear={!!jobId}
                  clearIcon='x'>
                  {jobs}
                </Select>
              </div>
              <div className={styles.btnWrapper}>
                <button
                  onClick={this.onCancelModal}
                  className={styles.stepsBtn}>
                  Cancel
                </button>
                <button
                  disabled={requestInProcess || selectedJobId === null}
                  onClick={this.handleSetJob}
                  className={styles.stepsBtn}>
                  Save
                </button>
              </div>
              {failToSet && (
                <div className={styles.errorText}>Fail to set a job</div>
              )}
            </div>
          </div>
        </CustomModal>
      </section>
    );
  }
}
export default JobSetTable;
