import { createSelector } from 'reselect';

const rootSelector = state => state.adminPart;

export const selectCompaniesData = createSelector(
  [rootSelector],
  state => state.companiesData,
);

export const selectCompanies = createSelector(
  [rootSelector],
  state => state.companies,
);

export const selectCountries = createSelector(
  [rootSelector],
  state => state.countries,
);

export const selectStates = createSelector(
  [rootSelector],
  state => state.states,
);

export const selectOrganizationSizes = createSelector(
  [rootSelector],
  state => state.organizationSizes,
);

export const selectCompanyIndustries = createSelector(
  [rootSelector],
  state => state.companyIndustries,
);

export const selectIsUpdateCompanies = createSelector(
  [rootSelector],
  state => state.isNeedUpdateCompaniesData,
);

export const selectUsers = createSelector(
  [rootSelector],
  state => state.users,
);

export const selectRoles = createSelector(
  [rootSelector],
  state => state.roles,
);
