import { createSelector } from 'reselect';

const rootSelector = state => state.dashboardView;

export const selectDivisions = createSelector(
  [rootSelector],
  state => state.divisions,
);

export const selectEmployees = createSelector(
  [rootSelector],
  state => state.employees,
);

export const selectEmployeesFormat = createSelector(
  [rootSelector],
  state => state.employeesFormat,
);

export const selectEmployeesJobs = createSelector(
  [rootSelector],
  state => state.employeeJobs,
);

export const selectEmployeeLines = createSelector(
  [rootSelector],
  state => state.employeeLines,
);

export const selectEmployeePerformance = createSelector(
  [rootSelector],
  state => state.employeePerformance,
);

export const selectEmployeePotential = createSelector(
  [rootSelector],
  state => state.employeePotential,
);

export const selectEmployeeRatings = createSelector(
  [rootSelector],
  state => state.employeeRatings,
);

export const selectEmployeeReadiness = createSelector(
  [rootSelector],
  state => state.employeeReadiness,
);

export const selectEmployeeStatuses = createSelector(
  [rootSelector],
  state => state.employeeStatuses,
);

export const selectLastUpdate = createSelector(
  [rootSelector],
  state => state.lastUpdate,
);

export const selectPIKey = createSelector(
  [rootSelector],
  state => state.piApiKey,
);

export const selectActiveFilters = createSelector(
  [rootSelector],
  state => state.activeFilters,
);
