import React, { PureComponent } from 'react';

import api from '../../../../api';
import { CustomModal } from '../../../admin-pages/components';
import styles from './CalculatorTable.module.scss';
import {Tooltip} from "antd";

const hovers = {
  highPotential: 'Has significant potential to move up (usually at least two levels in the organization) and/or move into other functional areas or development assignments quickly and proficiently.',
  coreContributor: 'A candidate for another role at their current level, or in some cases with reasonable development/growth, could move to a role one level up in the organization.',
  pro: 'Often a deep subject matter expert and/or person in a specialized function who is well-placed in that role and development should be focused on deepening and broadening that expertise and its impact on the organization.',
  issue: 'Performance is an issue (which can include someone new in role) and is the issue that needs to be addressed before any consideration of any upward or lateral development should occur.',
};

const dataInit = [
  {
    id: 5,
    colorClass: styles.colorOne,
    colorFill: '#F9D772',
    hover: hovers.coreContributor
  },
  {
    id: 2,
    colorClass: styles.colorTwo,
    colorFill: '#28A181',
    hover: hovers.highPotential
  },
  {
    id: 1,
    colorClass: styles.colorTwo,
    colorFill: '#28A181',
    hover: hovers.highPotential
  },
  {
    id: 8,
    colorClass: styles.colorThree,
    colorFill: '#C8C8C8',
    hover: hovers.issue
  },
  {
    id: 6,
    colorClass: styles.colorOne,
    colorFill: '#F9D772',
    hover: hovers.coreContributor
  },
  {
    id: 3,
    colorClass: styles.colorTwo,
    colorFill: '#28A181',
    hover: hovers.highPotential
  },
  {
    id: 9,
    colorClass: styles.colorThree,
    colorFill: '#C8C8C8',
    hover: hovers.issue
  },
  {
    id: 7,
    colorClass: styles.colorThree,
    colorFill: '#C8C8C8',
    hover: hovers.coreContributor
  },
  {
    id: 4,
    colorClass: styles.colorOne,
    colorFill: '#F9D772',
    hover: hovers.pro
  },
];

const dataRevertLabels = [
  {
    id: 4,
    colorClass: styles.colorOne,
    colorFill: '#F9D772',
    hover: hovers.pro
  },
  {
    id: 3,
    colorClass: styles.colorTwo,
    colorFill: '#28A181',
    hover: hovers.highPotential
  },
  {
    id: 1,
    colorClass: styles.colorTwo,
    colorFill: '#28A181',
    hover: hovers.highPotential
  },
  {
    id: 7,
    colorClass: styles.colorThree,
    colorFill: '#C8C8C8',
    hover: hovers.coreContributor
  },
  {
    id: 6,
    colorClass: styles.colorOne,
    colorFill: '#F9D772',
    hover: hovers.coreContributor
  },
  {
    id: 2,
    colorClass: styles.colorTwo,
    colorFill: '#28A181',
    hover: hovers.highPotential
  },
  {
    id: 9,
    colorClass: styles.colorThree,
    colorFill: '#C8C8C8',
    hover: hovers.issue
  },
  {
    id: 8,
    colorClass: styles.colorThree,
    colorFill: '#C8C8C8',
    hover: hovers.issue
  },
  {
    id: 5,
    colorClass: styles.colorOne,
    colorFill: '#F9D772',
    hover: hovers.coreContributor
  },
];


const dataRevertValues = [
  {
    id: 4,
    colorClass: styles.colorOne,
    colorFill: '#F9D772',
    hover: hovers.pro
  },
  {
    id: 7,
    colorClass: styles.colorThree,
    colorFill: '#C8C8C8',
    hover: hovers.coreContributor
  },
  {
    id: 9,
    colorClass: styles.colorThree,
    colorFill: '#C8C8C8',
    hover: hovers.issue
  },
  {
    id: 3,
    colorClass: styles.colorTwo,
    colorFill: '#28A181',
    hover: hovers.highPotential
  },
  {
    id: 6,
    colorClass: styles.colorOne,
    colorFill: '#F9D772',
    hover: hovers.coreContributor
  },
  {
    id: 8,
    colorClass: styles.colorThree,
    colorFill: '#C8C8C8',
    hover: hovers.issue
  },
  {
    id: 1,
    colorClass: styles.colorTwo,
    colorFill: '#28A181',
    hover: hovers.highPotential
  },
  {
    id: 2,
    colorClass: styles.colorTwo,
    colorFill: '#28A181',
    hover: hovers.highPotential
  },
  {
    id: 5,
    colorClass: styles.colorOne,
    colorFill: '#F9D772',
    hover: hovers.coreContributor
  },
];

const dataRevertValuesAndLabels = [
  {
    id: 5,
    colorClass: styles.colorOne,
    colorFill: '#F9D772',
    hover: hovers.coreContributor
  },
  {
    id: 8,
    colorClass: styles.colorThree,
    colorFill: '#C8C8C8',
    hover: hovers.issue
  },
  {
    id: 9,
    colorClass: styles.colorThree,
    colorFill: '#C8C8C8',
    hover: hovers.issue
  },
  {
    id: 2,
    colorClass: styles.colorTwo,
    colorFill: '#28A181',
    hover: hovers.highPotential
  },
  {
    id: 6,
    colorClass: styles.colorOne,
    colorFill: '#F9D772',
    hover: hovers.coreContributor
  },
  {
    id: 7,
    colorClass: styles.colorThree,
    colorFill: '#C8C8C8',
    hover: hovers.coreContributor
  },
  {
    id: 1,
    colorClass: styles.colorTwo,
    colorFill: '#28A181',
    hover: hovers.highPotential
  },
  {
    id: 3,
    colorClass: styles.colorTwo,
    colorFill: '#28A181',
    hover: hovers.highPotential
  },
  {
    id: 4,
    colorClass: styles.colorOne,
    colorFill: '#F9D772',
    hover: hovers.pro
  },
];


const infoIconWithToolTip = ({colorFill, toolTipText}) => (
  <Tooltip title={toolTipText}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24">
      <g fill={colorFill}>
        <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2.033 16.01c.564-1.789 1.632-3.932 1.821-4.474.273-.787-.211-1.136-1.74.209l-.34-.64c1.744-1.897 5.335-2.326 4.113.613-.763 1.835-1.309 3.074-1.621 4.03-.455 1.393.694.828 1.819-.211.153.25.203.331.356.619-2.498 2.378-5.271 2.588-4.408-.146zm4.742-8.169c-.532.453-1.32.443-1.761-.022-.441-.465-.367-1.208.164-1.661.532-.453 1.32-.442 1.761.022.439.466.367 1.209-.164 1.661z"/>
      </g>
    </svg>
  </Tooltip>
);

class CalculatorTable extends PureComponent {
  state = {
    boxData: [],
    isOpenModal: false,
  };

  showModal = () => {
    const { jobName, employeeJobs, employeesFormat } = this.props;
    const job = employeeJobs.find(el => el.name === jobName);
    this.setState({isOpenModal: true});
    api.employees.getJobSuccession(job?.id || 0)
      .then(res => {
        for (let key in res.boxIndexToEmployeeMap) {
          res.boxIndexToEmployeeMap[key] = res.boxIndexToEmployeeMap[key].filter(obj => employeesFormat.find(el => el.email === obj.email))
        }
        return res
      })
      .then(res => this.setState({boxData: res.boxIndexToEmployeeMap}))
      .catch(res => console.log(res));
  };

  render() {
    const { idArr = [], isJob = false, isRevertValues, isRevertLabels, onlyView } = this.props;
    const { boxData, isOpenModal } = this.state;
    const data = isRevertLabels ? isRevertValues ? dataRevertValuesAndLabels : dataRevertLabels : isRevertValues ? dataRevertValues : dataInit;
    return (
      <section className={styles.wrapper}>
        { data.map(({ colorClass, id }) => {
          let isShowColor;
          idArr.map(item => {
            if (!item) return
            if (item === id) {
              return isShowColor = true
            }
            return isShowColor
          });

          return (
            <div
              className={styles.item}
              onClick={isJob && !onlyView ? this.showModal : () => null}
              key={id}
            >
              <span className={`${isShowColor && colorClass} ${styles.circle}`} />
            </div>
          )
        })}
        <CustomModal
          visible={isJob && isOpenModal}
          onCancel={() => this.setState({isOpenModal: false})}
          className={styles.modalWrap}
          closable
        >
          {isJob   && isOpenModal &&
            <div className={styles.innerWrap}>
              {data.map(({ id, colorFill, hover }) => {
                let showData = [];
                let count = 1;

                for (let item in boxData) {
                  if (+item === +id) {
                    showData = boxData[item]
                  }
                }

                return (
                  <div className={styles.itemModal} key={id}>
                    <ul className={styles.boxListWrap}>
                      {showData.map(({ fullName, email }, index) => (
                        <li className={styles.boxListItemWrap} key={index}>
                          <span className={styles.index}>{count++}</span>
                          <span className={styles.boxListItem}>{fullName}</span>
                        </li>
                      ))}
                    </ul>
                    <div className={`${styles.circleInModal}`} >
                      {
                        infoIconWithToolTip({
                          colorFill,
                          toolTipText: hover
                        })
                      }
                    </div>
                  </div>
                )
              })}
            </div>
          }
        </CustomModal>
      </section>
    )
  }
}
export default CalculatorTable;
