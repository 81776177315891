import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import styles from './Loader.module.scss';

class LoaderPortal extends Component {
  el = document.createElement('div');

  componentDidMount() {
    document.body.appendChild(this.el);
  }

  componentWillUnmount() {
    document.body.removeChild(this.el);
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.el);
  }
};

class Loader extends Component {
  render() {
    return (
      <LoaderPortal>
        <div className={`${styles.wrapper}`}>
          <div className={styles.loader}>></div>
        </div> 
      </LoaderPortal>  
    )
  }
};

export default Loader;
