import React, { PureComponent } from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';

import api from '../../../../api';
import { openNotificationWithIcon } from '../../../../utils';

import { CustomModal } from '../../../admin-pages/components';

import {faPencilAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import styles from './SetTerminationDateTable.module.scss';

class SetTerminationDateTable extends PureComponent {
  state = {
    isOpenModal: false,
    requestInProcess: false,
    terminationDateNew: null,
  };

  showTable = () =>
    this.setState({ isOpenModal: true, terminationDateNew: null });

  handleSetTerminationDate = () => {
    const { userId, currentJob, division, employeeStatus, terminationDate } =
      this.props;
    const { terminationDateNew } = this.state;
    const params = {
      id: userId,
      terminationDate: terminationDateNew.toISOString(true),
    };

    this.setState({ requestInProcess: true });

    api.employees
      .setTerminationDate(params)
      .then(() => api.employees.getEmployee(userId))
      .then((user) =>
        this.props.updateUser({ user, currentJob, userId, division })
      )
      .then(() => {
        this.requestNotification(
          `Termination date was successfully ${terminationDate ? 'updated' : 'added'}`,
          true
        );
        this.setState({
          isOpenModal: false,
          terminationDateNew: null,
        });
      })
      .catch(() => {
        const message =
          employeeStatus === 'AP'
            ? 'Impossible to set a termination date for the applicant'
            : "The date isn't updating";

        this.requestNotification(message, false);
        this.setState({
          isOpenModal: false,
        });
      });
  };

  disabledDate = (current) => {
    const { dateOfHire } = this.props;
    if (!dateOfHire) return false;
    // Can not select days before dateOfHire
    return current && current < moment(dateOfHire);
  };

  setTerminationDateNew = (date) => {
    this.setState({ terminationDateNew: date });
  };

  onCancelModal = () => {
    this.setState({
      isOpenModal: false,
      requestInProcess: false,
    });
  };

  requestNotification = (message, success = true) => {
    const notificationSettings = {
      type: success ? 'success' : 'error',
      message: message,
    };
    openNotificationWithIcon(notificationSettings);
    this.setState({ requestInProcess: false });
  };

  render() {
    const dateFormat = 'YYYY-MM-DD';
    const { isOpenModal, requestInProcess, terminationDateNew } = this.state;
    const {
      periodOfWork,
      dateOfHire,
      terminationDate,
      onlyView,
      employeeStatus,
    } = this.props;
    let parsedPeriodOfWork;
    if (periodOfWork) {
      parsedPeriodOfWork = periodOfWork.replace(/^\r?\n|\r/, '');
    }

    return (
      <section className={styles.wrapper}>
        {onlyView ? (
          <div className={styles.pointer}>
            <span className={styles.noWrap}>{parsedPeriodOfWork || '-'}</span>
          </div>
        ) : (
          <div className={styles.pointer} onClick={this.showTable}>
            <span className={styles.noWrap}>{parsedPeriodOfWork || '-'}</span>
            <div className={styles.iconPencil}>
              <FontAwesomeIcon icon={faPencilAlt}/>
            </div>
          </div>
        )}
        <CustomModal
          visible={isOpenModal}
          onCancel={this.onCancelModal}
          className={styles.modalWrap}>
          <div className={styles.innerWrap}>
            <div className={styles.content}>
              <div className={styles.dates}>
                <span>Start Date</span>
                <DatePicker
                  placeholder={`Date of hire isn't set`}
                  defaultValue={
                    dateOfHire ? moment(dateOfHire, dateFormat) : undefined
                  }
                  format={dateFormat}
                  disabled={true}
                />
              </div>
              <div className={styles.dates}>
                <span>Termination Date</span>
                <DatePicker
                  disabled={requestInProcess || employeeStatus === 'AP'}
                  disabledDate={this.disabledDate}
                  defaultValue={
                    terminationDate
                      ? moment(terminationDate, dateFormat)
                      : undefined
                  }
                  format={dateFormat}
                  onChange={(date) =>
                    this.setState({ terminationDateNew: date })
                  }
                />
              </div>
            </div>
            {employeeStatus === 'AP' && (
              <div className={styles.underContent}>
                <p>Impossible to set a termination date for the applicant</p>
              </div>
            )}
            <div className={styles.btnWrapper}>
              <button onClick={this.onCancelModal} className={styles.stepsBtn}>
                Cancel
              </button>
              <button
                disabled={requestInProcess || !terminationDateNew}
                onClick={this.handleSetTerminationDate}
                className={styles.stepsBtn}>
                Save
              </button>
            </div>
          </div>
        </CustomModal>
      </section>
    );
  }
}
export default SetTerminationDateTable;
