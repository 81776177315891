import {
  GET_COMPANIES_MANAGMENT_SUCCESS,
  GET_USERS_MANAGMENT_SUCCESS,
  GET_COUNTRIES_SUCCESS,
  GET_STATES_SUCCESS,
  GET_ORGANIZATION_SIZES_SUCCESS,
  GET_COMPANY_INDUSTRIES_SUCCESS,
  IS_NEED_UPDATE_COMPANIES,
} from '../../constants/constants';

export const getUsersManagment = data => ({
  type: GET_USERS_MANAGMENT_SUCCESS,
  data,
});

export const getCompaniesManagment = data => ({
  type: GET_COMPANIES_MANAGMENT_SUCCESS,
  data,
});

export const getCountries = data => ({
  type: GET_COUNTRIES_SUCCESS,
  data,
});

export const getStates = data => ({
  type: GET_STATES_SUCCESS,
  data,
});

export const getOrganizationSizes = data => ({
  type: GET_ORGANIZATION_SIZES_SUCCESS,
  data,
});

export const getCompanyIndustries = data => ({
  type: GET_COMPANY_INDUSTRIES_SUCCESS,
  data,
});

export const isUpdateCompanies = data => ({
  type: IS_NEED_UPDATE_COMPANIES,
  data,
});
