import React, { PureComponent } from "react";
import { Checkbox, Collapse, Select, Table, Tooltip } from "antd";
import { compose } from "recompose";
import { connect } from "react-redux";

import { ReactComponent as IconApply } from "../../../images/icons/apply.svg";
import { ReactComponent as IconSave } from "../../../images/icons/save.svg";
import { ReactComponent as IconTrash } from "../../../images/icons/trashDarkGray.svg";
import Icon_3 from "../../../images/icons/csv.svg";
import Icon_4 from "../../../images/icons/chainLinks.svg";
import Icon_5 from  "../../../images/icons/copy.svg"

import {
  downloadFile,
  getFormatData,
  openNotificationWithIcon,
  copyTextInBuffer,
} from "../../../utils";
import api from "../../../api";
import { selectUser } from '../Login/selectors';

import {
  CalculatorTable,
  ConfirmDelSave,
  SetTerminationDateTable,
  SetStatusTable,
  SortIcon,
} from "../components";
import { Loader, LoaderMini } from "../../../components";
import DataSetBadges from "../components/DatasetBadges/DatasetBadges";

import {
  behavioralTooltip,
  cognitiveTooltip,
  consultativeTooltip,
  factorTooltip,
  headerTooltip,
  plansData,
} from "./tooltipsData";
import {
  getEmployees,
  getEmployeesFormat,
  updateFilters,
  updateUser,
  removeEmployee,
  updateEmployees,
} from "./actions";
import {
  selectDivisions,
  selectEmployeePerformance,
  selectEmployeePotential,
  selectEmployeeRatings,
  selectEmployeeReadiness,
  selectEmployeeStatuses,
  selectEmployees,
  selectEmployeesFormat,
  selectEmployeesJobs,
  selectFiltersPresets,
  selectLastUpdate,
  selectIsNeedToUpdateEmployees,
} from "./selectors";
import CreateLinkModal from "./CreateLinkModal";
import styles from "./Perfomance.module.scss";
import CreateDashBoardModal from '../components/CreateDashBoardModal/CreateDashBoardModal';

const { Panel } = Collapse;
const {
  behavioralJob,
  cognitive,
  cognitiveJob,
  consultativeSkill,
  factorCombinations,
  firstScoreText,
  empStatusText,
  overalScoreText,
  pattern,
} = headerTooltip;
const actions = [
  // {
  //   img: Icon_1,
  //   title: 'Load',
  // },
  // {
  //   img: Icon_2,
  //   title: 'Save',
  // },
  {
    img: Icon_3,
    title: ".XLSX",
    text: ".XLSX"
  },
  {
    img: Icon_4,
    title: "LINK",
    text: "LINK"
  },
  {
    img: Icon_5,
    title: "COPY",
    text: ""
  }
];

const isNumeric = (n) => !isNaN(parseFloat(n)) && isFinite(n);

const noData = (mod = false) => (
    <span className={mod ? styles.scoreNoDataMod : styles.scoreNoData}>
    No data
  </span>
);

const setColor = (colorCode) => {
  switch (colorCode) {
    case 1:
      return styles.behaviorGreen;
    case 2:
      return styles.behaviorYellow;
    case 3:
      return styles.behaviorRed;
    default:
      return styles.behaviorBlack;
  }
};

const renderContent = (text, row) => {
  if (row.subHeaderRow) {
    return {
      props: {
        colSpan: 0,
      },
    };
  }
  return text;
};

const renderBehavioralResults = (value, record) => {
  const toolTipValue = (value) =>
      factorTooltip[value.title] ? (
          <div className={styles.toolTipWrap}>
            <b>{factorTooltip[value.title].title}</b>
            <span>{factorTooltip[value.title].text}</span>
          </div>
      ) : null;

  if (record.subHeaderRow)
    return value
        ? value.title
            ? titleWithToolTip(value.title, toolTipValue(value))
            : noData(true)
        : "";
  if (value)
    return (
        <span className={`${styles.behavior} ${setColor(value.colorCode)}`}>
        {titleWithToolTip(value.title, toolTipValue(value))}
      </span>
    );
  return noData();
};

const renderBehavioralResultsFCM = (value, record) => {
  const toolTipValue = (value) =>
      factorTooltip[`${value.colorCode}`] ? (
          <div className={styles.toolTipWrap}>
            <span>{factorTooltip[`${value.colorCode}`].text}</span>
          </div>
      ) : null;

  if (record.subHeaderRow)
    return value
        ? value.title
            ? titleWithToolTip(value.title, toolTipValue(value))
            : noData(true)
        : "";
  if (value)
    return titleWithToolTip(
        <span className={`${styles.behavior} ${setColor(value.colorCode)}`} />,
        toolTipValue(value)
    );
  return noData();
};

const renderCognitiveScoreResults = (value, record) => {
  const { cognitiveResult, divisionRow, jobTitle, subHeaderRow } = record;

  if (divisionRow && !jobTitle) return;

  if (subHeaderRow) {
    return isNumeric(value)
        ? titleWithToolTip(value, cognitiveJob)
        : noData(true);
  }
  if (isNumeric(value)) {
    const colorCode = cognitiveResult.colorCode;

    return (
        <span className={`${styles.behavior} ${setColor(colorCode)}`}>
        {titleWithToolTip(value, cognitiveTooltip[colorCode])}
      </span>
    );
  }
  return noData();
};

const renderCalculator = (
    value,
    record,
    index,
    arr = [],
    foo = () => null,
    column = ""
) => {
  if (record.subHeaderRow) return;
  return (
      <Select
          placeholder={value || "N/A"}
          showArrow
          dropdownClassName={styles.statusDropdown}
          onChange={(e) => foo(e, record, column)}
          suffixIcon={<span className={styles.selectIcon} />}
      >
        {arr
            .filter((item) => item !== "NA")
            .map((plan) => (
                <Select.Option value={plan} key={plan}>
                  {plan}
                </Select.Option>
            ))}
      </Select>
  );
};

const renderConsultativeScoreResults = (value, record, columnTitle) => {
  if (isNumeric(value)) {
    const colorCode = record.skillsData.scoreData[columnTitle].colorCode;
    return (
        <span className={`${styles.score} ${setColor(colorCode)}`}>
        {titleWithToolTip(`${value}%`, consultativeTooltip[colorCode])}
      </span>
    );
  }
  return noData();
};

const renderSkillsData = (value, record) => {
  if (record.subHeaderRow) return;
  if (value?.length !== 0) return value;
  return "-";
};

const renderPerformance = (value, record) => {
  if (record.divisionRow && !record.jobTitle) return;
  if (record.jobTitle) {
    if (value !== null && value.length !== 0) return value;
    return "-";
  }
  return value || "-";
};

const titleWithToolTip = (title, toolTipText) => (
    <Tooltip title={toolTipText}>{title}</Tooltip>
);

const titleWithToolTipAbsoute = (title, toolTipText) => (
    <Tooltip title={toolTipText}>
      <span style={{ position: "absolute", width: "150px" }}>{title}</span>
    </Tooltip>
);

const RenderGraph = ({ record }) => {
  const {
    comboRangeData,
    divisionRow,
    jobTitle,
    subHeaderRow,
    patternImageURL,
  } = record;
  const getGraph = (jobRow, patternImageURL) => {
    if (divisionRow && !jobTitle) return;

    return patternImageURL ? (
        <img
            src={patternImageURL}
            className={styles.graphImg}
            alt="Graph"
            onError={(e) => {
              e.target.onerror = null;
            }}
        />
    ) : jobRow ? (
        noData(true)
    ) : (
        noData()
    );
  };
  return (
      <Tooltip
          title={
            subHeaderRow ? (
                <div className={styles.graphImgModal}>
                  {getGraph(subHeaderRow, patternImageURL)}
                  <p>{behavioralJob}</p>
                </div>
            ) : (
                <div className={styles.graphImgModal}>
                  {getGraph(subHeaderRow, patternImageURL)}
                  <div>
              <span>{`High ${
                  comboRangeData ? comboRangeData.high : "No data"
              }: `}</span>
                    <b>
                      {comboRangeData &&
                      behavioralTooltip.high[comboRangeData.high].title}
                    </b>
                    <span>
                {comboRangeData &&
                behavioralTooltip.high[comboRangeData.high].text}
              </span>
                  </div>
                  <div>
              <span>{`Low ${
                  comboRangeData ? comboRangeData.low : "No data"
              }: `}</span>
                    <b>
                      {comboRangeData &&
                      behavioralTooltip.low[comboRangeData.low].title}
                    </b>
                    <span>
                {comboRangeData &&
                behavioralTooltip.low[comboRangeData.low].text}
              </span>
                  </div>
                </div>
            )
          }
      >
        {getGraph(subHeaderRow, patternImageURL)}
      </Tooltip>
  );
};

const showStatusTextOnHover = (arrayOfString) => (
    <ul className={styles.showArrayOnHover}>
      {arrayOfString.map((item, index) => (
          <li key={index}>{item}</li>
      ))}
    </ul>
);

const chooseTextByKey = (plan) => plansData[plan];

export class Perfomance extends PureComponent {
  state = {
    currentFilterSetName: "Unset",
    hideCognitive: false,
    actualPerformance: true,
    behavioral: true,
    cognitive: true,
    columnsData: this.columns,
    consultativeSkill: true,
    empStatus: true,
    filterDivisions: [],
    filterEmploymentsStatus: [],
    filterFactorComboMatch: [],
    filterFirstScore: [],
    filterOverallScore: [],
    currentFilter: null,
    filtersApply: false,
    pageNumber: 0,
    perfomanceList: [],
    divisions: [],
    isCreateLinkModalOpen: false,

    defaultSetFilter: {
      filterDivisions: [],
      filterEmploymentsStatus: [],
      filterFactorComboMatch: [],
      filterFirstScore: [],
      filterOverallScore: [],
      title: "Unset",
      displayBlocks: {
        actualPerformance: true,
        behavioral: true,
        cognitive: true,
        empStatus: true,
        consultativeSkill: true,
        generalData: true,
      },
    },
    employeeSetId: this.props.datasets[0]?.id,

    generalData: true,
    isLoading: false,
    isEmployeesLoading: false,
    maxWidthTable: 1000,
    linkToShare: null,
    sortType: null,
    sortDirection: null,
    windowStartInnerHeight: window.innerHeight,
    marginBottomStart: 0,
    node: null,
    isActiveMiniLoading: false,
    rowCount: 1,
    order: {
      type: "",
      direction: "",
    },
    isOpenModal: false,
  };

  columns = [
    {
      title: "Employee Information",
      children: [
        {
          title: "№",
          dataIndex: "count",
          width: 40,
          render: (text, row) => {
            const { divisionRow, jobTitle } = row;
            if (row.subHeaderRow) {
              return {
                children: (
                    <div className={styles.jobTitleWrap}>
                      {divisionRow && (
                          <span className={styles.jobSupervisor}>
                        {divisionRow}
                      </span>
                      )}
                      {jobTitle && (
                          <span className={styles.jobTitle}>{jobTitle}</span>
                      )}
                    </div>
                ),
                props: {
                  colSpan: 3,
                },
              };
            }
            return text;
          },
        },
        {
          title: () => (
              <span className={styles.sortImg}>
              <SortIcon
                  text={"Last Name"}
                  onClick={() => this.sortBy("lastName")}
                  type={
                    this.state.sortType === "lastName"
                        ? this.state.sortDirection
                        : null
                  }
              />
            </span>
          ),
          dataIndex: "lastName",
          render: renderContent,
          width: 86,
          className: `${styles.name}`,
        },
        {
          title: () => (
              <span className={styles.sortImg}>
              <SortIcon
                  text={"First Name"}
                  onClick={() => this.sortBy("firstName")}
                  type={
                    this.state.sortType === "firstName"
                        ? this.state.sortDirection
                        : null
                  }
              />
            </span>
          ),
          dataIndex: "firstName",
          width: 86,
          render: renderContent,
          className: `${styles.name}`,
        },
      ],
    },
    {
      title: "Organizational Data",
      children: [
        {
          title: () => (
              <span className={styles.sortImg}>
              <SortIcon
                  text={"Location"}
                  onClick={() => this.sortBy("Location")}
                  type={
                    this.state.sortType === "Location"
                        ? this.state.sortDirection
                        : null
                  }
              />
            </span>
          ),
          dataIndex: "location",
          width: 104,
          className: `${styles.location}`,
          render: (value, record) => {
            if (record.subHeaderRow) {
              return;
            }
            return value ? value : "-";
          },
        },
        {
          title: () => (
              <span className={styles.sortImg}>
              <SortIcon
                  text={"Tenure"}
                  onClick={() => this.sortBy("WorkPeriod")}
                  type={
                    this.state.sortType === "WorkPeriod"
                        ? this.state.sortDirection
                        : null
                  }
              />
            </span>
          ),
          dataIndex: "periodOfWork",
          width: 100,
          render: (value, record) => {
            const {
              subHeaderRow,
              hireDate,
              terminationDate,
              id,
              currentJob,
              division,
              employeeStatus,
            } = record;
            const { onlyView } = this.props;

            return subHeaderRow ? null : (
                <div className={`${styles.calculator}`}>
                  <SetTerminationDateTable
                      userId={id}
                      currentJob={currentJob?.title}
                      division={division}
                      updateUser={this.props.updateUser}
                      periodOfWork={value}
                      dateOfHire={hireDate}
                      terminationDate={terminationDate}
                      onlyView={onlyView}
                      employeeStatus={employeeStatus}
                  />
                </div>
            );
          },
        },
      ],
    },
    {
      title: titleWithToolTip("Cognitive (CA)", cognitive),
      dataIndex: "Cognitive (CA)",
      children: [
        {
          title: () => (
              <span className={styles.sortImg}>
              <SortIcon
                  text={"Score"}
                  onClick={() => this.sortBy("Score")}
                  type={
                    this.state.sortType === "Score"
                        ? this.state.sortDirection
                        : null
                  }
              />
            </span>
          ),
          dataIndex: "cognitiveResult.actual",
          width: 80,
          className: `${styles.wordWrap}`,
          render: renderCognitiveScoreResults,
        },
      ],
    },
    {
      title: "Behavioral (BA)",
      children: [
        {
          title: titleWithToolTip("Pattern", pattern),
          dataIndex: "PI",
          className: `${styles.graphContainer} ${styles.wordWrap}`,
          width: 100,
          render: (value, record) => <RenderGraph record={record} />,
        },
        {
          title: "",
          dataIndex: "behavioralResults[0]",
          width: 54,
          render: renderBehavioralResults,
          className: `${styles.behaviorHeader} ${styles.wordWrap}`,
        },
        {
          title: (
              <div style={{ position: "relative" }}>
                {titleWithToolTipAbsoute(
                    "Factor Combinations",
                    factorCombinations
                )}
              </div>
          ),
          dataIndex: "behavioralResults[1]",
          width: 54,
          render: renderBehavioralResults,
          className: `${styles.behaviorHeader} ${styles.wordWrap}`,
        },
        {
          title: "",
          dataIndex: "behavioralResults[2]",
          width: 54,
          render: renderBehavioralResults,
          className: `${styles.behaviorHeader} ${styles.behaviorHeaderFixed} ${styles.wordWrap}`,
        },
        {
          title: "",
          dataIndex: "behavioralResults[3]",
          width: 54,
          render: renderBehavioralResults,
          className: `${styles.behaviorHeader} ${styles.wordWrap}`,
        },
        {
          title: "",
          dataIndex: "behavioralResults[4]",
          width: 54,
          render: renderBehavioralResults,
          className: `${styles.behaviorHeader} ${styles.wordWrap}`,
        },
        {
          title: "",
          dataIndex: "behavioralResults[5]",
          width: 54,
          render: renderBehavioralResults,
          className: `${styles.wordWrap}`,
        },
        {
          title: () => (
              <span className={styles.sortImg}>
              <SortIcon
                  text={"Factor Combo Match"}
                  onClick={() => {
                    this.sortBy("FactorComboMatch");
                    this.addSort();
                  }}
                  type={
                    this.state.sortType === "FactorComboMatch"
                        ? this.state.sortDirection
                        : null
                  }
              />
            </span>
          ),
          dataIndex: "behavioralResults[6]",
          width: 58,
          className: `${styles.comboMatch}`,
          render: renderBehavioralResultsFCM,
        },
      ],
    },
    {
      title: titleWithToolTip("Current Skill", consultativeSkill),
      dataIndex: "Current Skill",
      children: [
        {
          title: () => (
              <span className={styles.sortImg}>
              <SortIcon
                  Component={titleWithToolTip("Op", "Open")}
                  onClick={() => this.sortBy("Op")}
                  type={
                    this.state.sortType === "Op" ? this.state.sortDirection : null
                  }
              />
            </span>
          ),
          dataIndex: "skillsData.openSkill",
          width: 52,
          height: "100%",
          render: renderSkillsData,
        },
        {
          title: () => (
              <span className={styles.sortImg}>
              <SortIcon
                  Component={titleWithToolTip("In", "Investigate")}
                  onClick={() => this.sortBy("In")}
                  type={
                    this.state.sortType === "In" ? this.state.sortDirection : null
                  }
              />
            </span>
          ),
          dataIndex: "skillsData.investigationSkill",
          width: 52,
          render: renderSkillsData,
        },
        {
          title: () => (
              <span className={styles.sortImg}>
              <SortIcon
                  Component={titleWithToolTip("Pr", "Present")}
                  onClick={() => this.sortBy("Pr")}
                  type={
                    this.state.sortType === "Pr" ? this.state.sortDirection : null
                  }
              />
            </span>
          ),
          dataIndex: "skillsData.presentSkill",
          width: 52,
          render: renderSkillsData,
        },
        {
          title: () => (
              <span className={styles.sortImg}>
              <SortIcon
                  Component={titleWithToolTip("Con", "Confirm")}
                  onClick={() => this.sortBy("Con")}
                  type={
                    this.state.sortType === "Con"
                        ? this.state.sortDirection
                        : null
                  }
              />
            </span>
          ),
          dataIndex: "skillsData.confirmSkill",
          width: 56,
          render: renderSkillsData,
        },
        {
          title: () => (
              <span className={styles.sortImg}>
              <SortIcon
                  Component={titleWithToolTip("Pos", "Position")}
                  onClick={() => this.sortBy("Pos")}
                  type={
                    this.state.sortType === "Pos"
                        ? this.state.sortDirection
                        : null
                  }
              />
            </span>
          ),
          dataIndex: "skillsData.positionSkill",
          width: 56,
          render: renderSkillsData,
        },
        {
          title: () => (
              <span className={styles.sortImg}>
              <SortIcon
                  Component={titleWithToolTip("Overall Score", overalScoreText)}
                  onClick={() => this.sortBy("OverallScore")}
                  type={
                    this.state.sortType === "OverallScore"
                        ? this.state.sortDirection
                        : null
                  }
              />
            </span>
          ),
          dataIndex: "skillsData.scoreData.overallScore.value",
          width: 66,
          render: (value, record) => {
            const { skillsData, subHeaderRow } = record;
            if (subHeaderRow) return;
            const count = skillsData && skillsData.scoreData.assesmentsCount;
            const columnTitle = "overallScore";
            return (
                <div className={styles.actualWrap}>
                  {renderConsultativeScoreResults(value, record, columnTitle)}
                  {count && <span className={styles.actualBadge}>{count}</span>}
                </div>
            );
          },
        },
        {
          title: titleWithToolTip("1st Score", firstScoreText),
          dataIndex: "skillsData.scoreData.benchmark.value",
          width: 56,
          render: (value, record) => {
            if (record.subHeaderRow) return;
            const columnTitle = "benchmark";
            return renderConsultativeScoreResults(value, record, columnTitle);
          },
        },
      ],
    },
    {
      title: "Performance",
      children: [
        {
          title: "",
          dataIndex: "perfomanceData.firstPerfColumn",
          width: 70,
          render: renderPerformance,
        },
        {
          title: "",
          dataIndex: "perfomanceData.secondPerfColumn",
          width: 70,
          render: renderPerformance,
        },
        {
          title: "",
          dataIndex: "perfomanceData.thirdPerfColumn",
          width: 70,
          render: renderPerformance,
        },
        {
          title: "",
          dataIndex: "perfomanceData.fourthPerfColumn",
          width: 70,
          render: renderPerformance,
        },
        {
          title: "",
          dataIndex: "perfomanceData.fifthPerfColumn",
          width: 70,
          rende: renderPerformance,
        },
        {
          title: () => (
              <span className={styles.sortImg}>
              <SortIcon
                  text={"Rating"}
                  onClick={() => this.sortBy("Rating")}
                  type={
                    this.state.sortType === "Rating"
                        ? this.state.sortDirection
                        : null
                  }
              />
            </span>
          ),
          dataIndex: "perfomanceData.employeeRating",
          width: 90,
          render: (value, record) => {
            if (record.subHeaderRow) return;
            if (this.props.onlyView) {
              return <span>{chooseTextByKey(value) || "N/A"}</span>;
            }
            return (
                <Select
                    placeholder={chooseTextByKey(value) || "N/A"}
                    showArrow
                    dropdownClassName={styles.statusDropdown}
                    getPopupContainer={(trigger) => trigger.parentNode}
                    onChange={(e) => this.updateEmployee(e, record, "rating")}
                    suffixIcon={<span className={styles.selectIcon} />}
                >
                  {this.props.employeeRatings.map((plan) => (
                      <Select.Option value={plan} key={chooseTextByKey(plan)}>
                        {chooseTextByKey(plan)}
                      </Select.Option>
                  ))}
                </Select>
            );
          },
        },
      ],
    },
    {
      title: "9-box & readiness calculator",
      children: [
        {
          title: "Performance",
          dataIndex: "successionData.perfomance",
          width: 90,
          render: (value, record, index) =>
              renderCalculator(
                  value,
                  record,
                  index,
                  this.props.employeePerformance,
                  this.updateEmployee,
                  "perfomance"
              ),
        },
        {
          title: "Potential",
          dataIndex: "successionData.potential",
          width: 90,
          render: (value, record, index) =>
              renderCalculator(
                  value,
                  record,
                  index,
                  this.props.employeePotential,
                  this.updateEmployee,
                  "potential"
              ),
        },
        {
          title: "9-Box Image",
          dataIndex: "successionData.boxIndex",
          className: `${styles.calculatorContainer}`,
          width: 90,
          render: (value, record) => {
            const { divisionId, divisionRow, subHeaderRow } = record;
            if (subHeaderRow && !divisionRow) {
              return;
            }
            return (
                <div
                    className={`${styles.calculator} ${
                        subHeaderRow && styles.divisionCalculator
                    }`}
                >
                  <CalculatorTable
                      divisionId={subHeaderRow && divisionId}
                      idArr={subHeaderRow ? [1, 2, 3, 4, 5, 6, 7, 8, 9] : [value]}
                      isDivision={divisionRow}
                  />
                </div>
            );
          },
        },
        {
          title: "Readiness",
          dataIndex: "successionData.readiness",
          width: 105,
          render: (value, record, index) =>
              renderCalculator(
                  value,
                  record,
                  index,
                  this.props.employeeReadiness,
                  this.updateEmployee,
                  "readiness"
              ),
        },
      ],
    },
    {
      title: titleWithToolTip(
          "Emp Status",
          showStatusTextOnHover(empStatusText)
      ),
      dataIndex: "Emp Status",
      children: [
        {
          title: () => (
              <span className={styles.sortImg}>
              <SortIcon
                  Component={titleWithToolTip(
                      "Status",
                      showStatusTextOnHover(empStatusText)
                  )}
                  onClick={() => this.sortBy("Status")}
                  type={
                    this.state.sortType === "Status"
                        ? this.state.sortDirection
                        : null
                  }
              />
            </span>
          ),
          dataIndex: "employeeStatus",
          width: 65,
          render: (value, record) => {
            const { subHeaderRow } = record;
            const { onlyView, updateUser } = this.props;

            if (subHeaderRow) return;

            return (
                <SetStatusTable
                    value={value}
                    updateUser={updateUser}
                    onlyView={onlyView}
                    statuses={["INAC", "AP", "AC"]}
                    record={record}
                />
            );
          },
        },
      ],
    },
    {
      title: "Action",
      width: 60,
      children: [
        {
          title: "",
          dataIndex: "canDelete",
          width: 60,
          render: (value, record) => {
            const { user } = this.props;

            const disabled =
                !user || (user && user.roles.includes("ACCOUNT OWNER/MANAGER"));

            return (
                value && (
                    <ConfirmDelSave
                        render={<IconTrash className={styles.iconTrash} />}
                        disabled={disabled}
                        deleteEmployee
                        employee={record}
                        afterDeleteEmployee={() => this.onDeleteEmployee(record)}
                        sendNotification={this.requestNotification}
                    />
                )
            );
          },
        },
      ],
    },
  ];

  getDivisions() {
    !this.props.onlyView &&
    api.employees.getEmployeesDivisions().then((res) => {
      this.setState({ divisions: res });
    });
  }

  componentDidMount() {
    const { isNeedToUpdateEmployees, onUpdateEmployees, onlyView } = this.props;

    if (isNeedToUpdateEmployees) {
      onUpdateEmployees(false);
    }

    onlyView ? this.getEmployeesUnAuth() : this.getEmployees();
    this.refreshShowingColumns();
    const node = document.querySelector(
        ".ant-table-header.ant-table-hide-scrollbar"
    );
    if (node) {
      const {
        style: { marginBottom },
      } = node;
      const baseMarginBottom = Number.parseInt(marginBottom);
      this.setState({
        windowStartInnerHeight: window.innerHeight,
        marginBottomStart: baseMarginBottom,
        node,
      });
      window.addEventListener("resize", this.onResize);
    }
    const table = document.querySelector(".ant-table-body");
    if (table) {
      table.addEventListener("scroll", this.onScrollTable);
    }
    this.getDivisions();
  }

  componentDidUpdate(prevProps) {
    const { node: startNode } = this.state;

    if (!startNode) {
      const node = document.querySelector(
          ".ant-table-header.ant-table-hide-scrollbar"
      );
      if (node) {
        const {
          style: { marginBottom },
        } = node;
        const baseMarginBottom = Number.parseInt(marginBottom);
        this.setState({
          windowStartInnerHeight: window.innerHeight,
          marginBottomStart: baseMarginBottom,
          node,
        });
        window.addEventListener("resize", this.onResize);
      }
    }
    const {
      activeFilters,
      employeesFormat,
      isNeedToUpdateEmployees,
      onUpdateEmployees,
      datasets,
    } = this.props;
    const {
      filtersApply,
      filterDivisions,
      filterEmploymentsStatus,
      filterFactorComboMatch,
      filterFirstScore,
      filterOverallScore,
    } = this.state;

    if (prevProps.datasets.length === 0 && datasets.length !== 0) {
      this.setState({ employeeSetId: datasets[0].id });
      this.getEmployees();
    }

    if (isNeedToUpdateEmployees !== prevProps.isNeedToUpdateEmployees) {
      this.onRebuildDashboard();

      isNeedToUpdateEmployees && onUpdateEmployees(false);
    }

    if (!filtersApply && activeFilters && employeesFormat.length) {
      this.setState(
          {
            filterDivisions,
            filterEmploymentsStatus,
            filterFactorComboMatch,
            filterFirstScore,
            filterOverallScore,
            filtersApply: true,
          },
          () => {
            this.refreshShowingColumns();
            this.props.onGetEmployeesFormat({
              employeesFormatted: employeesFormat,
            });
          }
      );
    }
  }

  componentWillUnmount() {
    const table = document.querySelector(".ant-table-body");

    table.removeEventListener("scroll", this.onScrollTable);
    window.removeEventListener("resize", this.onResize);
  }

  onScrollTable = (e) => {
    if (
        e.target.scrollHeight - (e.target.scrollTop + e.target.offsetHeight) <
        1000
    ) {
      this.props.onlyView ? this.getEmployeesUnAuth() : this.getEmployees();
    }
  };

  onResize = () => {
    const { windowStartInnerHeight, marginBottomStart, node } = this.state;
    node.style.marginBottom = `${Math.round(
        (window.innerHeight * marginBottomStart) / windowStartInnerHeight
    )}px`;
  };

  sortBy = (type) => {
    const { sortType, sortDirection } = this.state;
    let newSortDirection = sortDirection;
    let newSortType = sortType;

    if (type === sortType) {
      if (sortDirection === "downSort") {
        newSortDirection = "topSort";
      } else if (sortDirection === "topSort") {
        newSortDirection = null;
      } else {
        newSortDirection = "downSort";
      }
    } else {
      newSortDirection = "downSort";
      newSortType = type;
    }

    if (newSortDirection !== sortDirection)
      this.setState({ sortDirection: newSortDirection });
    if (newSortType !== sortType) this.setState({ sortType: newSortType });

    this.addSort(newSortType, newSortDirection);
  };

  addSort = (sortType, sortDirection) => {
    if (sortType === "FactorComboMatch") {
      return this.setState(
          {
            pageNumber: 0,
            perfomanceList: [],
            order: sortDirection
                ? {
                  type: sortType,
                  direction: sortDirection === "topSort" ? "asc" : "desc",
                }
                : { type: "", direction: "" },
          },
          () => {
            this.props.onlyView ? this.getEmployeesUnAuth() : this.getEmployees();
          }
      );
    }
  };

  setEmployees = (table, response) => {
    const { pageNumber, perfomanceList } = this.state;
    const { onGetEmployees, onGetEmployeesFormat } = this.props;

    const filteredFormatData = this.groupingEmployees(response);

    if (pageNumber === 0) {
      this.setState({ isEmployeesLoading: false });
      onGetEmployees(response);
    } else {
      this.setState({ isActiveMiniLoading: false });
    }

    if (
        (pageNumber + 1 === response.paginationData.totalPages ||
            pageNumber + 1 > response.paginationData.totalPages) &&
        table
    ) {
      table.removeEventListener("scroll", this.onScrollTable);
    } else if (table) {
      table.addEventListener("scroll", this.onScrollTable);
    }

    const groupedData = [...perfomanceList, ...filteredFormatData];
    const finishedData = {
      employeesFormatted: [...this.checkDublicate(groupedData)],
    };

    if (pageNumber > 0) finishedData.employees = response.employeeMap;
    const finishedDataString = JSON.stringify(finishedData);
    onGetEmployeesFormat(JSON.parse(finishedDataString));

    this.setState({
      pageNumber: pageNumber + 1,
      perfomanceList: [...JSON.parse(finishedDataString).employeesFormatted],
    });
  };

  getEmployeesUnAuth = () => {
    const {
      location: { pathname, search },
    } = this.props;
    const { pageNumber, order } = this.state;

    const id = pathname.match(/\d+$/)[0];
    const token = (search && search.match(/token=.+$/)[0]) || "";

    const table = document.querySelector(".ant-table-body");

    if (table) {
      table.removeEventListener("scroll", this.onScrollTable);
    }

    if (pageNumber === 0) {
      this.setState({ isEmployeesLoading: true });
    } else {
      this.setState({ isActiveMiniLoading: true });
    }

    api.employees
        .getEmployeesView(id, token, pageNumber, 40, order.direction)
        .then((res) => {
          this.setEmployees(table, res);

          this.setState({ isEmployeesLoading: false });
        })
        .catch((res) => {
          const { data, status } = res["response"];

          switch (status) {
            case 500: {
              return this.setState({
                isEmployeesLoading: false,
                error: "Bad token, access denied.",
              });
            }
            case 400: {
              !!data &&
              openNotificationWithIcon({
                type: "error",
                message: "Bad request",
                description: data,
              });

              return this.setState({
                isEmployeesLoading: false,
                error: "Token not found, access denied.",
              });
            }
            case 403: {
              return this.setState({
                isEmployeesLoading: false,
                error: "Token was expired, access denied.",
              });
            }
            default:
              return this.setState({
                isEmployeesLoading: false,
                error: "Something goes wrong, access denied.",
              });
          }
        });
  };

  getEmployees = () => {
    const { datasets, selectedDashboard } = this.props;
    const {
      filterDivisions,
      pageNumber,
      filterEmploymentsStatus,
      filterFactorComboMatch,
      filterFirstScore,
      filterOverallScore,
      order,
      employeeSetId,
    } = this.state;
    if (datasets?.length) {
      const params = {
        divisionNames: filterDivisions,
        empStatuses: filterEmploymentsStatus,
        factorComboMatchColors: filterFactorComboMatch,
        currentSkill1stScoreColors: filterFirstScore,
        currentSkill1OverallScoreColors: filterOverallScore,
      };

      const table = document.querySelector(".ant-table-body");

      if (table) {
        table.removeEventListener("scroll", this.onScrollTable);
      }

      if (pageNumber === 0) {
        this.setState({
          isEmployeesLoading: true,
        });

        table.scrollTop = 0;
      } else {
        this.setState({ isActiveMiniLoading: true });
      }
      this.setState({
        isEmployeesLoading: false,
      });

      const queryParams = {
        page: pageNumber,
        size: 40,
        order: order.direction,
        isSuccessionDashboard: selectedDashboard.succession,
        employeeSetId: employeeSetId || datasets[0]?.id,
      };

      api.employees.getPartEmployees(queryParams, params).then((res) => {
        this.setEmployees(table, res);

        this.setState(
            () => ({
              isEmployeesLoading: false,
              hideCognitive: !res.cognitiveView,
            }),
            this.refreshShowingColumns
        );
      });
    }
  };

  checkDublicate = (data) => {
    let checkingJob = "";

    const arr = data.filter((item) => {
      if (item.divisionRow) {
        checkingJob = "";
      }

      if (item.jobTitle) {
        if (checkingJob === item?.jobTitle) {
          return false;
        }

        checkingJob = item.jobTitle;
      }

      return item;
    });

    return arr;
  };

  groupingEmployees = (data) => {
    const { divisions, employeeJobs, employeeMap } = data;
    const { rowCount, perfomanceList } = this.state;
    let count = rowCount;
    const newEmployees = [];

    const addHeaderLine = (jobName, divisionName = "", divisonOnly = false) => {
      const divisionData = divisions?.find(({ name }) => name === divisionName);

      const jobTitle = jobName.includes("_supervisor")
          ? jobName.replace(/_supervisor/, " ")
          : jobName;
      const job = employeeJobs.find(({ name }) => name === jobTitle);

      divisonOnly &&
      newEmployees.push({
        id: `division-${count}`,
        divisionRow: divisionName || null,
        divisionId: divisionData ? divisionData.id : 0,
        subHeaderRow: true,
      });

      const jobRow = {
        id: `job-${count}`,
        jobTitle: jobTitle,
        subHeaderRow: true,
        patternImageURL: job ? job.patternImageURL : null,
        perfomanceData: {
          firstPerfColumn: job ? job.firstPerformanceColumn : "",
          secondPerfColumn: job ? job.secondPerformanceColumn : "",
          thirdPerfColumn: job ? job.thirdPerformanceColumn : "",
          fourthPerfColumn: job ? job.fourthPerformanceColumn : "",
          fifthPerfColumn: job ? job.fifthPerformanceColumn : "",
        },
        behavioralResults: [
          {
            title: job && job.behavioralHeaders ? job.behavioralHeaders[0] : "",
          },
          {
            title: job && job.behavioralHeaders ? job.behavioralHeaders[1] : "",
          },
          {
            title: job && job.behavioralHeaders ? job.behavioralHeaders[2] : "",
          },
          {
            title: job && job.behavioralHeaders ? job.behavioralHeaders[3] : "",
          },
          {
            title: job && job.behavioralHeaders ? job.behavioralHeaders[4] : "",
          },
          {
            title: job && job.behavioralHeaders ? job.behavioralHeaders[5] : "",
          },
        ],
        cognitiveResult: { actual: job ? job.cognitiveTarget : "" },
      };

      if (!divisonOnly && divisionName.length) {
        jobRow.id = `job-division-${count}`;
        jobRow.divisionRow = divisionName;
        jobRow.divisionId = divisionData ? divisionData.id : 0;

        if (divisionData?.supervisorJob) {
          const supervisorJob = employeeJobs.find(
              ({ name }) => name === divisionData.supervisorJob
          );

          if (supervisorJob) {
            const { cognitiveTarget, patternImageURL, behavioralHeaders } =
                supervisorJob;

            jobRow.cognitiveResult = {
              actual: cognitiveTarget,
            };
            jobRow.patternImageURL = patternImageURL || null;
            jobRow.behavioralResults = [
              {
                title: behavioralHeaders ? behavioralHeaders[0] : "",
              },
              {
                title: behavioralHeaders ? behavioralHeaders[1] : "",
              },
              {
                title: behavioralHeaders ? behavioralHeaders[2] : "",
              },
              {
                title: behavioralHeaders ? behavioralHeaders[3] : "",
              },
              {
                title: behavioralHeaders ? behavioralHeaders[4] : "",
              },
              {
                title: behavioralHeaders ? behavioralHeaders[5] : "",
              },
            ];
          }
        }
      }
      newEmployees.push(jobRow);
    };

    for (let name in employeeMap) {
      let jobNumber = 1;
      for (let job in employeeMap[name]) {
        if (jobNumber === 1) {
          job.includes("_supervisor")
              ? addHeaderLine(job, name)
              : addHeaderLine(job, name, true);
        } else {
          addHeaderLine(job);
        }
        // eslint-disable-next-line
        employeeMap[name][job].forEach((item) => {
          item.currentJob = {
            title: job,
          };

          item.canDelete = true;

          item.count = count;
          count++;
          return newEmployees.push(item);
        });
        jobNumber++;
      }
    }

    this.setState({ rowCount: count });

    if (
        perfomanceList.find(
            (item) => item.divisionRow === newEmployees[0]?.divisionRow
        )
    ) {
      newEmployees.splice(0, 1);
    }

    return newEmployees;
  };

  refreshShowingColumns = () => {
    const {
      behavioral,
      cognitive,
      consultativeSkill,
      actualPerformance,
      generalData,
      empStatus,
      hideCognitive,
    } = this.state;

    const generalDataWidth = 204;
    const cognitiveWidth = 80;
    const consultativeSkillWidth = 382;
    const actualPerformanceWidth = 438;
    const behavioralWidth = 475;
    const empStatusWidth = 65;

    const width =
        275 +
        (cognitive && !hideCognitive ? cognitiveWidth : 0) +
        (consultativeSkill ? consultativeSkillWidth : 0) +
        (behavioral ? behavioralWidth : 0) +
        (generalData ? generalDataWidth : 0) +
        (empStatus ? empStatusWidth : 0) +
        (actualPerformance ? actualPerformanceWidth : 0);

    const result = [
      this.columns.find(({ title }) => title === "Employee Information"),
      cognitive &&
      !hideCognitive &&
      this.columns.find(({ dataIndex }) => dataIndex === "Cognitive (CA)"),
      behavioral &&
      this.columns.find(({ title }) => title === "Behavioral (BA)"),
      consultativeSkill &&
      this.columns.find(({ dataIndex }) => dataIndex === "Current Skill"),
      actualPerformance &&
      this.columns.find(({ title }) => title === "Performance"),
      empStatus &&
      this.columns.find(({ dataIndex }) => dataIndex === "Emp Status"),
      generalData &&
      this.columns.find(({ title }) => title === "Organizational Data"),
      this.columns.find(({ title }) => title === "Action"),
    ].filter(Boolean);
    this.setState({
      columnsData: result,
      maxWidthTable: width,
    });
  };

  onGetCurrentFilter = () => {
    const {
      filterDivisions,
      filterEmploymentsStatus,
      filterFactorComboMatch,
      filterFirstScore,
      filterOverallScore,
      actualPerformance,
      behavioral,
      cognitive,
      empStatus,
      consultativeSkill,
      generalData,
    } = this.state;
    return {
      divisionsFilter: {
        divisions: filterDivisions,
      },
      jobsFilter: {
        jobs: [],
      },
      currentFilterLinkData: {
        performanceFilters: {
          title: null,
          filterDivisions,
          filterEmploymentsStatus,
          filterFactorComboMatch,
          filterFirstScore,
          filterOverallScore,
          displayBlocks: {
            actualPerformance,
            behavioral,
            cognitive,
            empStatus,
            consultativeSkill,
            generalData,
          },
        },
        successionFilters: null,
      },
    };
  };

  onRebuildDashboard = () => {
    const currentFilter = this.onGetCurrentFilter();

    this.setState(
        {
          currentFilter,
          perfomanceList: [],
          pageNumber: 0,
          rowCount: 1,
        },
        () => {
          this.refreshShowingColumns();
          this.getEmployees();
        }
    );
  };

  onDeleteEmployee = (employee) => {
    this.props.removeEmployee({
      userId: employee.id,
      currentJob: employee.currentJob.title,
      division: employee.division,
    });
  };

  getLinkToDashboard = (liveness) => {
    const {
      filterDivisions,
      filterEmploymentsStatus,
      filterFactorComboMatch,
      filterFirstScore,
      filterOverallScore,
      employeeSetId,
    } = this.state;

    const params = {
      divisionNames: filterDivisions,
      empStatuses: filterEmploymentsStatus,
      factorComboMatchColors: filterFactorComboMatch,
      currentSkill1stScoreColors: filterFirstScore,
      currentSkill1OverallScoreColors: filterOverallScore,
      liveness,
    };

    this.setState(
        () => ({ linkToShare: "inProgress" }),
        () => {
          const notificationSettings = {
            type: "error",
            message: `Link wasn't copied to a clipboard`,
          };

          api.employees
              .getLink(params)
              .then((res) => {
                const path = window.location.origin;
                const [id, { token }] = [res.url.match(/\d+$/)[0], res];
                const linkToShare = `${path}/dashboardView/${id}?token=${token}${
                    employeeSetId ? `&employeeSetId=${employeeSetId}` : ""
                }`;

                this.setState({
                  linkToShare,
                });

                try {
                  copyTextInBuffer(linkToShare);

                  notificationSettings.type = "success";
                  notificationSettings.message = "Link was copied to a clipboard";

                  openNotificationWithIcon(notificationSettings);
                } catch (e) {
                  openNotificationWithIcon(notificationSettings);
                }
              })
              .catch(() => {
                this.setState({ linkToShare: null });
                openNotificationWithIcon(notificationSettings);
              })
              .finally(() => {
                this.setState({ isCreateLinkModalOpen: false });
              });
        }
    );
  };

  requestNotification = (message, success = true) => {
    const notificationSettings = {
      type: success ? "success" : "error",
      message: message,
    };
    openNotificationWithIcon(notificationSettings);
    this.setState({ isLoading: false });
  };

  updateEmployee = (value, record, column) => {
    this.setState({ isLoading: true });

    const params = [
      {
        name: column,
        value: value,
      },
    ];

    return api.employees
        .updateEmployee(record.id, params)
        .then(() => api.employees.getEmployee(record.id))
        .then((user) => {
          this.props.updateUser({
            user,
            currentJob: record.currentJob.title,
            userId: record.id,
            division: record.division,
          });

          this.requestNotification("Update was successful");
          this.getEmployees();
        })
        .catch((err) => {
          const errorMessage = err.response.data.message
              ? err.response.data.message
              : err.response.data;
          console.warn(errorMessage);
        });
  };

  onChangeRating = (value, record) => {
    this.setState({ isLoading: true });

    const params = [
      {
        name: "rating",
        value: value,
      },
    ];

    api.employees
        .updateEmployee(record.id, params)
        .then(() => {
          this.requestNotification("Rating was successful changed");
          this.getEmployees();
        })
        .catch((res) => {
          const errorMessage = res.response.data.message
              ? res.response.data.message
              : res.response.data;
          this.requestNotification(errorMessage, false);
        });
  };

  toggleCopyModal = () => {
    this.setState(prev => ({ ...prev, isOpenModal: !prev.isOpenModal }))
  }

  actionsClick = (type) => {
    switch (type) {
      case "Load":
        return null;
      case "Save":
        return null;
      case ".XLSX":
        return this.downloadFileInFormat("XLSX");
      case "LINK":
        return this.setState({ isCreateLinkModalOpen: true });
      case "COPY":
        return this.toggleCopyModal();
      case ".CSV":
        return this.downloadFileInFormat("CSV");
      default:
        return;
    }
  };

  downloadFileInFormat = (type) => {
    const { selectedDashboard, datasets } = this.props;
    const { employeeSetId } = this.state;

    this.setState({ isLoading: true });

    const queryParams = {
      contentType: type,
      dashboardId: selectedDashboard.id,
      employeeSetId,
      isSuccession: selectedDashboard.succession,
    };

    const filter = {
      // TODO: add filter params
    };

    api.files
        .generateEmployeesFile(queryParams, filter)
        .then((res) => {
          const { succession, name: dashboardName } = selectedDashboard;
          const dashboardType = succession ? "succession" : "perfomance";

          const { name: datasetName } = datasets.find(
              ({ id }) => id === employeeSetId
          );

          const name = `${dashboardType}_${dashboardName}_${datasetName}.xlsx`;
          downloadFile(res, name);

          this.requestNotification("Downloading was successful");
        })
        .catch((res) => this.requestNotification(res.message, false));
  };

  checkNewFilter = () => {
    const { defaultSetFilter } = this.state;
    const {
      filterDivisions,
      filterEmploymentsStatus,
      filterFactorComboMatch,
      filterFirstScore,
      filterOverallScore,
      actualPerformance,
      behavioral,
      cognitive,
      empStatus,
      consultativeSkill,
      generalData,
    } = this.state;

    const newFilterSet = {
      filterDivisions,
      filterEmploymentsStatus,
      filterFactorComboMatch,
      filterFirstScore,
      filterOverallScore,
      title: "Unset",
      displayBlocks: {
        actualPerformance,
        behavioral,
        cognitive,
        empStatus,
        consultativeSkill,
        generalData,
      },
    };
    return JSON.stringify(defaultSetFilter) === JSON.stringify(newFilterSet);
  };

  handleDivisionFilter = (value) => this.setState({ filterDivisions: value });
  handleEmploymentsStatusFilter = (value) =>
      this.setState({ filterEmploymentsStatus: value });
  handleFactorComboMatchFilter = (value) =>
      this.setState({ filterFactorComboMatch: value });
  handleFirstScoreFilter = (value) =>
      this.setState({ filterFirstScore: value });
  handleOverallScoreFilter = (value) =>
      this.setState({ filterOverallScore: value });

  handlePresetsFilter = (value) => {
    const { defaultSetFilter } = this.state;
    const { filtersPresets } = this.props;
    const currentFilterSet = value
        ? [...filtersPresets, defaultSetFilter].find((set) => set.title === value)
        : defaultSetFilter;
    const {
      title,
      filterDivisions,
      filterEmploymentsStatus,
      filterFactorComboMatch,
      filterFirstScore,
      filterOverallScore,
      displayBlocks: {
        actualPerformance,
        behavioral,
        cognitive,
        empStatus,
        consultativeSkill,
        generalData,
      },
    } = currentFilterSet;

    this.setState({
      currentFilterSetName: title,
      filterDivisions,
      filterEmploymentsStatus,
      filterFactorComboMatch,
      filterFirstScore,
      filterOverallScore,
      actualPerformance,
      behavioral,
      cognitive,
      empStatus,
      consultativeSkill,
      generalData,
    });
  };

  sortTable = (param) => {
    const { sortType, sortDirection } = this.state;

    const data = param.employeesFormatted || param;
    let sortedData;

    if (sortType === "FactorComboMatch") {
      sortedData = data;
    } else {
      const formatData = getFormatData({ data, sortType, sortDirection });

      sortedData = Object.keys(formatData).includes("employeesFormatted")
          ? formatData.employeesFormatted
          : formatData;
    }

    let counter = 1;

    return sortedData.map((item) => {
      if (item.count) {
        item.count = counter;
        counter++;
      }

      return item;
    });
  };

  onChangeDataSet = (employeeSetId) => this.setState({ employeeSetId });

  render() {
    const {
      actualPerformance,
      behavioral,
      cognitive,
      columnsData,
      consultativeSkill,
      empStatus,
      filterDivisions,
      filterEmploymentsStatus,
      filterFactorComboMatch,
      filterFirstScore,
      filterOverallScore,
      generalData,
      isEmployeesLoading,
      isLoading,
      maxWidthTable,
      linkToShare,
      hideCognitive,
      defaultSetFilter,
      currentFilterSetName,
      divisions,
      isActiveMiniLoading,
      employeeSetId,
      isCreateLinkModalOpen,
    } = this.state;
    const { employeesFormat, filtersPresets, onGetUpdatedFilters, datasets, onCreateDashboard, selectedDashboard } =
        this.props;

    const filtersPresetsWithDefaultTitles = [
      defaultSetFilter,
      ...(Array.isArray(filtersPresets) ? filtersPresets : []),
    ].map((el) => el.title);
    const sortedEmployeesFormat = this.sortTable(employeesFormat);

    if (isLoading) return <Loader />;

    const mapEmployeeStatuses = {
      ACTIVE: "Active",
      INACTIVE: "Inactive",
      APPLICANT: "Applicant",
    };
    const colors = ["Green", "Yellow", "Gray"];

    return (
        <>
          <section className={styles.wrapper} id="perfomanceWrap">
            <div
                className={
                  this.props.onlyView
                      ? styles.collapseWrapOnlyView
                      : styles.collapseWrap
                }
            >
              {this.props.onlyView ? (
                  <Panel header={`Performance Dashboard`} key="1" />
              ) : (
                  <Collapse
                      accordion
                      expandIconPosition="right"
                      expandIcon={() => <span className={styles.collapseArrow} />}
                  >
                    <Panel header={`Performance Dashboard`} key="1">
                      <div>
                        <div className={styles.actionsWrap}>
                          <div className={styles.actionsButtons}>
                            {actions.map(({ img, title, text }, i) =>
                                title === "LINK" ? (
                                    <Tooltip
                                        key={i}
                                        title={
                                          <span className={styles.tooltipOnButtonLink}>
                                  click to generate a link to this dashboard and
                                  copy it to the clipboard
                                </span>
                                        }
                                    >
                                      <button
                                          className={styles.actionsBtn}
                                          onClick={() => this.actionsClick(title)}
                                          key={i}
                                      >
                                        <img
                                            className={styles.actionsImg}
                                            src={img}
                                            alt={title}
                                        />
                                        {text}
                                      </button>
                                    </Tooltip>
                                ) : (
                                    <button
                                        className={styles.actionsBtn}
                                        onClick={() => this.actionsClick(title)}
                                        key={i}
                                    >
                                      <img
                                          className={styles.actionsImg}
                                          src={img}
                                          alt={title}
                                      />
                                      {text}
                                    </button>
                                )
                            )}
                          </div>
                        </div>
                        <DataSetBadges
                            datasets={datasets}
                            selected={employeeSetId}
                            onChange={this.onChangeDataSet}
                        />
                        <div className={styles.filterSets}>
                          <h3 className={styles.itemTitle}>Filters</h3>
                          <div
                              className={`${styles.filterOption} ${
                                  filtersPresetsWithDefaultTitles.length > 0 &&
                                  styles.teamFilterHasValue
                              }`}
                          >
                            <Select
                                value={currentFilterSetName}
                                style={{ width: "220px" }}
                                showArrow
                                suffixIcon={
                                  <span className={styles.selectFilterIcon} />
                                }
                                dropdownClassName={styles.teamFilterDropdown}
                                placeholder="Filter Sets"
                                onChange={this.handlePresetsFilter}
                            >
                              {filtersPresetsWithDefaultTitles.map((item, i) => (
                                  <Select.Option value={item} key={i}>
                                    {item}
                                  </Select.Option>
                              ))}
                            </Select>
                          </div>
                          <ConfirmDelSave
                              render={
                                <button
                                    className={`${styles.actionsBtnImage} ${
                                        currentFilterSetName === "Unset"
                                            ? styles.actionsBtnDisabled
                                            : ""
                                    }`}
                                    disabled={currentFilterSetName === "Unset"}
                                >
                                  <IconTrash />
                                </button>
                              }
                              disabled={currentFilterSetName === "Unset"}
                              deleteFilter
                              perfomance
                              onGetUpdatedFilters={onGetUpdatedFilters}
                              currentFilterSetName={currentFilterSetName}
                              sendNotification={this.requestNotification}
                              setSavedSetAsCurrent={this.handlePresetsFilter}
                          />
                        </div>
                        <div className={`${styles.teamFilter}`}>
                          <div
                              className={`${styles.filterOption} ${
                                  filterDivisions.length > 0 &&
                                  styles.teamFilterHasValue
                              }`}
                          >
                            <Select
                                value={filterDivisions}
                                mode="multiple"
                                style={{ width: "220px" }}
                                showArrow
                                suffixIcon={
                                  <span className={styles.selectFilterIcon} />
                                }
                                dropdownClassName={styles.teamFilterDropdown}
                                placeholder="Team/Division"
                                onChange={this.handleDivisionFilter}
                            >
                              {divisions.map((item, index) => (
                                  <Select.Option value={item} key={index}>
                                    {item}
                                  </Select.Option>
                              ))}
                            </Select>
                          </div>
                          <div
                              className={`${styles.filterOption} ${
                                  filterEmploymentsStatus.length > 0 &&
                                  styles.teamFilterHasValue
                              }`}
                          >
                            <Select
                                value={filterEmploymentsStatus}
                                mode="multiple"
                                style={{ width: "220px" }}
                                showArrow
                                suffixIcon={
                                  <span className={styles.selectFilterIcon} />
                                }
                                dropdownClassName={styles.teamFilterDropdown}
                                placeholder="Employment Status"
                                onChange={this.handleEmploymentsStatusFilter}
                            >
                              {Object.keys(mapEmployeeStatuses).map((item, i) => (
                                  <Select.Option value={item} key={i}>
                                    {mapEmployeeStatuses[item]}
                                  </Select.Option>
                              ))}
                            </Select>
                          </div>
                          <br />
                          <div
                              className={`${styles.filterOption} ${
                                  filterFactorComboMatch.length > 0 &&
                                  styles.teamFilterHasValue
                              }`}
                          >
                            <Select
                                value={filterFactorComboMatch}
                                mode="multiple"
                                style={{ width: "220px" }}
                                showArrow
                                suffixIcon={
                                  <span className={styles.selectFilterIcon} />
                                }
                                dropdownClassName={styles.teamFilterDropdown}
                                placeholder="Factor Combo Match"
                                onChange={this.handleFactorComboMatchFilter}
                            >
                              {colors.map((item) => (
                                  <Select.Option value={item} key={item}>
                                    {item}
                                  </Select.Option>
                              ))}
                            </Select>
                          </div>
                          {
                            <div
                                className={`${styles.filterOption} ${
                                    filterFirstScore.length > 0 &&
                                    styles.teamFilterHasValue
                                }`}
                            >
                              <Select
                                  value={filterFirstScore}
                                  mode="multiple"
                                  style={{ width: "220px" }}
                                  showArrow
                                  suffixIcon={
                                    <span className={styles.selectFilterIcon} />
                                  }
                                  dropdownClassName={styles.teamFilterDropdown}
                                  placeholder="Current Skill - 1st Score"
                                  onChange={this.handleFirstScoreFilter}
                              >
                                {colors.map((item) => (
                                    <Select.Option value={item} key={item}>
                                      {item}
                                    </Select.Option>
                                ))}
                              </Select>
                            </div>
                          }
                          {
                            <div
                                className={`${styles.filterOption} ${
                                    filterOverallScore.length > 0 &&
                                    styles.teamFilterHasValue
                                }`}
                            >
                              <Select
                                  value={filterOverallScore}
                                  mode="multiple"
                                  style={{ width: "220px" }}
                                  showArrow
                                  suffixIcon={
                                    <span className={styles.selectFilterIcon} />
                                  }
                                  dropdownClassName={styles.teamFilterDropdown}
                                  placeholder="Current Skill - Overall Score"
                                  onChange={this.handleOverallScoreFilter}
                              >
                                {colors.map((item) => (
                                    <Select.Option value={item} key={item}>
                                      {item}
                                    </Select.Option>
                                ))}
                              </Select>
                            </div>
                          }
                        </div>
                        <div className={styles.checkboxesWrap}>
                          <h3 className={styles.itemTitle}>Show Blocks</h3>
                          <div className={styles.checkboxes}>
                            <Checkbox
                                onChange={() =>
                                    this.setState({ generalData: !generalData })
                                }
                                checked={generalData}
                            >
                              <span>Organizational Data</span>
                            </Checkbox>
                            <Checkbox
                                onChange={() =>
                                    this.setState({ behavioral: !behavioral })
                                }
                                checked={behavioral}
                            >
                              <span>Behavioral (BA)</span>
                            </Checkbox>
                            {hideCognitive ? null : (
                                <Checkbox
                                    onChange={() =>
                                        this.setState((prev) => ({
                                          cognitive: !prev.cognitive,
                                        }))
                                    }
                                    checked={cognitive}
                                >
                                  <span>Cognitive (CA)</span>
                                </Checkbox>
                            )}
                            <Checkbox
                                onChange={() =>
                                    this.setState({
                                      consultativeSkill: !consultativeSkill,
                                    })
                                }
                                checked={consultativeSkill}
                            >
                              <span>Skills</span>
                            </Checkbox>
                            <Checkbox
                                onChange={() =>
                                    this.setState({
                                      actualPerformance: !actualPerformance,
                                    })
                                }
                                checked={actualPerformance}
                            >
                              <span>{"Performance"}</span>
                            </Checkbox>
                            <Checkbox
                                onChange={() =>
                                    this.setState({ empStatus: !empStatus })
                                }
                                checked={empStatus}
                            >
                              <span>Emp. Status</span>
                            </Checkbox>
                          </div>
                        </div>
                        <div className={styles.itemFooter}>
                          <button
                              className={styles.footerButton}
                              onClick={this.onRebuildDashboard}
                          >
                            <div className={styles.centeredText}>
                              <IconApply className={styles.buttonImg} alt="Apple" />
                              <span>Apply Filters</span>
                            </div>
                          </button>
                          <ConfirmDelSave
                              render={
                                <button
                                    className={styles.footerButton}
                                    disabled={this.checkNewFilter()}
                                >
                                  <div className={styles.centeredText}>
                                    <IconSave
                                        className={styles.buttonImg}
                                        alt="Save"
                                    />
                                    <span>Save Filter Set</span>
                                  </div>
                                </button>
                              }
                              disabled={this.checkNewFilter()}
                              perfomance
                              existedNames={filtersPresetsWithDefaultTitles}
                              onGetUpdatedFilters={onGetUpdatedFilters}
                              settedFilters={{
                                filterDivisions,
                                filterEmploymentsStatus,
                                filterFactorComboMatch,
                                filterFirstScore,
                                filterOverallScore,
                                displayBlocks: {
                                  actualPerformance,
                                  behavioral,
                                  cognitive,
                                  empStatus,
                                  consultativeSkill,
                                  generalData,
                                },
                              }}
                              sendNotification={this.requestNotification}
                              setSavedSetAsCurrent={this.handlePresetsFilter}
                          />
                        </div>
                      </div>
                    </Panel>
                  </Collapse>
              )}
            </div>
            <div className={styles.tableWrap} style={{ maxWidth: maxWidthTable }}>
              <Table
                  columns={columnsData}
                  dataSource={sortedEmployeesFormat}
                  bordered
                  rowKey={(r, i) => i}
                  loading={
                    (isEmployeesLoading || linkToShare === "inProgress") && {
                      indicator: <Loader />,
                    }
                  }
                  pagination={false}
                  rowClassName={(record) =>
                      record.divisionRow
                          ? `${styles.boldDivision}`
                          : record.subHeaderRow
                          ? `${styles.bold}`
                          : `${styles.usial}`
                  }
                  size="small"
                  scroll={{ x: "max-content", y: "60vh" }}
              />
              <LoaderMini
                  className={styles["loading-mini"]}
                  visible={isActiveMiniLoading}
              />
            </div>
          </section>
          <CreateLinkModal
              visible={isCreateLinkModalOpen}
              onSuccess={this.getLinkToDashboard}
              onCancel={() => this.setState({ isCreateLinkModalOpen: false })}
          />
          <CreateDashBoardModal
              isCopy
              selectedDashboard={selectedDashboard}
              dataSets={this.props.datasets}
              isOpen={this.state.isOpenModal}
              onCreate={onCreateDashboard}
              onClose={this.toggleCopyModal}
          />
        </>
    );
  }
}

const mapStateToProps = (state) => ({
  divisions: selectDivisions(state),
  employees: selectEmployees(state),
  employeesFormat: selectEmployeesFormat(state),
  employeeJobs: selectEmployeesJobs(state),
  employeePerformance: selectEmployeePerformance(state),
  employeePotential: selectEmployeePotential(state),
  employeeRatings: selectEmployeeRatings(state),
  employeeReadiness: selectEmployeeReadiness(state),
  employeeStatuses: selectEmployeeStatuses(state),
  lastUpdate: selectLastUpdate(state),
  filtersPresets: selectFiltersPresets(state),
  isNeedToUpdateEmployees: selectIsNeedToUpdateEmployees(state),
  user: selectUser(state),
});

const mapDispatchToProps = (dispatch) => ({
  onGetUpdatedFilters: (data) => dispatch(updateFilters(data)),
  onGetEmployees: (data) => dispatch(getEmployees(data)),
  onGetEmployeesFormat: (data) => dispatch(getEmployeesFormat(data)),
  updateUser: ({ user, currentJob, userId, division }) =>
      dispatch(updateUser({ user, currentJob, userId, division })),
  removeEmployee: ({ currentJob, userId, division }) =>
      dispatch(removeEmployee({ currentJob, userId, division })),
  onUpdateEmployees: (data) => dispatch(updateEmployees(data)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
    Perfomance
);
