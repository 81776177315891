import React from 'react';
import styles from '../DashboardContent/styles.module.scss';
import {
  CalculatorTable,
  ConfirmDelSave,
  JobSetTable,
  SetStatusTable,
  SetTerminationDateTable,
  SortIcon
} from '../../redux/pages/components';
import {Select, Table, Tooltip} from 'antd';
import { SortingTitle } from './SortingTitle/SortingTitle';
import {
  behavioralTooltip,
  cognitiveTooltip, consultativeTooltip,
  factorTooltip,
  headerTooltip, plansData
} from '../../redux/pages/Perfomance/tooltipsData';
import {noData, titleWithToolTip, renderName, TitleEdit, titleWithToolTipAbsoute} from "./common";
import {DatePickerCell} from "./DatePickerCell/DatePickerCell";
import {READINESS_OPTIONS} from "../../utils/getFiltersConfig";

import {faTrashCan} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const {
  behavioralJob,
  cognitive,
  cognitiveJob,
  consultativeSkill,
  factorCombinations,
  firstScoreText,
  empStatusText,
  overalScoreText,
  pattern,
} = headerTooltip;

const isNumeric = (n) => !isNaN(parseFloat(n)) && isFinite(n);

const chooseTextByKey = (plan) => plansData[plan];

const setColor = (colorCode) => {
  switch (colorCode) {
    case 1:
      return styles.behaviorGreen;
    case 2:
      return styles.behaviorYellow;
    case 3:
      return styles.behaviorRed;
    default:
      return styles.behaviorBlack;
  }
};

const showStatusTextOnHover = (arrayOfString) => (
    <ul className={styles.showArrayOnHover}>
      {arrayOfString.map((item, index) => (
          <li key={index}>{item}</li>
      ))}
    </ul>
);

const renderCognitiveScoreResults = (value, record) => {
  const { cognitiveResult, divisionRow, jobTitle, subHeaderRow } = record;
  if(cognitiveResult === 'hidden') return;
  /*if (divisionRow && !jobTitle) return;*/

  if (subHeaderRow) {
    return isNumeric(value)
        ? titleWithToolTip(value, cognitiveJob)
        : noData(true);
  }
  if (isNumeric(value)) {
    const colorCode = cognitiveResult.colorCode;

    return (
        <span className={`${styles.behavior} ${setColor(colorCode)}`}>
        {titleWithToolTip(value, cognitiveTooltip[colorCode])}
      </span>
    );
  }
  return noData();
};

const RenderGraph = ({ record }) => {
  const {
    comboRangeData,
    divisionRow,
    jobTitle,
    subHeaderRow,
    patternImageURL,
  } = record;
  const getGraph = (jobRow, patternImageURL) => {
    if(patternImageURL === 'hidden') return;
    /*if (divisionRow && !jobTitle) return;*/

    return patternImageURL ? (
        <img
            src={patternImageURL}
            className={styles.graphImg}
            alt="Graph"
            onError={(e) => {
              e.target.onerror = null;
            }}
        />
    ) : jobRow ? (
        noData(true)
    ) : (
        noData()
    );
  };
  return (
      <Tooltip
          title={
            subHeaderRow ? (
                <div className={styles.graphImgModal}>
                  {getGraph(subHeaderRow, patternImageURL)}
                  <p>{behavioralJob}</p>
                </div>
            ) : (
                <div className={styles.graphImgModal}>
                  {getGraph(subHeaderRow, patternImageURL)}
                  <div>
              <span>{`High ${
                  comboRangeData ? comboRangeData.high : 'No data'
              }: `}</span>
                    <b>
                      {comboRangeData &&
                      behavioralTooltip.high[comboRangeData.high].title}
                    </b>
                    <span>
                {comboRangeData &&
                behavioralTooltip.high[comboRangeData.high].text}
              </span>
                  </div>
                  <div>
              <span>{`Low ${
                  comboRangeData ? comboRangeData.low : 'No data'
              }: `}</span>
                    <b>
                      {comboRangeData &&
                      behavioralTooltip.low[comboRangeData.low].title}
                    </b>
                    <span>
                {comboRangeData &&
                behavioralTooltip.low[comboRangeData.low].text}
              </span>
                  </div>
                </div>
            )
          }>
        {getGraph(subHeaderRow, patternImageURL)}
      </Tooltip>
  );
};

const renderCognitiveScoreResultsForJobs = (value, record) => {
  if (isNumeric(value)) {
    const colorCode = record.jobsColorCode;
    return (
        <span className={`${styles.behavior} ${setColor(colorCode)}`}>
        {titleWithToolTip(value, cognitiveTooltip[colorCode])}
      </span>
    );
  }
  return noData();
};

const renderBehavioralResults = (value, record) => {
  if(value === 'hidden') return;
  const toolTipValue = (value) =>
      factorTooltip[value.title] ? (
          <div className={styles.toolTipWrap}>
            <b>{factorTooltip[value.title].title}</b>
            <span>{factorTooltip[value.title].text}</span>
          </div>
      ) : null;

  if (record.subHeaderRow)
    return value
        ? value.title
            ? titleWithToolTip(value.title, toolTipValue(value))
            : noData(true)
        : '';
  if (value)
    return (
        <span className={`${styles.behavior} ${setColor(value.colorCode)}`}>
        {titleWithToolTip(value.title, toolTipValue(value))}
      </span>
    );
  return noData();
};

const renderBehavioralSuccessiomResults = (
    value,
    record,
    job,
    arrOfJobs,
    hideCognitive = false
) => {
  const toolTipValue = (value) =>
      factorTooltip[value.title] ? (
          <div className={styles.toolTipWrap}>
            <b>{factorTooltip[value.title].title}</b>
            <span>{factorTooltip[value.title].text}</span>
          </div>
      ) : null;

  const toolTipValueFCM = (value) =>
      factorTooltip[`${value.colorCode}`] ? (
          <div className={styles.toolTipWrap}>
            <span>{factorTooltip[`${value.colorCode}`].text}</span>
          </div>
      ) : null;

  const createTable = () => {
    try {
      const {
        job1: { behavioralResults: job1 },
        job2: { behavioralResults: job2 },
        job3: { behavioralResults: job3 },
      } = record;

      let headers;
      let jobsCognetiveTarget;
      let jobsColorCode;
      const renderRound = (value) =>
          titleWithToolTip(
              <span className={`${styles.behavior} ${setColor(value.colorCode)}`}>
            {value.title}
          </span>,
              toolTipValue(value)
          );
      const renderRoundFCM = (value) =>
          titleWithToolTip(
              <span className={`${styles.behavior} ${setColor(value.colorCode)}`}>
            {value.title}
          </span>,
              toolTipValueFCM(value)
          );
      const getArrSource = (job_type) => {
        switch (job_type) {
            // case 'current': {
            //   headers = arrOfJobs.find(
            //     (el) => el.name === record.currentJob.title
            //   );
            //   jobsCognetiveTarget = record.cognitiveResult?.target || '';
            //   jobsColorCode = record.cognitiveResult?.colorCode;
            //   return current;
            // }
          case 'job3': {
            headers = arrOfJobs.find((el) => el.id === record.job3.jobId);
            jobsCognetiveTarget = record.job3.cognitiveResult?.target || '';
            jobsColorCode = record.job3.cognitiveResult?.colorCode;
            return job3;
          }
          case 'job2': {
            headers = arrOfJobs.find((el) => el.id === record.job2.jobId);
            jobsCognetiveTarget = record.job2.cognitiveResult?.target || '';
            jobsColorCode = record.job2.cognitiveResult?.colorCode;
            return job2;
          }
          case 'job1': {
            headers = arrOfJobs.find((el) => el.id === record.job1.jobId);
            jobsCognetiveTarget = record.job1.cognitiveResult?.target || '';
            jobsColorCode = record.job1.cognitiveResult?.colorCode;
            return job1;
          }
          default: {
            headers = arrOfJobs.find(
                (el) => el.name === record.currentJob.title
            );
            jobsCognetiveTarget = record.cognitiveResult?.target || '';
            jobsColorCode = record.cognitiveResult?.colorCode;
            return record.currentJob;
          }
        }
      };
      const arrSourse = getArrSource(job);
      const dataSource = [
        {
          behavioralResults: arrSourse,
          cognitiveResult: record.cognitiveResult,
          jobsColorCode,
        },
      ];
      const columns = [
        {
          title: headers?.name || job,
          key: 'job-title',
          className: `${styles.tooltipJobTitle}`,
          children: [
            {
              title: 'Factor Combinations',
              children: [
                {
                  title: headers?.behavioralHeaders[0] || 'N/A',
                  dataIndex: 'behavioralResults[0]',
                  className: `${styles.fontSize9OnHover}`,
                  render: renderRound,
                },
                {
                  title: headers?.behavioralHeaders[1] || 'N/A',
                  dataIndex: 'behavioralResults[1]',
                  className: `${styles.fontSize9OnHover}`,
                  render: renderRound,
                },
                {
                  title: headers?.behavioralHeaders[2] || 'N/A',
                  dataIndex: 'behavioralResults[2]',
                  className: `${styles.fontSize9OnHover}`,
                  render: renderRound,
                },
                {
                  title: headers?.behavioralHeaders[3] || 'N/A',
                  dataIndex: 'behavioralResults[3]',
                  className: `${styles.fontSize9OnHover}`,
                  render: renderRound,
                },
                {
                  title: headers?.behavioralHeaders[4] || 'N/A',
                  dataIndex: 'behavioralResults[4]',
                  className: `${styles.fontSize9OnHover}`,
                  render: renderRound,
                },
                {
                  title: headers?.behavioralHeaders[5] || 'N/A',
                  dataIndex: 'behavioralResults[5]',
                  className: `${styles.fontSize9OnHover}`,
                  render: renderRound,
                },
              ],
            },
            {
              title: 'Factor Combo Match',
              children: [
                {
                  title: '',
                  dataIndex: 'behavioralResults[6]',
                  className: `${styles.fontSize9OnHover} ${styles.comboMatch}`,
                  width: 65,
                  render: renderRoundFCM,
                },
              ],
            },
          ],
        },
      ];
      if (!hideCognitive) {
        columns[0].children.unshift({
          title: 'Cognitive',
          children: [
            {
              title: jobsCognetiveTarget || 0,
              dataIndex: 'cognitiveResult.actual',
              width: 80,
              className: `${styles.fontSize9OnHover}`,
              render: renderCognitiveScoreResultsForJobs,
            },
          ],
        });
      }
      return (
          <Table
              dataSource={dataSource}
              pagination={false}
              bordered
              columns={columns}
              rowKey={(r, i) => i}
          />
      );
    } catch (e) {
      return null;
    }
  };

  if (record.subHeaderRow)
    return value
        ? value.title
            ? titleWithToolTip(value.title, toolTipValue(value))
            : noData(true)
        : '';
  if (record[job] && record[job].behavioralResults && record[job].behavioralResults.length) {
    return (
        <Tooltip title={createTable()}>
          <span className={`${styles.behavior} ${setColor(record[job].behavioralResults[6].colorCode)}`}/>
        </Tooltip>
    );
  }

  return noData();
};

const renderBehavioralResultsFCM = (value, record) => {
  const toolTipValue = (value) =>
      factorTooltip[`${value.colorCode}`] ? (
          <div className={styles.toolTipWrap}>
            <span>{factorTooltip[`${value.colorCode}`].text}</span>
          </div>
      ) : null;

  if (record.subHeaderRow)
    return value
        ? value.title
            ? titleWithToolTip(value.title, toolTipValue(value))
            : noData(true)
        : '';
  if (value)
    return titleWithToolTip(
        <span className={`${styles.behavior} ${setColor(value.colorCode)}`}/>,
        toolTipValue(value)
    );
  return noData();
};

const renderSkillsData = (value, record) => {
  if (record.subHeaderRow) return;
  if (value?.length !== 0) return value;
  return '-';
};

const renderPerformance = (value, record) => {
  if(value === 'hidden') return;
  /*if (record.divisionRow && !record.jobTitle) return;*/
  if (record.jobTitle) {
    if (value !== null && value?.length) return value;
    return '-';
  }
  return value || '-';
};

const renderCalculator = (
    value,
    record,
    index,
    arr = [],
    foo = () => null,
    column = '',
    arrDefault = [],
    placeholder,
    onlyView
) => {
  if (record.subHeaderRow) return;
  return (
      <Select
          getPopupContainer={trigger => trigger.parentElement}
          placeholder={arr[placeholder] || value || 'N/A'}
          showArrow
          dropdownClassName={styles.statusDropdown}
          onChange={(e) => foo(e, record, column,arr,arrDefault)}
          suffixIcon={<span className={styles.selectIcon}/>}
          disabled={onlyView}
      >
        {arr
            .filter((item) => item !== 'NA')
            .map((plan) => (
                <Select.Option value={plan} key={plan}>
                  {plan}
                </Select.Option>
            ))}
      </Select>
  );
};

const renderCompetencies = (value, record) => {
  if (record.jobTitle) {
    if (value && value.length !== 0) return value;
    return '-';
  }
  return value || '-';
};

const renderConsultativeScoreResults = (value, record, columnTitle) => {
  if (isNumeric(value)) {
    const colorCode = record.skillsData.scoreData[columnTitle].colorCode;
    return (
        <span className={`${styles.score} ${setColor(colorCode)}`}>
        {titleWithToolTip(`${value}%`, consultativeTooltip[colorCode])}
      </span>
    );
  }
  return noData();
};

export const getDashboardColumns = (state, props, sortBy, requestNotification, updateEmployee) => {
  const shared = props.roles.includes("ACCOUNT OWNER SHARED") || props.roles.includes("ACCOUNT OWNER/MANAGER");

  const onDeleteEmployee = (employee) => {
    props.removeEmployee({
      userId: employee.id,
      currentJob: employee.currentJob.title,
      division: employee.division,
    });
  };
  return [
    {
      title: 'Employee Information',
      children: [
        {
          title: '№',
          dataIndex: 'count',
          width: 40,
          render: (text, row) => {
            const { divisionRow, jobTitle, supervisorJob } = row;
            if (row.subHeaderRow) {
              return {
                children: (
                  <div className={styles.jobTitleWrap}>
                    {divisionRow && (
                      <span className={styles.jobSupervisor}>
                        {divisionRow}
                      </span>
                    )}
                     {supervisorJob && (
                      <span className={styles.jobSupervisor}>
                        {supervisorJob}
                      </span>
                    )}
                    {jobTitle && (
                      <span className={styles.jobTitle}>{jobTitle}</span>
                    )}
                  </div>
                ),
                props: {
                  colSpan: 3,
                },
              };
            }
            return text;
          },
        },
        {
          title: () =>
            <SortingTitle
              name='LAST_NAME'
              text='Last Name'
              sortBy={sortBy}
              sortType={state.sortType}
              sortDirection={state.sortDirection}
              onlyView={props.onlyView}
            />,
          dataIndex: 'lastName',
          render: renderName,
          width: 86,
          className: `${styles.name}`,
        },
        {
          title: () =>
            <SortingTitle
              name='FIRST_NAME'
              text='First Name'
              sortBy={sortBy}
              sortType={state.sortType}
              sortDirection={state.sortDirection}
              onlyView={props.onlyView}
            />,
          dataIndex: 'firstName',
          width: 86,
          render: renderName,
          className: `${styles.name}`,
        },
      ],
    },
     {
       title: titleWithToolTip('Cognitive (CA)', cognitive),
       dataIndex: 'Cognitive (CA)',
       children: [
         {
           title: () => (
             <span className={styles.sortImg}>
               <SortIcon
                 text={'Score'}
                 name="COGN_RES_SCORE"
                 onClick={sortBy}
                 onlyView={props.onlyView}
                 type={
                   state.sortType === 'COGN_RES_SCORE'
                     ? state.sortDirection
                     : null
                 }
               />
             </span>
           ),
           dataIndex: 'cognitiveResult.actual',
           width: 80,
           className: `${styles.wordWrap}`,
           render: renderCognitiveScoreResults,
         },
       ],
     },
    {
      title: 'BA Performance',
      children: [
        {
          title: titleWithToolTip('Pattern', pattern),
          dataIndex: 'PI',
          className: `${styles.graphContainer} ${styles.wordWrap}`,
          width: 100,
          render: (value, record) => <RenderGraph record={record}/>,
        },
        {
          title: '',
          dataIndex: 'behavioralResults[0]',
          width: 54,
          render: renderBehavioralResults,
          className: `${styles.behaviorHeader} ${styles.wordWrap}`,
        },
        {
          title: (
            <div style={{ position: 'relative' }}>
              {titleWithToolTipAbsoute(
                'Factor Combinations',
                factorCombinations
              )}
            </div>
          ),
          dataIndex: 'behavioralResults[1]',
          width: 54,
          render: renderBehavioralResults,
          className: `${styles.behaviorHeader} ${styles.wordWrap}`,
        },
        {
          title: '',
          dataIndex: 'behavioralResults[2]',
          width: 54,
          render: renderBehavioralResults,
          className: `${styles.behaviorHeader} ${styles.behaviorHeaderFixed} ${styles.wordWrap}`,
        },
        {
          title: '',
          dataIndex: 'behavioralResults[3]',
          width: 54,
          render: renderBehavioralResults,
          className: `${styles.behaviorHeader} ${styles.wordWrap}`,
        },
        {
          title: '',
          dataIndex: 'behavioralResults[4]',
          width: 54,
          render: renderBehavioralResults,
          className: `${styles.behaviorHeader} ${styles.wordWrap}`,
        },
        {
          title: '',
          dataIndex: 'behavioralResults[5]',
          width: 54,
          render: renderBehavioralResults,
          className: `${styles.wordWrap}`,
        },
        {
          title: () => (
            <span className={styles.sortImg}>
              <SortIcon
                text="Factor Combo Match"
                name="COMBO_MATCH_COLOR"
                onClick={sortBy}
                onlyView={props.onlyView}
                type={
                  state.sortType === 'COMBO_MATCH_COLOR'
                    ? state.sortDirection
                    : null
                }
              />
            </span>
          ),
          dataIndex: 'behavioralResults[6]',
          width: 58,
          className: `${styles.comboMatch}`,
          render: renderBehavioralResultsFCM,
        },
      ],
    },
    {
      title: 'BA Succession',
      children: [
        {
          title: titleWithToolTip('Pattern', pattern),
          dataIndex: 'SI',
          className: `${styles.graphContainer} ${styles.wordWrap}`,
          width: 100,
          render: (value, record) => <RenderGraph record={record}/>,
        },
        {
          title: () => (
            <span className={styles.sortImg}>
              <SortIcon
                text="Factor Combo Match"
                name="COMBO_MATCH_COLOR"
                onClick={sortBy}
                onlyView={props.onlyView}
                type={
                  state.sortType === 'COMBO_MATCH_COLOR'
                    ? state.sortDirection
                    : null
                }
              />
            </span>
          ),
          dataIndex: 'behavioralSuccessionResults[6]',
          width: 58,
          className: `${styles.comboMatch}`,
          render: (value, record) => {
            const cellValue = record.behavioralResults ? record.behavioralResults[6] : undefined;
            return renderBehavioralResultsFCM(cellValue, record)
          },
        },
        {
          title: 'Job 1',
          dataIndex: 'jobSuccession1',
          width: 100,
          render: (value, record) => {
            const { subHeaderRow, id, job1, currentJob, division } = record;
            const { employeeJobs, onlyView } = props;
            return subHeaderRow
              ? null
              : (
              <div className={`${styles.calculator}`}>
                <JobSetTable
                  employeeJobs={employeeJobs}
                  jobNumber={1}
                  userId={id}
                  division={division}
                  currentJob={currentJob?.title}
                  jobId={job1?.jobId}
                  updateUser={props.updateUser}
                  onlyView={(onlyView || !props.selectedDashboard.isOwned) && !shared}
                  dashboardId={props.selectedDashboard.id}
                  isSuccession={props.selectedDashboard.succession}
                />
              </div>
            );
          },
          className: `${styles.wordWrap}`,
        },
        {
          title: 'Factor Combo Match 1',
          dataIndex: 'job1.behavioralSuccessionResults[6]',
          width: 52,
          render: (value, record) =>
            renderBehavioralSuccessiomResults(
              value,
              record,
              'job1',
              props.employeeJobs,
              state.hideCognitive
            ),
          className: `${styles.fontSize9} ${styles.wordWrap}`,
        },
        {
          title: 'Job 2',
          dataIndex: 'jobSuccession2',
          width: 100,
          render: (value, record) => {
            const { subHeaderRow, id, job2, currentJob, division } = record;
            const { employeeJobs, onlyView } = props;
            return subHeaderRow
              ? null
              : (
              <div className={`${styles.calculator}`}>
                <JobSetTable
                  employeeJobs={employeeJobs}
                  jobNumber={2}
                  userId={id}
                  division={division}
                  currentJob={currentJob?.title}
                  jobId={job2?.jobId}
                  updateUser={props.updateUser}
                  onlyView={(onlyView || !props.selectedDashboard.isOwned) && !shared}
                  dashboardId={props.selectedDashboard.id}
                  isSuccession={props.selectedDashboard.succession}
                />
              </div>
            );
          },
          className: `${styles.wordWrap}`,
        },
        {
          title: 'Factor Combo Match 2',
          dataIndex: 'job2.behavioralSuccessionResults[6]',
          width: 54,
          render: (value, record) =>
            renderBehavioralSuccessiomResults(
              value,
              record,
              'job2',
              props.employeeJobs,
              state.hideCognitive
            ),
          className: `${styles.fontSize9} ${styles.wordWrap}`,
        },
        {
          title: 'Job 3',
          dataIndex: 'jobSuccession3',
          width: 100,
          render: (value, record) => {
            const { subHeaderRow, id, job3, currentJob, division } = record;
            const { employeeJobs, onlyView } = props;
            return subHeaderRow
              ? null
              : (
              <div className={`${styles.calculator}`}>
                <JobSetTable
                  employeeJobs={employeeJobs}
                  jobNumber={3}
                  userId={id}
                  division={division}
                  currentJob={currentJob?.title}
                  jobId={job3?.jobId}
                  updateUser={props.updateUser}
                  onlyView={(onlyView || !props.selectedDashboard.isOwned) && !shared}
                  dashboardId={props.selectedDashboard.id}
                  isSuccession={props.selectedDashboard.succession}
                />
              </div>
            );
          },
          className: `${styles.wordWrap}`,
        },
        {
          title: 'Factor Combo Match 3',
          dataIndex: 'job3.behavioralSuccessionResults[6]',
          width: 54,
          render: (value, record) =>
            renderBehavioralSuccessiomResults(
              value,
              record,
              'job3',
              props.employeeJobs,
              state.hideCognitive
            ),
          className: `${styles.fontSize9} ${styles.wordWrap}`,
        },
      ],
    },
    {
      title: titleWithToolTip('Current Skill', consultativeSkill),
      dataIndex: 'Current Skill',
      children: [
        {
          title: () => (
            <span className={styles.sortImg}>
              <SortIcon
                Component={titleWithToolTip('Op', 'Open')}
                name="OPEN_SKILL"
                onClick={sortBy}
                onlyView={props.onlyView}
                type={
                  state.sortType === 'OPEN_SKILL' ? state.sortDirection : null
                }
              />
            </span>
          ),
          dataIndex: 'skillsData.openSkill',
          width: 52,
          height: '100%',
          render: renderSkillsData,
        },
        {
          title: () => (
            <span className={styles.sortImg}>
              <SortIcon
                Component={titleWithToolTip('In', 'Investigate')}
                name="INVESTIGATION_SKILL"
                onClick={sortBy}
                onlyView={props.onlyView}
                type={
                  state.sortType === 'INVESTIGATION_SKILL' ? state.sortDirection : null
                }
              />
            </span>
          ),
          dataIndex: 'skillsData.investigationSkill',
          width: 52,
          render: renderSkillsData,
        },
        {
          title: () => (
            <span className={styles.sortImg}>
              <SortIcon
                Component={titleWithToolTip('Pr', 'Present')}
                name="PRESENT_SKILL"
                onClick={sortBy}
                onlyView={props.onlyView}
                type={
                  state.sortType === 'PRESENT_SKILL' ? state.sortDirection : null
                }
              />
            </span>
          ),
          dataIndex: 'skillsData.presentSkill',
          width: 52,
          render: renderSkillsData,
        },
        {
          title: () => (
            <span className={styles.sortImg}>
              <SortIcon
                Component={titleWithToolTip('Con', 'Confirm')}
                name="CONFIRM_SKILL"
                onClick={sortBy}
                onlyView={props.onlyView}
                type={
                  state.sortType === 'CONFIRM_SKILL'
                    ? state.sortDirection
                    : null
                }
              />
            </span>
          ),
          dataIndex: 'skillsData.confirmSkill',
          width: 56,
          render: renderSkillsData,
        },
        {
          title: () => (
            <span className={styles.sortImg}>
              <SortIcon
                Component={titleWithToolTip('Pos', 'Position')}
                name="POSITION_SKILL"
                onClick={sortBy}
                onlyView={props.onlyView}
                type={
                  state.sortType === 'POSITION_SKILL'
                    ? state.sortDirection
                    : null
                }
              />
            </span>
          ),
          dataIndex: 'skillsData.positionSkill',
          width: 56,
          render: renderSkillsData,
        },
        {
          title: () => (
            <span className={styles.sortImg}>
              <SortIcon
                Component={titleWithToolTip('Overall Score', overalScoreText)}
                name="OVERALL_SCORE"
                onClick={sortBy}
                onlyView={props.onlyView}
                type={
                  state.sortType === 'OVERALL_SCORE'
                    ? state.sortDirection
                    : null
                }
              />
            </span>
          ),
          dataIndex: 'skillsData.scoreData.overallScore.value',
          width: 66,
          render: (value, record) => {
            const { skillsData, subHeaderRow } = record;
            if (subHeaderRow) return;
            const count = skillsData && skillsData.scoreData.assesmentsCount;
            const columnTitle = 'overallScore';
            return (
              <div className={styles.actualWrap}>
                {renderConsultativeScoreResults(value, record, columnTitle)}
                {count && <span className={styles.actualBadge}>{count}</span>}
              </div>
            );
          },
        },
        {
          title: titleWithToolTip('1st Score', firstScoreText),
          dataIndex: 'skillsData.scoreData.benchmark.value',
          width: 56,
          render: (value, record) => {
            if (record.subHeaderRow) return;
            const columnTitle = 'benchmark';
            return renderConsultativeScoreResults(value, record, columnTitle);
          },
        },
      ],
    },
    {
      title:
          props.selectedDashboard.isOwned || shared
              ? <TitleEdit title={props.selectedDashboard.performanceName || 'Performance'} onEdit={props.updateDashboard} selectedDashboard={props.selectedDashboard} field={'performanceName'}/>
              :
              props.selectedDashboard.performanceName || 'Performance',
      dataIndex: 'Performance',
      children: [
        {
          title: '',
          dataIndex: 'perfomanceData.firstPerfColumn',
          width: 70,
          render: renderPerformance,
        },
        {
          title: '',
          dataIndex: 'perfomanceData.secondPerfColumn',
          width: 70,
          render: renderPerformance,
        },
        {
          title: '',
          dataIndex: 'perfomanceData.thirdPerfColumn',
          width: 70,
          render: renderPerformance,
        },
        {
          title: '',
          dataIndex: 'perfomanceData.fourthPerfColumn',
          width: 70,
          render: renderPerformance,
        },
        {
          title: '',
          dataIndex: 'perfomanceData.fifthPerfColumn',
          width: 70,
          render: renderPerformance,
        },
        {
          title: () => (
            <span className={styles.sortImg}>
              <SortIcon
                text="Rating"
                name="RATING"
                onClick={sortBy}
                onlyView={props.onlyView}
                type={
                  state.sortType === 'RATING'
                    ? state.sortDirection
                    : null
                }
              />
            </span>
          ),
          dataIndex: 'perfomanceData.employeeRating',
          width: 90,
          render: (value, record) => {
            if (record.subHeaderRow) return;
            if ((props.onlyView || !props.selectedDashboard.isOwned) && !shared) {
              return <span>{chooseTextByKey(value) || 'N/A'}</span>;
            }
            return (
              <Select
                placeholder={chooseTextByKey(value) || 'N/A'}
                showArrow
                dropdownClassName={styles.statusDropdown}
                getPopupContainer={(trigger) => trigger.parentNode}
                onChange={(e) => updateEmployee(e, record, 'rating')}
                suffixIcon={<span className={styles.selectIcon}/>}
              >
                {props.employeeRatings.map((plan) => (
                  <Select.Option value={plan} key={chooseTextByKey(plan)}>
                    {chooseTextByKey(plan)}
                  </Select.Option>
                ))}
              </Select>
            );
          },
        },
      ],
    },
    {
      title:
          props.selectedDashboard.isOwned || shared
              ? <TitleEdit title={props.selectedDashboard.competenciesName || 'Competencies'} onEdit={props.updateDashboard} selectedDashboard={props.selectedDashboard} field={'competenciesName'}/>
              :
              props.selectedDashboard.competenciesName || 'Competencies',
      dataIndex: 'Competencies',
      children: [
        {
          title: '',
          dataIndex: 'competenciesData.firstCompetenciesColumn',
          width: 70,
          render: renderCompetencies,
        },
        {
          title: '',
          dataIndex: 'competenciesData.secondCompetenciesColumn',
          width: 70,
          render: renderCompetencies,
        },
        {
          title: '',
          dataIndex: 'competenciesData.thirdCompetenciesColumn',
          width: 70,
          render: renderCompetencies,
        },
        {
          title: '',
          dataIndex: 'competenciesData.fourthCompetenciesColumn',
          width: 70,
          render: renderCompetencies,
        },
        {
          title: '',
          dataIndex: 'competenciesData.fifthCompetenciesColumn',
          width: 70,
          render: renderCompetencies,
        },
      ],
    },
    {
      title: '9-box & readiness calculator',
      children: [
        {
          title: 'Performance',
          dataIndex: 'successionData.perfomance',
          width: 90,
          render: (value, record, index) =>
            renderCalculator(
              value,
              record,
              index,
              state?.employeeLines9BoxData?.employeeLineFirst?.values || [],
              updateEmployee,
              'perfomance',
              state?.employeeLines9BoxData?.employeeLineFirst?.default || [],
              value==='LOW' ? 0 : value === 'EFFECTIVE' ? 1 : value === 'HIGH' ? 2 : value,
                (props.onlyView || !props.selectedDashboard.isOwned) && !shared
            ),
        },
        {
          title: 'Potential',
          dataIndex: 'successionData.potential',
          width: 90,
          render: (value, record, index) =>
            renderCalculator(
              value,
              record,
              index,
              state?.employeeLines9BoxData?.employeeLineSecond?.values || [],
              updateEmployee,
              'potential',
              state.employeeLines9BoxData?.employeeLineSecond?.default || [],
              value==='LOW' ? 0 : value === 'MODERATE' ? 1 : value === 'HIGH' ? 2 : 'N/A',
                (props.onlyView || !props.selectedDashboard.isOwned) && !shared
            ),
        },
        {
          title: '9-Box Image',
          dataIndex: 'successionData.boxIndex',
          className: `${styles.calculatorContainer}`,
          width: 90,
          render: (value, record) => {
            const { divisionId, divisionRow, subHeaderRow, jobTitle, nineBox, supervisorJob } = record;
            if (subHeaderRow && divisionRow && !nineBox) {
              return;
            }
            return (
              <div
                className={`${styles.calculator} ${
                  subHeaderRow && styles.divisionCalculator
                }`}
              >
                <CalculatorTable
                  divisionId={subHeaderRow && divisionId}
                  idArr={subHeaderRow ? [1, 2, 3, 4, 5, 6, 7, 8, 9] : [value]}
                  isDivision={divisionRow}
                  isJob={subHeaderRow}

                  employeesFormat={props.employeesFormat}
                  onlyView={props.onlyView}
                  employeeJobs={props.employeeJobs}
                  jobName={jobTitle || supervisorJob}
                  isRevertValues={props.employeeLines?.isRevertValues}
                  isRevertLabels={props.employeeLines?.isRevertLabels}
                />
              </div>
            );
          },
        },
        {
          title: 'Readiness',
          dataIndex: 'successionData.readiness',
          width: 105,
          render: (value, record, index) =>{
            const placeholder = READINESS_OPTIONS.findIndex(el => el?.toLowerCase() === value?.toLowerCase());
            return renderCalculator(
              value,
              record,
              index,
              props.employeeReadiness,
              updateEmployee,
              'readiness',
              [],
                placeholder >= 0 ? placeholder + 1 : null,
                (props.onlyView || !props.selectedDashboard.isOwned) && !shared
            )},
        },
      ],
    },
    {
      title: titleWithToolTip(
        'Emp Status',
        showStatusTextOnHover(empStatusText)
      ),
      dataIndex: 'Emp Status',
      children: [
        {
          title: () => (
            <span className={styles.sortImg}>
              <SortIcon
                Component={titleWithToolTip(
                  'Status',
                  showStatusTextOnHover(empStatusText)
                )}
                name="STATUS"
                onClick={sortBy}
                onlyView={props.onlyView}
                type={
                  state.sortType === 'STATUS'
                    ? state.sortDirection
                    : null
                }
              />
            </span>
          ),
          dataIndex: 'employeeStatus',
          width: 65,
          render: (value, record) => {
            const { subHeaderRow } = record;
            const { onlyView, updateUser } = props;

            if (subHeaderRow) return;

            return (
              <SetStatusTable
                value={value}
                updateUser={updateUser}
                dashboardId={props.selectedDashboard.id}
                onlyView={(onlyView || !props.selectedDashboard.isOwned) && !shared}
                statuses={['INAC', 'AP', 'AC']}
                record={record}
                isSuccession={props.selectedDashboard.succession}
              />
            );
          },
        },
      ],
    },
    {
      title: 'Organizational Data',
      children: [
        {
          title: () => (
              <span className={styles.sortImg}>
              <SortIcon
                  text='Location'
                  name='LOCATION'
                  onClick={sortBy}
                  onlyView={props.onlyView}
                  type={
                    state.sortType === 'LOCATION'
                        ? state.sortDirection
                        : null
                  }
              />
            </span>
          ),
          dataIndex: 'location',
          width: 104,
          className: `${styles.location}`,
          render: (value, record) => {
            if (record.subHeaderRow) {
              return;
            }
            return value ? value : '-';
          },
        },
        {
          title: () => <span>Start Date</span>,
          dataIndex: 'startDate',
          width: 100,
          render: (value, record) => {
            return (
                <DatePickerCell
                    value={record.hireDate}
                    record={record}
                    sendNotification={requestNotification}
                    onlyView={(props.onlyView || !props.selectedDashboard.isOwned) && !shared}
                    updateUser={props.updateUser}
                    isTerminate={false}
                    type='hireDate'
                    name='Start'
                    maxDate={record.terminationDate}
                    dashboardId={props.selectedDashboard.id}
                    isSuccession={props.selectedDashboard.succession}
                />
            )
          }
        },
        {
          title: () => <span>Termination Date</span>,
          dataIndex: 'termination',
          width: 100,
          render: (value, record) => {
            return (
                <DatePickerCell
                    value={record.terminationDate}
                    record={record}
                    sendNotification={requestNotification}
                    onlyView={(props.onlyView || !props.selectedDashboard.isOwned) && !shared}
                    updateUser={props.updateUser}
                    isTerminate={false}
                    type='terminationDate'
                    name='Termination'
                    minDate={record.hireDate}
                    dashboardId={props.selectedDashboard.id}
                    isSuccession={props.selectedDashboard.succession}
                />
            )
          }
        },
        {
          title: () => (
              <span className={styles.sortImg}>
              <SortIcon
                  text='Tenure'
                  name='TENURE'
                  onClick={sortBy}
                  onlyView={props.onlyView}
                  type={
                    state.sortType === 'TENURE'
                        ? state.sortDirection
                        : null
                  }
              />
            </span>
          ),
          dataIndex: 'periodOfWork',
          width: 100,
          render: (value, record) => {
            const {
              subHeaderRow,
              hireDate,
              terminationDate,
              id,
              currentJob,
              division,
              employeeStatus,
            } = record;
            const { onlyView } = props;

            return subHeaderRow ? null : (
                <div className={`${styles.calculator}`}>
                  {hireDate
                      ? (<span>{value || '-'}</span>)
                      : (<span>{'-'}</span>)
                  }
                </div>
            );
          },
        },
      ],
    },
    {
      title: 'Action',
      width: 60,
      children: [
        {
          title: '',
          dataIndex: 'canDelete',
          width: 60,
          render: (value, record) => {
            const { user } = props;

            const disabled =
                ((!user || (user && user.roles.includes('ACCOUNT OWNER/MANAGER'))) || !props.selectedDashboard.isOwned) && !shared;

            return (
              value && (
                <ConfirmDelSave
                  render={<FontAwesomeIcon className={styles.iconTrash} icon={faTrashCan}/>}
                  disabled={disabled}
                  deleteEmployee
                  employee={record}
                  afterDeleteEmployee={() => onDeleteEmployee(record)}
                  sendNotification={requestNotification}
                  dashboardId={props.selectedDashboard.id}
                />
              )
            );
          },
        },
      ],
    },
  ]
};
