import { combineReducers } from "redux";

import { adminReducer as adminPart } from "./admin-pages/reducer";
import { authReducer as auth } from "./pages/Login/reducer";
import { perfomanceReducer as perfomance } from "./pages/Perfomance/reducer";
import { dashboardReducerView as dashboardView } from "./pages/DashboardView/reducer";

export default combineReducers({
  adminPart,
  auth,
  perfomance,
  dashboardView,
});
