export const checkPasswordPattern = value =>
  typeof value === 'string' && /^(?=.*[a-zA-Z0-9]).{3,}$/.test(value);

export const checkUserPasswordPattern = value =>
  typeof value === 'string' && /^(?=.*\d)(?=.*[A-Z]).{10,32}$/.test(value);

export const checkPhonePattern = value => 
  typeof value === 'string' && /^[0-9()-\s]*$/.test(value);

export const checkEmailPattern = value =>
  typeof value === 'string' && value.length >= 8 && /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/.test(value);