import React from 'react';
import CustomModal from '../../../redux/admin-pages/components/CustomModal/CustomModal';
import styles from './DeleteDashboardModal.module.scss';

export const DeleteDashboardModal = ({ visible, ontoggleModal, onDelete }) => {
  return(
    <CustomModal visible={visible} onCancel={ontoggleModal} className={styles.modalWrap} >
      <div className={styles.innerWrap}>
        <div className={styles.content}>
          <span className={styles.title}>Are you sure you want to delete this dashboard?</span>
          <div className={styles.btnWrapper}>
            <button
              onClick={ontoggleModal}
              className={styles.stepsBtn}
            >
              Cancel
            </button>
            <button
              className={styles.stepsBtn}
              onClick={onDelete}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </CustomModal>
  )
};
