export const copyTextInBuffer = (text) => {
  const input = document.createElement('input');

  input.setAttribute('value', text);

  input.style.opacity = '0';

  document.body.appendChild(input);

  input.select();

  document.execCommand('copy');

  document.body.removeChild(input);
};
