export const downloadFile = (data, fileName) => {
  const type = fileName.split('.').pop();
  const blob = type === 'xlsx'
    ? new Blob([buffer(atob(data))], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' })
    : new Blob([data]);
  const a = document.createElement('a');

  document.body.appendChild(a).setAttribute('download', fileName);
  a.href = window.URL.createObjectURL(blob);
  a.click();
  window.URL.revokeObjectURL(a.href);
};

export const buffer = s => {
  let buf = new ArrayBuffer(s.length);
  let view = new Uint8Array(buf);
  for (let i = 0; i !== s.length; i++) {
    view[i] = s.charCodeAt(i) & 0xFF;
  }
  return buf;
};

const getLocale = () => {
  if (window.navigator.languages) {
    return window.navigator.languages[0];
  }
  return window.navigator.language;
}

export const formatDate = date => 
  new Date(date).toLocaleDateString(getLocale(), {
    hour: 'numeric',
    minute: 'numeric',
  });

export const onDisabledSaveButton = (form, requiredFild = [], firstAttempt = true) => {

  const objErrorForm = form.getFieldsError();
  const err = [];
  for (let key in objErrorForm) {
    err.push(objErrorForm[key])
  }
  const isErrorForm = err.filter(Boolean).length !== 0;
    
  const objEmptyForm = form.getFieldsValue();
  const empty = [];
  for (let key in objEmptyForm) {
    requiredFild.map(item => {
      if (key === item) {
        empty.push(objEmptyForm[key])
      }
      return empty
    })
  }
  const isEmptyForm = empty.filter(Boolean).length !== empty.length;

  if (firstAttempt) {
    return isEmptyForm;
  }
  return isEmptyForm || isErrorForm;
};
