import React, { PureComponent } from 'react';
import { Input } from 'antd';
import classnames from 'classnames';

import api from '../../../../api';
import {
  CustomModal,
  BlockDeleteMethods,
} from '../../../admin-pages/components';
import styles from './ConfirmDelSave.module.scss';

class ConfirmDelSave extends PureComponent {
  state = {
    isOpenModal: false,
    requestInProcess: false,
    failToSet: false,
    disableSaveButton: true,
  };

  ref = React.createRef();

  showTable = () => this.setState({ isOpenModal: true });
  hideTable = () =>
    this.setState({
      isOpenModal: false,
      requestInProcess: false,
      failToSet: false,
      disableSaveButton: true,
      nameOfSet: '',
    });

  onChange = (val) => {
    const { existedNames } = this.props;
    const exist = existedNames.find((el) => el === val.target.value);
    this.setState({
      disableSaveButton: exist,
      nameOfSet: val.target.value,
      failToSet: false,
    });
  };

  handleSaveSet = () => {
    const {
      settedFilters,
      onGetUpdatedFilters,
      sendNotification,
      setSavedSetAsCurrent,
      perfomance,
    } = this.props;
    const { nameOfSet } = this.state;
    const data = {
      ...settedFilters,
      title: nameOfSet,
    };
    const currentApi = perfomance
      ? api.employees.saveFilterP
      : api.employees.saveFilterS;
    this.setState({ requestInProcess: true });
    currentApi(data)
      .then(onGetUpdatedFilters)
      .then(() => {
        setSavedSetAsCurrent(nameOfSet);
        this.setState({
          failToSet: false,
          isOpenModal: false,
          requestInProcess: false,
        });
        sendNotification('Successfully saved');
      })
      .catch(() => this.setState({ failToSet: true }));
  };

  handleDeleteSet = () => {
    const {
      currentFilterSetName,
      setSavedSetAsCurrent,
      onGetUpdatedFilters,
      sendNotification,
      perfomance,
    } = this.props;
    const data = {
      title: currentFilterSetName,
    };
    const currentApi = perfomance
      ? api.employees.deleteFilterP
      : api.employees.deleteFilterS;
    this.setState({ requestInProcess: true });
    currentApi(data)
      .then(onGetUpdatedFilters)
      .then(() => {
        setSavedSetAsCurrent('Unset');
        this.setState({
          failToSet: false,
          isOpenModal: false,
          requestInProcess: false,
        });
        sendNotification('Set was successfully deleted');
      })
      .catch(() => this.setState({ failToSet: true, requestInProcess: false }));
  };

  handleDeleteEmployee = () => {
    const { employee, afterDeleteEmployee, sendNotification } = this.props;
    this.setState({ requestInProcess: true });
    const data = [employee.id];
    this.setState({ isOpenModal: false, requestInProcess: false });
    api.employees
      .deleteEmployee(data, this.props.dashboardId)
      .then(() => {
        this.setState({ isOpenModal: false, requestInProcess: false });
        sendNotification('Employee was successfully deleted');
      })
      .catch(() => {
        this.setState({ failToSet: true, requestInProcess: false });
        sendNotification('Something going wrong');
      });
    afterDeleteEmployee();
  };

  render() {
    const {
      isOpenModal,
      requestInProcess,
      failToSet,
      disableSaveButton,
      nameOfSet,
    } = this.state;
    const { render, disabled, deleteFilter, deleteEmployee } = this.props;

    return (
      <section className={classnames(styles.wrapper, disabled ? styles.disabled : '')}>
        <div
          onClick={disabled ? null : this.showTable}>
          {render}
        </div>
        <CustomModal
          visible={isOpenModal}
          onCancel={this.hideTable}
          className={styles.modalWrap}>
          {deleteFilter || deleteEmployee ? (
            <BlockDeleteMethods
              modalClose={this.hideTable}
              onYes={
                deleteFilter ? this.handleDeleteSet : this.handleDeleteEmployee
              }
              title={
                deleteFilter
                  ? 'Are you sure you want to delete selected filter set?'
                  : 'Are you sure you want to delete an employee?'
              }
            />
          ) : (
            <>
              <div className={styles.innerWrap}>
                <div className={styles.content}>
                  <div className={styles.textQuestion}>Enter Filter Name</div>
                  <Input
                    placeholder='Basic usage'
                    maxLength={20}
                    itemRef={this.ref}
                    onChange={this.onChange}
                  />
                  <div className={styles.text}>The name must be unique*</div>
                  <div className={styles.btnWrapper}>
                    <button
                      disabled={requestInProcess}
                      onClick={this.hideTable}
                      className={styles.stepsBtn}>
                      Cancel
                    </button>
                    <button
                      disabled={
                        requestInProcess || disableSaveButton || !nameOfSet
                      }
                      onClick={this.handleSaveSet}
                      className={styles.stepsBtn}>
                      Save Filter
                    </button>
                  </div>
                  {failToSet && (
                    <div className={styles.errorText}>Fail to save a Set</div>
                  )}
                </div>
              </div>
            </>
          )}
        </CustomModal>
      </section>
    );
  }
}
export default ConfirmDelSave;
