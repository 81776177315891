import React, { PureComponent } from 'react';
import { createForm } from 'rc-form';
import queryString from 'query-string';

import Logo from '../../../images/logo.png';

import api from '../../../api';

import ConfirmationFormInner from './ConfirmationFormInner';

import styles from './Confirmation.module.scss';

class Confirmation extends PureComponent {
  state = {
    showValidation: false,
    focusedInputName: '',
    params: {},
    isError: false,
    errorText: '',
  }

  onSubmit = e => {
    const { form } = this.props;
    const { params } = this.state;

    e.preventDefault();
    this.setState({showValidation: true});
    const formData = form.getFieldsValue();

    const data = {
      id: +params.id || null,
      password: formData.password || null,
      passwordConfirm: formData.confirmPassword || null,
      token: params.token || null
    }


    
    form.validateFields(err => {
      if (!err) {
        api.users.confirmationUser(data)
          .then((email) => {
            this.props.history.push(`/login?email=${email||""}&notification=confirmation`)
          })
          .catch((err) => this.setState({isError: true, errorText: err.response.data.message}));
      }
    });
  };

  onInputChange = () => {
    this.setState({showValidation: false, isError: false, textError: ''});
  }

  setFocusedInputName = (inputName) => {
    this.setState({focusedInputName: inputName})
  }

  UNSAFE_componentWillMount() {
    let url = this.props.location.search;
    this.setState({params: queryString.parse(url)})
  }

  render() {
    const { form } = this.props;
    const { showValidation, focusedInputName, isError, errorText } = this.state;

    return (
      <section className={styles.wrapper}>
        <div className={styles.inner}>
          <img src={Logo} className={styles.logoImg} alt="MindWire"/>
          <h2 className={styles.title}>Set Password</h2>
          <div className={styles.subTitle}>
            <span>Password must be:</span>
            <ul>
              <li>at least one capital letter</li>
              <li>at least one digit</li>
              <li>minimum 10 characters</li>
            </ul>
          </div>
          <form onSubmit={this.onSubmit}>
            <ConfirmationFormInner
              form={form}
              showValidation={showValidation}
              focusedInputName={focusedInputName}
              onInputChange={this.onInputChange}
              onFocus={(inputName) => this.setFocusedInputName(inputName)}
              onBlur={() => this.setFocusedInputName("")}
            />
            {isError && <p className={styles.errorText}>{errorText}</p>}

            <button className={styles.setPasswordBtn}>
              Set Password
            </button>
          </form>
        </div>
      </section>
    );
  }
}



export default createForm()(Confirmation);
