import api from '../../../api';
import { checkJwtExpired, clearStorage, TOKEN_KEY } from '../../../utils';
import { browserHistory } from '../../../browserHistory';

import {
  AUTH_START,
  AUTH_SUCCESS,
  AUTH_FAIL,
  CLEAR_AUTH_FAIL,
  PERFOMANCE_CLEAR_EMPLOYEES_SUCCESS,
  SUCCESSION_CLEAR_EMPLOYEES_SUCCESS,
  USER_DATA,
  PERFOMANCE_LAST_UPDATE_CLEAR,
  SUCCESSION_LAST_UPDATE_CLEAR,
  LOGOUT,
  USER_CHANGE_ROLE,
  USER_ROLE_GETBACK,
  GET_USER,
} from '../../../constants/constants';

// Authentication login
export const authStart = () => ({
  type: AUTH_START,
});

export const authSuccess = () => (dispatch) => {
  dispatch({
    type: AUTH_SUCCESS,
  });
};

export const authFail = (error) => ({
  type: AUTH_FAIL,
  error,
});

export const clearAuthFail = () => ({
  type: CLEAR_AUTH_FAIL,
});

export const userData = (data) => ({
  type: USER_DATA,
  data,
});

export const userChangeRole = (data) => ({
  type: USER_CHANGE_ROLE,
  data,
});

export const userGetBackRole = (data) => ({
  type: USER_ROLE_GETBACK,
  data,
});

export const getUser = (user) => ({
  type: GET_USER,
  user,
});

export const loginUser = (credentials) => (dispatch) => {
  dispatch(authStart());
  api.auth
    .login(credentials)
    .then((response) => {
      localStorage.setItem(TOKEN_KEY, response.jwtToken);

      dispatch(authSuccess());
      dispatch(userData(response));

      const isAdmin = response.roles.includes('ADMIN');

      // '/home' is quick fix for a hash router
      browserHistory.push(isAdmin ? '/companyManagement' : '/home');
    })
    .catch((response) => {
      // const notificationSettings = {
      //   type: 'error',
      //   message: 'Oops... something went wrong, try again'
      // };
      // if(response.response && response.response.status !== 404) {
      //   openNotificationWithIcon(notificationSettings);
      // }
      dispatch(authFail(response.response));
    });
};

export const initializeApp = () => (dispatch) => {
  const token = localStorage.getItem(TOKEN_KEY);
  if (token && !checkJwtExpired(token)) {
    dispatch(authSuccess());
  } else {
    clearStorage();
    dispatch({ type: LOGOUT });
    browserHistory.push('/login');
  }
};

// Authentication logout

const deleteSharedAccess = (jwtToken, userIdShared) => {
  api.users
    .deleteShareAccess({
      userId: userIdShared,
      jwtTokenShareAccess: jwtToken,
    })
    .then(() => clearStorage())
    .catch(() => clearStorage());
};

export const logoutUser = (userIdShared) => (dispatch) => {
  const token = localStorage.getItem(TOKEN_KEY);

  if (!token) {
    return;
  }

  api.auth
    .logout()
    .then(() => clearStorage())
    .catch(() => clearStorage());

  dispatch({ type: LOGOUT });
  dispatch({ type: SUCCESSION_CLEAR_EMPLOYEES_SUCCESS });
  dispatch({ type: PERFOMANCE_CLEAR_EMPLOYEES_SUCCESS });
  dispatch({ type: SUCCESSION_LAST_UPDATE_CLEAR });
  dispatch({ type: PERFOMANCE_LAST_UPDATE_CLEAR });

  if (userIdShared) {
    deleteSharedAccess(token, userIdShared)
  }

  browserHistory.push('/login');
};
