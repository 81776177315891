import React, { PureComponent } from 'react';
import { Form, Select, Switch, Checkbox } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import classnames from 'classnames';

import api from '../../../../api';
import { selectCompanies } from '../../selectors';
import { isUpdateCompanies } from '../../actions';
import {
  onDisabledSaveButton,
  openNotificationWithIcon,
  sortStrings,
  capitalize,
  compareStrings,
} from '../../../../utils';
import { Loader } from '../../../../components';
import { AreYouWantToCancel, CustomModal } from '../../components';
import { FormInput } from '../../../../components';
import styles from './AddUser.module.scss';
import { AddUserWithPopupTemplateHOC } from './AddUserWithPopupTemplateHOC';
import { parseString } from '../../../../utils';
import { selectUser } from '../../../pages/Login/selectors';

class AddUser extends PureComponent {
  state = {
    firstAttempt: true,
    focusedInputName: '',
    isLoading: false,
    modalType: 'none',
    initialPopUpData: null,
    isSaveButtonDisabled: true,
    passwordError: null,
    showValidation: true,
    cognitiveView: true,
    companies: [],
  };

  componentDidMount() {
    const { currentPeople, form, isDetails, isNotMainAdmin } = this.props;
    const {
      companyName,
      email,
      firstName,
      lastName,
      phoneNumber,
      roles,
      cognitiveView,
      companyManagedIds,
    } = currentPeople;

    if (companyManagedIds) {
      this.setState({ companies: [...companyManagedIds] });
    }

    let userRole;

    try {
      userRole = roles[0];
    } catch (e) {
      userRole = undefined;
    }
    this.props.onRef(this);

    const data = {
      companyName: isNotMainAdmin ? undefined : companyName || undefined,
      userRole: capitalize(userRole) || undefined,
      email: email || '',
      firstName: firstName || '',
      lastName: lastName || '',
      phone: phoneNumber || '',
      companies: companyManagedIds || [],
    };

    form.setFieldsValue(data);

    const initialPopUpData = {
      ...data,
      cognitiveView: isDetails ? !!cognitiveView : true,
    };

    this.setState(
      () => ({ initialPopUpData }),
      () =>
        this.setState((prev) => ({
          cognitiveView: prev.initialPopUpData.cognitiveView,
        }))
    );
  }

  componentWillUnmount() {
    this.props.onRef(undefined);
  }

  componentDidUpdate() {
    const { initialPopUpData, cognitiveView, companies = [] } = this.state;
    const { form } = this.props;
    const { companyName, userRole, email, firstName, lastName, phone } =
      form.getFieldsValue();

    const newPopUpData = {
      companyName,
      userRole,
      email,
      firstName,
      lastName,
      phone,
      companies,
      cognitiveView,
    };

    const isPopUpDataEqual =
      JSON.stringify(initialPopUpData) === JSON.stringify(newPopUpData);

    this.setState({ isSaveButtonDisabled: isPopUpDataEqual });
  }

  onEditClick = (ref, fieldName) => {
    if (fieldName !== this.state.focusedInputName) {
      this.setState({ focusedInputName: fieldName });
      ref.current.disabled = false;
    }
    return ref.current.focus();
  };

  onSubmit = (e) => {
    const {
      companies,
      currentPeople,
      form,
      isDetails,
      modalClose,
      isNotMainAdmin,
    } = this.props;

    const { companyName, email, phone, firstName, lastName, userRole } =
      form.getFieldsValue();

    const { cognitiveView } = this.state;

    e.preventDefault();

    this.setState({ firstAttempt: false });

    form.validateFields((err) => {
      if (err) {
        this.setState({ showValidation: true });
      }

      if (!err) {
        this.setState({ isLoading: true });
        const successfulText = isDetails
          ? 'Users were successfully changed'
          : 'Users were successfully added';
        let companyId = '';

        if (!isNotMainAdmin) {
          for (let key in companies) {
            if (companies[key] === companyName) {
              companyId = key;
            }
          }
        } else {
          companyId = currentPeople.companyId;
        }
        const currentApi = (data) =>
          isDetails ? api.users.updateUser(data) : api.users.addUser(data);

        const data = {
          active: true,
          companyId:
            companyId || (isNotMainAdmin ? Object.keys(companies)[0] : ''),
          companyName: isNotMainAdmin
            ? currentPeople.companyName
            : companyName || '',
          roles: [userRole ? userRole.toUpperCase() : 'USER'],
          email: email || '',
          firstName: firstName || '',
          id: isDetails ? currentPeople.id : null,
          lastName: lastName || '',
          phoneNumber: phone || '',
          cognitiveView: cognitiveView,
          companyManagedIds: [...this.state.companies],
        };

        currentApi(data)
          .then(() => {
            this.requestNotification(successfulText);
            modalClose(true);
          })
          .catch((res) => {
            const eroorMessage = res.response.data
              ? Object.values(res.response.data).join(', ')
              : res.message;
            this.requestNotification(eroorMessage, false);
          });
      }
    });
  };

  closeModal = (isShouldUpdate = false) => {
    const { getUsers, onUpdateCompanies } = this.props;
    this.displayModal('none');
    if (isShouldUpdate) {
      getUsers();
      onUpdateCompanies(true);
    }
  };

  requestNotification = (message, success = true) => {
    const notificationSettings = {
      type: success ? 'success' : 'error',
      message: message,
    };
    openNotificationWithIcon(notificationSettings);
    this.setState({ isLoading: false });
  };

  displayModal = (type) => this.setState({ modalType: type });

  getModalBlock = () => {
    const { modalType } = this.state;
    const { modalClose } = this.props;

    switch (modalType) {
      case 'cancel':
        return (
          <AreYouWantToCancel
            onNo={() => this.displayModal('none')}
            onYes={() => modalClose()}
          />
        );
      default:
        return null;
    }
  };

  closePopUp = () => {
    const { modalType } = this.state;
    const popUpsTypesWithoutRef = ['block', 'delete', 'unblock', 'cancel'];
    const isFindPopUpWithoutRed = popUpsTypesWithoutRef.find(
      (item) => item === modalType
    );

    if (isFindPopUpWithoutRed) {
      this.setState({ modalType: 'none' });
    }
  };

  onCancelHandle = () => {
    const { initialPopUpData, cognitiveView, companies } = this.state;
    const { form, modalClose, handleAccountManagerRole } = this.props;
    const { companyName, email, firstName, lastName, phone, userRole } =
      form.getFieldsValue();

    const newPopUpData = {
      companyName,
      userRole,
      email,
      firstName,
      lastName,
      phone,
      companies,
      cognitiveView,
    };

    const isPopUpDataEqual =
      JSON.stringify(initialPopUpData) === JSON.stringify(newPopUpData);

    if (!isPopUpDataEqual) {
      this.displayModal('cancel');
    } else {
      modalClose(false);
      handleAccountManagerRole(false);
    }
  };

  onToggleCognitiveView = () => {
    this.setState((prev) => ({ cognitiveView: !prev.cognitiveView }));
  };

  onInputChange = () => {
    this.setState({ showValidation: false });
  };

  onChangeUserRole = (role) => {
    const { companies } = this.state;
    const { form, handleAccountManagerRole } = this.props;

    if (compareStrings(role, 'ADMIN')) {
      form.setFieldsValue({ companyName: undefined });
    }

    if (!compareStrings(role, 'ACCOUNT MANAGER') && companies.length) {
      this.setState({ companies: [] });
    }

    handleAccountManagerRole(compareStrings(role, 'ACCOUNT MANAGER'));
  };

  onClickCheckbox = (id) => {
    const { companies } = this.state;

    const selectedCompanies = companies.includes(id)
      ? companies.filter((company) => company !== id)
      : [...companies, id];

    this.setState({ companies: [...selectedCompanies] });
  };

  render() {
    const {
      isLoading,
      firstAttempt,
      focusedInputName,
      isSaveButtonDisabled,
      passwordError,
      showValidation,
      cognitiveView,
      modalType,
    } = this.state;
    const {
      companies,
      form,
      isDetails,
      sections,
      isNotMainAdmin,
      isNotAccOwner,
      roles,
      user: { roles: userRoles = [] },
    } = this.props;
    const { getFieldDecorator, getFieldError } = form;
    const isCompanySelected = form.getFieldValue('companyName');
    const isUserRoleSelected = form.getFieldValue('userRole');
    const companiesName = Object.values(companies);
    const companiesId = Object.keys(companies);
    const requiredFields = [
      'lastName',
      'firstName',
      'email',
      'companyName',
      'userRole',
    ].filter((el) =>
      isNotMainAdmin ||
      compareStrings(isUserRoleSelected, 'ADMIN') ||
      compareStrings(isUserRoleSelected, 'ACCOUNT MANAGER')
        ? el !== 'companyName'
        : true
    );

    const isSubmitDisable =
      onDisabledSaveButton(form, requiredFields, firstAttempt ? true : false) ||
      isSaveButtonDisabled ||
      (compareStrings(isUserRoleSelected, 'ACCOUNT MANAGER') &&
        !this.state.companies.length);

    const isAccountManager =
      userRoles.includes('ACCOUNT MANAGER') ||
      userRoles.includes('ACCOUNT OWNER/MANAGER');

    const isAdmin =
      userRoles.includes('ADMIN') || userRoles.includes('ACCOUNT MANAGER');

    const showSelectCompany =
      isDetails &&
      !compareStrings(isUserRoleSelected, 'ACCOUNT MANAGER') &&
      !compareStrings(isUserRoleSelected, 'ADMIN') &&
      isAdmin;

    const showEditBtn =
      isDetails &&
      (userRoles.includes('ADMIN')
        ? compareStrings(isUserRoleSelected, 'ACCOUNT MANAGER') ||
          compareStrings(isUserRoleSelected, 'ADMIN')
        : !isAccountManager);

    return (
      <section className={styles.wrapper}>
        <h2 className={styles.title}>
          {isDetails ? 'User Details' : 'Add User'}
        </h2>
        <form onSubmit={this.onSubmit} id='user-data'>
          <div
            className={classnames(
              styles.sections,
              compareStrings(isUserRoleSelected, 'ACCOUNT MANAGER')
                ? styles.horizontalSections
                : ''
            )}>
            {sections.map((section) => (
              <div
                key={`section-${section.sectionTitle}`}
                className={styles.sectionWrap}>
                <h3 className={styles.titleSection}>{section.sectionTitle}</h3>
                {section.sectionTemplate.map((item) => (
                  <div
                    className={styles.inputSection}
                    key={`user-details-${item.fieldName}`}>
                    {getFieldDecorator(item.fieldName, {
                      rules: item.rules,
                    })(
                      <FormInput
                        className={`${styles.formInput}`}
                        disabled={
                          isDetails && item.fieldName !== focusedInputName
                        }
                        error={showValidation && getFieldError(item.fieldName)}
                        inputRef={item.ref}
                        mask={item.mask}
                        onFocus={() =>
                          this.onEditClick(item.ref, item.fieldName)
                        }
                        onBlur={() => this.setState({ focusedInputName: '' })}
                        label={item.label}
                        onChange={this.onInputChange}
                        isFocusedInput={focusedInputName === item.fieldName}
                      />
                    )}
                    {showEditBtn && (
                      <button
                        type='button'
                        className={styles.editBtn}
                        onClick={() =>
                          this.onEditClick(item.ref, item.fieldName)
                        }>
                        <FontAwesomeIcon icon={faPen} />
                      </button>
                    )}
                  </div>
                ))}
                 <div style={{background:"red"}}>
                <div
                   className={`${styles.inputSection} ${
                    isUserRoleSelected && styles.usersSelectHasValue
                  }`}>
                  {getFieldDecorator('userRole', {
                    rules: [
                      {
                        message: ' ',
                        required: true,
                      },
                      {
                        max: 250,
                        message: ' ',
                      },
                    ],
                  })(
                    <Select
                      placeholder='Select User Role*'
                      showArrow
                      dropdownClassName={styles.usersRolesDropdown}
                      disabled={
                        (!compareStrings(
                          isUserRoleSelected,
                          'ACCOUNT MANAGER'
                        ) &&
                          !compareStrings(isUserRoleSelected, 'ADMIN') &&
                          isDetails &&
                          userRoles.includes('ADMIN')) ||
                        isAccountManager
                      }
                      onChange={(isUserRoleSelected) =>
                        this.onChangeUserRole(isUserRoleSelected)
                      }
                      suffixIcon={<span className={styles.selectIcon} />}>
                      {roles &&
                        roles.map((item) => (
                          <Select.Option value={item} key={item}>
                            {parseString(item)}
                          </Select.Option>
                        ))}
                    </Select>
                  
                  )}
                     </div> 
                </div>
                {showSelectCompany && (
                  <div
                    className={`${styles.inputSection} ${
                      isCompanySelected && styles.companySelectHasValue
                    }`}>
                    {getFieldDecorator('companyName', {
                      rules: [
                        {
                          message: ' ',
                          required: false,
                        },
                        {
                          max: 250,
                          message: ' ',
                        },
                      ],
                    })(
                      <Select
                        placeholder='Select company*'
                        showArrow
                        showSearch
                        disabled={isAdmin}
                        dropdownClassName={styles.companiesDropdown}
                        suffixIcon={<span className={styles.selectIcon} />}>
                        {sortStrings(companiesName).map((item) => (
                          <Select.Option value={item} key={item}>
                            {item}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </div>
                )}
                <div>
                  {isNotMainAdmin && !isNotAccOwner && !isAccountManager ? (
                    <>
                      <span className={styles.cognitiveText}>
                        Enable Cognitive View
                      </span>
                      <Switch
                        checked={cognitiveView}
                        onChange={this.onToggleCognitiveView}
                      />
                    </>
                  ) : null}
                </div>
              </div>
            ))}
            {companiesName &&
              compareStrings(isUserRoleSelected, 'ACCOUNT MANAGER') && (
                <div
                  className={classnames(
                    styles.sectionWrap,
                    styles.sectionCompanies
                  )}>
                  <h3 className={styles.titleSection}>
                    {isAccountManager
                      ? 'Managed companies'
                      : 'Select managed companies'}
                  </h3>
                  <div className={styles.checkboxesWrap}>
                    <div className={styles.checkboxesList}>
                      {companiesId.map((id) =>
                        isAccountManager ? (
                          <p key={id}>
                            <span>{companies[id]}</span>
                          </p>
                        ) : (
                          <Checkbox
                            key={id}
                            disabled={isAccountManager}
                            name='companies'
                            onClick={() => this.onClickCheckbox(+id)}
                            checked={this.state.companies.includes(+id)}>
                            <span>{companies[id]}</span>
                          </Checkbox>
                        )
                      )}
                    </div>
                  </div>
                </div>
              )}
          </div>
        </form>
        {passwordError !== null && (
          <p className={styles.errorText}>{passwordError}</p>
        )}
        <div className={styles.btnWrap}>
          <button
            className={styles.btnSave}
            onClick={this.onSubmit}
            disabled={isSubmitDisable || isAccountManager}>
            {isDetails ? 'Save' : 'Add'}
          </button>
          <button className={styles.btnNo} onClick={this.onCancelHandle}>
            Cancel
          </button>
        </div>
        <CustomModal
          visible={modalType !== 'none'}
          style={{ width: 600 }}
          onCancel={this.closePopUp}
          className={styles.modalWrap}>
          {this.getModalBlock()}
        </CustomModal>
        {isLoading && <Loader />}
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  companies: selectCompanies(state),
  user: selectUser(state),
});

const mapDispatchToProps = (dispatch) => ({
  onUpdateCompanies: (data) => dispatch(isUpdateCompanies(data)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  AddUserWithPopupTemplateHOC,
  Form.create()
)(AddUser);
