import React, { PureComponent } from 'react';
import { Radio } from 'antd';

import api from '../../../../api';
import { openNotificationWithIcon } from '../../../../utils';

import { CustomModal } from '../../../admin-pages/components';

import styles from './SetStatusTable.module.scss';

const RADIO_CONFIG = [
  { name: 'Active', apiKey: 'AC', className: 'statusActive' },
  { name: 'Inactive', apiKey: 'INAC', className: 'statusInActive' },
  { name: 'Inactive-Voluntary', apiKey: 'INAC-VOL', className: 'statusInActiveVoluntary' },
  { name: 'Inactive-Involuntary', apiKey: 'INAC-INVOL', className: 'statusInActiveInvoluntary' },
  { name: 'Applicant', apiKey: 'AP', className: '' },
];

class SetStatusTable extends PureComponent {
  state = {
    isOpenModal: false,
    requestInProcess: false,
    checked: this.props.value,
    newStatus: '',
  };

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ checked: this.props.value });
    }
  }

  showTable = () => this.setState({ isOpenModal: true });

  handleChange = () => {
    const {
      record: { id, currentJob, division },
    } = this.props;
    const { checked } = this.state;

    const params = [
      {
        name: 'status',
        value: checked,
      },
    ];

    this.setState({ requestInProcess: true });

    api.employees
      .updateEmployee(id, params, this.props.dashboardId)
      .then(() => api.employees.getEmployee(id))
      .then((user) =>
        this.props.updateUser({
          user,
          currentJob: currentJob.title,
          userId: id,
          division: this.props.isSuccession ? 'No division' : division,
        })
      )
      .then(() => {
        this.requestNotification('Update was successful');
        this.setState({
          isOpenModal: false,
        });
      })
      .catch((err) => {
        const errorMessage = err.response.data.message
          ? err.response.data.message
          : err.response.data;

        this.requestNotification(errorMessage, false);
        this.setState({
          isOpenModal: false,
        });
      });
  };

  onChangeStatus = (e) => {
    this.setState({
      checked: e.target.value,
      newStatus: e.target.value,
    });
  };

  onCancelModal = () => {
    this.setState({
      isOpenModal: false,
      requestInProcess: false,
      checked: this.props.value,
      newStatus: '',
    });
  };

  requestNotification = (message, success = true) => {
    const notificationSettings = {
      type: success ? 'success' : 'error',
      message: message,
    };
    openNotificationWithIcon(notificationSettings);
    this.setState({ requestInProcess: false });
  };

  render() {
    const { onlyView, value } = this.props;
    const { isOpenModal, checked, newStatus, requestInProcess } = this.state;
    const currentStatus = RADIO_CONFIG.find(({ apiKey }) => apiKey === checked);

    return (
      <section className={styles.wrapper}>
        <span
          style={{cursor: onlyView ? 'not-allowed' : 'pointer'}}
          className={`${styles.statusTableWpar} ${styles[currentStatus.className]}`}
          onClick={() => !onlyView && this.showTable()}
        />
        <CustomModal
          visible={isOpenModal}
          onCancel={this.onCancelModal}
          className={styles.modalWrap}>
          <section className={styles.wrapper}>
            <h2 className={styles.title}>Change Status</h2>
            <div className={styles.radio}>
              <h3 className={styles.subTitle}>Select employee status</h3>
              <div className={styles.radioWrap}>
                <div className={styles.border}/>
                <Radio.Group
                  onChange={this.onChangeStatus}
                  value={checked}>
                  {RADIO_CONFIG.map(({ name, apiKey, className }) => (
                    <div key={name}>
                      <div className={styles.radioItemWrap}>
                        <span className={`${styles.statusWpar} ${styles[className]}`}/>
                        <Radio value={apiKey}>{name}</Radio>
                      </div>
                    </div>
                  ))}
                </Radio.Group>
              </div>
              {newStatus === 'AP' && (
                <span className={styles.warning}>Start date and/or termination date will be cleared</span>
              )}
            </div>
            <div className={styles.btnWrap}>
              <button
                disabled={requestInProcess}
                className={styles.btnDone}
                onClick={this.handleChange}>
                Change
              </button>
              <button className={styles.btnCancel} onClick={this.onCancelModal}>
                Cancel
              </button>
            </div>
          </section>
        </CustomModal>
      </section>
    );
  }
}

export default SetStatusTable;
