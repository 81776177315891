import {
  GET_USERS_MANAGMENT_SUCCESS,
  GET_COMPANIES_MANAGMENT_SUCCESS,
  GET_COUNTRIES_SUCCESS,
  GET_STATES_SUCCESS,
  GET_ORGANIZATION_SIZES_SUCCESS,
  GET_COMPANY_INDUSTRIES_SUCCESS,
  IS_NEED_UPDATE_COMPANIES,
} from '../../constants/constants';

export const initialState = {
  users: [],
  companies: {},
  companiesData: [],
  countries: [],
  states: [],
  organizationSizes: [],
  companyIndustries: [],
  isNeedUpdateCompaniesData: true,
  roles: [],
};

export const adminReducer = (state = initialState, { data, type }) => {
  switch (type) {
    case GET_USERS_MANAGMENT_SUCCESS:
      return {
        ...state,
        users: data.users,
        companies: data.companies,
        roles: data.roles,
      };
    case GET_COMPANIES_MANAGMENT_SUCCESS:
      return {
        ...state,
        companiesData: data,
      };
    case GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: data,
      };
    case GET_STATES_SUCCESS:
      return {
        ...state,
        states: data,
      };
    case GET_ORGANIZATION_SIZES_SUCCESS:
      return {
        ...state,
        organizationSizes: data,
      };
    case GET_COMPANY_INDUSTRIES_SUCCESS:
      return {
        ...state,
        companyIndustries: data,
      };
    case IS_NEED_UPDATE_COMPANIES:
      return {
        ...state,
        isNeedUpdateCompaniesData: data,
      };
    default:
      return state;
  }
};
