import {Tooltip} from "antd";
import styles from "../DashboardContent/styles.module.scss";
import React, {useEffect, useRef, useState} from "react";
import {FormInput} from "../index";
import cn from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPen} from "@fortawesome/free-solid-svg-icons";

export const titleWithToolTip = (title, toolTipText) => (
    <Tooltip title={toolTipText}>{title}</Tooltip>
);

export const noData = (mod = false) => (
    <span className={mod ? styles.scoreNoDataMod : styles.scoreNoData}>
    No data
  </span>
);

export const titleWithToolTipAbsoute = (title, toolTipText) => (
    <Tooltip title={toolTipText}>
        <span style={{ position: 'absolute', width: '150px' }}>{title}</span>
    </Tooltip>
);

export const TitleEdit = ({title, onEdit, selectedDashboard, field, disabledInput} ) => {
    const [editable, setEditable] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [newName, setNewName] = useState(selectedDashboard[field] || title);
    const inputRef = useRef();

    useEffect(() => {
        setNewName(selectedDashboard[field] || title);
    }, [selectedDashboard[field]])

    useEffect(() => {
        if (editable) {
            inputRef.current.focus();
            inputRef.current.selectionStart = inputRef.current.value.length;
            inputRef.current.scrollBy(1000, 0);
        }
    }, [editable]);
    const onSubmit = (e) => {
        e.preventDefault();
        setEditable(false);
        setDisabled(true);
        newName !== selectedDashboard[field] &&
        onEdit({[field]: newName}, true);
    };

    return (
        <form className={styles.titleName} onSubmit={onSubmit}>
            <FormInput
                className={cn(styles.formInput, disabled && styles.disabled)}
                value={newName}
                isFocusedInput={editable}
                disabled={disabled}
                inputRef={inputRef}
                onChange={(e) => setNewName(e.target.value)}
                onBlur={onSubmit}
            />
            <button
                type={"button"}
                onClick={() => {
                    !disabledInput && setEditable(true);
                    !disabledInput && setDisabled(false);
                }}
            >
                <FontAwesomeIcon icon={faPen}/>
            </button>
        </form>
    )
};

export const renderName = (text, row) => {
    if (row.subHeaderRow) {
        return {
            props: {
                colSpan: 0,
            },
        };
    }
    return text;
};
