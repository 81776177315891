import jwt from 'jsonwebtoken';

export const TOKEN_KEY = 'tokenJwt';

export const checkJwtExpired = token => {
  const decodedJwt = jwt.decode(token);
  return decodedJwt && Date.now() >= decodedJwt.exp * 1000;
};

export const clearStorage = () => {
  localStorage.removeItem(TOKEN_KEY);
};
