import React, { useEffect } from 'react';

import { Loader, LoaderMini } from '../../index';
import { Table as AntdTable } from 'antd';
import * as PropTypes from 'prop-types';
import styles from './Table.module.scss';

export const Table = ({
  maxWidthTable,
  columnsData,
  sortedEmployeesFormat,
  isEmployeesLoading,
  linkToShare,
  isActiveMiniLoading,
  getEmployees,
  paginationData,
  currentPage,
  onlyView,
  selectedDashboard
}) => {

  useEffect(() => {
    const tableContent = document.querySelector('.ant-table-body');
    const checkScroll = (event) => {
      const maxScroll = event.target.scrollHeight - event.target.clientHeight;
      const currentScroll = event.target.scrollTop;
      if (!isActiveMiniLoading && maxScroll && (currentScroll >= maxScroll - 1) && (currentPage < paginationData?.totalPages)) {
        getEmployees(true);
        tableContent.removeEventListener('scroll', checkScroll);
      }
    }

    tableContent.addEventListener('scroll', checkScroll);
    return () => {
      tableContent.removeEventListener('scroll', checkScroll);
    }
  }, [paginationData]);

  return(
    <div className={styles.tableWrap} style={{ maxWidth: /*onlyView ? 'unset' : maxWidthTable*/ 'unset' }}>
      <AntdTable
        columns={columnsData}
        locale={{emptyText: selectedDashboard?.employeeSetId ? null : 'Please, select the dataset'}}
        dataSource={isEmployeesLoading ? [] : sortedEmployeesFormat}
        bordered
        rowKey={(r, i) => i}
        loading={
          (isEmployeesLoading || linkToShare === 'inProgress') && {
            indicator: <Loader/>,
          }
        }
        pagination={false}
        rowClassName={(record) =>
          record.divisionRow
            ? `${styles.boldDivision}`
            : record.subHeaderRow
              ? `${styles.bold}`
              : `${styles.usial}`
        }
        size="small"
        scroll={{ x: 'max-content', y: '60vh' }}
      />
      <LoaderMini
        className={styles['loading-mini']}
        visible={isActiveMiniLoading}
      />
    </div>
  )
}

Table.propTypes = {
  maxWidthTable: PropTypes.number,
  columnsData: PropTypes.any,
  sortedEmployeesFormat: PropTypes.any,
  isEmployeesLoading: PropTypes.any,
  linkToShare: PropTypes.any,
  isActiveMiniLoading: PropTypes.bool
}

Table.defaultProps = { isActiveMiniLoading: true }
