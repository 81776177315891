import React from 'react';
import { FiltersCollapse } from './FiltersCollapse/FiltersCollapse';
import { Table } from './Table/Table';
import styles from './FiltersCollapse/FiltersCollapse.module.scss';

class DashboardContent extends React.PureComponent {

  getFilterParams(dashboardData) {
    const {
      filterDivisions,
      filterEmploymentsStatus,
      filterFactorComboMatch,
      filterFirstScore,
      filterJobs,
      filterOverallScore,
      displayBlocks,
      filterCognitive,
      filterDivisionSupervisor,
      filterLocation,
      filterPerformance,
      filterPotential,
      filterRating,
      filterReadiness,
      filterTenure
    } = dashboardData;

    return {
      currentFilterLinkData: {
        performanceFilters: {
          displayBlocks,
          filterDivisions,
          filterEmploymentsStatus,
          filterFactorComboMatch,
          filterFirstScore,
          filterOverallScore,
          filterJobs,
          filterCognitive,
          filterDivisionSupervisor,
          filterLocation,
          filterPerformance,
          filterPotential,
          filterRating: filterRating?.filter(Boolean) || [],
          filterReadiness,
          filterTenure,
          title: null,
        },
        successionFilters: null
      },
      divisionsFilter: { divisions: filterDivisions },
      jobsFilter: { jobs: filterJobs }
    }
  }

  componentDidMount() {
    const { onRebuildDashboard, selectedDashboard, onlyView } = this.props;
    /*!onlyView && */onRebuildDashboard(this.getFilterParams(selectedDashboard))
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { selectedDashboard: prevDashboard } = prevProps;
    const { selectedDashboard: currentDashboard, onRebuildDashboard, onlyView } = this.props;

    if (prevDashboard.id !== currentDashboard.id/* && !onlyView*/) {
      onRebuildDashboard(this.getFilterParams(currentDashboard))
    }
  }

  render() {
    const {
      toggleModal,
      getLinkToDashboard,
      downloadFileInFormat,
      selectedDashboard,
      datasets,
      state,
      onRebuildDashboard,
      sortedEmployeesFormat,
      columnsData,
      updateDashboard,
      getEmployees,
      employeeRatings,
      employeeReadiness,
      deleteDashboard,
      isFiltersChanged,
      handleApplyNewFilters,
      onChangeCurrentFilter
    } = this.props;

    return (
      <section className={styles.wrapper} id="perfomanceWrap">
        <FiltersCollapse
          divisions={state.divisions}
          datasets={datasets}
          toggleModal={toggleModal}
          getLinkToDashboard={getLinkToDashboard}
          downloadFileInFormat={downloadFileInFormat}
          selectedDashboard={selectedDashboard}
          onRebuildDashboard={onRebuildDashboard}
          updateDashboard={updateDashboard}
          deleteDashboard={deleteDashboard}
          jobs={state.jobs}
          locations={state.locationNames}
          employeeRatings={employeeRatings}
          employeeReadiness={employeeReadiness}
          employeeLines9BoxData={state.employeeLines9BoxData}
          hideCognitive={state.hideCognitive}
          isActiveMiniLoading={state.isActiveMiniLoading}
          onlyView={this.props.onlyView}
          roles={this.props.roles}
          isFiltersChanged={isFiltersChanged}
          handleApplyNewFilters={handleApplyNewFilters}
          onChangeCurrentFilter={onChangeCurrentFilter}
        />
        <Table
          maxWidthTable={state.maxWidthTable}
          columnsData={columnsData}
          updateDashboard={updateDashboard}
          sortedEmployeesFormat={sortedEmployeesFormat}
          isEmployeesLoading={state.isEmployeesLoading}
          linkToShare={state.linkToShare}
          isActiveMiniLoading={state.isActiveMiniLoading}
          getEmployees={getEmployees}
          paginationData={state.paginationData}
          currentPage={state.pageNumber}
          selectedDashboard={selectedDashboard}
          onlyView={this.props.onlyView}
        />
      </section>
    )
  }
}

export default DashboardContent


