import React, { PureComponent } from "react";

import { CustomModal } from "../../../admin-pages/components";
import { FormInput } from "../../../../components";

import styles from "./CreateLinkModal.module.scss";

class CreateLinkModal extends PureComponent {
  state = { inputFocused: false, lifetime: 30 };

  onChange = (e) => this.setState({ lifetime: e.target.value });
  onFocus = () => this.setState({ inputFocused: true });
  onBlur = () =>
    this.setState(({ lifetime }) => ({
      inputFocused: false,
      lifetime: lifetime >= 1 ? parseInt(lifetime) : "",
    }));

  handleSubmit = () => {
    const { onSuccess, onCancel } = this.props;
    const { lifetime } = this.state;

    onSuccess(lifetime);
    onCancel();
  };

  render() {
    const { visible, onCancel } = this.props;
    const { inputFocused, lifetime } = this.state;

    const onClose = () => {
      onCancel();
      this.setState({lifetime: 30})
    }

    return (
      <CustomModal
        visible={visible}
        onCancel={onClose}
        className={styles.modalWrap}
      >
        <div className={styles.innerWrap}>
          <div className={styles.content}>
            <FormInput
              className={styles.formInput}
              label={"Lifetime (days)*"}
              value={lifetime}
              onChange={this.onChange}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              isFocusedInput={inputFocused || lifetime}
              type="number"
            />

            <div className={styles.btnWrapper}>
              <button onClick={onClose} className={styles.stepsBtn}>
                Cancel
              </button>
              <button
                disabled={!lifetime}
                onClick={this.handleSubmit}
                className={styles.stepsBtn}
              >
                Generate
              </button>
            </div>
          </div>
        </div>
      </CustomModal>
    );
  }
}

export default CreateLinkModal;
