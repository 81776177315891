import React, { PureComponent } from 'react';
import {Checkbox, Form} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { faDatabase } from '@fortawesome/free-solid-svg-icons';

import api from '../../../../api';
import {FormInput, Loader} from '../../../../components';
import { AreYouWantToCancel, CustomModal } from '../../components';
import styles from './EditCompanySurvey.module.scss';
import {compose} from 'recompose';
import QueueModal from "../../../pages/components/QueueModal/QueueModal";
let formInput_1 = React.createRef();
let formInput_2 = React.createRef();

const companyInformationTemplate = [
  {
    fieldName: 'apiKey',
    ref: formInput_1,
    label: 'Api key',
    rules: [{
      message: ' ',
      type: 'string',
    }, {
      max: 250,
      message: ' ',
    }],
  },
];

const contactDataTemplate = [
  {
    fieldName: 'token',
    ref: formInput_2,
    label: 'Token',
    rules: [{
      message: ' ',
      type: 'string',
    }, {
      max: 250,
      message: ' ',
    }],
  },
];


const sectionsForMainAdmin = [
  {sectionTitle: 'Predictive Index', sectionTemplate: companyInformationTemplate},
  {sectionTitle: 'Qualtrics', sectionTemplate: contactDataTemplate}
];

const sections = [
  {sectionTitle: 'Predictive Index', sectionTemplate: companyInformationTemplate},
];

class EditCompanySurvey extends PureComponent {
  state = {
    cancelModal: false,
    focusedInputName: '',
    isAllSurveys: false,
    surveys: null,
    availableSurveys: null,
    nonAvailableSurveys: null,
    companyCheckedSurveysIds: [],
    isSaveButtonDisabled: true,
    initialPopUpData: null,
    isLoading: false,
    isGetSurveysClick: false,
    sections: this.props.notMainAdmin ? sections : sectionsForMainAdmin,
    queueModal: false,
    queueData: null
  }

  componentDidMount() {
    this.props.onRef(this);
    const withDataSetting = true;
    this.getCompanySurvey(withDataSetting);
  }
  componentWillUnmount() {
    this.props.onRef(undefined)
  }
  componentDidUpdate() {
    const {surveys, companyCheckedSurveysIds, initialPopUpData} = this.state;
    const {form} = this.props;
    const {
      apiKey,
      token,
    } = form.getFieldsValue();

    const initialAssignedSurveys = surveys === null ? [] : surveys.filter(item => item.assigned).map(item => item.id);

    const isTheSameInitialAndHandledSurveysList =
          JSON.stringify(initialAssignedSurveys) === JSON.stringify(companyCheckedSurveysIds);

    const isInputsChanged = initialPopUpData !== null && (initialPopUpData.apiKey !== apiKey || initialPopUpData.token !== token);
    this.setState({isSaveButtonDisabled: isTheSameInitialAndHandledSurveysList && !isInputsChanged})
  }

  updateAndGetCompanySurveys = (e) => {
    const { sendNotification } = this.props;
    this.setState({isLoading: true});
    const { form } = this.props;
    e.preventDefault();
    form.validateFields(err => {
      if (!err) {
        form.validateFields(err => {
          if (!err) {
            const currentApi = () => api.companies.refreshSurveys();
            currentApi()
              .then(() => {
                const withDataSetting = false;
                this.getCompanySurvey(withDataSetting);
              })
              .catch(res => {
                this.setState({isLoading: false});
                sendNotification(res.response.data, false);
              });
          }
        });

      }
    });
  }

  getCompanySurvey = (withDataSetting) => {
    this.setState({isLoading: true});
    const { currentCompany, form, notMainAdmin } = this.props;
    const data = {params: {companyId: currentCompany.id}}
    api.companies.getCompanySurveysInfo(data)
      .then(res => {
        if (notMainAdmin) {
          const { piApiKey} = res;
          form.setFieldsValue({
            apiKey: piApiKey || '',
          });
          const initialPopUpData = {
            apiKey: piApiKey || '',
          };
          this.setState({
            initialPopUpData
          });
        } else {
          const {qualtricsSurveyData, piApiKey, qualtricsApiKey} = res;
          if(withDataSetting){form.setFieldsValue({
            apiKey: piApiKey || '',
            token: qualtricsApiKey || '',
          });}
          const nonAvailableSurveys = qualtricsSurveyData.filter(item => item.blocked);
          const availableSurveys = qualtricsSurveyData.filter(item => !item.blocked);
          const assignedSurveys = qualtricsSurveyData.filter(item => item.assigned).map(item => item.id);
          const initialPopUpData = {
            companyCheckedSurveysIds: assignedSurveys,
            apiKey: piApiKey || '',
            token: qualtricsApiKey || '',
          }
          this.setState({
            surveys: qualtricsSurveyData,
            availableSurveys: availableSurveys,
            nonAvailableSurveys: nonAvailableSurveys,
            companyCheckedSurveysIds: assignedSurveys,
            initialPopUpData
          })
        }
      }).then(() => this.setState({isLoading: false}));
  };

  onEditClick = (ref, fieldName ) => {
    if (fieldName !== this.state.focusedInputName) {
      this.setState({focusedInputName: fieldName});
      ref.current.disabled = false;
    }
    return (
      ref.current.focus()
    )
  };

  onSubmit = e => {
    const { companyCheckedSurveysIds } = this.state;
    const { currentCompany, form, modalClose, sendNotification } = this.props;
    e.preventDefault();


    const {
      apiKey,
      token,
    } = form.getFieldsValue();

    form.validateFields(err => {
      if (!err) {
        const successfulText = 'Surveys were successfully updated';
        const currentApi = data => api.companies.updateSurveys(data);
        const data = {
          companyId: currentCompany.id,
          surveyIds: (companyCheckedSurveysIds.length && companyCheckedSurveysIds) || null,
          piApiKey: apiKey,
          qualtricsApiKey: token,
        };

        currentApi(data)
          .then(() => {
            sendNotification(successfulText);
            api.employees.updateAssesmentData();
            modalClose(true);
          })
          .catch(res => {
            if(res.response.data.canQueue) {
              this.setState({queueModal: true, queueData: data, isLoading: false})
            } else {
              sendNotification(res.response.data.message, false)
            }
          });
      }
    });
  };

  onSubmitQueue = (data) => {
    const { modalClose, sendNotification } = this.props;
    const successfulText = 'Surveys were successfully updated';
    const currentApi = data => api.companies.updateSurveys(data, true);
    currentApi(data)
        .then(() => {
          sendNotification(successfulText);
          api.employees.updateAssesmentData();
          modalClose(true);
        })
        .catch(res => {
          sendNotification(res.response.data.message, false)
        })
  }

  changeSurveysList = (e, id) => {
    const {checked} = e.target;
    if(checked) {
      this.setState(previousState => ({
        companyCheckedSurveysIds: [...previousState.companyCheckedSurveysIds, id]
      }))
    }
    else {
      this.setState({companyCheckedSurveysIds: this.state.companyCheckedSurveysIds.filter(item =>
        item !== id
      )})
    }
  }

  onCancelHandle = () => {
    const {initialPopUpData, companyCheckedSurveysIds} = this.state;
    const { form, modalClose, notMainAdmin } = this.props;
    if (notMainAdmin) {
      const {
        apiKey,
      } = form.getFieldsValue();
      const newPopUpData = {
        apiKey,
      }

      const isPopUpDataEqual = JSON.stringify(initialPopUpData) === JSON.stringify(newPopUpData);

      if (!isPopUpDataEqual) {
        this.setState({ cancelModal: true })
      }
      else {
        modalClose();
      }
    } else {
      const {
        apiKey,
        token,
      } = form.getFieldsValue();
      const newPopUpData = {
        companyCheckedSurveysIds,
        apiKey,
        token,
      }

      const isPopUpDataEqual = JSON.stringify(initialPopUpData) === JSON.stringify(newPopUpData);

      if (!isPopUpDataEqual) {
        this.setState({ cancelModal: true })
      }
      else {
        modalClose();
      }
    }

  }
  render() {
    const {
      cancelModal,
      focusedInputName,
      isAllSurveys,
      availableSurveys,
      nonAvailableSurveys,
      companyCheckedSurveysIds,
      isSaveButtonDisabled,
      isLoading,
      sections,
      queueData
    } = this.state;
    const { form, modalClose, notMainAdmin } = this.props;
    const { getFieldDecorator, getFieldError } = form;

    return (
      <section className={styles.wrapper}>
        <h2 className={styles.title}>{'Keys'}</h2>
        <form id='company-surveys-data' onSubmit={this.onSubmit}>
          <div>
            {
              sections.map(section => (
                <div key={`section-${section.sectionTitle}`} className={styles.sectionWrap}>
                  <h3 className={styles.titleSection}>{section.sectionTitle}</h3>
                  {section.sectionTemplate.map(item => (
                    <div className={`${styles.inputSection} ${styles.inputSectionAdditionalMargins}`} key={`company-information-${item.fieldName}`}>
                      {getFieldDecorator(item.fieldName, {
                        rules: item.rules,
                      })(
                        <FormInput
                          className={`${styles.formInput}`}
                          error={getFieldError(item.fieldName)}
                          inputRef={item.ref}
                          mask={item.mask}
                          // onFocus={() => this.onEditClick( item.ref, item.fieldName  )}
                          onBlur={() => this.setState({focusedInputName: ''})}
                          label={item.label}
                          disabled={item.fieldName !== focusedInputName}
                          isFocusedInput={focusedInputName === item.fieldName}
                        />,
                      )}
                      <button type="button" className={styles.editBtn} onClick={() => this.onEditClick( item.ref, item.fieldName )}>
                        <FontAwesomeIcon icon={faPen} />
                      </button>
                    </div>
                  ))}
                </div>
              ))
            }
          </div>
          <button className={styles.visuallyHidden} />
        </form>
        {!notMainAdmin && (
          <>
            <div className={styles.buttonsRow}>
              <button className={styles.btnGet} onClick={this.updateAndGetCompanySurveys}>Get Survey IDs</button>
              <Checkbox
                onChange={() => this.setState({isAllSurveys: !isAllSurveys})}
                checked={isAllSurveys}
              >
                <span className={styles.boldSpan}>Show all surveys</span>
              </Checkbox>
            </div>
            <div className={styles.surveysBlock}>
              {availableSurveys !== null ? availableSurveys.map(item =>
                (
                  <Checkbox
                    key={item.surveyId}
                    className={styles.surveysCheckBox}
                    checked={companyCheckedSurveysIds.indexOf(item.id ) !== -1}
                    onChange={(e) => this.changeSurveysList(e, item.id)}
                  >
                    <span className={styles.boldSpan}>{item.name}</span>
                  </Checkbox>)
              ) : (
                <div className={styles.noDataBlock}>
                  <FontAwesomeIcon icon={faDatabase} />
                  <p className={styles.boldSpan}>No surveys</p>
                </div>
              )
              }
              {isAllSurveys && nonAvailableSurveys !== null && nonAvailableSurveys.map(item =>
                (
                  <Checkbox
                    key={item.surveyId}
                    className={styles.surveysCheckBox}
                    onChange={(e) => this.changeSurveysList(e, item.id)}
                    checked
                    disabled
                  >
                    <span className={styles.boldSpan}>{item.name}</span>
                  </Checkbox>)
              )
              }
            </div>
          </>
        ) }
        <div className={styles.btnWrap}>
          <button
            className={styles.btnSave}
            onClick={this.onSubmit}
            disabled={isSaveButtonDisabled}
          >
            {'Save'}
          </button>
          <button className={styles.btnNo} onClick={this.onCancelHandle}>Cancel</button>
        </div>
        <CustomModal
          visible={cancelModal}
          className={styles.modalWrap}
        >
          <AreYouWantToCancel
            onNo={() => this.setState({ cancelModal: false })}
            onYes={() => {
              modalClose();
            }}
          />;
        </CustomModal>
        <QueueModal
            visible={this.state.queueModal}
            onCancel={() => this.setState({queueModal: false})}
            onContinue={() => this.onSubmitQueue(queueData)}/>
        {isLoading && <Loader />}
      </section>
    );
  }
}

export default compose(
  Form.create(),
)(EditCompanySurvey);

