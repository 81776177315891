import React, { PureComponent, useEffect, useRef, useState } from "react";
import { Table, Tooltip, Upload } from "antd";
import { connect } from "react-redux";
import { compose } from "recompose";
import cn from "classnames";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload, faPen, faUpload} from "@fortawesome/free-solid-svg-icons";

import { capitalize, formatDate, openNotificationWithIcon } from "../../../../utils";
import { selectUserRole } from "../../Login/selectors";

import { FormInput } from "../../../../components";
import {
  AreYouWantToCancel,
  CustomModal,
} from "../../../admin-pages/components";

import styles from "./DataSetTable.module.scss";
import {faTrashCan} from "@fortawesome/free-solid-svg-icons/faTrashCan";

class DataSetTable extends PureComponent {
  state = { deleteModal: false, selectedDataset: null };
  isAccountOwner = this.props.roles.includes("ACCOUNT OWNER") || this.props.roles.includes("ACCOUNT OWNER SHARED");
  columns = [
    {
      title: "#",
      dataIndex: "count",
      align: "center",
      width: 55,
      render: (_, __, index) => <span>{index + 1}</span>,
    },
    {
      title: "Dataset Name",
      render: (text, { name, id, isOwned }) => (
        <RenderDatasetName
          text={text}
          dataSetName={name}
          onEdit={this.props.onEdit}
          id={id}
          isOwned={this.isAccountOwner || isOwned || this.props.roles.includes('ACCOUNT OWNER/MANAGER')}
          isShared={this.props.roles.includes("ACCOUNT OWNER SHARED")}
        />
      ),
      dataIndex: "name",
      /*width: 240,*/
    },
    {
      title: "Owner",
      render: (text, { ownerFirstName, ownerLastName }) => (
        <span>
            {`
               ${ownerFirstName}
               ${ownerLastName.replace("admin with sharing access", "").replace("account manager with sharing access", "")}
            `}
          </span>
      ),
      dataIndex: "ownerName",
      width: 200,
      hidden: !this.isAccountOwner,
    },
    {
      title: "Role",
      render: (text, { ownerRole }) => <span>{capitalize(ownerRole)}</span>,
      dataIndex: "role",
      width: 180,
      hidden: !this.isAccountOwner,
    },
    {
      title: "Creation Date",
      render: (text, { createdAt }) => (
        <span>{createdAt ? formatDate(createdAt) : "-"}</span>
      ),
      dataIndex: "createdAt",
      width: this.isAccountOwner ? 180 : 220,
    },
    {
      title: "Last Time Updated",
      dataIndex: "updatedAt",
      render: (text, { updatedAt }) => (
        <span>{updatedAt ? formatDate(updatedAt) : "-"}</span>
      ),
      width: this.isAccountOwner ? 180 : 220,
    },
    {
      title: "Employees Amount",
      dataIndex: "employeesCount",
      width: this.isAccountOwner ? 120 : 140,
      render: (text, { employeesCount }) => (
        <span>{Number(employeesCount) || "-"}</span>
      ),
    },
    {
      title: "Actions",
      key: "action",
      width: 160,
      render: (text, { id, name, isOwned }) => {
        const isDisabled = () => {
          const isShared = this.props.roles.includes("ACCOUNT OWNER SHARED") || this.props.roles.includes('ACCOUNT OWNER/MANAGER');
          if (isShared) return !isShared;
          return this.isAccountOwner ? !this.isAccountOwner : !isOwned
        }
        return (
          <div className={styles.actionsTableCol}>
            <Tooltip title={"Download Dataset"}>
              <button
                className={styles.download}
                onClick={() => this.props.onDownload(id, name)}
              >
                <FontAwesomeIcon alt="action download" icon={faDownload} />
              </button>
            </Tooltip>
            <Tooltip title={"Upload Dataset"}>
              <div>
                <Upload
                  disabled={isDisabled()}
                  className={styles.upload}
                  accept={".xlsx"}
                  beforeUpload={(file) => {
                    this.props.onUpload(file, id);
                    return false;
                  }}
                  fileList={[]}
                  onClick={() => this.props.onUpload(id)}
                >
                  <FontAwesomeIcon alt="action upload"  icon={faUpload}/>
                </Upload>
              </div>
            </Tooltip>
            <Tooltip title={"Delete Dataset"}>
              <button
                disabled={isDisabled()}
                className={styles.delete}
                onClick={() =>
                  this.setState({ deleteModal: true, selectedDataset: id })
                }
              >
                <FontAwesomeIcon alt="action delete" icon={faTrashCan} />
              </button>
            </Tooltip>
          </div>
        )
      },
    },
  ].filter((col) => !col.hidden);

  onDelete(id) {
    this.props.onDelete(id);
  }

  render() {
    const columns = this.columns;
    const { deleteModal, selectedDataset } = this.state;
    const { dataSets } = this.props;

    return (
      <div className={styles.tableWrap}>
        <Table
          dataSource={dataSets}
          columns={columns}
          pagination={false}
          bordered
          rowKey="id"
          rowClassName={({ isOwned }) => (isOwned ? styles.owned : "")}
        />
        <CustomModal visible={deleteModal}>
          <AreYouWantToCancel
            title={"Are you sure you want to delete the dataset?"}
            onNo={() =>
              this.setState({ deleteModal: false, selectedDataset: null })
            }
            onYes={() => {
              this.setState({ deleteModal: false });
              this.onDelete(selectedDataset);
            }}
          />
        </CustomModal>
      </div>
    );
  }
}

const RenderDatasetName = ({ dataSetName, onEdit, id, isOwned, isShared }) => {
  const [editable, setEditable] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [newName, setNewName] = useState(dataSetName);
  const inputRef = useRef();

  useEffect(() => {
    if (editable) {
      inputRef.current.focus();
      inputRef.current.selectionStart = inputRef.current.value.length;
      inputRef.current.scrollBy(1000, 0);
    }
  }, [editable]);

  const onSubmit = (e) => {
    e.preventDefault();
    setEditable(false);
    setDisabled(true);
    newName !== dataSetName &&
      onEdit(newName, id).then((res) =>{
        if (res) {
          setNewName(newName);
          openNotificationWithIcon({type:'success', message: 'Dataset was updated'}) ;
        }else{
          setNewName(dataSetName);
          openNotificationWithIcon({type:'error', message: 'Dataset error'})
        }
      }
      );
  };

  const isDisabled = () => {
    if (isShared) return !isShared;
    return !isOwned
  }

  return (
    <form className={styles.datasetName} onSubmit={onSubmit}>
      <FormInput
        className={cn(styles.formInput, disabled && styles.disabled)}
        value={newName}
        isFocusedInput={editable}
        disabled={disabled}
        inputRef={inputRef}
        onChange={(e) => setNewName(e.target.value)}
        onBlur={onSubmit}
      />
      <button
        disabled={isDisabled()}
        type={"button"}
        onClick={() => {
          setEditable(true);
          setDisabled(false);
        }}
      >
        <FontAwesomeIcon icon={faPen}/>
      </button>
    </form>
  );
};

const mapStateToProps = (state) => ({
  roles: selectUserRole(state),
});

export default compose(connect(mapStateToProps))(DataSetTable);
