import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { Upload } from 'antd';
import { connect } from 'react-redux';

import { ReactComponent as PerformanceIcon } from '../../../images/icons/performance.svg';
import { ReactComponent as SuccessionIcon } from '../../../images/icons/succession.svg';
import { ReactComponent as LeadershinIcon } from '../../../images/icons/leadership.svg';
import { faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import api from '../../../api';
import { openNotificationWithIcon, downloadFile } from '../../../utils';

import { lastUpdateData } from '../Perfomance/actions';
import { selectLastUpdate } from '../Perfomance/selectors';

import { Loader } from '../../../components';
import DataSetTable from '../components/DataSetTable/DataSetTable';
import CreateDataSetModal from '../components/CreateDataSetModal/CreateDataSetModal';

import styles from './Home.module.scss';
import QueueModal from "../components/QueueModal/QueueModal";

const description = [
  {
    btnTitle: 'View Dashboards',
    descr:
      'Select to view and analyze existing employees/groups/teams for use in analyzing, coaching, driving performance, etc.',
    icon: <PerformanceIcon alt="Performance Dashboard"/>,
    title: 'Performance Dashboard',
    to: '/dashboards',
  },
  {
    btnTitle: 'View Dashboards',
    descr:
      'Select to view groups of employees for development, career pathing and succession purposes',
    icon: <SuccessionIcon alt="Succession Dashboard"/>,
    title: 'Succession Dashboard',
    to: '/dashboards',
  },
  {
    btnTitle: 'View Leadership',
    descr: 'In development and beta-testing',
    icon: <LeadershinIcon alt="Leadership Dashboard"/>,
    title: 'Leadership Dashboard',
    to: '/leadership',
    btnProps: {
      disabled: true,
    },
  },
];


class Home extends PureComponent {
  state = {
    isLoading: false,
    isOpenModal: false,
    inputFocused: false,
    cancelModal: false,
    dataSets: [],
    queueModal: false,
    queueData: null
  };

  componentDidMount() {
    const { onLastUpdateData } = this.props;
    api.employees.getLastUpdate().then((res) => onLastUpdateData(res));
    this.getDatasets();
  }

  handleClick = type => {
    this.setState({isLoading: true});

    switch (type) {
      case 'download':
        return this.download();
      case 'refresh':
        return this.refresh();
      default:
        return;
    }
  };

  requestNotification = (message, success = true) => {
    const notificationSettings = {
      type: success ? 'success' : 'error',
      message: message,
    };
    openNotificationWithIcon(notificationSettings);
    this.setState({ isLoading: false });
  };

  download = (id, name = 'template') => {
    this.setState({ isLoading: true });
    api.files
      .downloadTemplate('XLSX', id)
      .then((res) => {
        downloadFile(res, `${name}.xlsx`);
        this.requestNotification('Downloading was successful');
      })
      .catch((err) => {
        const errorMessage =
          err?.response?.data?.message || JSON.stringify(err);
        this.requestNotification(errorMessage, false);
      });
  };

  refresh = () => {
    api.employees
      .updateAssesmentData()
      .then(() => this.requestNotification('Refreshing was successful'))
      .catch((res) =>
        this.requestNotification(res.response.data.message, false)
      );
  };

  importFile = (file, id) => {
    this.setState({ isLoading: true });
    const formData = new FormData();
    formData.append('file', file);

    api.files
      .uploadCSV(formData, id)
      .then((res) => {
        this.getDatasets();
        this.props.onLastUpdateData(res);
        this.requestNotification('Uploading was successful');
      })
      .catch((res) => {
        const errorMessage = res.response
          ? res.response.data.message || res.response.data
          : 'The server is not responding';
        if(res.response.data.canQueue) {
          this.setState({queueModal: true, queueData: {file, id}, isLoading: false })
        } else {
          this.requestNotification(errorMessage, false);
        }
      });
  };

  importFileQueue = (file, id) => {
    this.setState({ isLoading: true });
    const formData = new FormData();
    formData.append('file', file);

    api.files
        .uploadCSV(formData, id, true)
        .then((res) => {
          this.requestNotification('Uploading has been set to queue');
        })
        .catch((res) => {
          const errorMessage = res.response
              ? res.response.data.message || res.response.data
              : 'The server is not responding';

          this.requestNotification(errorMessage, false);
        });
  };

  getDatasets = () => {
    this.setState({ isLoading: true });
    api.employees
      .getDataSets()
      .then((res) => {
        const dataSets = res.reduce((total, item) => {
          if (item.isOwned) {
            total[0] = [item, ...(total[0] ?? [])];
          } else if (item.ownerRole !== 'USER') {
            total[1] = [item, ...(total[1] ?? [])];
          } else {
            total[2] = [item, ...(total[2] ?? [])];
          }
          return total;
        }, []);
        this.setState({
          dataSets: dataSets.reduce((total, item) => [...total, ...item], []),
          isLoading: false,
        });
      })
      .catch((e) => {
        this.requestNotification(e.response.data.message, false);
      });
  };

  createDataset = (name) => {
    this.setState({ isLoading: true });
    api.employees
      .createDataSet({ name })
      .then((res) => {
        this.getDatasets();
        this.setState({ isOpenModal: false, isLoading: false });
      })
      .catch((e) => {
        this.requestNotification(e.response.data.message, false);
      });
  };

  editDataset = async (name, id) => {
    this.setState({ isLoading: true });
    return await api.employees
      .updateDataSet({ name, id })
      .then((res) => {
        this.getDatasets();
        this.setState({ isLoading: false });
        return true;
      })
      .catch((e) => {
        this.getDatasets();
        this.requestNotification(e.response.data.message, false);
        return false;
      });
  };

  deleteDataset = (id) => {
    this.setState({ isLoading: true });
    api.employees
      .deleteDataSet(id)
      .then((res) => {
        this.getDatasets();
        this.requestNotification('Dataset was deleted successfully', true);
      })
      .catch((e) => {
        this.requestNotification(e.response.data.message || e.response.data, false);
      });
  };

  render() {
    const { isLoading, isOpenModal, dataSets, queueData } = this.state;

    return (
      <>
        <section className={styles.wrapper}>
          <h1 className={styles.title}>
            Welcome to the MindWire Analytics Platform (MAP)
          </h1>
          <p className={styles.subTitle}>
            In order to build a dashboard, you must provide MindWire with your
            company and employee data. You can download and upload a completed
            template below or have the option to refresh previously uploaded
            data for existing employees. If you need assistance, please click
            the “Help” button above.
          </p>
          <button
            className={styles.dataSetBtn}
            onClick={() =>
              this.setState({ isOpenModal: true, cancelModal: false })
            }
          >
            <FontAwesomeIcon icon={faPlus}/>
            Create Dataset
          </button>
          <DataSetTable
            dataSets={dataSets}
            onEdit={this.editDataset}
            onDelete={this.deleteDataset}
            onDownload={this.download}
            onUpload={this.importFile}
          />
          <CreateDataSetModal
            isOpen={isOpenModal}
            onClose={() => this.setState({ isOpenModal: false })}
            onCreate={this.createDataset}
          />
          <h2 className={`${styles.title} ${styles.titleTwo}`}>
            Available Dashboard Types
          </h2>
          <div className={styles.descriptionWrap}>
            {description.map(
              ({ btnTitle, icon, title, descr, to, btnProps = {} }, i) => (
                <div className={styles.descriptionItem} key={i}>
                  <span>{icon}</span>
                  <h3 className={styles.descriptionTitle}>{title}</h3>
                  <p className={styles.descriptionText}>{descr} &copy;</p>
                  <Link to={to} className={styles.stepsBtn} {...btnProps}>
                    {btnTitle}
                  </Link>
                </div>
              )
            )}
          </div>
        </section>
        <QueueModal
            visible={this.state.queueModal}
            onCancel={() => this.setState({queueModal: false})}
            onContinue={() => this.importFileQueue(queueData?.file, queueData?.id)}
        />
        {isLoading && <Loader/>}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  lastUpdate: selectLastUpdate(state),
})

const mapDispatchToProps = (dispatch) => ({
  onLastUpdateData: (data) => dispatch(lastUpdateData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
