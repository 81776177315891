import React from 'react';
import styles from './FiltersCollapse.module.scss';
import { Collapse } from 'antd';
import { Formik, Form } from 'formik';
import { DashboardActions } from '../DashboardActions/DashboardActions';
import { DashboardType } from '../DashboardType/DashboardType';
import { ShowBlocks } from '../ShowBlocks/ShowBlocks';
import { DataSet } from '../DataSet/DataSet';
import { Filters } from '../Filters/Filters';
import { getUpdateDashboardObject } from '../../../utils/getUpdateDashboardObject';
import CreateLinkModal from '../../../redux/pages/Perfomance/CreateLinkModal';
import { FormInput } from '../../index';
import CustomModal from '../../../redux/admin-pages/components/CustomModal/CustomModal';
import { AreYouWantToCancel } from '../../../redux/admin-pages/components';
import { getEmpStatusValues } from '../../../utils/getEmpStatusValues';
import { DeleteDashboardModal } from '../DeleteDashboardModal/DeleteDashboardModal';

const RATING_VALUE = {
  low: 'L',
  effective: 'S',
  high: 'H',
}

export class FiltersCollapse extends React.PureComponent {
  state = {
    isCreateLinkModalOpen: false,
    isEditNameModalOpen: false,
    cancelModal: false,
    isDashboardNameChanged: false,
    isDeleteModalOpen: false,
    isDashboardChanges: false
  }

  componentDidUpdate(prevProps, prevState, snapshot) {

    if(prevProps.selectedDashboard.id !== this.props.selectedDashboard.id) {
      this.setState({isDashboardChanges: true},
          () => {this.setState({isDashboardChanges: false})})
    }
  }

  render() {
    const { isCreateLinkModalOpen, isEditNameModalOpen, cancelModal, isDashboardNameChanged, isDeleteModalOpen } = this.state;

    const toggleCreateLinkModal = () =>
      this.setState((prev) => ({ ...prev, isCreateLinkModalOpen: !prev.isCreateLinkModalOpen }));

    const toggleEditNameModal = () => {
      if (isDashboardNameChanged) {
        return this.setState({ cancelModal: true });
      }
      return this.setState((prev) => ({ ...prev, isEditNameModalOpen: !prev.isEditNameModalOpen, cancelModal: false }))
    };

    const handleYesCancelModal = () =>
      this.setState({ cancelModal: false, isEditNameModalOpen: false, isDashboardNameChanged: false });

    const handleNoCancelModal = () =>
      this.setState({ cancelModal: false });

    const handleChangeName = (e, handleChange, initialName, currentName ) => {
      handleChange(e);
      if (initialName !== currentName && !isDashboardNameChanged) {
        this.setState({ isDashboardNameChanged: true })
      }
      if (initialName === currentName && isDashboardNameChanged) {
        this.setState({ isDashboardNameChanged: false })
      }
    };

    const {
      toggleModal,
      getLinkToDashboard,
      downloadFileInFormat,
      selectedDashboard = {},
      datasets,
      divisions,
      onRebuildDashboard,
      updateDashboard,
      jobs,
      locations,
      employeeRatings,
      employeeReadiness,
      employeeLines9BoxData,
      hideCognitive,
      isActiveMiniLoading,
      deleteDashboard,
      isFiltersChanged,
      handleApplyNewFilters,
      roles
    } = this.props;

    const toggleDeleteModal = () =>
      this.setState((prev) => ({ ...prev, isDeleteModalOpen: !prev.isDeleteModalOpen }));

    const rename9boxValues = (values) => {
      const defaultPerfValues = employeeLines9BoxData.employeeLineFirst.default;
      const defaultPotentialValues = employeeLines9BoxData.employeeLineSecond.default;
      const performanceIndexes = values.filterPerformance.map((item) => employeeLines9BoxData.employeeLineFirst.values.indexOf(item)).filter(item => item >= 0);
      const potentialIndexes = values.filterPotential.map((item) => employeeLines9BoxData.employeeLineSecond.values.indexOf(item)).filter(item => item >= 0);
      const performanceValues = performanceIndexes.map(item => defaultPerfValues[item]);
      const potentialValues = potentialIndexes.map(item => defaultPotentialValues[item]);
      return {filterPerformance: performanceValues, filterPotential: potentialValues}
    }

    const handleSubmit = (values) => {
      values.filterCognitive = values.filterCognitive.map(item => item === 'Has Cognitive');
      updateDashboard(getUpdateDashboardObject({...values, performanceName: selectedDashboard.performanceName, competenciesName: selectedDashboard.competenciesName, ...rename9boxValues(values)}));
      handleApplyNewFilters(true);
    }

    const handleUpdateName = (values) => {
      const params = getUpdateDashboardObject({...values, ...rename9boxValues(values)});
      updateDashboard(params).then(toggleEditNameModal);
    };

    const handleDeleteDashboard = () =>
      deleteDashboard(selectedDashboard.id, toggleDeleteModal);

    return (
      <>
        <div
          className={
            this.props.onlyView
              ? styles.collapseWrapOnlyView
              : styles.collapseWrap
          }
        >
          {this.props.onlyView ? (
            <Collapse.Panel header={selectedDashboard.name || `Dashboard name`} key="1"/>
          ) : (
            <Collapse
              accordion
              expandIconPosition="right"
              expandIcon={() => <span className={styles.collapseArrow}/>}
            >
              <Collapse.Panel
                header={
                  <DashboardActions
                    toggleModal={toggleModal}
                    toggleCreateLinkModal={toggleCreateLinkModal}
                    toggleEditNameModal={toggleEditNameModal}
                    toggleDeleteModal={toggleDeleteModal}
                    getLinkToDashboard={getLinkToDashboard}
                    downloadFileInFormat={downloadFileInFormat}
                    selectedDashboard={selectedDashboard}
                    roles={roles}
                    updateDashboard={updateDashboard}
                    isSuccession={selectedDashboard.succession}
                  />
                }
                key="1"
              >
                <div>
                  <Formik onSubmit={handleSubmit} initialValues={selectedDashboard} enableReinitialize={this.state.isDashboardChanges}>
                    {({ values, setFieldValue, submitCount, initialValues }) => {
                      const handleSetValue = (name, value) => {
                        setFieldValue(name, value);
                        const isChanged = JSON.stringify(initialValues) !== JSON.stringify({ ...values, [name]: value });
                        isFiltersChanged && !isChanged && handleApplyNewFilters(true);
                        !isFiltersChanged && handleApplyNewFilters();
                      }
                      return (
                        <Form>
                          {/*<DashboardType isSuccession={values.succession} handleChange={setFieldValue}/>*/}
                          <DataSet
                            datasets={datasets}
                            selected={values.employeeSetId}
                            handleChange={handleSetValue}
                          />
                          <ShowBlocks
                            displayBlocks={values.displayBlocks}
                            handleChange={handleSetValue}
                            hideCognitive={hideCognitive}
                          />
                          <Filters
                            currentValues={values}
                            jobs={jobs}
                            hideCognitive={hideCognitive}
                            initialValues={selectedDashboard}
                            roles={roles}
                            divisions={divisions}
                            handleChange={handleSetValue}
                            onRebuildDashboard={onRebuildDashboard}
                            employeeRatings={employeeRatings}
                            employeeReadiness={employeeReadiness}
                            employeeLines9BoxData={employeeLines9BoxData}
                            isActiveMiniLoading={isActiveMiniLoading}
                            submitCount={submitCount}
                            locations={locations}
                          />
                        </Form>
                      )
                    }}
                  </Formik>
                </div>
              </Collapse.Panel>
            </Collapse>
          )}
          <CreateLinkModal
            visible={isCreateLinkModalOpen}
            onSuccess={getLinkToDashboard}
            onCancel={toggleCreateLinkModal}
          />
          <DeleteDashboardModal
            visible={isDeleteModalOpen}
            ontoggleModal={toggleDeleteModal}
            onDelete={handleDeleteDashboard}
          />
          <CustomModal visible={isEditNameModalOpen} className={styles.modalWrap} onCancel={toggleEditNameModal}>
            <div className={styles.innerWrap}>
              <div className={styles.content}>
                <Formik onSubmit={handleUpdateName} initialValues={selectedDashboard} enableReinitialize>
                  {({ values, handleChange, initialValues }) => (
                    <>
                      <Form>
                        <FormInput
                          name="name"
                          label={'Dashboard Name*'}
                          value={values.name}
                          onChange={(e) => handleChangeName(e,handleChange, initialValues.name, values.name)}
                        />
                        <div className={styles.btnWrapper}>
                          <button
                            type="button"
                            onClick={toggleEditNameModal}
                            className={styles.stepsBtn}
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className={styles.stepsBtn}
                            disabled={initialValues.name === values.name || !values.name.length}
                          >
                            Confirm
                          </button>
                        </div>
                      </Form>
                    </>
                  )}
                </Formik>
                <CustomModal visible={cancelModal}>
                  <AreYouWantToCancel
                    onNo={handleNoCancelModal}
                    onYes={handleYesCancelModal}
                  />
                </CustomModal>
              </div>
            </div>
          </CustomModal>
        </div>
      </>
    )
  }
}
