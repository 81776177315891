import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { getEmployees, getEmployeesFormat } from "./actions";
import {
  selectDivisions,
  selectEmployees,
  selectEmployeesFormat,
  selectEmployeesJobs,
  selectEmployeeLines,
  selectEmployeeRatings,
  selectEmployeeReadiness,
  selectEmployeeStatuses,
  selectLastUpdate,
  selectActiveFilters,
} from "./selectors";
import { Perfomance } from "../Perfomance/Perfomance";
import DashboardContent from "../../../components/DashboardContent/DashboardContent";
import ContentContainer from '../../../components/DashboardContent/container';
import api from "../../../api";
import {Loader} from "../../../components";

class DashboardView extends PureComponent {
  state = {
    currentFilterSetName: 'Unset',
    hideCognitive: false,
    actualPerformance: true,
    behavioral: true,
    cognitive: true,
    columnsData: this.columns,
    consultativeSkill: true,
    empStatus: true,
    filterDivisions: [],
    filterEmploymentsStatus: [],
    filterFactorComboMatch: [],
    filterFirstScore: [],
    filterOverallScore: [],
    currentFilter: null,
    filtersApply: false,
    pageNumber: 0,
    perfomanceList: [],
    divisions: [],
    isCreateLinkModalOpen: false,
    employeeLines9BoxData: {
      employeeLineFirst: {
        title: 'Performance',
        values: []
      },
      employeeLineSecond: {
        title: 'Potential',
        values: []
      }
    },
    defaultSetFilter: {
      filterDivisions: [],
      filterEmploymentsStatus: [],
      filterFactorComboMatch: [],
      filterFirstScore: [],
      filterOverallScore: [],
      filterJobs: [],
      title: 'Unset',
      displayBlocks: {
        behavioralPerformance: true,
        behavioralSuccession: true,
        cognitive: true,
        competencies: true,
        currentSkills: true,
        customSkills: true,
        employmentStatus: true,
        nineBox: true,
        organizationalData: true,
        performance: true,
      },
    },
    employeeSetId: new URLSearchParams(this.props.location.search).get('employeeSetId'),
    filters: {
      displayBlocks: {
        behavioralPerformance: true,
        behavioralSuccession: true,
        cognitive: true,
        competencies: true,
        currentSkills: true,
        customSkills: true,
        employmentStatus: true,
        nineBox: true,
        organizationalData: true,
        performance: true,
      },
    },
    generalData: true,
    isLoading: true,
    isEmployeesLoading: false,
    maxWidthTable: 1000,
    linkToShare: null,
    sortType: null,
    sortDirection: null,
    windowStartInnerHeight: window.innerHeight,
    marginBottomStart: 0,
    node: null,
    isActiveMiniLoading: false,
    rowCount: 1,
    order: {
      type: '',
      direction: '',
    },
    isOpenModal: false,
    selectedDashboard: null
  };

  componentDidMount() {
    const {
      location: { pathname, search },
    } = this.props;
    const id = pathname.match(/\d+$/)[0];
    const token = (search && search.match(/token=.+$/)[0]) || '';
    this.setState({isLoading: true})
    /*api.dashboards
        .getDashboards()
        .then((res) => {
          this.setState({selectedDashboard: res.find(({ id }) => id == new URLSearchParams(this.props.location.search).get('dashboardId')) || {}, isLoading: false})
        });*/

    api.employees
        .getEmployeesView(id, token, 0, 40)
        .then((res) => {
          this.setState({selectedDashboard: res.dashboardData.find(({ id }) => id == new URLSearchParams(this.props.location.search).get('dashboardId')) || {}, isLoading: false})
        });
  }

  render() {
    const props = { ...this.props, onlyView: true, datasets: [] };
    const {isLoading, selectedDashboard} = this.state;

    if (isLoading) return <Loader/>;

    return <ContentContainer {...props} state={this.state} selectedDashboard={selectedDashboard}/>;
  }
}

const mapStateToProps = (state) => ({
  divisions: selectDivisions(state),
  employees: selectEmployees(state),
  employeesFormat: selectEmployeesFormat(state),
  employeeJobs: selectEmployeesJobs(state),
  employeeLines: selectEmployeeLines(state),
  employeeRatings: selectEmployeeRatings(state),
  employeeReadiness: selectEmployeeReadiness(state),
  employeeStatuses: selectEmployeeStatuses(state),
  lastUpdate: selectLastUpdate(state),
  activeFilters: selectActiveFilters(state),
});

const mapDispatchToProps = (dispatch) => ({
  onGetEmployees: (data) => dispatch(getEmployees(data)),
  onGetEmployeesFormat: (data) => dispatch(getEmployeesFormat(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardView);
