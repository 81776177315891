import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import api from '../../api';
import { openNotificationWithIcon } from '../../utils';
import { getCompaniesManagment } from '../../redux/admin-pages/actions';
import { getUser } from '../../redux/pages/Login/actions';

import { selectUser, selectIsAuth } from '../../redux/pages/Login/selectors';
import { selectCompaniesData } from '../../redux/admin-pages/selectors';

import styles from './WarningBanner.module.scss';

class WarningBanner extends PureComponent {
  state = { isBannerVisible: false };

  componentDidMount() {
    this.checkAuth();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.state.isBannerVisible !== prevState.isBannerVisible){
      this.checkAuth();
    }
  }

  checkAuth = () => {
    const {
      isAuth,
      user: { roles, companyId },
    } = this.props;

    let isBannerVisible = false;

    if (isAuth) {
      if (roles.includes('ACCOUNT OWNER SHARED') || roles.includes('ACCOUNT OWNER/MANAGER')) {
        const sharedCompanyId = +localStorage.getItem('sharedCompanyId');

        isBannerVisible = this.isCompanyDeleted(sharedCompanyId);
      } else if (!roles.includes('ADMIN')) {
        isBannerVisible = this.isCompanyDeleted(companyId);
      }
    }

    if (this.state.isBannerVisible !== isBannerVisible)
      this.setState({ isBannerVisible });
  };

  isCompanyDeleted = (companyId) => {
    const {
      user: { companies: userCompanies = [], companyId: userCompanyId, roles },
      companies: allCompanies = [],
    } = this.props;

    const companies =
      roles.includes('ACCOUNT OWNER') && userCompanyId && !userCompanies.length
        ? allCompanies
        : userCompanies;

    if (!companies.length) {
      if (
        roles.includes('ACCOUNT OWNER') &&
        userCompanyId &&
        !userCompanies.length
      ) {
        this.getCompanies();
      } else {
        this.getUser();
      }
    }

    return !!companies.find(
      ({ id, deletionTime }) => id === companyId && deletionTime
    );
  };

  handleUndoDeletion = () => {
    const {
      user: { companyId },
    } = this.props;

    api.companies
      .undoSelfDelete(companyId)
      .then(() => {
        this.updateData();
        this.requestNotification('Company deletion was successfully canceled');
      })
      .catch((res) => this.requestNotification(res.message, false));
  };

  requestNotification = (message, success = true) => {
    const notificationSettings = {
      type: success ? 'success' : 'error',
      message: message,
    };

    openNotificationWithIcon(notificationSettings);
  };

  updateData = () => {
    this.getUser();
    this.getCompanies();
  };

  getCompanies = () => {
    const {
      onGetUsersManagment,
      user: { roles = [] },
    } = this.props;

    if (roles.length && !roles.includes('USER'))
      api.companies
        .getCompanies()
        .then((res) => {
          let count = 1;
          const resFormatter = res.map((item) => {
            item.count = count;
            count++;
            return item;
          });

          onGetUsersManagment(resFormatter);
        })
        .catch((res) => this.requestNotification(res.message, false));
  };

  getUser = () => {
    const {
      onGetUser,
      user: { id, roles = [] },
    } = this.props;

    if (roles.length && !roles.includes('USER'))
      api.users
        .getUser(id)
        .then((res) => {
          onGetUser(res);
        })
        .catch((res) => this.requestNotification(res.message, false));
  };

  render() {
    const dateFormat = 'YYYY-MM-DD h:mm:ss a';

    const {
      user: { roles, companyId: userCompanyId, companies: userCompanies = [] },
      companies: allCompanies,
    } = this.props;
    const { isBannerVisible } = this.state;

    let deletionTime = '';
    const companies = userCompanies.length ? userCompanies : allCompanies;
    if (companies && companies.length) {
      const companyId = roles.includes('ACCOUNT OWNER SHARED') || roles.includes('ACCOUNT OWNER/MANAGER')
        ? +localStorage.getItem('sharedCompanyId')
        : userCompanyId;

      const currentCompany = companies.find(({ id }) => id === companyId);

      deletionTime = currentCompany ? currentCompany.deletionTime : '';
    }

    return isBannerVisible ? (
      <div className={styles.wrap}>
        <div className={styles.inner}>
          <section className={styles.section}>
            {deletionTime && (
              <div className={styles.sectionDesc}>
                Your company and all the data will be deleted{' '}
                {moment(deletionTime).format(dateFormat)}.
              </div>
            )}
            {roles.includes('ACCOUNT OWNER') && (
              <div className={styles.sectionActions}>
                <button
                  className={styles.button}
                  onClick={this.handleUndoDeletion}>
                  Undo deletion
                </button>
              </div>
            )}
          </section>
        </div>
      </div>
    ) : null;
  }
}

const mapStateToProps = (state) => ({
  isAuth: selectIsAuth(state),
  user: selectUser(state),
  companies: selectCompaniesData(state),
});

const mapDispatchToProps = (dispatch) => ({
  onGetUsersManagment: (data) => dispatch(getCompaniesManagment(data)),
  onGetUser: (data) => dispatch(getUser(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WarningBanner);
