import React, { PureComponent } from 'react';
import { checkUserPasswordPattern } from '../../utils'

import { FormInput } from '../../components';

class HeaderFormInner extends PureComponent {
  oldNewPasswordTheSame = (rule, value, callback) => {
    const { form } = this.props;
    if (form.getFieldValue('currentPassword') === form.getFieldValue('newPassword') && 
        form.getFieldValue('confirmNewPassword') === form.getFieldValue('newPassword')) {
      callback('Old and new password must be different');
    }
    callback();
  };

  validatePassword = (rule, value, callback) => {
    if (value && !checkUserPasswordPattern(value)) {
      callback('The entered password doesn’t match password policies');
    }
    callback();
  };

  comparePassword = (rule, value, callback) => {
    const { form } = this.props;
    if (form.getFieldValue('newPassword') !== form.getFieldValue('confirmNewPassword')) {
      callback('Passwords don’t match');
    }
    callback();
  };

  render() {
    const { form, showValidation, focusedInputName, onFocus, onBlur, onInputChange } = this.props;
    return (
      <>
        {form.getFieldDecorator('currentPassword', {
          initialValue: '',
          validateTrigger: ['onSubmit'],
          rules: [
            {
              required: true,
              message: <span key="currentPasswordError">Please enter your current password</span>,
            },
            {
              validator: this.oldNewPasswordTheSame,
            }
          ],
        })(
          <FormInput
            type="password"
            label={'Current password'}
            error={showValidation && form.getFieldError('currentPassword')}
            onFocus={() => onFocus('currentPassword')}
            onBlur={onBlur}
            onChange={onInputChange}
            isFocusedInput={focusedInputName === 'currentPassword'}
          />,
        )}
        {form.getFieldDecorator('newPassword', {
          initialValue: '',
          validateTrigger: ['onSubmit'],
          rules: [
            {
              required: true,
              message: <span key="passwordError">Please enter your new password</span>,
            },
            {
              validator: this.validatePassword,
            }
          ],
        })(
          <FormInput
            type="password"
            label={'New password'}
            error={showValidation && form.getFieldError('newPassword')}
            onFocus={() => onFocus('newPassword')}
            onBlur={onBlur}
            onChange={onInputChange}
            isFocusedInput={focusedInputName === 'newPassword'}
          />,
        )}
        {form.getFieldDecorator('confirmNewPassword', {
          initialValue: '',
          validateTrigger: ['onSubmit'],
          rules: [
            {
              required: true,
              message: <span key="confirmPasswordError">Please confirm your new password</span>,
            },
            {
              validator: this.comparePassword,
            }
          ],
        })(
          <FormInput
            type="password"
            label={'Confirm new password'}
            onChange={onInputChange}
            onFocus={() => onFocus('confirmNewPassword')}
            onBlur={onBlur}
            isFocusedInput={focusedInputName === 'confirmNewPassword'}
            error={showValidation && form.getFieldError('confirmNewPassword')}
          />,
        )}
      </>
    );
  }
}

export default HeaderFormInner;
