const RATING_VALUE = {
  low: 'L',
  effective: 'S',
  high: 'H',
}

export const getUpdateDashboardObject = (dashboardParams) => {
  const {
    id,
    employeeSetId,
    name,
    displayBlocks,
    filterDivisions,
    filterEmploymentsStatus,
    filterFactorComboMatch,
    filterFirstScore,
    filterOverallScore,
    filterJobs,
    filterCognitive,
    filterLocation,
    filterPerformance,
    filterPotential,
    filterRating,
    filterReadiness,
    filterTenure,
    succession,
    isOwned,
    performanceName,
    competenciesName
  } = dashboardParams;

  return {
    id,
    employeeSetId,
    name,
    displayBlocks: {
      behavioralPerformance: !!displayBlocks.behavioralPerformance,
      behavioralSuccession: !!displayBlocks.behavioralSuccession,
      cognitive: !!displayBlocks.cognitive,
      competencies: !!displayBlocks.competencies,
      currentSkills: !!displayBlocks.currentSkills,
      customSkills: !!displayBlocks.customSkills,
      employmentStatus: !!displayBlocks.employmentStatus,
      nineBox: !!displayBlocks.nineBox,
      organizationalData: !!displayBlocks.organizationalData,
      performance: !!displayBlocks.performance,
    },
    filterDivisions,
    filterEmploymentsStatus,
    filterFactorComboMatch,
    filterFirstScore,
    filterOverallScore,
    filterJobs,
    filterCognitive: filterCognitive,
    filterDivisionSupervisor: [],
    filterLocation,
    filterPerformance: filterPerformance/*filterPerformance.length ? filterPerformance.map(item => item.toUpperCase()) : []*/,
    filterPotential: filterPotential/*.length ? filterPotential.map(item => item.toUpperCase()) : []*/,
    filterRating: filterRating?.filter(Boolean).length
      ? filterRating.map(item => RATING_VALUE[item.toLowerCase()])
      : [],
    filterReadiness,
    filterTenure,
    succession,
    isOwned: !!isOwned,
    performanceName,
    competenciesName
  }
}
