import { plansData } from '../redux/pages/Perfomance/tooltipsData';

const COLORS_OPTIONS = ['Green', 'Yellow', 'Gray'];
const COGNITIVE_OPTIONS = ['Has Cognitive', 'No Cognitive Data'];
const TENURE_OPTIONS = ['less than 1 year', '1-3 years', '3-5 years', '5+ years'];
export const READINESS_OPTIONS = ['Now', '6-12 months', '12-24 months', '24+ months'];
const EMP_STATUSES_OPtIONS = ['Active', 'Inactive', 'Inactive-Voluntary', 'Inactive-Involuntary', 'Applicant'];
const mapEmployeeStatuses = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  APPLICANT: 'Applicant',
};
const RATING_VALUE = {
  l: 'low',
  s: 'effective',
  h: 'high',
}

export const PERFORMANCE_VALUES = [
  'LOW',
  'EFFECTIVE',
  'HIGH'
];

export const POTENTIAL_VALUES = [
  'LOW',
  'MODERATE',
  'HIGH'
];

const capitalizeFirstLetter = (values) =>
  values?.map(string => string.charAt(0).toUpperCase() + string.toLowerCase().slice(1)) || [];

export const getCognitiveValues = (cognitive, values) => {
  return cognitive && values?.length
    ? values.map(item => {
      if (typeof item === 'boolean') {
        return item ? COGNITIVE_OPTIONS[0] : COGNITIVE_OPTIONS[1]
      }
      return item
    }) : []
}

export const getFiltersConfig = ({
  currentValues,
  employeeLines9BoxData,
  jobsOptions,
  divisionsOptions,
  employeeRatings,
  employeeReadiness,
  hideCognitive,
  locations
}) => {
  const {
    filterCognitive,
    filterDivisionSupervisor,
    filterDivisions,
    filterEmploymentsStatus,
    filterFactorComboMatch,
    filterFirstScore,
    filterJobs,
    filterLocation,
    filterOverallScore,
    filterPerformance,
    filterPotential,
    filterRating,
    filterReadiness,
    filterTenure,
    succession,
    displayBlocks: {
      currentSkills,
      behavioralPerformance,
      behavioralSuccession,
      employmentStatus,
      cognitive,
      nineBox,
      organizationalData,
      performance
    }
  } = currentValues;
  const { employeeLineFirst, employeeLineSecond } = employeeLines9BoxData;

  return [
    {
      name: 'filterDivisions',
      mode: 'multiple',
      value: succession ? [] : filterDivisions,
      placeholder: 'Team/Division',
      options: divisionsOptions || [],
      disabled: succession
    },
    {
      name: 'filterFirstScore',
      mode: 'multiple',
      value: currentSkills ? filterFirstScore : [],
      placeholder: 'Current Skill - 1st Score',
      options: COLORS_OPTIONS,
      disabled: !currentSkills
    },
    {
      name: 'filterOverallScore',
      mode: 'multiple',
      value: currentSkills ? filterOverallScore : [],
      placeholder: 'Current Skill - Overall Score',
      options: COLORS_OPTIONS,
      disabled: !currentSkills
    },
    {
      name: 'filterJobs',
      mode: 'multiple',
      value: filterJobs,
      placeholder: 'Jobs',
      options: jobsOptions ? Object.values(jobsOptions) : [],
      disabled: false
    },
    {
      name: 'filterCognitive',
      mode: 'multiple',
      value: getCognitiveValues(cognitive, filterCognitive),
      placeholder: 'Cognitive',
      options: COGNITIVE_OPTIONS,
      disabled: !cognitive || hideCognitive
    },
    {
      name: 'filterFactorComboMatch',
      mode: 'multiple',
      value: behavioralPerformance || behavioralSuccession ? filterFactorComboMatch : [],
      placeholder: 'Factor Combo Match',
      options: COLORS_OPTIONS,
      disabled: !behavioralPerformance && !behavioralSuccession
    },
    {
      name: 'filterEmploymentsStatus',
      mode: 'multiple',
      value: employmentStatus ? filterEmploymentsStatus : [],
      placeholder: 'Employment Status',
      options: EMP_STATUSES_OPtIONS,
      disabled: !employmentStatus
    },
    {
      name: 'filterRating',
      mode: 'multiple',
      value: filterRating?.filter(Boolean).map(item => item.length === 1 ? plansData[item] : item) || [],
      placeholder: 'Rating',
      options: employeeRatings?.map(item => plansData[item]) || [],
      disabled: !performance
    },
    {
      name: 'filterLocation',
      mode: 'multiple',
      value: filterLocation,
      placeholder: 'Location',
      options: locations ? Object.values(locations) : [],
      disabled: !organizationalData
    },
    {
      name: 'filterTenure',
      mode: 'multiple',
      value: filterTenure,
      placeholder: 'Tenure',
      options: TENURE_OPTIONS,
      disabled: !organizationalData
    },
    {
      name: 'filterReadiness',
      mode: 'multiple',
      value: filterReadiness,
      placeholder: 'Readiness',
      options: READINESS_OPTIONS,
      disabled: !nineBox
    },
    {
      name: 'filterPerformance',
      mode: 'multiple',
      value: filterPerformance ? filterPerformance : [],
      placeholder: employeeLines9BoxData.employeeLineFirst.title || 'Performance',
      options: employeeLineFirst.values,
      optionValues: PERFORMANCE_VALUES,
      disabled: !nineBox,
      className: 'capptilase'
    },
    {
      name: 'filterPotential',
      mode: 'multiple',
      value: filterPotential ? filterPotential : [],
      placeholder: employeeLines9BoxData.employeeLineSecond.title || 'Potential',
      options: employeeLineSecond.values,
      optionValues: POTENTIAL_VALUES,
      disabled: !nineBox
    },
  ]
};
