import React from 'react';

export const getFormatData = ({
  data,
  sortType,
  sortDirection,
  isSuccession = false,
}) => {
  switch (sortType) {
    case 'lastName': {
      if (sortDirection === 'topSort') {
        return isSuccession
          ? [divideByJob, sortByLastNameTop, flutter].reduce(
              (res, foo) => foo(res),
              data
            )
          : [divideByDivision, sortByLastNameTop, flutter].reduce(
              (res, foo) => foo(res),
              data
            );
      } else if (sortDirection === 'downSort') {
        return isSuccession
          ? [divideByJob, sortByLastNameBottom, flutter].reduce(
              (res, foo) => foo(res),
              data
            )
          : [divideByDivision, sortByLastNameBottom, flutter].reduce(
              (res, foo) => foo(res),
              data
            );
      }

      return data;
    }
    case 'firstName': {
      if (sortDirection === 'topSort') {
        return isSuccession
          ? [divideByJob, sortByFirstNameTop, flutter].reduce(
              (res, foo) => foo(res),
              data
            )
          : [divideByDivision, sortByFirstNameTop, flutter].reduce(
              (res, foo) => foo(res),
              data
            );
      } else if (sortDirection === 'downSort') {
        return isSuccession
          ? [divideByJob, sortByFirstNameBottom, flutter].reduce(
              (res, foo) => foo(res),
              data
            )
          : [divideByDivision, sortByFirstNameBottom, flutter].reduce(
              (res, foo) => foo(res),
              data
            );
      }

      return data;
    }
    case 'Score': {
      if (sortDirection === 'topSort') {
        return isSuccession
          ? [divideByJob, sortByScoreTop, flutter].reduce(
              (res, foo) => foo(res),
              data
            )
          : [divideByDivision, sortByScoreTop, flutter].reduce(
              (res, foo) => foo(res),
              data
            );
      } else if (sortDirection === 'downSort') {
        return isSuccession
          ? [divideByJob, sortByScoreBottom, flutter].reduce(
              (res, foo) => foo(res),
              data
            )
          : [divideByDivision, sortByScoreBottom, flutter].reduce(
              (res, foo) => foo(res),
              data
            );
      }
      return data;
    }
    case 'FactorComboMatch': {
      if (sortDirection === 'topSort') {
        return isSuccession
          ? [divideByJob, sortByFactorComboMatchTop, flutter].reduce(
              (res, foo) => foo(res),
              data
            )
          : [divideByDivision, sortByFactorComboMatchTop, flutter].reduce(
              (res, foo) => foo(res),
              data
            );
      } else if (sortDirection === 'downSort') {
        return isSuccession
          ? [divideByJob, sortByFactorComboMatchBottom, flutter].reduce(
              (res, foo) => foo(res),
              data
            )
          : [divideByDivision, sortByFactorComboMatchBottom, flutter].reduce(
              (res, foo) => foo(res),
              data
            );
      }
      return data;
    }
    case 'Op': {
      if (sortDirection === 'topSort') {
        return [divideByDivision, sortByOpTop, flutter].reduce(
          (res, foo) => foo(res),
          data
        );
      } else if (sortDirection === 'downSort') {
        return [divideByDivision, sortByOpBottom, flutter].reduce(
          (res, foo) => foo(res),
          data
        );
      }
      return data;
    }
    case 'Pos': {
      if (sortDirection === 'topSort') {
        return [divideByDivision, sortByPosTop, flutter].reduce(
          (res, foo) => foo(res),
          data
        );
      } else if (sortDirection === 'downSort') {
        return [divideByDivision, sortByPosBottom, flutter].reduce(
          (res, foo) => foo(res),
          data
        );
      }
      return data;
    }
    case 'In': {
      if (sortDirection === 'topSort') {
        return [divideByDivision, sortByInTop, flutter].reduce(
          (res, foo) => foo(res),
          data
        );
      } else if (sortDirection === 'downSort') {
        return [divideByDivision, sortByInBottom, flutter].reduce(
          (res, foo) => foo(res),
          data
        );
      }
      return data;
    }
    case 'Pr': {
      if (sortDirection === 'topSort') {
        return [divideByDivision, sortByPrTop, flutter].reduce(
          (res, foo) => foo(res),
          data
        );
      } else if (sortDirection === 'downSort') {
        return [divideByDivision, sortByPrBottom, flutter].reduce(
          (res, foo) => foo(res),
          data
        );
      }
      return data;
    }
    case 'Con': {
      if (sortDirection === 'topSort') {
        return [divideByDivision, sortByConTop, flutter].reduce(
          (res, foo) => foo(res),
          data
        );
      } else if (sortDirection === 'downSort') {
        return [divideByDivision, sortByConBottom, flutter].reduce(
          (res, foo) => foo(res),
          data
        );
      }
      return data;
    }
    case 'OverallScore': {
      if (sortDirection === 'topSort') {
        return [divideByDivision, sortByOverallScoreTop, flutter].reduce(
          (res, foo) => foo(res),
          data
        );
      } else if (sortDirection === 'downSort') {
        return [divideByDivision, sortByOverallScoreBottom, flutter].reduce(
          (res, foo) => foo(res),
          data
        );
      }
      return data;
    }
    case 'Rating': {
      if (sortDirection === 'topSort') {
        return [divideByDivision, sortByRatingTop, flutter].reduce(
          (res, foo) => foo(res),
          data
        );
      } else if (sortDirection === 'downSort') {
        return [divideByDivision, sortByRatingBottom, flutter].reduce(
          (res, foo) => foo(res),
          data
        );
      }
      return data;
    }
    case 'Status': {
      if (sortDirection === 'topSort') {
        return isSuccession
          ? [divideByJob, sortByStatusTop, flutter].reduce(
              (res, foo) => foo(res),
              data
            )
          : [divideByDivision, sortByStatusTop, flutter].reduce(
              (res, foo) => foo(res),
              data
            );
      } else if (sortDirection === 'downSort') {
        return isSuccession
          ? [divideByJob, sortByStatusBottom, flutter].reduce(
              (res, foo) => foo(res),
              data
            )
          : [divideByDivision, sortByStatusBottom, flutter].reduce(
              (res, foo) => foo(res),
              data
            );
      }
      return data;
    }
    case 'Location': {
      if (sortDirection === 'topSort') {
        return isSuccession
          ? [divideByJob, sortByLocationTop, flutter].reduce(
              (res, foo) => foo(res),
              data
            )
          : [divideByDivision, sortByLocationTop, flutter].reduce(
              (res, foo) => foo(res),
              data
            );
      } else if (sortDirection === 'downSort') {
        return isSuccession
          ? [divideByJob, sortByLocationBottom, flutter].reduce(
              (res, foo) => foo(res),
              data
            )
          : [divideByDivision, sortByLocationBottom, flutter].reduce(
              (res, foo) => foo(res),
              data
            );
      }
      return data;
    }
    case 'WorkPeriod': {
      if (sortDirection === 'topSort') {
        return isSuccession
          ? [divideByJob, sortByWorkPeriodTop, flutter].reduce(
              (res, foo) => foo(res),
              data
            )
          : [divideByDivision, sortByWorkPeriodTop, flutter].reduce(
              (res, foo) => foo(res),
              data
            );
      } else if (sortDirection === 'downSort') {
        return isSuccession
          ? [divideByJob, sortByWorkPeriodBottom, flutter].reduce(
              (res, foo) => foo(res),
              data
            )
          : [divideByDivision, sortByWorkPeriodBottom, flutter].reduce(
              (res, foo) => foo(res),
              data
            );
      }
      return data;
    }
    default:
      return data;
  }
};

export const getSortedData = ({
  data,
  sortType,
  sortDirection,
  sortColumn,
}) => {
  switch (sortType) {
    case 'Numeric': {
      return sortNumeric(data, sortDirection, sortColumn);
    }
    case 'Alphabetical': {
      return sortAlphabetical(data, sortDirection, sortColumn);
    }
    default:
      return data;
  }
};

export const divideByJob = (data) => {
  const arrOfArrs = [];
  data.forEach((el) => {
    if (el['jobTitle']) {
      arrOfArrs.push(el);
    } else {
      const lastEl = arrOfArrs[arrOfArrs.length - 1];
      Array.isArray(lastEl) ? lastEl.push(el) : arrOfArrs.push([el]);
    }
  });
  return [arrOfArrs];
};

const sortByLastNameTop = (arr) =>
  arr.map((division) =>
    division.map((jobs) => {
      const isArray = Array.isArray(jobs);
      if (isArray) {
        return jobs.sort((a, b) => {
          const first = a.lastName.trim() || 'ZZZZZZZZZZZZZZ';
          const second = b.lastName.trim() || 'ZZZZZZZZZZZZZZ';
          if (first < second) {
            return -1;
          }
          if (first > second) {
            return 1;
          }
          return 0;
        });
      }
      return jobs;
    })
  );

const sortByLastNameBottom = (arr) =>
  arr.map((division) =>
    division.map((jobs) => {
      const isArray = Array.isArray(jobs);
      if (isArray) {
        return jobs.sort((a, b) => {
          const first = a.lastName.trim() || 'AAAAAAAAAAAAA';
          const second = b.lastName.trim() || 'AAAAAAAAAAAAA';
          if (first < second) {
            return 1;
          }
          if (first > second) {
            return -1;
          }
          return 0;
        });
      }
      return jobs;
    })
  );

const sortByFirstNameTop = (arr) =>
  arr.map((division) =>
    division.map((jobs) => {
      const isArray = Array.isArray(jobs);
      if (isArray) {
        return jobs.sort((a, b) => {
          const first = a.firstName.trim() || 'ZZZZZZZZZZZZZZ';
          const second = b.firstName.trim() || 'ZZZZZZZZZZZZZZ';
          if (first < second) {
            return -1;
          }
          if (first > second) {
            return 1;
          }
          return 0;
        });
      }
      return jobs;
    })
  );

const sortByFirstNameBottom = (arr) =>
  arr.map((division) =>
    division.map((jobs) => {
      const isArray = Array.isArray(jobs);
      if (isArray) {
        return jobs.sort((a, b) => {
          const first = a.firstName.trim() || 'AAAAAAAAAAAAA';
          const second = b.firstName.trim() || 'AAAAAAAAAAAAA';
          if (first < second) {
            return 1;
          }
          if (first > second) {
            return -1;
          }
          return 0;
        });
      }
      return jobs;
    })
  );

const sortByWorkPeriodTop = (arr) =>
  arr.map((division) =>
    division.map((jobs) => {
      const isArray = Array.isArray(jobs);
      if (isArray) {
        return jobs.sort((a, b) => {
          const first = a.periodOfWorkMillis || Infinity;
          const second = b.periodOfWorkMillis || Infinity;
          return first - second;
        });
      }
      return jobs;
    })
  );

const sortByWorkPeriodBottom = (arr) =>
  arr.map((division) =>
    division.map((jobs) => {
      const isArray = Array.isArray(jobs);
      if (isArray) {
        return jobs.sort((a, b) => {
          const first = a.periodOfWorkMillis || 0;
          const second = b.periodOfWorkMillis || 0;
          return second - first;
        });
      }
      return jobs;
    })
  );

const sortByLocationTop = (arr) =>
  arr.map((division) =>
    division.map((jobs) => {
      const isArray = Array.isArray(jobs);
      if (isArray) {
        return jobs.sort((a, b) => {
          const first = a.location || 'ZZZZZZZZZZZZZZ';
          const second = b.location || 'ZZZZZZZZZZZZZZ';
          if (first < second) {
            return -1;
          }
          if (first > second) {
            return 1;
          }
          return 0;
        });
      }
      return jobs;
    })
  );

const sortByLocationBottom = (arr) =>
  arr.map((division) =>
    division.map((jobs) => {
      const isArray = Array.isArray(jobs);
      if (isArray) {
        return jobs.sort((a, b) => {
          const first = a.location || 'AAAAAAAAAAAAA';
          const second = b.location || 'AAAAAAAAAAAAA';
          if (first < second) {
            return 1;
          }
          if (first > second) {
            return -1;
          }
          return 0;
        });
      }
      return jobs;
    })
  );

const sortAlphabetical = (arr, sortDirection, sortColumn) => {
  if (sortDirection === null) {
    return arr;
  }
  const tempArr = [...arr];
  const sortColumnArr = sortColumn.split('.');

  return tempArr.sort((a, b) => {
    let first =
      sortColumnArr.reduce((value, entry) => value[entry], a)?.toLowerCase() ||
      'ZZZZZZZZZZZZZZ';
    let second =
      sortColumnArr.reduce((value, entry) => value[entry], b)?.toLowerCase() ||
      'ZZZZZZZZZZZZZZ';
      
    if (sortDirection === 'downSort') {
      [first, second] = [second, first];
    }
    if (first < second) {
      return -1;
    }
    if (first > second) {
      return 1;
    }
    return 0;
  });
};

export const sortStrings = (arr, sortDirection) => {
  if (sortDirection === null) {
    return arr;
  }

  return [...arr].sort((a, b) => {
    let first = a?.toLowerCase() || 'ZZZZZZZZZZZZZZ';
    let second = b?.toLowerCase() || 'ZZZZZZZZZZZZZZ';

    if (sortDirection === 'downSort') {
      [first, second] = [second, first];
    }
    if (first < second) {
      return -1;
    }
    if (first > second) {
      return 1;
    }
    return 0;
  });
};

const sortNumeric = (arr, sortDirection, sortColumn) => {
  if (sortDirection === null) {
    return arr;
  }
  const tempArr = [...arr];
  const sortColumnArr = sortColumn.split('.');
  return tempArr.sort((a, b) => {
    let first = sortColumnArr.reduce((value, entry) => value[entry], a) || 0;
    let second = sortColumnArr.reduce((value, entry) => value[entry], b) || 0;
    if (sortDirection === 'downSort') {
      [first, second] = [second, first];
    }
    return first - second;
  });
};

const mapForStatus = {
  AC: 3,
  AP: 2,
  INAC: 1,
};

const sortByStatusBottom = (arr) =>
  arr.map((division) =>
    division.map((jobs) => {
      const isArray = Array.isArray(jobs);
      if (isArray) {
        return jobs.sort((a, b) => {
          const first =
            (a.employeeStatus && mapForStatus[a.employeeStatus]) || 0;
          const second =
            (b.employeeStatus && mapForStatus[b.employeeStatus]) || 0;
          return second - first;
        });
      }
      return jobs;
    })
  );

const sortByStatusTop = (arr) =>
  arr.map((division) =>
    division.map((jobs) => {
      const isArray = Array.isArray(jobs);
      if (isArray) {
        return jobs.sort((a, b) => {
          const first =
            (a.employeeStatus && mapForStatus[a.employeeStatus]) || Infinity;
          const second =
            (b.employeeStatus && mapForStatus[b.employeeStatus]) || Infinity;
          return first - second;
        });
      }
      return jobs;
    })
  );

const mapForRating = {
  H: 3,
  S: 2,
  L: 1,
};
const sortByRatingBottom = (arr) =>
  arr.map((division) =>
    division.map((jobs) => {
      const isArray = Array.isArray(jobs);
      if (isArray) {
        return jobs.sort((a, b) => {
          const first =
            (a.perfomanceData &&
              a.perfomanceData.employeeRating &&
              mapForRating[a.perfomanceData.employeeRating]) ||
            0;
          const second =
            (b.perfomanceData &&
              b.perfomanceData.employeeRating &&
              mapForRating[b.perfomanceData.employeeRating]) ||
            0;
          return second - first;
        });
      }
      return jobs;
    })
  );

const sortByRatingTop = (arr) =>
  arr.map((division) =>
    division.map((jobs) => {
      const isArray = Array.isArray(jobs);
      if (isArray) {
        return jobs.sort((a, b) => {
          const first =
            (a.perfomanceData &&
              a.perfomanceData.employeeRating &&
              mapForRating[a.perfomanceData.employeeRating]) ||
            Infinity;
          const second =
            (b.perfomanceData &&
              b.perfomanceData.employeeRating &&
              mapForRating[b.perfomanceData.employeeRating]) ||
            Infinity;
          return first - second;
        });
      }
      return jobs;
    })
  );

const sortByOverallScoreBottom = (arr) =>
  arr.map((division) =>
    division.map((jobs) => {
      const isArray = Array.isArray(jobs);
      if (isArray) {
        return jobs.sort((a, b) => {
          const first =
            (a.skillsData &&
              a.skillsData.scoreData &&
              a.skillsData.scoreData.overallScore &&
              a.skillsData.scoreData.overallScore.value) ||
            0;
          const second =
            (b.skillsData &&
              b.skillsData.scoreData &&
              b.skillsData.scoreData.overallScore &&
              b.skillsData.scoreData.overallScore.value) ||
            0;
          return second - first;
        });
      }
      return jobs;
    })
  );

const sortByOverallScoreTop = (arr) =>
  arr.map((division) =>
    division.map((jobs) => {
      const isArray = Array.isArray(jobs);
      if (isArray) {
        return jobs.sort((a, b) => {
          const first =
            (a.skillsData &&
              a.skillsData.scoreData &&
              a.skillsData.scoreData.overallScore &&
              a.skillsData.scoreData.overallScore.value) ||
            Infinity;
          const second =
            (b.skillsData &&
              b.skillsData.scoreData &&
              b.skillsData.scoreData.overallScore &&
              b.skillsData.scoreData.overallScore.value) ||
            Infinity;
          return first - second;
        });
      }
      return jobs;
    })
  );

const sortByPosBottom = (arr) =>
  arr.map((division) =>
    division.map((jobs) => {
      const isArray = Array.isArray(jobs);
      if (isArray) {
        return jobs.sort((a, b) => {
          const first = (a.skillsData && a.skillsData.positionSkill) || 0;
          const second = (b.skillsData && b.skillsData.positionSkill) || 0;
          return second - first;
        });
      }
      return jobs;
    })
  );

const sortByPosTop = (arr) =>
  arr.map((division) =>
    division.map((jobs) => {
      const isArray = Array.isArray(jobs);
      if (isArray) {
        return jobs.sort((a, b) => {
          const first =
            (a.skillsData && a.skillsData.positionSkill) || Infinity;
          const second =
            (b.skillsData && b.skillsData.positionSkill) || Infinity;
          return first - second;
        });
      }
      return jobs;
    })
  );

const sortByConBottom = (arr) =>
  arr.map((division) =>
    division.map((jobs) => {
      const isArray = Array.isArray(jobs);
      if (isArray) {
        return jobs.sort((a, b) => {
          const first = (a.skillsData && a.skillsData.confirmSkill) || 0;
          const second = (b.skillsData && b.skillsData.confirmSkill) || 0;
          return second - first;
        });
      }
      return jobs;
    })
  );

const sortByConTop = (arr) =>
  arr.map((division) =>
    division.map((jobs) => {
      const isArray = Array.isArray(jobs);
      if (isArray) {
        return jobs.sort((a, b) => {
          const first = (a.skillsData && a.skillsData.confirmSkill) || Infinity;
          const second =
            (b.skillsData && b.skillsData.confirmSkill) || Infinity;
          return first - second;
        });
      }
      return jobs;
    })
  );

const sortByPrBottom = (arr) =>
  arr.map((division) =>
    division.map((jobs) => {
      const isArray = Array.isArray(jobs);
      if (isArray) {
        return jobs.sort((a, b) => {
          const first = (a.skillsData && a.skillsData.openSkill) || 0;
          const second = (b.skillsData && b.skillsData.openSkill) || 0;
          return second - first;
        });
      }
      return jobs;
    })
  );

const sortByPrTop = (arr) =>
  arr.map((division) =>
    division.map((jobs) => {
      const isArray = Array.isArray(jobs);
      if (isArray) {
        return jobs.sort((a, b) => {
          const first = (a.skillsData && a.skillsData.presentSkill) || Infinity;
          const second =
            (b.skillsData && b.skillsData.presentSkill) || Infinity;
          return first - second;
        });
      }
      return jobs;
    })
  );

const sortByInBottom = (arr) =>
  arr.map((division) =>
    division.map((jobs) => {
      const isArray = Array.isArray(jobs);
      if (isArray) {
        return jobs.sort((a, b) => {
          const first = (a.skillsData && a.skillsData.investigationSkill) || 0;
          const second = (b.skillsData && b.skillsData.investigationSkill) || 0;
          return second - first;
        });
      }
      return jobs;
    })
  );

const sortByInTop = (arr) =>
  arr.map((division) =>
    division.map((jobs) => {
      const isArray = Array.isArray(jobs);
      if (isArray) {
        return jobs.sort((a, b) => {
          const first =
            (a.skillsData && a.skillsData.investigationSkill) || Infinity;
          const second =
            (b.skillsData && b.skillsData.investigationSkill) || Infinity;
          return first - second;
        });
      }
      return jobs;
    })
  );

const sortByOpBottom = (arr) =>
  arr.map((division) =>
    division.map((jobs) => {
      const isArray = Array.isArray(jobs);
      if (isArray) {
        return jobs.sort((a, b) => {
          const first = (a.skillsData && a.skillsData.openSkill) || 0;
          const second = (b.skillsData && b.skillsData.openSkill) || 0;
          return second - first;
        });
      }
      return jobs;
    })
  );

const sortByOpTop = (arr) =>
  arr.map((division) =>
    division.map((jobs) => {
      const isArray = Array.isArray(jobs);
      if (isArray) {
        return jobs.sort((a, b) => {
          const first = (a.skillsData && a.skillsData.openSkill) || Infinity;
          const second = (b.skillsData && b.skillsData.openSkill) || Infinity;
          return first - second;
        });
      }
      return jobs;
    })
  );

const sortByFactorComboMatchBottom = (arr) =>
  arr.map((division) =>
    division.map((jobs) => {
      const isArray = Array.isArray(jobs);
      if (isArray) {
        return jobs.sort((a, b) => {
          const firstLength = a.behavioralResults.length;
          const secondLength = b.behavioralResults.length;
          const first =
            (firstLength &&
              a.behavioralResults[firstLength - 1]['colorCode']) ||
            Infinity;
          const second =
            (secondLength &&
              b.behavioralResults[secondLength - 1]['colorCode']) ||
            Infinity;
          return first - second;
        });
      }
      return jobs;
    })
  );

const sortByFactorComboMatchTop = (arr) =>
  arr.map((division) =>
    division.map((jobs) => {
      const isArray = Array.isArray(jobs);
      if (isArray) {
        return jobs.sort((a, b) => {
          const firstLength = a.behavioralResults.length;
          const secondLength = b.behavioralResults.length;
          const first =
            (firstLength &&
              a.behavioralResults[firstLength - 1]['colorCode']) ||
            0;
          const second =
            (secondLength &&
              b.behavioralResults[secondLength - 1]['colorCode']) ||
            0;
          return second - first;
        });
      }
      return jobs;
    })
  );

const getValuesToScoreSort = (a, b, top) => [
  (a.cognitiveResult && a.cognitiveResult.actual) || (top ? Infinity : 0),
  (b.cognitiveResult && b.cognitiveResult.actual) || (top ? Infinity : 0),
];
const sortByScoreBottom = (arr) =>
  arr.map((division) =>
    division.map((jobs) => {
      const isArray = Array.isArray(jobs);
      if (isArray) {
        return jobs.sort((a, b) => {
          const [first, second] = getValuesToScoreSort(a, b);
          return second - first;
        });
      }
      return jobs;
    })
  );

const sortByScoreTop = (arr) =>
  arr.map((division) =>
    division.map((jobs) => {
      const isArray = Array.isArray(jobs);
      if (isArray) {
        return jobs.sort((a, b) => {
          const [first, second] = getValuesToScoreSort(a, b, true);
          return first - second;
        });
      }
      return jobs;
    })
  );

export const divideByDivision = (arr) => {
  const arrayOfArrs = [];
  arr.forEach((el) => {
    if (el.divisionId) {
      arrayOfArrs.push([el]);
    } else if (el.jobTitle) {
      const lastIndex = arrayOfArrs.length > 0 ? arrayOfArrs.length - 1 : 0;
      if (!Array.isArray(arrayOfArrs[lastIndex])) {
        arrayOfArrs[lastIndex] = [];
      }
      arrayOfArrs[lastIndex].push(el);
    } else {
      const lastIndex = arrayOfArrs.length - 1;
      const lastIndexInside = arrayOfArrs[lastIndex].length - 1;
      const isArray = Array.isArray(arrayOfArrs[lastIndex][lastIndexInside]);
      if (isArray) {
        arrayOfArrs[lastIndex][lastIndexInside].push(el);
      } else {
        arrayOfArrs[lastIndex].push([el]);
      }
    }
  });
  return arrayOfArrs;
};

export const flutter = (arr) =>
  arr.reduce((ans, el) => ans.concat(Array.isArray(el) ? flutter(el) : el), []);

export const decorateByFilter = (filterName, name, className) => {
  if (!name.toLowerCase().includes(filterName) || !filterName) return name;

  const arr = name.toLowerCase().split(filterName);

  let result = '';
  let bufRes = '';
  arr.forEach((item, index) => {
    if (!item && !index) {
      result = (
        <span className={className}>{name.slice(0, filterName.length)}</span>
      );
      bufRes += filterName;
    } else {
      result = (
        <>
          {result}
          {name.slice(bufRes.length, bufRes.length + item.length)}
          {index < arr.length - 1 && (
            <span className={className}>
              {name.slice(
                (bufRes + item).length,
                (bufRes + item).length + filterName.length
              )}
            </span>
          )}
        </>
      );
      bufRes = bufRes + item + filterName;
    }
  });

  return result;
};
