import React, {PureComponent} from "react";
import styles from "./DashboardsMenu.module.scss";
import {Layout, Menu, Tooltip} from "antd";
import classnames from "classnames";
import CreateDashBoardModal from "../CreateDashBoardModal/CreateDashBoardModal";
import {ReactComponent as IconPerformance} from '../../../../images/icons/performance.svg';
import {ReactComponent as IconSuccession} from '../../../../images/icons/succession.svg';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars, faPlus} from "@fortawesome/free-solid-svg-icons";
import {faArrowLeftLong} from "@fortawesome/free-solid-svg-icons/faArrowLeftLong";
import {faArrowRightLong} from "@fortawesome/free-solid-svg-icons/faArrowRightLong";

const {Sider} = Layout;
const {SubMenu} = Menu;

class DashboardsMenu extends PureComponent {
    state = {
        collapsed: false,
        selectedId: this.props.dashboards.filter(item => !item.succession)[0]?.id || 0,
        isOpenModal: false,
        dataSets: [],
    }

    render() {
    const {collapsed, isOpenModal} = this.state;
    const {dashboards, dataSets, selectedId, onCreateDashboard, toggleModal, onChange} = this.props;

    const onToggleModal = () => toggleModal(true);

    return (
      <div style={{minWidth: collapsed ? '79px' : '212px'}} className={classnames(styles.menu, collapsed && styles.collapsed)}>
        <Sider collapsible>
          <div className={styles.dashboardsTitle}>
            <div className={styles.title}>Dashboards</div>
              <Tooltip title={collapsed ? 'click to expand' : 'click to collapse'}>
                  <FontAwesomeIcon
                      icon={faArrowLeftLong}
                      onClick={() => this.setState({collapsed: !collapsed})}/>
              </Tooltip>
          </div>
            <Tooltip title={collapsed ? 'add new' : false}>
                <button className={styles.dashboardsBtn}
                        onClick={() => this.setState({isOpenModal: true})}>
                    <FontAwesomeIcon icon={faPlus}/>
                    {collapsed ? '' : 'Add New'}
                </button>
            </Tooltip>
          <Menu
            defaultOpenKeys={["1", "2"]}
            defaultSelectedKeys={[`${selectedId}`]}
            selectedKeys={[`${selectedId}`]}
            mode="inline"
          >
            <SubMenu
              key="1"
              title={collapsed ? <Tooltip title={'Performance'}><IconPerformance/></Tooltip> : 'Performance'}
            >
              {dashboards
                .filter((dashboard) => !dashboard.succession)
                .map((dashboard) => (
                  <Menu.Item
                    key={dashboard.id}
                    className={classnames(
                      selectedId === dashboard.id && styles.selected,
                      dashboard.isOwned && "isOwned"
                    )}
                    onClick={() => onChange(dashboard.id)}
                  >
                    <MenuItemIcon name={dashboard.name} collapsed={collapsed}/>
                    <div className={styles.dashboardsItem}>
                      {dashboard.name}
                    </div>
                  </Menu.Item>
                ))}
            </SubMenu>
            <SubMenu
              key="2"
              title={collapsed ? <Tooltip title={'Succession'}><IconSuccession/></Tooltip> : "Succession"}
            >
              {dashboards
                .filter((dashboard) => dashboard.succession)
                .map((dashboard) => (
                  <Menu.Item
                    key={dashboard.id}
                    className={classnames(
                      selectedId === dashboard.id && styles.selected,
                      dashboard.isOwned && "isOwned"
                    )}
                    onClick={() => onChange(dashboard.id)}
                  >
                    <MenuItemIcon name={dashboard.name} collapsed={collapsed}/>
                    <div className={styles.dashboardsItem}>
                      {dashboard.name}
                    </div>
                  </Menu.Item>
                ))}
            </SubMenu>
          </Menu>
        </Sider>
        <CreateDashBoardModal
          dataSets={dataSets}
          isOpen={isOpenModal}
          onCreate={onCreateDashboard}
          onClose={() => this.setState({isOpenModal: false})}/>
      </div>
    );
  }

}

const MenuItemIcon = ({name, collapsed}) => (
    <Tooltip title={collapsed ? name : false}>
        <div className={styles.dashboardsIcon}>
            {name ? name[0] : 'D'}
        </div>
    </Tooltip>
);

export default DashboardsMenu;
