import React, { PureComponent } from 'react';
import { createForm } from 'rc-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

import Logo from '../../../images/logo.png';

import api from '../../../api';
import { openNotificationWithIcon } from '../../../utils';

import ForgotPasswordFormInner from './ForgotPasswordFormInner';

import styles from './ForgotPassword.module.scss';

class ForgotPassword extends PureComponent {
  state = {
    showValidation: false,
    focusedInputName: '',
    isError: false,
    errorText: ''
  }

  onSubmit = e => {
    const { form } = this.props;

    e.preventDefault();
    this.setState({showValidation: true});
    const formData = form.getFieldsValue();

    const data = {
      email: formData.email || null,
    }
    
    form.validateFields(err => {
      if (!err) {  
        api.users.forgotPasswordUser(data)
          .then(() => {
            openNotificationWithIcon({ 
              type: 'success', 
              message: 'Confirmation link was successfully sent to the provided email. Please go there and follow this link',
              duration: 30
            });
          })
          .catch((err) => this.setState({isError: true, errorText: err.response.data.message}));
      }
    });
  };

  onInputChange = () => {
    this.setState({showValidation: false, isError: false, errorText: '' });
  }

  setFocusedInputName = (inputName) => {
    this.setState({focusedInputName: inputName})
  }

  render() {
    const { form } = this.props;
    const { showValidation, focusedInputName, isError, errorText } = this.state;

    return (
      <section className={styles.wrapper}>
        <div className={styles.inner}>
          <img src={Logo} className={styles.logoImg} alt="MindWire"/>
          <h2 className={styles.title}>Forgot Password</h2>
          <p className={styles.subTitle}>
            Can’t remember your password?<br></br>Click the "Send New Link" button to receive the recovery link in your email
          </p>
          <form onSubmit={this.onSubmit}>
            <ForgotPasswordFormInner
              form={form}
              showValidation={showValidation}
              focusedInputName={focusedInputName}
              onInputChange={this.onInputChange}
              onFocus={(inputName) => this.setFocusedInputName(inputName)}
              onBlur={() => this.setFocusedInputName("")}
            />
            {isError && <p className={styles.errorText}>{errorText}</p>}
            <button className={styles.btn}>
              <span><FontAwesomeIcon icon={faEnvelope} /></span>
              Send New Link
            </button>
            <div className={styles.forgotPassword}>
              <Link to={'/login'} className={styles.stepsBtn}>Back to login</Link>
            </div>
          </form>
        </div>
      </section>
    );
  }
}



export default createForm()(ForgotPassword);