import api from '../api';

export const addCompany = (data) =>
  api.post('/companies', data).then((res) => res.data);

export const blockCompany = (data) =>
  api.put('/companies/block', data).then((res) => res.data);

export const deleteCompany = (data) =>
  api.post('/companies/delete', data).then((res) => res.data);

export const getCompanies = () => api.get('/companies').then((res) => res.data);

export const getCompanySurveysInfo = (params) =>
  api.get('/companies/surveysInfo', params).then((res) => res.data);

export const getCompany9boxSettings = () =>
  api.get('/companies/9BoxSuccession').then((res) => res.data);

export const update9BoxSettings = (data) =>
  api.post('/companies/9BoxSuccession', data).then((res) => res.data);

export const unblockCompany = (data) =>
  api.put('/companies/unblock', data).then((res) => res.data);

export const updateCompany = (data) =>
  api.put('/companies/update', data).then((res) => res.data);

export const updateSurveys = (data,useQueue) =>
  api.post(`/companies/updateSurveys?&useQueue=${(!!useQueue)}`, data).then((res) => res.data);

export const refreshSurveys = () =>
  api.get('/companies/refreshSurveys').then((res) => res.data);

export const shareAccess = (id) =>
  api.get(`/companies/${id}/share-access`).then((res) => res.data);
  
  export const getCompaniesData = () => api.get('/companies/123456789').then(res => res.data);

export const getCountries = () => api.get('/companies/countries').then(res => res.data);

export const getOrganizationSizes = data => api.get('/companies/company-size', data).then(res => res.data);

export const getCompanyIndustries = () => api.get('/companies/industries').then(res => res.data);

export const selfDelete = (id) =>
  api.post(`/companies/selfDelete?companyId=${id}`).then((res) => res.data);

export const undoSelfDelete = (id) =>
  api.post(`/companies/undoSelfDelete?companyId=${id}`).then((res) => res.data);
