import React from 'react';
import styles from './FilterActions.module.scss';
import { getEmpStatusValues } from '../../../utils/getEmpStatusValues';
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFloppyDisk} from "@fortawesome/free-solid-svg-icons/faFloppyDisk";

export const FilterActions = ({
  onRebuildDashboard,
  currentValues,
  initialValues,
  roles,
  handleChange,
  isActiveMiniLoading,
  submitCount
}) => {
  const {
    behavioralSuccession,
    behavioralPerformance,
    cognitive,
    competencies,
    currentSkills,
    customSkills,
    employmentStatus,
    nineBox,
    organizationalData,
    performance,
  } = currentValues.displayBlocks;

  const { isAppliedFilters: isAplliedCurrent, ...otherCurrentValues } = currentValues;
  const { isAppliedFilters: isAppliedInitial, ...otherInitialValues } = initialValues;

  const isDisableSave = (JSON.stringify(otherCurrentValues) === JSON.stringify(otherInitialValues)) || isActiveMiniLoading;
  const isDisabledApply = (isDisableSave && !currentValues.isAppliedFilters) || isActiveMiniLoading;

  const DISPLAY_BLOCKS = {
    behavioralSuccession: !!behavioralSuccession,
    behavioralPerformance: !!behavioralPerformance,
    cognitive: !!cognitive,
    competencies: !!competencies,
    currentSkills: !!currentSkills,
    customSkills: !!customSkills,
    employmentStatus: !!employmentStatus,
    nineBox: !!nineBox,
    organizationalData: !!organizationalData,
    performance: !!performance,
  };

  const {
    filterCognitive,
    filterDivisionSupervisor,
    filterDivisions,
    filterEmploymentsStatus,
    filterFactorComboMatch,
    filterFirstScore,
    filterJobs,
    filterLocation,
    filterOverallScore,
    filterPerformance,
    filterPotential,
    filterRating,
    filterReadiness,
    filterTenure
  } = currentValues;

  const RATING_VALUE = {
    low: 'L',
    effective: 'S',
    high: 'H',
  }

  const handleApplyFilter = () => {
    handleChange('isAppliedFilters', true);
    onRebuildDashboard({
      currentFilterLinkData: {
        performanceFilters: {
          displayBlocks: { ...DISPLAY_BLOCKS },
          filterDivisions,
          filterEmploymentsStatus: getEmpStatusValues(filterEmploymentsStatus),
          filterFactorComboMatch,
          filterFirstScore,
          filterOverallScore,
          filterJobs,
          filterPerformance: filterPerformance.length ? filterPerformance.map(item => item.toUpperCase()) : [],
          filterPotential: filterPotential.length ? filterPotential.map(item => item.toUpperCase()) : [],
          filterRating: filterRating?.filter(Boolean).length
            ? filterRating.map(item => RATING_VALUE[item.toLowerCase()])
            : [],
          filterReadiness,
          filterTenure,
          filterLocation,
          hasCognitive: filterCognitive?.length ? filterCognitive.map(item => item === 'Has Cognitive') : [],
          title: null,
        },
        successionFilters: null
      },
      divisionsFilter: { divisions: filterDivisions },
      jobsFilter: { jobs: filterJobs },
      employeeSetId: currentValues.employeeSetId,
      isAppliedFilters: true
    })
  }

  return(
    <div className={styles.actionsWrapper}>
      <button
        type='button'
        className={styles.footerButton}
        onClick={handleApplyFilter}
        disabled={isDisabledApply}
      >
        <div className={styles.centeredText}>
          <FontAwesomeIcon className={styles.buttonImg} alt="apply" icon={faCheck}/>
          <span>Apply Filters</span>
        </div>
      </button>
      <button
        type='submit'
        className={styles.footerButton}
        disabled={isDisableSave || submitCount || (!initialValues.isOwned && roles.includes('ACCOUNT OWNER'))}
      >
        <div className={styles.centeredText}>
          <FontAwesomeIcon className={styles.buttonImg} alt="Save" icon={faFloppyDisk}/>
          <span>Save Dashboard</span>
        </div>
      </button>
    </div>
  )
}
