import {
  AUTH_START,
  AUTH_SUCCESS,
  AUTH_FAIL,
  CLEAR_AUTH_FAIL,
  LOGOUT,
  USER_DATA,
  USER_CHANGE_ROLE,
  USER_ROLE_GETBACK,
  GET_USER,
} from '../../../constants/constants';

export const initialState = {
  isAuth: false,
  isLoading: false,
  error: null,
  user: {
    id: '',
    roles: [],
    username: '',
    authenticated: '',
    jwtOldToken: '',
    userIdShared: '',
  },
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_START:
      return {
        ...state,
        isLoading: true,
      };
    case AUTH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isAuth: true,
        error: null,
      };
    case AUTH_FAIL:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case CLEAR_AUTH_FAIL:
      return {
        ...state,
        error: null,
        isLoading: false,
      };
    case USER_DATA:
      return {
        ...state,
        user: action.data,
      };
    case USER_CHANGE_ROLE:
      return {
        ...state,
        user: {
          ...state.user,
          jwtOldToken: state.user.jwtToken,
          roles: action.data.roles,
          jwtToken: action.data.jwt,
          userIdShared: action.data.userId,
        },
      };
    case USER_ROLE_GETBACK:
      return {
        ...state,
        user: {
          ...state.user,
          roles: action.data.roles,
          jwtToken: state.user.jwtOldToken,
          jwtOldToken: '',
          userIdShared: '',
        },
      };
    case LOGOUT:
      return {
        ...initialState,
        isAuth: false,
        user: {
          id: '',
          roles: [],
          username: '',
          authenticated: '',
          jwtOldToken: '',
          userIdShared: '',
        },
      };

    case GET_USER:
      return {
        ...state,
        user: { ...action.user },
      };
    default:
      return state;
  }
};
