import React, { PureComponent } from 'react';

import { FormInput } from '../../../components';
import styles from './ForgotPassword.module.scss';

class ForgotPasswordFormInner extends PureComponent {
  render() {
    const { form, showValidation, focusedInputName, onFocus, onBlur, onInputChange, userNameInitialValue = '' } = this.props;
    return (
      <>
        {form.getFieldDecorator('email', {
          initialValue: `${userNameInitialValue}`,
          validateTrigger: ['onSubmit'],
          rules: [
            {
              required: true,
              message: <span key="emailError">Please enter your email address</span>,
            },
            {
              type: 'email',
              message: <span key="emailFormatError">Email address format is incorrect</span>,
            },
          ],
        })(
          <FormInput
            label={'Email'}
            className={`${styles.emailInput}`}
            error={showValidation && form.getFieldError('email')}
            onFocus={() => onFocus('email')}
            onBlur={onBlur}
            onChange={onInputChange}
            isFocusedInput={focusedInputName === 'email'}
          />,
        )}
      </>
    );
  }
}

export default ForgotPasswordFormInner;
