import api from "../api";

export const getEmployeesCSV = () =>
  api.get("/employees/generateEmployeesCSV").then((res) => res.data);

export const generateEmployeesFile = (queryParams = {}, params = {}) =>
  api
    .post(
      `/employees/generateEmployeesExportFile?${Object.keys(queryParams)
        .map((key) => `${key}=${queryParams[key]}`)
        .join("&")}`,
      params
    )
    .then((res) => res.data);

export const generateEmployeesSuccessionFile = (type) =>
  api
    .get(`/employees/generateEmployeesExportFileSuccession?contentType=${type}`)
    .then((res) => res.data);

export const downloadTemplate = (type, dataSetId) =>
  api
    .get(
      `/employees/downloadTemplate?contentType=${type}&employeeSetId=${dataSetId}`
    )
    .then((res) => res.data);

export const uploadCSV = (file, employeeSetId, useQueue) => {
  const headers = {
    "Content-Type": "application/octet-stream",
  };
  return api
    .post(`/employees/import?&employeeSetId=${employeeSetId}&useQueue=${(!!useQueue)}`, file, {
      headers,
    })
    .then((res) => res.data);
};
