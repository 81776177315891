import React from 'react';
import styles from './DataSet.module.scss'
import { Tooltip } from 'antd';
import classnames from 'classnames';

export const DataSet = ({ datasets, selected, handleChange }) => {
  const onChange = (event) => {
    const { name, value } = event.target;
    handleChange(name, Number(value));
  }

  return (
    <div className={styles.badgesWrap}>
      <div className={styles.badgesTitle}>Dataset</div>
      <div className={styles.badges}>
        {datasets.map(({ id, name }) => (
          <Tooltip key={id} title={name}>
            <button
              value={id}
              name='employeeSetId'
              type='button'
              className={classnames(
                styles.badgesItem,
                id === selected && styles.selected
              )}
              onClick={onChange}
            >
              {name}
            </button>
          </Tooltip>
        ))}
        {!datasets.length && <div>No datasets available</div>}
      </div>
    </div>
  )
}
