import React, { PureComponent } from 'react';
import { Form } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen} from '@fortawesome/free-solid-svg-icons';

import api from '../../../../api';
import {FormInput, Loader} from '../../../../components';
import { AreYouWantToCancel, CustomModal } from '../../components';
import styles from './Edit9Box.module.scss';
import {compose} from 'recompose';
import {
  onDisabledSaveButton,
} from '../../../../utils';

import {faArrowDownLong} from "@fortawesome/free-solid-svg-icons/faArrowDownLong";
import {faArrowUpLong} from "@fortawesome/free-solid-svg-icons/faArrowUpLong";
import {faArrowLeftLong} from "@fortawesome/free-solid-svg-icons/faArrowLeftLong";
import {faArrowRightLong} from "@fortawesome/free-solid-svg-icons/faArrowRightLong";
import {faRotateLeft} from "@fortawesome/free-solid-svg-icons/faRotateLeft";
import {faRotateRight} from "@fortawesome/free-solid-svg-icons/faRotateRight";

let formInput_1 = React.createRef();
let formInput_2 = React.createRef();
let formInput_3 = React.createRef();
let formInput_4 = React.createRef();
let formInput_5 = React.createRef();
let formInput_6 = React.createRef();
let formInput_7 = React.createRef();
let formInput_8 = React.createRef();

const label1Template = [
  {
    fieldName: 'label1',
    ref: formInput_1,
    label: 'Label 1 ',
    rules: [{
      message: ' ',
      type: 'string',
    }, {
      max: 250,
      message: ' ',
    }],
  },
  {
    fieldName: 'label1Value1',
    ref: formInput_2,
    label: 'Value 1 ',
    labelColored: '(Low)',
    labelColor: '#adadad',
    rules: [{
      message: ' ',
      type: 'string',
    }, {
      max: 250,
      message: ' ',
    }],
  },
  {
    fieldName: 'label1Value2',
    ref: formInput_3,
    label: 'Value 2 ',
    labelColored: '(Effective)',
    labelColor: '#d9b64c',
    rules: [{
      message: ' ',
      type: 'string',
    }, {
      max: 250,
      message: ' ',
    }],
  },
  {
    fieldName: 'label1Value3',
    ref: formInput_4,
    label: 'Value 3 ',
    labelColored: '(High)',
    labelColor: '#28A181',
    rules: [{
      message: ' ',
      type: 'string',
    }, {
      max: 250,
      message: ' ',
    }],
  },
];

const label2Template = [
  {
    fieldName: 'label2',
    ref: formInput_5,
    label: 'Label 2 ',
    rules: [{
      message: ' ',
      type: 'string',
    }, {
      max: 250,
      message: ' ',
    }],
  },
  {
    fieldName: 'label2Value1',
    ref: formInput_6,
    label: 'Value 1 ',
    labelColored: '(Low)',
    labelColor: '#adadad',
    rules: [{
      message: ' ',
      type: 'string',
    }, {
      max: 250,
      message: ' ',
    }],
  },
  {
    fieldName: 'label2Value2',
    ref: formInput_7,
    label: 'Value 2 ',
    labelColored: '(Effective)',
    labelColor: '#d9b64c',
    rules: [{
      message: ' ',
      type: 'string',
    }, {
      max: 250,
      message: ' ',
    }],
  },
  {
    fieldName: 'label2Value3',
    ref: formInput_8,
    label: 'Value 3 ',
    labelColored: '(High)',
    labelColor: '#28A181',
    rules: [{
      message: ' ',
      type: 'string',
    }, {
      max: 250,
      message: ' ',
    }],
  },
];


const sectionsForMainAdmin = [
  {sectionTitle: 'Perfomance', sectionTemplate: label1Template},
  {sectionTitle: 'Potential', sectionTemplate: label2Template}
];
const valuesFalse = ['Value 3', undefined, undefined, 'Value 2', undefined, undefined, 'Value 1', 'Value 2', 'Value 3'];
const valuesTrue = ['Value 1', undefined, undefined, 'Value 2', undefined, undefined, 'Value 3', 'Value 2', 'Value 1'];

class Edit9Box extends PureComponent {
  state = {
    cancelModal: false,
    focusedInputName: '',
    isSaveButtonDisabled: true,
    initialPopUpData: null,
    isLoading: false,
    labels: true,
    values: true,
    firstAttempt: true,
  }

  componentDidMount() {
    this.props.onRef(this);
    this.get9boxSettings();
  }
  componentWillUnmount() {
    this.props.onRef(undefined)
  }
  componentDidUpdate() {
    const { initialPopUpData, labels, values } = this.state;
    const {form} = this.props;
    const {
      label1,
      label1Value1,
      label1Value2,
      label1Value3,
      label2,
      label2Value1,
      label2Value2,
      label2Value3,
    } = form.getFieldsValue();

    const newPopUpData = {
      label1,
      label1Value1,
      label1Value2,
      label1Value3,
      label2,
      label2Value1,
      label2Value2,
      label2Value3,
      labels,
      values,
    }

    const isInputsNotChanged =
          JSON.stringify(initialPopUpData) === JSON.stringify(newPopUpData);

    this.setState({isSaveButtonDisabled: isInputsNotChanged})
  }

    get9boxSettings = () => {
      this.setState({isLoading: true});
      const { form } = this.props;

      api.companies.getCompany9boxSettings()
        .then(res => {
          const {employeeLineFirst, employeeLineSecond, isRevertLabels, isRevertValues} = res;
          form.setFieldsValue({
            label1: employeeLineFirst.title || '',
            label1Value1: employeeLineFirst.values[0] || '',
            label1Value2: employeeLineFirst.values[1] || '',
            label1Value3: employeeLineFirst.values[2] || '',
            label2: employeeLineSecond.title || '',
            label2Value1: employeeLineSecond.values[0] || '',
            label2Value2: employeeLineSecond.values[1] || '',
            label2Value3: employeeLineSecond.values[2] || '',
          });

          const initialPopUpData = {
            label1: employeeLineFirst.title || '',
            label1Value1: employeeLineFirst.values[0] || '',
            label1Value2: employeeLineFirst.values[1] || '',
            label1Value3: employeeLineFirst.values[2] || '',
            label2: employeeLineSecond.title || '',
            label2Value1: employeeLineSecond.values[0] || '',
            label2Value2: employeeLineSecond.values[1] || '',
            label2Value3: employeeLineSecond.values[2] || '',
            labels: isRevertLabels,
            values: isRevertValues

          }
          this.setState({
            initialPopUpData, labels: isRevertLabels, values: isRevertValues
          })
        }).then(() => this.setState({isLoading: false}));
    };

  onEditClick = (ref, fieldName ) => {
    if (fieldName !== this.state.focusedInputName) {
      this.setState({focusedInputName: fieldName});
      ref.current.disabled = false;
    }
    return (
      ref.current.focus()
    )
  };

  onSubmit = e => {
    const { form, modalClose, sendNotification } = this.props;
    const { labels, values } = this.state;
    e.preventDefault();
    this.setState({firstAttempt: false});


    const {
      label1,
      label1Value1,
      label1Value2,
      label1Value3,
      label2,
      label2Value1,
      label2Value2,
      label2Value3,
    } = form.getFieldsValue();

    form.validateFields(err => {
      if (!err) {
        const successfulText = '9box settings successfully changed';
        const data = {
          employeeLineFirst: {
            title: label1,
            values: [label1Value1, label1Value2, label1Value3],
          },
          employeeLineSecond: {
            title: label2,
            values: [label2Value1, label2Value2, label2Value3],
          },
          isRevertLabels: labels,
          isRevertValues: values,
        };

        api.companies.update9BoxSettings(data)
          .then(() => {
            sendNotification(successfulText);
            modalClose(true);
          })
          .catch(res => sendNotification(res.message, false));
      }
    });
  };

  onCancelHandle = () => {
    const { initialPopUpData, labels, values } = this.state;
    const {form, modalClose} = this.props;
    const {
      label1,
      label1Value1,
      label1Value2,
      label1Value3,
      label2,
      label2Value1,
      label2Value2,
      label2Value3,
    } = form.getFieldsValue();

    const newPopUpData = {
      label1,
      label1Value1,
      label1Value2,
      label1Value3,
      label2,
      label2Value1,
      label2Value2,
      label2Value3,
      labels,
      values,
    }

    const isInputsChanged =
        JSON.stringify(initialPopUpData) === JSON.stringify(newPopUpData);
    if (!isInputsChanged) {
      this.setState({ cancelModal: true })
    }
    else {
      modalClose();
    }

  }
  render() {
    const {
      cancelModal,
      focusedInputName,
      isSaveButtonDisabled,
      isLoading,
      labels,
      values,
      firstAttempt,
    } = this.state;

    const {
      label1,
      label2,
    } = this.props.form.getFieldsValue();

    const { form, modalClose } = this.props;
    const { getFieldDecorator, getFieldError } = form;
    const requiredFields = ['label1', 'label1Value1', 'label1Value2', 'label1Value3', 'label2', 'label2Value1', 'label2Value2', 'label2Value3'];

    return (
      <section className={styles.wrapper}>
        <h2 className={styles.title}>{'9-box settings'}</h2>
        <h3 className={styles.titleSectionBig}>Values Setting</h3>
        <div className={styles.valuesSetting} >
          <form id='9box-values' onSubmit={this.onSubmit}>
            <div className={styles.container9boxValues}>
              {
                sectionsForMainAdmin.map(section => (
                  <div key={`section-${section.sectionTitle}`} className={styles.sectionWrap}>
                    {section.sectionTemplate.map((item , i)=> (
                      <div className={`${styles.inputSection} ${styles.inputSectionAdditionalMargins} ${i === 0 ? styles.boldText : ''} `} key={`company-information-${item.fieldName}`}>
                        {getFieldDecorator(item.fieldName, {
                          rules: item.rules,
                        })(
                          <FormInput
                            className={`${styles.formInput}`}
                            error={getFieldError(item.fieldName)}
                            inputRef={item.ref}
                            mask={item.mask}
                            onBlur={() => this.setState({focusedInputName: ''})}
                            label={
                              <>
                                <span>{item.label}</span>
                                {item.labelColored ? (
                                  <>
                                    <span style={{color: item.labelColor}}>{item.labelColored}</span>
                                    <span>*</span>
                                  </>
                                ) : (
                                  <span>*</span>
                                )  }
                              </>
                            }
                            disabled={item.fieldName !== focusedInputName}
                            isFocusedInput={focusedInputName === item.fieldName}
                          />,
                        )}
                        <button type="button" className={styles.editBtn} onClick={() => this.onEditClick( item.ref, item.fieldName )}>
                          <FontAwesomeIcon icon={faPen} />
                        </button>
                      </div>
                    ))}
                  </div>
                ))
              }
            </div>
            <button className={styles.visuallyHidden} />
          </form>
        </div>
        <h3 className={styles.titleSectionBig}>Orientation Logic</h3>
        <div className={styles.orientationLogic} >
          <div className={styles.btnsChangeOrientation}>
            <div className={styles.btnWrapper}>
              <span className={styles.btnLabel}>Labels:</span>
              <button
                className={`${styles.btnNo}`}
                onClick={() => this.setState(prev => ({labels: !prev.labels}))}
              >
                {<FontAwesomeIcon style={{fontSize: 20}} icon={labels ? faRotateLeft : faRotateRight}/>}
              </button>
            </div>
            <div className={styles.btnWrapper}>
              <span className={styles.btnLabel}>Values:</span>
              <button
                className={`${styles.btnNo}`}
                onClick={() => this.setState(prev => ({values: !prev.values}))}
              >
                <FontAwesomeIcon icon={ values ? faArrowDownLong : faArrowUpLong } style={{fontSize: 20, marginRight: 5}}/>
                <FontAwesomeIcon icon={ values ? faArrowLeftLong : faArrowRightLong } style={{fontSize: 20}}/>
              </button>
            </div>
          </div>
          <div className={styles.orientationMatrix}>
            <div className={styles.matrixBlock}>
              {
                values ?
                  valuesTrue.map((el, i) =>  <div className={styles.block} key={i}>{ el }</div>) :
                  valuesFalse.map((el, i) =>  <div className={styles.block} key={i}>{ el }</div>)
              }
            </div>
            <span className={styles.label1}>{labels ? label1 || 'Label 1' : label2 || 'Label 2'}</span>
            <span className={styles.label2}>{labels ? label2 || 'Label 2' : label1 || 'Label 1'}</span>
          </div>
        </div>
        <div className={styles.btnWrap}>
          <button
            className={styles.btnSave}
            onClick={this.onSubmit}
            disabled={onDisabledSaveButton(form, requiredFields, firstAttempt ) || isSaveButtonDisabled}
          >
            {'Save'}
          </button>
          <button className={styles.btnNo} onClick={this.onCancelHandle}>Cancel</button>
        </div>
        <CustomModal
          visible={cancelModal}
          className={styles.modalWrap}
        >
          <AreYouWantToCancel
            onNo={() => this.setState({ cancelModal: false })}
            onYes={() => {
              modalClose();
            }}
          />;
        </CustomModal>
        {isLoading && <Loader />}
      </section>
    );
  }
}

export default compose(
  Form.create(),
)(Edit9Box);

