import api from '../api';

export const addUser = data => api.post('/users', data).then(res => res.data);

export const blockUsers = data => api.put('/users/block', data).then(res => res.data);

export const changePassword = (data) => api.put(`/changePassword`, data).then(res => res.data);

export const deleteUsers = data => api.post('/users/delete', data).then(res => res.data);

export const getUsers = () => api.get('/users').then(res => res.data);

export const getUser = id => api.get(`/users/${id}`).then(res => res.data);

export const unblockUsers = data => api.put('/users/unblock', data).then(res => res.data);

export const updateUser = data => api.put('/users/update', data).then(res => res.data);

export const deleteShareAccess = data => api.post('/users/shared-access-disable', data).then(res => res.data);

export const confirmationUser = data => api.post('/users/confirmation', data).then(res => res.data);

export const forgotPasswordUser = data => api.post('/users/recoveryPassword', data).then(res => res.data);
