import React from "react";
import styles from "./RefreshAssessments.module.scss";
import {formatDate, openNotificationWithIcon} from "../../../../utils";
import {useSelector} from "react-redux";
import {selectUserRole} from "../../Login/selectors";
import api from "../../../../api";
import {selectLastUpdate} from "../../Perfomance/selectors";
import classnames from 'classnames';
import {Tooltip} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRotateLeft, faRotateBack, faRotateLeft} from "@fortawesome/free-solid-svg-icons";
import {faArrowRotateBack} from "@fortawesome/free-solid-svg-icons/faArrowRotateBack";
import {faArrowsRotate} from "@fortawesome/free-solid-svg-icons/faArrowsRotate";

const RefreshAssessments = ({className, asMenuItem}) => {
    const roles = useSelector(selectUserRole);
    const isUser = roles.includes('USER');
    const isAccountOwner = roles.includes('ACCOUNT OWNER');
    const isAccountOwnerShared = roles.includes('ACCOUNT OWNER SHARED');

    const requestNotification = (message, success = true) => {
        const notificationSettings = {
            type: success ? 'success' : 'error',
            message: message
        };
        openNotificationWithIcon(notificationSettings);
    };
    const lastUpdate = useSelector(selectLastUpdate)
    const lastUpdatePI = lastUpdate.find(({dataSource}) => dataSource === 'PI');

    const onRefresh = () => {
        api.employees.updateAssesmentData()
            .then(() => requestNotification('Data refreshing is started. It can take some time'))
            .catch(res => requestNotification(res.response.data.message, false));
    };

    return (
        <>
            {(isAccountOwner || isUser || isAccountOwnerShared) && !roles.includes('ADMIN') &&
            (asMenuItem ?
                <button className={classnames(className, styles.menuItem)} onClick={onRefresh}>
                    {lastUpdatePI ?
                        <Tooltip title={`Last time updated: ${formatDate(lastUpdatePI.lastChange)}`}>
                            <div className={styles.title}>
                                Refresh Data
                            </div>
                        </Tooltip>
                        :
                        <div className={styles.title}>
                            Refresh Data
                        </div>}
                </button>
                :
                <div className={styles.refresh}>
                    <div className={styles.refreshText}>
                        <FontAwesomeIcon className={styles.refreshImg} alt="Reload" icon={faArrowsRotate}/>
                        Refresh Assessments Data
                    </div>
                    <div className={styles.refreshDesc}>Use this option if there are no changes to the last uploaded
                        template and you would like to refresh the assessment data for existing employees.
                    </div>
                    <button className={styles.stepsBtn}
                            onClick={onRefresh}>
                        Refresh Data
                    </button>
                    <div className={styles.refreshTime}>
                        Last time updated: {lastUpdatePI && formatDate(lastUpdatePI.lastChange)}
                    </div>
                </div>)}
        </>
    )
}

export default RefreshAssessments;
