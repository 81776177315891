import { notification } from 'antd';

export const notificationType = {
  error: 'error',
  warning: 'warning',
  success: 'success',
};

export const openNotificationWithIcon = ({ type = 'success', message = '', description = '', duration = 3, key}) => {
  if(type === notificationType.error) {
    duration = 6;
  }
  notification.config({ duration });
  notification[type]({
    message,
    key,
    description,
    style: {
      top: '10px'
    }
  });
};
