import React, {PureComponent} from 'react';
import CustomModal from "../../../admin-pages/components/CustomModal/CustomModal";
import styles from "./QueueModal.module.scss";

class QueueModal extends PureComponent {
    state ={
    }
    render() {
        const {visible, onCancel, onContinue} = this.props;
        return (
            <CustomModal
                className={styles.modalWrap}
                visible={visible}
                onCancel={onCancel}
            >
                <div className={styles.innerWrap}>
                    <h2 className={styles.title}>There are too many requests to Database at the moment. Your operation may take some time.</h2>
                    <form onSubmit={e => e.preventDefault()}>
                        <div className={styles.btnWrap}>
                            <button
                                className={styles.btnYes}
                                onClick={() => {
                                    onContinue();
                                    onCancel();
                                }}
                            >
                                Continue
                            </button>
                            <button
                                className={styles.btnNo}
                                onClick={onCancel}
                            >
                                Cancel
                            </button>
                        </div>
                    </form>
                </div>

            </CustomModal>
        );
    }
}

export default QueueModal;
