import { createSelector } from 'reselect';

const rootSelector = state => state.auth;

export const selectIsAuth = createSelector(
  [rootSelector],
  state => state.isAuth,
);

export const selectUserRole = createSelector(
  [rootSelector],
  state => state.user.roles,
);

export const selectUser = createSelector(
  [rootSelector],
  state => state.user,
);

export const selectUserTokens = createSelector(
  [rootSelector],
  state => ({
    jwtOldToken: state.user.jwtOldToken,
    jwtToken: state.user.jwtToken,
    userIdShared: state.user.userIdShared
  }),
);

export const selectAuthError = createSelector(
  [rootSelector],
  state => state.error,
);

export const selectUsername = createSelector(
  [rootSelector],
  state => state.user.username,
);
