import React, { PureComponent } from 'react';
import { Table, Tooltip } from 'antd';
import api from '../../../api';
import { connect } from 'react-redux';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPenToSquare, faTrashCan} from "@fortawesome/free-regular-svg-icons";
import {faPlusCircle, faLock, faLockOpen, faKey} from "@fortawesome/free-solid-svg-icons";

import {
  TOKEN_KEY,
  getSortedData,
  openNotificationWithIcon,
  decorateByFilter,
} from '../../../utils';
import {
  getCompaniesManagment,
  getCountries,
  getOrganizationSizes,
  getCompanyIndustries,
  isUpdateCompanies,
} from '../actions';
import { userChangeRole } from '../../pages/Login/actions';

import {
  selectCompaniesData,
  selectIsUpdateCompanies,
  selectCountries,
  selectStates,
  selectOrganizationSizes,
  selectCompanyIndustries,
} from '../selectors';
import { selectUser } from '../../pages/Login/selectors';

import EditCompanySurvey from '../components/EditCompanySurvey/EditCompanySurvey';
import { Loader } from '../../../components';
import { SortIcon } from '../../pages/components';
import { AddCompany, BlockDeleteMethods, CustomModal } from '../components';

import styles from './CompanyManagement.module.scss';
import {faShareNodes} from "@fortawesome/free-solid-svg-icons/faShareNodes";

let popUp_1 = React.createRef();

const actions = [
  {
    img:  <FontAwesomeIcon alt='action unblock' icon={faLockOpen} />,
    title: 'action_unblock',
  },
  {
    img: <FontAwesomeIcon alt='action block' icon={faLock}/>,
    title: 'action_block',
  },
  {
    img: <FontAwesomeIcon alt='action delete' icon={faTrashCan}/>,
    title: 'action_delete',
  },
  {
    img: <FontAwesomeIcon alt='action add' icon={faPlusCircle}/>,
    title: 'action_add',
  },
];

class CompanyManagement extends PureComponent {
  state = {
    currentCompany: {},
    filterName: '',
    isLoading: false,
    modalType: 'none',
    selectedRowKeys: [],
    itemsPerPage: 10,
    currentPage: 1,
    sortType: null,
    sortDirection: null,
    sortColumn: null,
  };

  columns = [
    {
      title: '#',
      dataIndex: 'count',
      align: 'center',
      width: 55,
    },
    {
      title: () => (
        <span className={styles.sortImg}>
          <SortIcon
            text={'Company name'}
            onClick={() => this.sortBy('Alphabetical', 'name')}
            type={
              this.state.sortColumn === 'name' ? this.state.sortDirection : null
            }
          />
        </span>
      ),
      dataIndex: 'name',
      render: (text, record) => (
        <span>
          {decorateByFilter(
            this.state.filterName,
            record.name,
            styles.searchedWord
          )}
        </span>
      ),
    },
    {
      title: () => (
        <span className={styles.sortImg}>
          <SortIcon
            text={'User amount'}
            onClick={() => this.sortBy('Numeric', 'usersCount')}
            type={
              this.state.sortColumn === 'usersCount'
                ? this.state.sortDirection
                : null
            }
          />
        </span>
      ),
      dataIndex: 'usersCount',
      align: 'center',
      width: 100,
      className: `${styles.amount}`,
    },
    {
      title: () => (
        <span className={styles.sortImg}>
          <SortIcon
            text={'Geography'}
            onClick={() => this.sortBy(null, 'countryId')}
            type={
              this.state.sortColumn === 'countryId'
                ? this.state.sortDirection
                : null
            }
          />
        </span>
      ),
      width: 150,
      render: (text, record) => {
        const geography = this.getGeography(record.countryId, record.stateId);

        return (
          <span>
            {decorateByFilter(
              this.state.filterName,
              geography,
              styles.searchedWord
            )}
          </span>
        );
      },
      dataIndex: 'countryId',
      className: `${styles.tdCenter}`,
    },
    {
      title: () => (
        <span className={styles.sortImg}>
          <SortIcon
            text={'Organization size'}
            onClick={() => this.sortBy(null, 'companySizeTierId')}
            type={
              this.state.sortColumn === 'companySizeTierId'
                ? this.state.sortDirection
                : null
            }
          />
        </span>
      ),
      width: 100,
      render: (text, record) => (
        <span>{this.getCompanySize(record.companySizeTierId)}</span>
      ),
      dataIndex: 'companySizeTierId',
      className: `${styles.tdCenter}`,
    },
    {
      title: () => (
        <span className={styles.sortImg}>
          <SortIcon
            text={'Company industry'}
            onClick={() => this.sortBy(null, 'companyIndustryId')}
            type={
              this.state.sortColumn === 'companyIndustryId'
                ? this.state.sortDirection
                : null
            }
          />
        </span>
      ),
      width: 100,
      render: (text, record) => {
        const industry = this.getCompanyIndustry(record.companyIndustryId);

        return (
          <span>
            {decorateByFilter(
              this.state.filterName,
              industry,
              styles.searchedWord
            )}
          </span>
        );
      },
      dataIndex: 'companyIndustryId',
      className: `${styles.tdCenter}`,
    },
    {
      title: 'Actions',
      key: 'action',
      width: 160,
      render: (text, record) => {
        const {
          user: { roles = [] },
        } = this.props;
        const disabled = !record.shareAccess;

        return (
          <div className={styles.actionsTableCol}>
            <Tooltip
              title={
                (roles.includes('ACCOUNT MANAGER') ? 'Show' : 'Edit') +
                ' Company Details'
              }>
              <span
                className={styles.details}
                onClick={() => this.displayModal('details', record)}>
                <FontAwesomeIcon alt='action details' icon={faPenToSquare} />
              </span>
            </Tooltip>
            {!roles.includes('ACCOUNT MANAGER') && (
              <Tooltip title={'API Key Entry'}>
                <span
                  className={styles.details}
                  onClick={() => this.displayModal('editSurvey', record)}>
                  <FontAwesomeIcon alt='action edit surveys' icon={faKey} rotation={45}/>
                </span>
              </Tooltip>
            )}
            <Tooltip title={'Enable Share Access with MW Admins'}>
              <button
                className={`${styles.details} ${
                  disabled ? styles.actionsBtnDisabled : ''
                }`}
                disabled={disabled}
                onClick={() => this.logInAs(record)}>
                <FontAwesomeIcon alt='action logIn' icon={faShareNodes}/>
              </button>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  componentDidMount() {
    const { onUpdateCompanies } = this.props;

    this.getCompanies();
    this.getCompaniesData();
    this.getCountries();
    this.getOrganizationSizes();
    this.getCompanyIndustries();

    onUpdateCompanies(false);
  }

  getCompaniesData = () => {
    this.setState({ isLoading: true });

    api.companies
      .getCompaniesData()
      .finally(() => this.setState({ isLoading: false }));
  };

  getCountries = () => {
    const { onGetCountries } = this.props;

    this.setState({ isLoading: true });

    api.companies.getCountries().then(
      (res) => {
        onGetCountries(res);

        this.setState({ isLoading: false });
      },
      () => this.setState({ isLoading: false })
    );
  };

  getOrganizationSizes = () => {
    const { onGetOrganizationSizes } = this.props;

    this.setState({ isLoading: true });

    api.companies.getOrganizationSizes().then(
      (res) => {
        onGetOrganizationSizes(res);

        this.setState({ isLoading: false });
      },
      () => this.setState({ isLoading: false })
    );
  };

  getCompanyIndustries = () => {
    const { onGetCompanyIndustries } = this.props;

    this.setState({ isLoading: true });

    api.companies.getCompanyIndustries().then(
      (res) => {
        onGetCompanyIndustries(res);

        this.setState({ isLoading: false });
      },
      () => this.setState({ isLoading: false })
    );
  };

  getGeography = (countryId, stateId) => {
    const { countries } = this.props;

    const country = countries.find(
      (country) => country.countryId === +countryId
    );

    if (!country) return '-';

    let state = '';
    if (stateId)
      state = country.stateDtoList.find((state) => state.stateId === +stateId);

    return country.countryName + `${state ? ', ' + state.stateName : ''}`;
  };

  getCompanySize = (id) => {
    const { organizationSizes } = this.props;

    const size = organizationSizes.find((size) => size.id === +id);

    return size ? size.companySizeTier : '-';
  };

  getCompanyIndustry = (id) => {
    const { companyIndustries } = this.props;

    const industry = companyIndustries.find((industry) => industry.id === +id);

    return industry ? industry.industryName : '-';
  };

  logInAs = (record) => {
    const {
      loginAs,
      user: { roles = [] },
    } = this.props;

    api.companies
      .shareAccess(record.id)
      .then((data) => ({
        jwt: data.jwtTokenShareAccess,
        roles: [
          roles.includes('ACCOUNT MANAGER')
            ? 'ACCOUNT OWNER/MANAGER'
            : 'ACCOUNT OWNER SHARED',
        ],
        userId: data.userId,
      }))
      .then((data) => {
        localStorage.setItem(TOKEN_KEY, data.jwt);
        localStorage.setItem('sharedCompanyId', record.id);

        return loginAs(data);
      })
      .catch(console.log);
  };

  getCompanies = () => {
    const { onGetUsersManagment } = this.props;
    this.setState({ isLoading: true });
    api.companies.getCompanies().then(
      (res) => {
        let count = 1;
        const resFormatter = res.map((item) => {
          item.count = count;
          count++;
          return item;
        });
        onGetUsersManagment(resFormatter);
        this.setState({ isLoading: false });
      },
      () => this.setState({ isLoading: false })
    );
  };

  handleMethod = (type) => {
    const { selectedRowKeys } = this.state;
    const companies = selectedRowKeys.length > 1 ? 'Companies' : 'Company';
    this.setState({ isLoading: true });

    const getSuccessTitle = (type) => {
      switch (type) {
        case 'block':
          return `${companies} was successfully blocked`;
        case 'unblock':
          return `${companies} was successfully unblocked`;
        case 'delete':
          return `${companies} was successfully deleteded`;
        default:
          return;
      }
    };

    const getCurrentMethod = (type, data) => {
      switch (type) {
        case 'block':
          return api.companies.blockCompany(data);
        case 'unblock':
          return api.companies.unblockCompany(data);
        case 'delete':
          return api.companies.deleteCompany(data);
        default:
          return;
      }
    };

    getCurrentMethod(type, selectedRowKeys)
      .then(() => {
        this.setState({ selectedRowKeys: [] });
        this.closeModal(true);
        this.requestNotification(getSuccessTitle(type));
      })
      .catch((res) => this.requestNotification(res.message, false));
  };

  requestNotification = (message, success = true) => {
    const notificationSettings = {
      type: success ? 'success' : 'error',
      message: message,
    };
    openNotificationWithIcon(notificationSettings);
    this.setState({ isLoading: false });
  };

  onSelectChange = (selectedRowKeys) => this.setState({ selectedRowKeys });

  onSearchName = (e) => {
    if (e.target.value.length > 0) {
      this.setState({ filterName: e.target.value.toLowerCase() });
    } else {
      this.setState({ filterName: '' });
    }
  };

  displayModal = (type, record = {}) =>
    this.setState({ modalType: type, currentCompany: record });

  getModalBlock = () => {
    const { modalType, currentCompany, selectedRowKeys } = this.state;
    const companies = selectedRowKeys.length > 1 ? 'companies' : 'company';

    switch (modalType) {
      case 'add':
        return (
          <AddCompany
            modalClose={this.closeModal}
            isNotAccountOwner
            currentCompany={currentCompany}
            sendNotification={this.requestNotification}
            onRef={(ref) => (popUp_1 = ref)}
            isMainAdmin
          />
        );
      case 'editSurvey':
        return (
          <EditCompanySurvey
            modalClose={this.closeModal}
            currentCompany={currentCompany}
            sendNotification={this.requestNotification}
            onRef={(ref) => (popUp_1 = ref)}
          />
        );
      case 'block':
        return (
          <BlockDeleteMethods
            modalClose={this.closeModal}
            onYes={() => this.handleMethod('block')}
            title={`Are you sure you want to block ${companies}?`}
          />
        );
      case 'delete':
        return (
          <BlockDeleteMethods
            modalClose={this.closeModal}
            onYes={() => this.handleMethod('delete')}
            title={`Are you sure you want to delete ${companies}?`}
          />
        );
      case 'unblock':
        return (
          <BlockDeleteMethods
            modalClose={this.closeModal}
            onYes={() => this.handleMethod('unblock')}
            title={`Are you sure you want to unblock ${companies}?`}
          />
        );
      case 'details':
        return (
          <AddCompany
            modalClose={this.closeModal}
            currentCompany={currentCompany}
            sendNotification={this.requestNotification}
            isNotAccountOwner
            onRef={(ref) => (popUp_1 = ref)}
            isDetails
            isMainAdmin
          />
        );
      default:
        return null;
    }
  };

  closeModal = (isShouldUpdate = false) => {
    this.setState({ modalType: 'none' });
    if (isShouldUpdate) {
      this.getCompanies();
    }
  };

  actionsClick = (type) => {
    switch (type) {
      case 'action_block':
        return this.displayModal('block');
      case 'action_unblock':
        return this.displayModal('unblock');
      case 'action_delete':
        return this.displayModal('delete');
      case 'action_add':
        return this.displayModal('add');
      default:
        return;
    }
  };

  actionsTooltipText = (type) => {
    switch (type) {
      case 'action_block':
        return 'Lock Company Account';
      case 'action_unblock':
        return 'Unlock Company Account';
      case 'action_delete':
        return 'Delete Company Account';
      case 'action_add':
        return 'Create New Client Company';
      default:
        return '';
    }
  };

  closePopUp = () => {
    const { modalType } = this.state;
    const popUpsTypesWithoutRef = ['block', 'delete', 'unblock'];
    const isFindPopUpWithoutRed = popUpsTypesWithoutRef.find(
      (item) => item === modalType
    );
    if (isFindPopUpWithoutRed) {
      this.setState({ modalType: 'none' });
    } else {
      popUp_1.onCancelHandle();
    }
  };

  sortBy = (type, sortColumn) => {
    const { sortType, sortDirection } = this.state;

    this.setState({ sortColumn });

    if (type === sortType) {
      if (sortDirection === 'topSort') {
        return this.setState({ sortDirection: 'downSort' });
      } else if (sortDirection === 'downSort') {
        return this.setState({ sortDirection: null });
      }

      return this.setState({ sortDirection: 'topSort' });
    } else {
      return this.setState({ sortDirection: 'topSort', sortType: type });
    }
  };

  sortTable = (data) => {
    const { sortType, sortDirection, sortColumn } = this.state;

    switch (sortColumn) {
      case 'countryId':
        return this.sortByGeography(data);
      case 'companySizeTierId':
        return this.sortBySize(data);
      case 'companyIndustryId':
        return this.sortByIndustry(data);
      default:
        return getSortedData({ data, sortType, sortDirection, sortColumn });
    }
  };

  sortByGeography = (data) => {
    const { sortDirection } = this.state;

    const sortedData = [...data].sort((a, b) => {
      const geographyA = this.getGeography(a.countryId, a.stateId);
      const geographyB = this.getGeography(b.countryId, b.stateId);

      if (!a.countryId || !b.countryId) {
        return !a.countryId ? 1 : -1;
      } else {
        return geographyA > geographyB ? 1 : -1;
      }
    });

    return sortDirection === 'topSort' ? sortedData.reverse() : sortedData;
  };

  sortBySize = (data) => {
    const { sortDirection } = this.state;

    const sortedData = [...data].sort((a, b) => {
      const сompanySizeA = this.getCompanySize(a.companySizeTierId);
      const сompanySizeB = this.getCompanySize(b.companySizeTierId);

      if (!a.companySizeTierId || !b.companySizeTierId) {
        return !a.companySizeTierId ? 1 : -1;
      } else {
        const numA = +сompanySizeA.substr(
          0,
          сompanySizeA.indexOf(сompanySizeA.includes('+') ? '+' : '-')
        );
        const numB = +сompanySizeB.substr(
          0,
          сompanySizeB.indexOf(сompanySizeB.includes('+') ? '+' : '-')
        );

        return numA > numB ? 1 : -1;
      }
    });

    return sortDirection === 'topSort' ? sortedData.reverse() : sortedData;
  };

  sortByIndustry = (data) => {
    const { sortDirection } = this.state;

    const sortedData = [...data].sort((a, b) => {
      const сompanyIndustryA = this.getCompanyIndustry(a.companyIndustryId);
      const сompanyIndustryB = this.getCompanyIndustry(b.companyIndustryId);

      if (!a.companyIndustryId || !b.companyIndustryId) {
        return !a.companyIndustryId ? 1 : -1;
      } else {
        return сompanyIndustryA > сompanyIndustryB ? 1 : -1;
      }
    });

    return sortDirection === 'topSort' ? sortedData.reverse() : sortedData;
  };

  getFilteredCompanies = (companies) => {
    const { filterName } = this.state;

    return companies.filter(
      ({ name: companyName, countryId, stateId, companyIndustryId }) => {
        const name = companyName.toLowerCase();
        const geography = countryId
          ? this.getGeography(countryId, stateId).toLowerCase()
          : '';
        const industry = companyIndustryId
          ? this.getCompanyIndustry(companyIndustryId).toLowerCase()
          : '';

        return (
          name.includes(filterName) ||
          geography.includes(filterName) ||
          industry.includes(filterName)
        );
      }
    );
  };

  render() {
    const {
      isLoading,
      selectedRowKeys,
      modalType,
      itemsPerPage,
      currentPage,
      filterName,
    } = this.state;
    const {
      companies,
      user: { roles = [] },
    } = this.props;
    let sortedCompanies = this.sortTable(companies);

    const isAccountManager = roles.includes('ACCOUNT MANAGER');

    const rowSelection = {
      selectedRowKeys,
      hideDefaultSelections: true,
      onChange: this.onSelectChange,
      getCheckboxProps: () => ({
        disabled: isAccountManager,
      }),
    };

    if (filterName) {
      sortedCompanies = this.getFilteredCompanies(sortedCompanies);
    }

    return (
      <>
        <section className={styles.wrapper}>
          <h2 className={styles.title}>Company management</h2>
          <div className={styles.optionsWrap}>
            <div className={styles.searchWrap}>
              <input onChange={this.onSearchName} placeholder={'Search'} />
            </div>
            {!isAccountManager && (
              <div className={styles.actionsWrap}>
                {actions.map(({ img, title }, i) => {
                  const disabledBtns =
                    title === 'action_block' ||
                    title === 'action_unblock' ||
                    title === 'action_delete';
                  const disabled = disabledBtns && selectedRowKeys.length === 0;

                  return (
                    <Tooltip key={i} title={this.actionsTooltipText(title)}>
                      <div  className={` ${
                          disabled ? styles.actionsBtnWrapDisabled : styles.actionsBtnWrap
                        }`}>
                        <button
                          className={`${styles.actionsBtn} ${
                            disabled ? styles.actionsBtnDisabled : ''
                          }`}
                          onClick={() => this.actionsClick(title)}
                          key={i}>
                          {img}
                        </button>
                      </div>
                    </Tooltip>
                  );
                })}
              </div>
            )}
          </div>
          <div className={styles.tableWrap}>
            <Table
              rowSelection={rowSelection}
              columns={this.columns}
              dataSource={sortedCompanies}
              bordered
              rowKey='id'
              rowClassName={(record) =>
                record.active === false && `${styles.blocked}`
              }
              pagination={{
                pageSizeOptions: ['5', '10', '20', '30'],
                onChange: (current) => this.setState({ currentPage: current }),
                onShowSizeChange: (current, size) =>
                  this.setState({ itemsPerPage: size, currentPage: current }),
                showTotal: (total) =>
                  `${
                    Math.ceil(total / itemsPerPage) < currentPage
                      ? Math.ceil(total / itemsPerPage)
                      : currentPage
                  } of ${Math.ceil(total / itemsPerPage)}`,
                defaultCurrent: 1,
                showSizeChanger: true,
                showTitle: false,
                locale: { items_per_page: '' },
              }}
            />
          </div>
          <CustomModal
            visible={modalType !== 'none'}
            onCancel={this.closePopUp}
            className={styles.modalWrap}>
            {this.getModalBlock()}
          </CustomModal>
        </section>
        {isLoading && <Loader />}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  companies: selectCompaniesData(state),
  isNeedUpdateCompanies: selectIsUpdateCompanies(state),
  countries: selectCountries(state),
  states: selectStates(state),
  organizationSizes: selectOrganizationSizes(state),
  companyIndustries: selectCompanyIndustries(state),
  user: selectUser(state),
});

const mapDispatchToProps = (dispatch) => ({
  loginAs: (data) => dispatch(userChangeRole(data)),
  onGetUsersManagment: (data) => dispatch(getCompaniesManagment(data)),
  onGetCountries: (data) => dispatch(getCountries(data)),
  onGetOrganizationSizes: (data) => dispatch(getOrganizationSizes(data)),
  onGetCompanyIndustries: (data) => dispatch(getCompanyIndustries(data)),
  onUpdateCompanies: (data) => dispatch(isUpdateCompanies(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyManagement);
