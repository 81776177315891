import { getEmpStatusValues } from './getEmpStatusValues';

export const getFiltersParams = (currentFilter, withDisplayBlocks = false) => {
  const filtersParams = {
    divisionNames: currentFilter?.currentFilterLinkData.performanceFilters.filterDivisions || [],
    empStatuses: getEmpStatusValues(currentFilter?.currentFilterLinkData.performanceFilters.filterEmploymentsStatus || []),
    factorComboMatchColors: currentFilter?.currentFilterLinkData.performanceFilters.filterFactorComboMatch || [],
    currentSkill1stScoreColors: currentFilter?.currentFilterLinkData.performanceFilters.filterFirstScore || [],
    currentSkill1OverallScoreColors: currentFilter?.currentFilterLinkData.performanceFilters.filterOverallScore || [],
    jobNames:currentFilter?.currentFilterLinkData.performanceFilters.filterJobs || [],
    hasCognitive:currentFilter?.currentFilterLinkData.performanceFilters.filterCognitive || currentFilter?.currentFilterLinkData.performanceFilters.hasCognitive || [],
    ratingNames: currentFilter?.currentFilterLinkData.performanceFilters.filterRating || [],
    performanceNames: currentFilter?.currentFilterLinkData.performanceFilters.filterPerformance || [],
    potentialNames: currentFilter?.currentFilterLinkData.performanceFilters.filterPotential || [],
    readinessPeriods: currentFilter?.currentFilterLinkData.performanceFilters.filterReadiness || [],
    locationNames: currentFilter?.currentFilterLinkData.performanceFilters.filterLocation || [],
    tenurePeriods: currentFilter?.currentFilterLinkData.performanceFilters.filterTenure || [],
  };
  const displayBlocksParams = {
    behavioralPerformance: !!currentFilter?.currentFilterLinkData.performanceFilters.displayBlocks.behavioralPerformance,
    behavioralSuccession: !!currentFilter?.currentFilterLinkData.performanceFilters.displayBlocks.behavioralSuccession,
    cognitive: !!currentFilter?.currentFilterLinkData.performanceFilters.displayBlocks.cognitive,
    competencies: !!currentFilter?.currentFilterLinkData.performanceFilters.displayBlocks.competencies,
    currentSkills: !!currentFilter?.currentFilterLinkData.performanceFilters.displayBlocks.currentSkills,
    customSkills: !!currentFilter?.currentFilterLinkData.performanceFilters.displayBlocks.customSkills,
    employmentStatus: !!currentFilter?.currentFilterLinkData.performanceFilters.displayBlocks.employmentStatus,
    nineBox: !!currentFilter?.currentFilterLinkData.performanceFilters.displayBlocks.nineBox,
    organizationalData: !!currentFilter?.currentFilterLinkData.performanceFilters.displayBlocks.organizationalData,
    performance: !!currentFilter?.currentFilterLinkData.performanceFilters.displayBlocks.performance,
  };

  return withDisplayBlocks ? { ...filtersParams, displayBlocks: displayBlocksParams } : filtersParams;
}
