import React from "react";
import { NavLink, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Menu, Dropdown, Radio } from "antd";
import { compose } from "recompose";
import { createForm } from "rc-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

import Logo from "../../images/logo.png";

import { COPYRIGHT } from "../constants";

import { TOKEN_KEY, openNotificationWithIcon } from "../../utils";
import api from "../../api";
import { browserHistory } from "../../browserHistory";
import { logoutUser, userGetBackRole } from "../../redux/pages/Login/actions";
import {
  selectUserRole,
  selectUserTokens,
  selectUsername,
} from "../../redux/pages/Login/selectors";

import {
  CustomModal,
  AreYouWantToCancel,
} from "../../redux/admin-pages/components";
import RefreshAssessments from "../../redux/pages/components/RefreshAssessments/RefreshAssessments";

import HeaderFormInner from "./HeaderFormInner";
import styles from "./Header.module.scss";

const userData = [
  {
    to: "/",
    exact: true,
    text: "HOME",
  },
  {
    text: "Dashboards",
    to: "/dashboards",
  },
  // {
  //   to: '/leadership',
  //   exact: true,
  //   text: 'LEADERSHIP',
  // },
  {
    to: "/help",
    exact: true,
    text: "HELP",
  },
];

const adminData = [
  {
    to: "/companyManagement",
    exact: true,
    text: "Company Management",
  },
  {
    to: "/userManagement",
    exact: true,
    text: "User Management",
  },
];

const moderatorData = [
  {
    to: "/",
    exact: true,
    text: "HOME",
  },
  {
    text: "Dashboards",
    to: "/dashboards",
  },
  {
    to: "/companyDetails",
    exact: true,
    text: "Company Details",
  },
  {
    to: "/userManagement",
    exact: true,
    text: "User Management",
  },
  {
    to: "/help",
    exact: true,
    text: "HELP",
  },
];

const Header = ({
  roles,
  onlogoutUser,
  onHelpPopUpOpen,
  onlyShow,
  type,
  logBack,
  onUpdatePerfomancePage,
  onUpdateSuccessionPage,
  tokens,
  form,
  username,
}) => {
  const isAdmin = roles.includes("ADMIN") || roles.includes("ACCOUNT MANAGER");
  const isAccountOwner = roles.includes("ACCOUNT OWNER");
  const isAccountOwnerShared = roles.includes("ACCOUNT OWNER SHARED");
  const isAccountOwnerManager = roles.includes("ACCOUNT OWNER/MANAGER");
  const isUser = roles.includes("USER");
  const data = isAdmin
    ? adminData
    : isAccountOwner || isAccountOwnerShared || isAccountOwnerManager
    ? moderatorData
    : userData;

  const [companyName, setCompanyName] = React.useState("*********");
  const [showValidation, setShowValidation] = React.useState(false);
  const [focusedInputName, setFocusedInputName] = React.useState("");
  const [isPasswordModalOpen, setIsPasswordModalOpen] = React.useState(false);
  const [textError, setTextError] = React.useState("");
  const [isCancelModalOpen, setIsCancelModalOpen] = React.useState(false);

  React.useEffect(() => {
    if (isAccountOwnerShared || isAccountOwnerManager) {
      api.companies
        .getCompanies()
        .then((comp) => setCompanyName(comp[0]?.name));
    }
  }, [isAccountOwnerShared, isAccountOwnerManager]);

  const handleClick = (e, path) => {
    window.scrollTo(0, 0);
    if (path === "/help") {
      e.preventDefault();
      onHelpPopUpOpen();
    }
  };

  const getBackToAdmin = () => {
    const { userIdShared, jwtOldToken, jwtToken } = tokens;
    api.users
      .deleteShareAccess({
        userId: userIdShared,
        jwtTokenShareAccess: jwtToken,
      })
      .then(() => {
        localStorage.setItem(TOKEN_KEY, jwtOldToken);
        localStorage.removeItem("sharedCompanyId");
        logBack({
          roles: [isAccountOwnerManager ? "ACCOUNT MANAGER" : "ADMIN"],
        });
        browserHistory.push("/companyManagement");
      })
      .catch(console.log);
  };

  const onSubmit = (e) => {
    const formData = form.getFieldsValue();
    e.preventDefault();
    setShowValidation(true);

    const data = {
      email: username,
      currentPassword: formData.currentPassword,
      newPassword: formData.newPassword,
      passConfirm: formData.confirmNewPassword,
    };

    form.validateFields((err) => {
      if (!err) {
        api.users
          .changePassword(data)
          .then(() => {
            setIsPasswordModalOpen(false);
            openNotificationWithIcon({
              type: "success",
              message: "Password was successfully set",
            });
          })
          .catch((err) => setTextError(err.response.data));
      }
    });
  };

  const onInputChange = () => {
    setShowValidation(false);
    setTextError("");
  };

  const onCancel = () => {
    const { confirmNewPassword, currentPassword, newPassword } =
      form.getFieldsValue();
    const isData = Boolean(
      currentPassword || newPassword || confirmNewPassword
    );
    if (isData) {
      setIsCancelModalOpen(true);
    } else {
      setIsPasswordModalOpen(false);
      setTextError("");
    }
  };

  const onCloseAllModals = () => {
    setIsCancelModalOpen(false);
    setIsPasswordModalOpen(false);
    setTextError("");
  };

  const menu = (
    <Menu getPopupContainer={(trigger) => trigger.parentNode}>
      <Menu.Item key="1">
        <button
          className={`${styles.link} ${styles.linkMenu} ${styles.logout}`}
          onClick={() => setIsPasswordModalOpen(true)}
        >
          Change password
        </button>
      </Menu.Item>
      {(isAccountOwner || isUser || isAccountOwnerShared) &&
        !roles.includes("ADMIN") && (
          <Menu.Item key="2">
            <RefreshAssessments
              asMenuItem
              className={`${styles.link} ${styles.linkMenu} ${styles.logout}`}
            />
          </Menu.Item>
        )}

      <Menu.Item key="3">
        <button
          className={`${styles.link} ${styles.linkMenu} ${styles.logout}`}
          onClick={() => onlogoutUser(tokens.userIdShared)}
        >
          Sign out
        </button>
      </Menu.Item>
    </Menu>
  );

  const navLink = (to, exact, text) => (
    <NavLink
      key={text}
      to={to}
      exact={exact}
      onClick={(e) => handleClick(e, to)}
      className={styles.link}
      activeClassName={styles.linkActive}
    >
      {text}
    </NavLink>
  );

  const dropdown = (text, menu) => (
    <Dropdown
      key={text}
      overlay={menu}
      trigger={["click"]}
      getPopupContainer={(trigger) => trigger.parentNode}
    >
      <div className={`${styles.link}`}>
        {text}
        <span>
          <FontAwesomeIcon className={styles.menuIcon} icon={faChevronDown} />
        </span>
      </div>
    </Dropdown>
  );

  return (
    <>
      <header>
        <div className={styles.logoWrapper}>
        {onlyShow ? (
          <img src={Logo} width='205' alt='MindWire' />
        ) : (
          <Link
            to={isAdmin ? '/companyManagement' : '/'}
            className={styles.logo}>
            <img src={Logo} width='205' alt='MindWire' />
          </Link>
        )}
          <p className={styles.logoCopyright}>{COPYRIGHT}</p>
        </div>
        <div className={styles.links}>
          <nav>
            {data.map(({ to, exact, text, children }, i) =>
              onlyShow ? (
                <div
                  key={i}
                  className={`${styles.link} ${
                    to === type && styles.linkActive
                  }`}
                >
                  {text}
                </div>
              ) : children ? (
                dropdown(
                  text,
                  <Menu>
                    {children.map(({ to, exact, text }) => (
                      <Menu.Item key={text}>
                        {navLink(to, exact, text)}
                      </Menu.Item>
                    ))}
                  </Menu>
                )
              ) : (
                navLink(to, exact, text)
              )
            )}
          </nav>
          {/* {onlyShow ? (
            <div className={`${styles.link}`}>Sign out</div>
          ) : (
            <button className={`${styles.link} ${styles.logout}`} onClick={() => onlogoutUser()}>Sign out</button>
          )} */}
          {onlyShow ? (
            <div className={`${styles.link}`}>
              MENU
              <span>
                <FontAwesomeIcon
                  className={styles.menuIcon}
                  icon={faChevronDown}
                />
              </span>
            </div>
          ) : (
            dropdown("MENU", menu)
          )}
        </div>
      </header>
      {(isAccountOwnerShared || isAccountOwnerManager) && (
        <div className={styles.infoWhenShared}>
          <span
            className={styles.companyName}
          >{`${companyName} account `}</span>
          <button className={styles.footerButton} onClick={getBackToAdmin}>
            <div className={styles.centeredText}>
              <span>back to Admin Panel</span>
            </div>
          </button>
        </div>
      )}
      <CustomModal
        visible={isPasswordModalOpen}
        onCancel={onCancel}
        className={styles.modalWrap}
      >
        <div className={styles.innerWrap}>
          <div className={styles.content}>
            <h2 className={styles.title}>Change Password</h2>
            <div className={styles.subTitle}>
              <span>Password must be:</span>
              <ul>
                <li>at least one capital letter</li>
                <li>at least one digit</li>
                <li>minimum 10 characters</li>
              </ul>
            </div>
            <form onSubmit={onSubmit}>
              <HeaderFormInner
                form={form}
                showValidation={showValidation}
                focusedInputName={focusedInputName}
                onInputChange={onInputChange}
                onFocus={(inputName) => setFocusedInputName(inputName)}
                onBlur={() => setFocusedInputName("")}
              />
              <p className={styles.errorText}>{textError}</p>
              <div className={styles.btnWrapper}>
                <button className={styles.stepsBtn}>Save</button>
                <button
                  type="button"
                  onClick={onCancel}
                  className={styles.stepsBtn}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </CustomModal>
      <CustomModal visible={isCancelModalOpen} className={styles.modalWrap}>
        <AreYouWantToCancel
          onNo={() => setIsCancelModalOpen(false)}
          onYes={onCloseAllModals}
        />
        ;
      </CustomModal>
    </>
  );
};

const mapStateToProps = (state) => ({
  roles: selectUserRole(state),
  tokens: selectUserTokens(state),
  username: selectUsername(state),
});

const mapDispatchToProps = (dispatch) => ({
  onlogoutUser: (id) => dispatch(logoutUser(id)),
  logBack: (data) => dispatch(userGetBackRole(data))
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  createForm()
)(Header);
