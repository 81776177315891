import {
  CLEAR_EMPLOYEES_SUCCESS_VIEW,
  GET_EMPLOYEES_FORMAT_VIEW,
  GET_EMPLOYEES_SUCCESS_VIEW,
  LAST_UPDATE_CLEAR_VIEW,
  LAST_UPDATE_SUCCESS_VIEW,
} from '../../../constants/constants';

export const getEmployees = data => ({
  type: GET_EMPLOYEES_SUCCESS_VIEW,
  data,
});

export const getEmployeesFormat = data => ({
  type: GET_EMPLOYEES_FORMAT_VIEW,
  data,
});

export const clearEmployees = data => ({
  type: CLEAR_EMPLOYEES_SUCCESS_VIEW,
  data,
});

export const lastUpdateData = data => ({
  type: LAST_UPDATE_SUCCESS_VIEW,
  data,
});

export const lastUpdateDataClear = () => ({
  type: LAST_UPDATE_CLEAR_VIEW,
});
