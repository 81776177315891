import React from 'react';

import styles from './IconButton.module.scss';

const IconButton = ({ icon, className = '', onClick, ...props }) => (
  <button
    className={`${styles.iconButton} ${className}`}
    onClick={onClick}
    type="button"
    {...props}
  >
    {icon}
  </button>
);

export default IconButton;
