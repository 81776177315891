import React, { PureComponent } from 'react';
import { checkUserPasswordPattern } from '../../../utils'

import { FormInput } from '../../../components';
class ConfirmationFormInner extends PureComponent {

  validatePassword = (rule, value, callback) => {
    if (value && !checkUserPasswordPattern(value)) {
      callback('The entered password doesn’t match password policies');
    }
    callback();
  };

  comparePassword = (rule, value, callback) => {
    const { form } = this.props;
    if (form.getFieldValue('password') !== form.getFieldValue('confirmPassword')) {
      callback('Passwords don’t match');
    }
    callback();
  };

  render() {
    const { form, showValidation, focusedInputName, onFocus, onBlur, onInputChange } = this.props;
    return (
      <>
        {form.getFieldDecorator('password', {
          initialValue: '',
          validateTrigger: ['onSubmit'],
          rules: [
            {
              required: true,
              message: <span key="passwordError">Please enter your password</span>,
            },
            {
              validator: this.validatePassword,
            }
          ],
        })(
          <FormInput
            type="password"
            label={'Password'}
            error={showValidation && form.getFieldError('password')}
            onFocus={() => onFocus('password')}
            onBlur={onBlur}
            onChange={onInputChange}
            isFocusedInput={focusedInputName === 'password'}
          />,
        )}
        {form.getFieldDecorator('confirmPassword', {
          initialValue: '',
          validateTrigger: ['onSubmit'],
          rules: [
            {
              required: true,
              message: <span key="confirmPasswordError">Please confirm your password</span>,
            },
            {
              validator: this.comparePassword,
            }
          ],
        })(
          <FormInput
            type="password"
            label={'Confirm Password'}
            onChange={onInputChange}
            onFocus={() => onFocus('confirmPassword')}
            onBlur={onBlur}
            isFocusedInput={focusedInputName === 'confirmPassword'}
            error={showValidation && form.getFieldError('confirmPassword')}
          />,
        )}
      </>
    );
  }
}

export default ConfirmationFormInner;
