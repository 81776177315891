export const behavioralTooltip = {
  'low': {
    'A': {
      title: 'Harmony.',
      text: 'Encouragement, freedom from conflict, opportunities to work collaboratively, team focused.',
    },
    'B': {
      title: 'Privacy. ',
      text: 'Freedom from office politics, stick to facts and substance/less small talk, time and “room” to think.',
    },
    'C': {
      title: 'Action.',
      text: 'Variety, freedom from routine, productive response to pressure, multiple priorities. Bulleted, short, action-oriented communications, keep them busy.',
    },
    'D': {
      title: 'Freedom from Structure.',
      text: 'Flexibility, spontaneity, opportunity to figure out how to “get there”, ability to delegate details.',
    },
  },
  'high': {
    'A': {
      title: 'Outcomes.',
      text: 'Independence, to be challenged, to decide, competition, control. Motivated by winning and (avoiding) losing.',
    },
    'B': {
      title: 'Inclusion.',
      text: 'Opportunities to interact with, influence others, think out loud, be in on things, have their opinion sought, appreciation for how they do things with others.',
    },
    'C': {
      title: 'Stability.',
      text: 'Time to think and plan before speaking, acting, deciding. Appreciation for loyalty and dependability. To understand why and have time to prepare for change. Pressure makes them less productive.',
    },
    'D': {
      title: 'No Mistakes.',
      text: 'Depth and detail of what is correct/right/expected. Regular feedback, chance to build in-depth job expertise. Precise, written instructions, formal training, structure.',
    },
  },
};

export const cognitiveTooltip = {
  '1': 'Likely a performance asset when it comes to new/different. Also more likely to overcome knowledge, skill, experience gaps quickly.',
  '2': 'Less likely to be a performance asset when it comes to new/different. May require more time, structure, reinforcement on new/different.',
  '3': 'More likely to have a negative impact on performance, when it comes to new/different. May need significant time, support, structure, reinforcement on new/different.',
};

export const consultativeTooltip = {
  '1': 'Likely many/most areas are an asset to the person’s performance.',
  '2': 'Likely some areas of strength and areas for real improvement.',
  '3': 'Likely that many/most areas could improve significantly.',
};

export const factorTooltip = {
  'A/B': {
    title: 'Task Oriented.',
    text: 'Critical thinker, creative problem solver, analytical, adept at making things more efficient and/or effective, direct, even blunt communications, less delegation of authority.',
  },
  'B/A': {
    title: 'People Oriented.',
    text: 'Coaches, teaches, shares information, seeks input. Delegates authority to others, collaborative, persuasive and looks to build buy-in and consensus.',
  },
  'A/C': {
    title: 'Proactive.',
    text: 'Takes initiative, driven to take action, gets things started, pushes self and others, ambitious, impatient with routine, productive response to pressure, change agent. Listens sparingly.',
  },
  'C/A': {
    title: 'Responsive.',
    text: 'Listens, thinks, plans before taking action. Cooperative, tolerant, dependable, persistent, methodical and steady. Pressure makes this person less productive. Excellent listeners.',
  },
  'A/D': {
    title: 'Risk Tolerant.',
    text: 'Independent, decisive, confident, outcome wins over perfection, risk is opportunity, venturesome, focused on big picture.',
  },
  'D/A': {
    title: 'Seeks Certainty.',
    text: 'Before they decide, act, speak want to be sure it is correct. Cooperative with the way the organization does things. Likes rules and structure, careful, conservative, views risk as a potential problem.',
  },
  'B/C': {
    title: 'Quick to Connect.',
    text: 'When meeting/engaging with others: positive, lively, enthusiastic, optimistic style of expression, motivating, persuasive.',
  },
  'C/B': {
    title: 'Takes Time to Connect.',
    text: 'When meeting/engaging with others: Reserved, introspective, thinks before responding, some initial skepticism of others, trust is built with actions over time, more comfortable with familiar.',
  },
  'B/D': {
    title: 'People over Rules.',
    text: 'Persuasive, enthusiastic communicator, inspires and motivates, share ideas out loud as they occur to the person, flexible and adaptable approach to the process and people. Readily delegates tasks to others.',
  },
  'D/B': {
    title: 'Rules over People.',
    text: 'Structured, thoughtful, purposeful approach to the work. Where there isn’t structure they will create it and then follow it. Sincere, disciplined, factual thorough communications. Few or no exceptions to the “rules”. Holds control of details and process tightly, reluctant to delegate tasks until trust is earned.',
  },
  'C/D': {
    title: 'Innovative & Unconventional.',
    text: 'Creative, innovative approach. Comfortable with ambiguity and finding new and novel ways to do things, tolerant of some failures/set-backs along the way. Calm and level-headed, especially in the face of crisis. Persistent--won’t take no for an answer. Deadlines and exact rules are negotiable.',
  },
  'D/C': {
    title: 'Follows Up & Through.',
    text: 'Thorough with strong follow up and follow through to completion, comfortable with clarity, has multiple contingency plans, more literal about deadlines and “rules” and getting things right.',
  },
  '1': {
    title: 'Green:',
    text: 'Has most (or all) of the most important behaviors the job requires.  Likely that this person will be naturally good at and motivated by the key behaviors the job requires.',
  },
  '2': {
    title: 'Yellow:',
    text: 'Has (and is also missing) about half of the most important behaviors the job requires. It’s likely this person will need to consistently and successfully change those “missing” behaviors and rely on other elements (cognitive, knowledge, skills, experience, etc.) to be successful with the behavioral requirements of the job.',
  },
  '3': {
    title: 'Gray:',
    text: 'Has very few (or none) of the most important behaviors the job requires.  Will likely need to make significant, lasting, and often difficult changes across many natural behaviors and rely heavily on other elements (cognitive, knowledge, skills, experience, etc.) to be successful with the behavioral requirements of the job.',
  },
};

export const headerTooltip = {
  'behavioralJob' : 'Job-Specific, customized standard of excellence for the behaviors required for the role.',
  'cognitive' : 'Reflects how quickly and proficiently a person learns and incorporates new and different. (Not related to what a person already knows.',
  'cognitiveJob' : 'Job-Specific, customized target score needed for the cognitive demands of this role.',
  'consultativeSkill' : 'A snapshot of a person’s current skill. Skills are variable--anyone can learn, practice and improve.',
  'empStatusText' : ['Employment Status', 'Green: Active Employee', 'Gray: Inactive Employee', 'Orange: Applicant'],
  'factorCombinations' : 'The behaviors resulting from the interaction of two factors.',
  'firstScoreText' : '1st Score indicates the total score an assessment taker received upon completion of their first SSAT or ISAT assessment.',
  'overalScoreText' : 'Overall score indicates the total score an assessment taker received upon completion of their most recent SSAT or ISAT assessment.',
  'pattern' : 'Reflects a person’s natural, hard-wired strengths, behaviors, and needs.',
};

export const plansData = {
  'L' : 'Low',
  'S' : 'Effective',
  'H' : 'High',
};
