import React from 'react';
import styles from './SortingTitle.module.scss';
import { SortIcon } from '../../../redux/pages/components';

export const SortingTitle = ({
  name,
  text,
  sortBy,
  sortType,
  sortDirection,
  Component = null,
  onlyView
}) => (
  <span className={styles.sortImg}>
    <SortIcon
      Component={Component}
      text={text}
      name={name}
      onClick={sortBy}
      onlyView={onlyView}
      type={
        sortType === name
          ? sortDirection
          : null
      }
    />
  </span>
);
