import React from 'react';
import { checkEmailPattern, checkPhonePattern } from '../../../../utils';

export const AddUserWithPopupTemplateHOC = (WrappedComponent) => (props) => {
  let formInput_1 = React.createRef();
  let formInput_2 = React.createRef();
  let formInput_3 = React.createRef();
  let formInput_4 = React.createRef();

  const validatePhone = (rule, value, callback) => {
    if (value && !checkPhonePattern(value)) {
      callback('Invalid phone number');
    }
    callback();
  };

  const validateEmail = (rule, value, callback) => {
    if (value && !checkEmailPattern(value)) {
      callback('Invalid email');
    }
    callback();
  };

  const generalData = [
    {
      fieldName: 'lastName',
      ref: formInput_1,
      label: 'Last Name*',
      rules: [
        {
          message: ' ',
          required: true,
        },
        {
          max: 250,
          message: ' ',
        },
      ],
    },
    {
      fieldName: 'firstName',
      ref: formInput_2,
      label: 'First Name*',
      rules: [
        {
          message: ' ',
          required: true,
        },
        {
          max: 250,
          message: ' ',
        },
      ],
    },
    {
      fieldName: 'email',
      ref: formInput_3,
      label: 'Email / Login*',
      validateTrigger: ['onSubmit'],
      rules: [
        {
          message: ' ',
          required: true,
          type: 'email',
        },
        {
          validator: validateEmail,
        },
      ],
    },
    {
      fieldName: 'phone',
      ref: formInput_4,
      label: 'Phone Number',
      mask: '(999) 999-9999',
      rules: [
        {
          message: ' ',
          required: false,
        },
        {
          validator: validatePhone,
        },
      ],
    },
  ];

  const sections = [
    { sectionTitle: 'General Data', sectionTemplate: generalData },
  ];
  return (
    <div>
      <WrappedComponent {...props} sections={sections} />
    </div>
  );
};
