import React, { PureComponent } from 'react';
import { Layout } from 'antd';

import { getSortedData, openNotificationWithIcon } from '../../../utils';
import api from '../../../api';

import { Loader, DashboardContent } from '../../../components';
import DashboardsMenu from '../components/DashboardsMenu/DashboardsMenu';
import { Perfomance } from '../../../redux/pages';


import styles from './Dashboards.module.scss';
import CreateDashBoardModal from '../components/CreateDashBoardModal/CreateDashBoardModal';
import { AreYouWantToCancel, CustomModal } from '../../admin-pages/components';

class Dashboards extends PureComponent {
  state = {
    isLoading: false,
    createModal: {
      isCreate: true,
      isOpen: false,
    },
    isConfirmModalOpen: false,
    dataSets: [],
    jobs: [],
    dashboards: [],
    selectedDashboardId: 0,
    preSelectedDashboardId: 0,
    isAppliedNewFilters: false,
    typeModal: 'dashboard'
  };

  handleApplyNewFilters = (isInitial = false)  => this.setState({ isAppliedNewFilters: !isInitial })

  componentDidMount() {
    this.getDatasets();
    this.getDashBoards();
    // this.getJobs();
  }

  requestNotification = (message, success = true) => {
    const notificationSettings = {
      type: success ? 'success' : 'error',
      message: message,
    };
    openNotificationWithIcon(notificationSettings);
    this.setState({ isLoading: false });
  };

  getJobs() {
    !this.props.onlyView &&
    api.employees.getEmployeesJobs().then((res) => {
      this.setState({ jobs: res });
    });
  }

  getDatasets = () => {
    this.setState({ isLoading: true });
    api.employees
      .getDataSets()
      .then((res) => {
        const dataSets = res.reduce((total, item) => {
          if (item.isOwned) {
            total[0] = [item, ...(total[0] ?? [])];
          } else if (item.ownerRole !== 'USER') {
            total[1] = [item, ...(total[1] ?? [])];
          } else {
            total[2] = [item, ...(total[2] ?? [])];
          }
          return total;
        }, []);
        this.setState({
          dataSets: dataSets.reduce(
            (total, item) => [...total, ...item],
            []
          ) /*, isLoading: false*/,
        });
      })
      .catch((e) => {
        this.requestNotification(e.response.data.message, false);
      });
  };

  getDashBoards = (shouldSelectedId) => {
    this.setState({ isLoading: true });
    api.dashboards
      .getDashboards()
      .then((res) => {
        const dashboardsResult = res
          .reduce((total, item) => {
            if (item.isOwned) {
              total[0] = [item, ...(total[0] ?? [])];
            } else {
              total[1] = [item, ...(total[1] ?? [])];
            }
            return total;
          }, [])
          .reduce(
            (total, item) => [
              ...getSortedData({
                data: total,
                sortType: 'Alphabetical',
                sortDirection: 'upSort',
                sortColumn: 'name',
              }),
              ...getSortedData({
                data: item,
                sortType: 'Alphabetical',
                sortDirection: 'upSort',
                sortColumn: 'name',
              }),
            ],
            []
          );
        this.setState({
          dashboards: dashboardsResult,
          isLoading: false,
          selectedDashboardId:
            shouldSelectedId || dashboardsResult.filter(dashboard => !dashboard.succession)[0]?.id || dashboardsResult[0]?.id,
        });
      })
      .catch((e) => {
        this.requestNotification(e.response?.data?.message, false);
      });
  };

  createDashBoard = (params) => {
    api.dashboards
      .addDashboard(params)
      .then((res) => {
        this.setState({ createModal: { isCreate: true, isOpen: false }, isLoading: false });
        this.requestNotification('Dashboard was created successfully', true);
        this.getDashBoards(res.id);
      })
      .catch((e) => {
        this.requestNotification(e.response.data.message, false);
      });
  };

  updateDashboard = (params, withoutRefreshDashboards) => {
      const selectedDashboard =
          this.state.dashboards.find(({ id }) => id === this.state.selectedDashboardId) || {};
    api.dashboards
      .updateDashboard(withoutRefreshDashboards ? {...selectedDashboard, ...params} : params)
      .then((res) => {
          const copiedDashboards = [...this.state.dashboards];
          const newDashboards = copiedDashboards.map((dashboard) => {
              if(dashboard.id === this.state.selectedDashboardId) {
                return {...selectedDashboard, ...params}
              }
              return dashboard
          })
        this.setState({ isOpenModal: false, isLoading: false, dashboards: newDashboards});
        this.requestNotification('Dashboard was updated', true);
        !withoutRefreshDashboards && this.getDashBoards(res.id, withoutRefreshDashboards);
      })
      .catch((e) => {
        this.requestNotification(e.response.data.message, false);
      })
  }

  deleteDashboard = (id, callback) => {
    api.dashboards
      .deleteDashboard(id)
      .then(() => {
        this.requestNotification('Dashboard was deleted successfully', true);
        this.getDashBoards();
      })
      .catch((e) => {
        this.requestNotification(e.response.data.message, false);
      })
      .finally(callback)
  }

  onChangeDashboard = (selectedDashboardId) => {
    const {roles} = this.props;
    const shared = roles.includes("ACCOUNT OWNER SHARED");
    if (this.state.isAppliedNewFilters && (this.state.dashboards.find(({ id }) => id === this.state.selectedDashboardId)?.isOwned || shared)) {
      return this.setState({ preSelectedDashboardId: selectedDashboardId, isConfirmModalOpen: true, typeModal: 'dashboard'});
    }
    return this.setState({ selectedDashboardId, isAppliedNewFilters: false });
  };

  handleYes = () => {
    if (this.state.typeModal === 'dashboard'){
      this.setState(prev => ({
      selectedDashboardId: prev.preSelectedDashboardId,
      preSelectedDashboardId: 0,
      isConfirmModalOpen: false,
      isAppliedNewFilters: false
     }));
    } else {
    this.setState(prev => ({
      ...prev,
      isConfirmModalOpen: false,
      isAppliedNewFilters: false,
      createModal: { isCreate: false, isOpen: !prev.createModal.isOpen }
     }));
    }
  }

  handleNo = () => this.setState({ isConfirmModalOpen: false });

  toggleModal = (isCreate) =>{
    const {roles} = this.props;
    const shared = roles.includes("ACCOUNT OWNER SHARED");
    if (!this.state.createModal.isOpen && this.state.isAppliedNewFilters && (this.state.dashboards.find(({ id }) => id === this.state.selectedDashboardId)?.isOwned || shared)) {
      this.setState({ typeModal: 'copy', isConfirmModalOpen: true,});
    } else{
      this.setState(prev => ({
        ...prev,
        createModal: { isCreate: !!isCreate, isOpen: !prev.createModal.isOpen }
      }));
    }
}
  render() {
    const {
      isLoading,
      dataSets = [],
      jobs = [],
      dashboards,
      selectedDashboardId,
      createModal,
      isConfirmModalOpen
    } = this.state;
    const isAccountOwnerShared = this.props.roles.includes("ACCOUNT OWNER SHARED");
    const isAccountOwnerManager = this.props.roles.includes("ACCOUNT OWNER/MANAGER");
    const height = (isAccountOwnerShared || isAccountOwnerManager) ? '269px' : '185px';
    if (isLoading) return <Loader/>;

    const selectedDashboard =
      dashboards.find(({ id }) => id === selectedDashboardId) || {};

    return (
      <Layout className={styles.dashboards} style={{height: 'calc(100vh - ' + height + ')'}}>
        <DashboardsMenu
          dataSets={dataSets}
          dashboards={dashboards}
          onCreateDashboard={(params) => this.createDashBoard(params)}
          selectedId={selectedDashboardId}
          onChange={this.onChangeDashboard}
          toggleModal={this.toggleModal}
        />
        <Layout className={styles.layout}>
          {!dashboards.length ?
            <div className={styles.empty}>
              Looks like you don’t have any dashboards yet. Click the “Add New” button to create Dashboard
            </div>
            :
            <DashboardContent
              datasets={dataSets}
              jobs={jobs}
              selectedDashboard={selectedDashboard}
              onCreateDashboard={this.createDashBoard}
              updateDashboard={this.updateDashboard}
              toggleModal={this.toggleModal}
              handleApplyNewFilters={this.handleApplyNewFilters}
              deleteDashboard={this.deleteDashboard}
              isFiltersChanged={this.state.isAppliedNewFilters}
            />}
        </Layout>
        <CreateDashBoardModal
          isCopy={!createModal.isCreate}
          selectedDashboard={selectedDashboard}
          dataSets={dataSets}
          isOpen={createModal.isOpen}
          onCreate={this.createDashBoard}
          onClose={this.toggleModal}
        />
        <CustomModal visible={isConfirmModalOpen} onCancel={this.handleNo}>
          <AreYouWantToCancel
            title='You have unsaved changes. If you continue, you will lose your progress'
            onYes={this.handleYes}
            onNo={this.handleNo}
            yesText='Continue'
            noText='Cancel'
          />
        </CustomModal>
      </Layout>
    );
  }
}

export default Dashboards;
