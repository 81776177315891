import React from 'react';
import styles from './ShowBlocks.module.scss';
import { Checkbox } from 'antd';

const getCheckboxes = (blocks = {}, hideCognitive) => {
  const {
    behavioralPerformance,
    behavioralSuccession,
    cognitive,
    competencies,
    currentSkills,
    customSkills,
    employmentStatus,
    nineBox,
    organizationalData,
    performance,

  } = blocks;

  return [
    { label: 'BA Performance', value: !!behavioralPerformance, apiKey: 'behavioralPerformance', filterName: ['filterFactorComboMatch']},
    { label: 'BA Succession', value: !!behavioralSuccession, apiKey: 'behavioralSuccession', filterName: ['filterFactorComboMatch'] },
    !hideCognitive && { label: 'Cognitive (CA)', value: !!cognitive, apiKey: 'cognitive', filterName: ['filterCognitive'] },
    { label: 'Current Skills', value: !!currentSkills, apiKey: 'currentSkills', filterName: ['filterFirstScore', 'filterOverallScore'] },
    // TODO display this late { label: 'Custom Skills', value: !!customSkills, apiKey: 'customSkills' },
    { label: 'Performance', value: !!performance, apiKey: 'performance', filterName: ['filterRating'] },
    { label: 'Competencies', value: !!competencies, apiKey: 'competencies' },
    { label: '9 box', value: !!nineBox, apiKey: 'nineBox', filterName: ['filterReadiness', 'filterPerformance', 'filterPotential'] },
    { label: 'Emp. Status', value: !!employmentStatus, apiKey: 'employmentStatus', filterName: ['filterEmploymentsStatus']},
    { label: 'Organizational Data', value: !!organizationalData, apiKey: 'organizationalData', filterName: ['filterTenure', 'filterLocation'] },
  ].filter(Boolean)
};

export const ShowBlocks = ({ displayBlocks = {}, handleChange, hideCognitive }) => {
  const checkboxes = getCheckboxes(displayBlocks, hideCognitive);

  const onChange = (event, filterName) => {
    const { name, checked } = event.target;
    handleChange('displayBlocks', { ...displayBlocks, [name]: checked });
    if(!checked && filterName?.length) {
      filterName.forEach(item => handleChange(item, []));
    }
  }

  return (
    <>
      <span className={styles.itemTitle}>Show Blocks</span>
      <div className={styles.checkboxes}>
        {checkboxes.map(({ label, value, apiKey, filterName }) => (
          <Checkbox
            name={apiKey}
            key={apiKey}
            onChange={e => onChange(e, filterName)}
            checked={!!value}
            className={styles.checkbox}
          >
            <span>{label}</span>
          </Checkbox>
        ))}
      </div>
    </>
  )
};
